@use './../../Style.scss' as *;

.templateLibrary {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    height: 100%;

    .loading {
        width: 100%;
        height: 100%;
    }

    .sideMenu {
        flex-shrink: 0;
        width: 65px;
        height: 100%;
        background: var(--templateLibrary_bg);
    }

    .mainContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        height: 100%;
        padding-block: 16px 6px;
        padding-inline: 3px;
    }
}
