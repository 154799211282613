@use '../../../../Style.scss' as *;

.heading {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 52px;
    padding-block-end: 16px;
    padding-inline: 20px;

    .title {
        font-size: 16px;
        font-weight: 500;
        color: var(--activation_text);
    }

    .searchBox {
        position: relative;

        .searchInput {
            width: 205px;
            height: 36px;

            :global(.MuiOutlinedInput-root) {
                width: 100%;
                height: 100%;

                :global(.MuiOutlinedInput-input) {
                    font-size: 14px;
                    color: var(--activation_text);

                    width: 100%;
                    height: 100%;
                    padding-inline: 15px 37px;
                    box-shadow: none;
                }
            }
        }

        .icon {
            position: absolute;
            inset: 0 12px 0 auto;

            color: var(--activation_text);

            width: 20px;
            height: 20px;
            margin-block: auto;
        }
    }
}
