@use '../../Style.scss' as *;

.welcomeScreen {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    width: 100%;
    height: 100%;
    padding-block-start: 30px;

    .topWave {
        position: absolute;
        inset: 0 0 auto auto;
        z-index: -1;
    }

    .bottomWave {
        position: absolute;
        inset: auto auto 0 0;
        z-index: -1;
    }

    .logoContainer {
        width: 130px;

        .logo {
            width: 100%;
            height: 100%;
        }
    }

    .detailsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        width: 100%;
        height: max-content;

        .heading {
            font-family: var(--popin);
            font-size: 32px;
            font-weight: 600;
            text-align: center;
            color: var(--activation_text);
        }

        .details {
            font-family: var(--popin);
            font-size: 16px;
            text-align: center;
            color: var(--activation_text);

            .highlight {
                font-family: var(--popin);
                font-weight: 700;
            }
        }

        .videoContainer {
            position: relative;
            padding-block: 25px;
            cursor: pointer;

            .videoThumbnail {
                width: 600px;
                border-radius: 16px;
            }

            .overlay {
                position: absolute;
                inset: 0 0 0 0;
                border-radius: 50%;
                width: 80px;
                height: 80px;
                background: var(--background-white);
                transition: 0.2s all linear;
                @include displayFlex(row, center, center);
                margin: auto;
                opacity: 0.75;

                .playIcon {
                    font-size: 55px;
                    color: var(--activation_text);

                    margin: auto;
                }
            }

            &:hover {
                .overlay {
                    transform: scale(1.2);
                    opacity: 1;
                }
            }
        }

        .btn {
            font-weight: 500 !important;
            color: var(--textWhite) !important;
            text-transform: none !important;
            white-space: nowrap;
            line-height: 19px;
            width: max-content;
            height: 40px;
            padding-inline: 30px !important;
            padding-block-start: 7px !important;
            border: none !important;
            border-radius: 1000px !important;
            background: linear-gradient(90deg, var(--primary-color) 24.31%, #e963ff 105.79%);
            max-width: max-content !important;

            .icon {
                padding-inline-end: 7px;
            }
        }
    }
}
