.groupMembers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    overflow: auto;

    .noFreeMembers {
        font-size: 16px;
        line-height: 22px;
        color: var(--activation_text);
        text-align: center;

        max-width: 300px;
        padding-block: 30px;
    }

    .tableContainer {
        flex-shrink: 0;
        width: 100%;
        height: max-content;

        .table {
            .tableBody {
                .tableRow {
                    height: 40px;
                    cursor: pointer;

                    &:nth-of-type(odd) {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
                    }

                    .MuiTableCell-root {
                        padding: 0;
                        border: none !important;

                        &:nth-of-type(1) {
                            padding-inline: 15px;
                        }

                        &:nth-of-type(2) {
                            padding-inline-end: 15px;
                        }

                        &:nth-of-type(3) {
                            padding-inline-end: 20px;
                        }
                    }

                    .checkbox {
                        color: var(--text_gray);
                    }

                    .participant {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 12px;

                        width: max-content;
                        max-width: 350px;

                        .MuiAvatar-root {
                            font-size: 14px;

                            width: 28px;
                            height: 28px;
                        }

                        .participantName {
                            font-size: 16px;
                            color: var(--activation_text);
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            width: max-content;
                            max-width: 300px;
                            overflow: hidden;
                        }
                    }

                    .groupName {
                        font-size: 12px;
                        color: var(--textWhite);
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        width: max-content;
                        max-width: 150px;
                        margin-inline-start: auto;
                        padding-block: 4px 3px;
                        padding-inline: 10px;
                        border-radius: 100px;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .showAllToggle {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-block: 16px;
        padding-block: 2px;
        padding-inline: 15px;
        border-radius: 100px;
        background: rgba(99, 120, 255, 0.05);
        cursor: pointer;
        transition: all 150ms ease-in-out;

        &:hover {
            background: rgba(99, 120, 255, 0.15);
        }

        .text {
            font-size: 12px;
            color: var(--secondary_text);
        }

        .icon {
            color: var(--secondary_text);

            &.inverted {
                rotate: 180deg;
            }
        }
    }
}
