@use './../../../Style.scss' as *;

.modal {
    & > div:nth-of-type(1) {
        background: rgba(0, 0, 0, 0.85);
    }

    .popupBox {
        position: absolute;
        inset: 0 0 0 0;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 340px;
        // height: 274px;
        height: 236px;
        margin: auto;
        background: var(--background-white);
        border-radius: 16px;
        outline: 0;

        .csvUploadDetails {
            font-size: 14px;
            font-weight: 400;
            color: var(--text_color);
            text-align: center;

            padding: 20px 20px;
            padding-block-start: 24px;

            span {
                color: var(--parple);
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }

                a {
                    color: var(--parple);
                    cursor: pointer;
                }
            }

            a {
                color: var(--parple);
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        .csvInput {
            position: relative;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 300px;
            height: 82px;
            padding: 10px 16px;
            background: var(--csvUpload_grey);
            border-radius: 4px;

            .textContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 8px;
                position: relative;

                .text {
                    font-size: 12px;
                    font-weight: 400;
                    text-align: center;
                }

                .upload {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--parple);
                }
            }

            input {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            .uploadedContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                .fileName {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--activation_text);
                    display: inline-block;
                    width: 200px;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }
        }

        .error {
            font-size: 12px;
            font-weight: 400;
            color: #b3261e;

            height: 14px;
            margin-block-start: 4px;
            margin-inline-end: auto;
            margin-inline-start: 20px;
            margin-block-end: 0;
        }

        .btnContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;

            width: 100%;
            height: 56px;
            margin-block-start: 12px;
            padding: 0 28px;
            background: rgba(99, 120, 255, 0.08);

            :is(.importBtn, .cancelBtn) {
                font-size: 14px;
                font-weight: 500;
                text-transform: none;
                min-width: auto;
                // padding: 0;
            }

            .importBtn {
                color: var(--textWhite);
                width: auto;
                min-width: 83px;
                height: 32px;
                background-color: var(--parple);
            }

            .cancelBtn {
                color: var(--parple);
                background: transparent;
            }
        }

        .newDesign {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .csvInput {
                width: min-content;
                height: min-content;
                padding: 0;
                background: transparent;
            }

            .error {
                line-height: 15px;

                width: 73%;
                height: min-content;

                margin: auto;
                padding-block-start: 10px;
            }

            .csvUploadDetails {
                padding: 0;
                padding-block-start: 20px;
            }

            .btnContainer {
                justify-content: center;

                height: min-content;
                padding-block-start: 10px;
                background: transparent;
            }
        }
    }
}
