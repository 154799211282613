@import '../../Style.scss';

.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiModal-root .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
}

.MuiPopover-root .MuiBackdrop-root {
    background-color: transparent;
}

/* default styling. Nothing to do with freexing first row and column */
.pickname_main {
    width: 100%;
    padding-block-start: 20px;
    background: rgb(255, 255, 255);
    background: var(--gradientbg);
    // background: var(--lightbg);
    height: var(--activitycontentHeight);

    .pickNameContainer {
        // max-width: 960px;
        width: 100%;
        margin-inline: auto;
        margin-block: 0;
        padding: 0 8px;
    }

    .pick_head {
        width: 100%;
        text-align: center;

        p {
            font-family: var(--font-stack);
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: var(--activation_text);
        }
    }

    .pickname_wrap {
        width: 100%;
        overflow: hidden auto;
        margin-block-start: 12px;
        height: 426px;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .pickNumber_card {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px 12px;
            padding: 0 34px;
            padding-block-end: 12px;

            .single_point_btn {
                display: none;
            }

            .pickNumber_service {
                // margin: 6px;
                cursor: pointer;

                .flip-box {
                    background-color: transparent;
                    width: 172px;
                    height: 96px;

                    border-radius: 12px;
                    // perspective: 920px;

                    // .flip-box-back {
                    //     filter: blur(10px);
                    //     transition: 0ms all ease-out;
                    // }

                    // &.flip180 .flip-box-back {
                    //     filter: blur(0);
                    // }
                }

                .flip-box-inner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    transition: transform 0.4s;
                    transform-style: preserve-3d;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.no_transform {
                        transform-style: initial !important;
                        transform: initial !important;
                    }
                }

                .flip-box.flip180 .flip-box-inner {
                    transform: rotateY(180deg);
                }

                .flip-box-front,
                .flip-box-back {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    // border: 4px solid var(--box3);
                    // background: var(--box3);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                .flip-box-front {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                    // color: black;
                    border-radius: 12px;

                    // &.no_transform {

                    //     &>* {
                    //         transform: rotateY(180deg);
                    //     }
                    // }

                    h2 {
                        font-family: var(--popin);
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 40px;
                        color: var(--textWhite);
                    }
                }

                .flip-box-front img {
                    // height: 50px;
                    width: 52px;
                }

                .flip-box-back {
                    background-color: #ffffff;
                    color: #000;
                    transform: rotateY(180deg);
                    border-radius: 12px;
                    padding: 6px 12px;

                    &.no_transform {
                        transform: rotateY(0deg);

                        // &>* {
                        //     transform: rotateY(180deg);
                        // }
                    }

                    .MuiAvatar-root {
                        width: 44px;
                        height: 44px;
                    }

                    p {
                        display: inline-block;

                        font-family: var(--font-stack);
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--activation_text);

                        margin-block-start: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &.autoPickModal {
                            width: 130px;
                        }

                        &.pickNamesCardView {
                            width: 160px;
                        }

                        // &.modal_name_hide{
                        //     display: block;
                        // }
                        // &.modal_name_show{
                        //     display: none;
                        // }
                    }
                }
            }
        }
    }
}

.pickWheel_sec {
    width: 100%;
    display: flex;
    align-items: flex-start;

    .pick_head {
        width: 100%;
        text-align: center;
        -webkit-transition: var(--transition);
        -moz-transition: var(--transition);
        -o-transition: var(--transition);
        transition: var(--transition);

        p {
            font-family: var(--font-stack);
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: var(--activation_text);
        }
    }

    .pickwheel_left {
        width: auto;
        flex: 1;

        .wheel_name {
            position: relative;
            text-align: center;

            width: 100%;
            margin-block-start: 0px;

            p {
                position: absolute;
                inset: auto 0 47% 0;
                z-index: 1;

                font-size: 16px;
                font-weight: 400;
                color: var(--greytext_color);
            }

            // .mainbox{
            //     position: relative;
            //     width: 350px;
            //     height: 350px;
            //     margin: 24px auto 0;
            //   }
            //   .mainbox:after{
            //     position: absolute;
            //     content: '';
            //     width: 32px;
            //     height: 32px;
            //     // background: url('arrow-left.png') no-repeat;
            //     background-size: 32px;
            //     right: -30px;
            //     top: 50%;
            //     transform: translateY(-50%);
            //   }
            //   .box{
            //     width: 100%;
            //     height: 100%;
            //     position: relative;
            //     font-weight: bold;
            //     border-radius: 50%;
            //     border: 10px solid #fff;
            //     overflow: hidden;
            //     transition: all ease 5s;
            //   }
            //   span{
            //     width: 50%;
            //     height: 50%;
            //     display: inline-block;
            //     position: absolute;
            //   }
            //   .span1{
            //     clip-path: polygon(0 92%, 100% 50%, 0 8%);
            //     background-color: #FFC700;
            //     top: 82px;
            //     left: 0;
            //   }
            //   .span2{
            //     clip-path: polygon(100% 92%, 0 50%, 100% 8%);
            //     background-color: #EF476F;
            //     top:  82px;
            //     right: 0;
            //   }
            //   .span3{
            //     clip-path: polygon(50% 0%, 8% 100%, 92% 100%);
            //     background-color: #ffaa00;
            //     bottom: 0;
            //     left:  82px;
            //   }
            //   .span4{
            //     clip-path: polygon(50% 100%, 92% 0, 8% 0);
            //     background-color: #22ff00;
            //     top: 0;
            //     left:  82px;
            //   }
            //   .box1 .span3 b{
            //     transform: translate(-50%, -50%) rotate(-270deg);
            //  }
            //  .box1 .span1 b,
            //  .box2 .span1 b{
            //    transform: translate(-50%, -50%) rotate(185deg);
            //  }
            //  .box2 .span3 b{
            //    transform: translate(-50%, -50%) rotate(90deg);
            //  }
            //  .box1 .span4 b,
            //  .box2 .span4 b{
            //    transform: translate(-50%, -50%) rotate(-85deg);
            //  }

            //  span b{
            //    font-size: 1em;
            //    color: var(--textWhite);
            //    position: absolute;
            //    top: 50%;
            //    left: 50%;
            //    transform: translate(-50%, -50%);

            //  }

            //  .box2{
            //    width: 100%;
            //    height: 100%;
            //    transform: rotate(-135deg);
            //  }
        }
    }

    .pickwheel_right {
        width: 250px;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: relative;

        .pick_head {
            p {
                text-align: start;
                padding-inline-start: 40px;
                white-space: nowrap;
                word-break: keep-all;
            }
        }

        &.close {
            width: 0;
            right: 0;

            .pickwheel_name .pickname_arrow {
                transform: rotate(180deg);
                right: 20px;
            }

            .pick_head {
                width: 0;
                opacity: 0;

                p {
                    white-space: nowrap;
                    word-break: keep-all;
                }
            }

            .select_name {
                width: 0;
            }

            .pickwheel_name {
                border: none;
            }
        }

        .pickwheel_name {
            margin-block-start: 24px;
            width: 100%;
            height: 380px;
            // overflow: auto;
            border-inline-start: 1px solid var(--light-bg);
            position: relative;

            .pickname_arrow {
                -webkit-transition: 0.3s ease-in-out;
                -moz-transition: 0.3s ease-in-out;
                -o-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                position: absolute;
                top: calc(50% - 32px);
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid var(--light-bg);
                right: 233px;
                background-color: var(--background-white);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 11;
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                svg {
                    color: var(--activation_text);
                    font-size: 16px;
                    display: flex;
                }
            }

            .select_name {
                width: 100%;
                padding-inline-start: 40px;
                height: 100%;
                overflow: hidden auto;
                -webkit-transition: 0.3s ease-in-out;
                -moz-transition: 0.3s ease-in-out;
                -o-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;

                .MuiChip-root {
                    width: fit-content;
                    background: var(--background-white);
                    // border: 2px solid #ef476f;

                    .MuiAvatar-root {
                        margin-inline-start: 3px;
                        width: 24px;
                        height: 24px;
                        font-size: 10px;
                        line-height: 17px;
                    }

                    .MuiChip-label {
                        font-family: var(--font-stack);
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--text_color);
                        padding-inline-start: 8px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 150px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

// flipcard_modal
:is(.flipcard_modal, .autoCreateGroup) {
    .MuiBox-root {
        position: absolute;
        top: 0%;
        left: 0%;
        bottom: 0;
        right: 0;
        height: fit-content;
        margin: auto;
        // transform: translate(-50%, -50%);
        width: auto;
        border: none !important;
        outline: none !important;
        background: none;
        border-radius: 12px;
        padding: 0;
        width: fit-content;
        min-width: 320px;
        max-width: 780px;
    }

    .close_modal {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        // border-radius: 50%;
        // background: ;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // opacity: 0.8;
        z-index: 0;

        &:hover {
            opacity: 1;
        }
    }

    .auto_pick_number {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .MuiIconButton-root {
            font-size: 1.5rem;
            color: var(--parple);

            padding: 8px;
            border-radius: 50%;
            border: 1px solid var(--parple);

            &:hover {
                background: var(--parple);
                color: var(--textWhite);
            }

            &.Mui-disabled {
                opacity: 0.4;
            }
        }

        .number {
            h3 {
                font-family: var(--popin);
                font-weight: 600;
                font-size: 60px;
                line-height: 60px;
                color: var(--activation_text);
            }
        }
    }

    .pickNumber_service {
        margin: 6px;
        cursor: pointer;

        .flip-box {
            background-color: transparent;
            width: 321px;
            height: 204px;
            border-radius: 12px;
        }

        .flip-box-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            // transition: transform 0.8s;
            // transform-style: preserve-3d;
            // transform: translateZ(0);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        // .flip-box.flip180 .flip-box-inner {
        //     transform: rotateY(180deg);
        // }

        .flip-box-front,
        .flip-box-back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            // transform: translateZ(0);
            background: var(--box3);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .flip-box-front {
            background: var(--box3);
            // color: black;
            border-radius: 12px;

            h2 {
                font-family: var(--popin);
                font-weight: 600;
                font-size: 96px;
                line-height: 96px;
                color: var(--textWhite);
            }
        }

        .flip-box-front img {
            height: 50px;
            width: 50px;
        }

        .flip-box-back {
            background-color: var(--background-white);
            color: #000;
            // transform: rotateY(180deg);
            border-radius: 12px;
            padding: 0px;
            align-items: flex-start;
            justify-content: flex-start;

            .flip_head {
                background: var(--box3);
                width: 100%;
                text-align: center;
                border-radius: 0;
                border-start-start-radius: 12px;
                border-start-end-radius: 12px;
                padding: 12px;

                h4 {
                    font-family: var(--font-stack);
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 20px;
                    color: var(--textWhite);
                }
            }

            .flip_body {
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: center;
                margin-block-start: 20px;
                width: 100%;
                height: 100%;

                // position: relative;

                & > img {
                    margin-block-start: 24px;
                }

                .pickname_star {
                    // position: absolute;
                    // bottom: 8px;
                    // right: 8px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;

                    width: 100%;
                    padding-inline-start: 16px;
                    padding-inline-end: 7.76px;
                    padding-block-start: 10px;
                    padding-block-end: 8px;

                    .starbtn {
                        gap: 2px;
                        min-width: 32px;
                        min-height: 32px;
                        border-radius: 36px;
                        padding: 6px;

                        img {
                            width: 20px;
                            height: 20px;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                }

                & > img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }

            .MuiAvatar-root {
                width: 60px;
                height: 60px;
            }

            p {
                display: inline-block;

                font-family: var(--font-stack);
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: var(--activation_text);
                margin-block-start: 5px;

                &.profile_name {
                    width: 315px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

// .flipcard_modal or autopick
.backToWheel {
    font-size: 14px;
    font-weight: 500;
    color: var(--backgroundcolor);
    cursor: pointer;
}

:is(.flipcard_modal, .autoCreateGroup) {
    .auto_pick_modal {
        min-width: 320px;
        width: auto;
        max-width: 780px;
        background: var(--background-white);
        border-radius: 12px;

        .modal_head {
            background: var(--parple_08);
            width: 100%;
            text-align: center;
            border-radius: 0;
            border-start-start-radius: 12px;
            border-start-end-radius: 12px;
            padding: 12px;

            h4 {
                font-family: var(--font-stack);
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: var(--parple);
            }
        }

        .auto_pick_modal_body {
            width: 100%;
            display: flex;
            align-items: center;
            padding-inline: 40px;
            padding-block-start: 24px;
            padding-block-end: 0px;

            .pick_name_card_wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .pickNumber_card {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;

                .pickNumber_service {
                    margin: 10px 8px;
                    cursor: pointer;

                    .flip-box {
                        background-color: transparent;
                        width: 124px;
                        height: 102.5px;
                        border-radius: 12px;
                        perspective: 720px;
                    }

                    .flip-box-inner {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        transition: transform 0.8s;
                        transform-style: preserve-3d;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.no_transform {
                            transform-style: initial !important;
                            transform: initial !important;
                        }
                    }

                    .flip-box.flip180 .flip-box-inner {
                        transform: rotateY(180deg);
                    }

                    .flip-box-front,
                    .flip-box-back {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                        // border: 4px solid var(--box3);
                        // background: var(--box3);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }

                    .flip-box-front {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                        // color: black;
                        border-radius: 12px;

                        h2 {
                            font-family: var(--popin);
                            font-weight: 600;
                            font-size: 40px;
                            line-height: 40px;
                            color: var(--textWhite);

                            svg {
                                font-size: 48px;
                            }
                        }
                    }

                    .flip-box-front img {
                        height: 50px;
                        width: 50px;
                    }

                    .flip-box-back {
                        background-color: #ffffff;
                        color: #000;
                        transform: rotateY(180deg);
                        border-radius: 12px;
                        padding: 3px;

                        &.no_transform {
                            transform: initial;
                        }

                        // .modal_name_hide{
                        //     display: none;
                        // }
                        // .modal_name_show{
                        //     display: block;
                        // }
                        &:hover {
                            border-radius: 12px 12px 18px 18px;
                            border: 4px solid var(--parple) !important;

                            .single_point_btn {
                                opacity: 1;
                                display: block;
                            }

                            & > p {
                                opacity: 0;
                            }
                        }

                        .single_point_btn {
                            position: absolute;
                            opacity: 0;
                            display: none;
                            bottom: -2px;
                            left: 0;
                            width: 100%;
                            z-index: 11;

                            .button_wraper {
                                width: 100%;

                                .starbtn.big {
                                    padding: 4px 10px;
                                    min-width: 100%;
                                    min-height: 28px;

                                    p {
                                        font-size: 14px;
                                        color: var(--textWhite);
                                        margin: 0;
                                        margin-inline-start: 5px;
                                    }
                                }

                                .staricon_box {
                                    img {
                                        width: 16px;
                                    }
                                }

                                .point_box2 {
                                    left: 5px;

                                    p {
                                        color: var(--textWhite);
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        .MuiAvatar-root {
                            width: 44px;
                            height: 44px;
                        }

                        p {
                            display: inline-block;

                            font-family: var(--font-stack);
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 20px;
                            color: var(--activation_text);

                            margin-block-start: 5px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            &.autoPickModal {
                                width: 110px;
                            }
                        }
                    }
                }
            }
        }

        .auto_pick_modal_foot {
            width: 100%;
            padding: 20px;
            text-align: center;

            .auto_pick_foot_btn {
                @include autopick;
                background: var(--parple);

                &:hover {
                    background: var(--btnhover);
                }

                &.Mui-disabled {
                    background: var(--light-bg);
                }
            }

            .button_wraper {
                margin: 0 auto;
            }

            // .starbtn{
            //     margin: 0 auto;
            //     &.big{
            //         padding: 4px 20px;
            //         border-radius: 40px;
            //         span{
            //             font-size: 16px;
            //             font-weight: 500;
            //             line-height: 16px;
            //         }
            //         img{
            //             width: 32px;
            //         }
            //     }
            // }
            .backToWheel {
                margin-block: 12px 10px;
                line-height: 20px;
                display: block;
            }
        }
    }
}
