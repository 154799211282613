@use '../../Style.scss' as *;

.chatBot_sec {
    width: 100%;
    height: 100%;
    background: var(--clr_grad_bg_02);

    .alternateFlowBtn {
        position: absolute;
        inset: auto 10px 10px auto;
        cursor: pointer;
    }
}

// chat_bot_Welcome_sec
.chat_bot_Welcome_sec {
    width: 100%;
    height: 100%;

    .new_Welcome_inner_sec {
        position: relative;
        width: 100%;
        height: 100%;
        @include displayFlex(column, center, center);

        .new_welcome_content {
            width: 100%;
            margin-inline: auto;
            text-align: center;

            .icon {
                width: 120px;
                text-align: center;
                margin-block-end: 40px;
                margin-inline: auto;

                img {
                    width: 100%;
                }
            }

            .head_sec {
                max-width: 500px;
                margin-inline: auto;
                margin-block-start: 200px;

                .title {
                    font-size: 24px;
                    font-weight: 500;
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                }

                .description {
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    margin-block-start: 20px;
                }
            }
        }
        .info_icon {
            position: absolute;
            right: 22px;
            top: 22px;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            svg {
                color: var(--secondary_text);
            }
        }

        .navigation_grid_box {
            margin-block-start: 48px;

            .ai_btn_group {
                @include displayFlex(column, center, center);
                gap: 10px;

                .blue_btn {
                    @include chatbot_btn;
                    min-height: 45px;
                    padding-inline: 32px;
                }

                .option_btn {
                    @include chatbot_default_btn;
                    background: none !important;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// random_Qa_sec
.random_Qa_sec {
    width: 100%;
    height: 100%;

    .inner_random_Qa {
        width: 100%;
        height: 100%;
        @include displayFlex(column, flex-start, flex-start);
        gap: 12px;
        max-width: 800px;
        margin-inline: auto;
        padding-block-start: 80px;

        .level_chip_wrapper {
            position: absolute;
            top: 10px;
            right: 12px;

            .level_chip {
                @include displayFlex(row, center, center);
                min-height: 32px;
                padding: 6px 18px;
                // border: 2px solid var(--bar_color_green);
                border-radius: 40px;
                color: var(--activation_text);
                font-size: 14px;
                line-height: 19px;
                gap: 6px;
                background: var(--lightDeepbg3);

                // &.Remember {
                //     border: 2px solid var(--bar_color_green);
                //     color: var(--bar_color_green);
                // }

                // &.Understand {
                //     border: 2px solid var(--bg_light_orange);
                //     color: var(--bg_light_orange);
                // }

                // &.Apply {
                //     border: 2px solid var(--bar_color_blue);
                //     color: var(--bar_color_blue);
                // }

                // &.Analyze {
                //     border: 2px solid var(--bg_red);
                //     color: var(--bg_red);
                // }

                // &.Evaluate {
                //     border: 2px solid var(--bg_deep_purple);
                //     color: var(--bg_deep_purple);
                // }

                // &.Create {
                //     border: 2px solid var(--box3);
                //     color: var(--box3);
                // }
            }
        }

        .nav_bar {
            width: 100%;
            @include displayFlex(row, center, flex-end);
            gap: 16px;
            max-width: 720px;
            margin-inline: auto;

            :is(.previous, .forward) {
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--textWhite);
                width: 32px;
                height: 32px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                cursor: pointer;

                &.disable {
                    opacity: 0.5;
                    cursor: default;
                }

                .icon {
                    font-size: 20px;
                    color: var(--secondary_text);
                }
            }

            .slideCount {
                color: var(--secondary_text);
            }

            &.alternative_nav_bar {
                position: absolute;
                max-width: fit-content;
                inset: auto 0 -18px 0;
                border: 3px solid transparent;
                background: linear-gradient(90deg, var(--background-white), var(--background-white)),
                    linear-gradient(90deg, rgb(99 120 255 / 100%), rgb(0 194 255 / 100%));
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                border-radius: 40px;
                isolation: isolate;

                :is(.previous, .forward) {
                    border: none;
                    min-height: 32px;
                    min-width: 32px;

                    &:hover {
                        background: var(--cpButton_18);
                    }

                    &.disable {
                        &:hover {
                            background: none;
                        }
                    }
                }

                .MuiTouchRipple-root {
                    z-index: 1;

                    .MuiTouchRipple-child {
                        background-color: var(--RippleBg_color2);
                    }
                }
            }
        }

        .use_Qa_group {
            width: 100%;
            max-width: 720px;
            margin-inline: auto;
            @include displayFlex(column, flex-start, flex-start);
            gap: 18px;

            &.alternate_use_Qa_group {
                margin-block-start: 60px;
            }

            .heading {
                width: 100%;

                .tilte {
                    font-size: 16px;
                    line-height: 25px;
                    font-weight: 400;
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                }
            }

            .btn_group {
                width: 100%;
                @include displayFlex(row, center, flex-start);
                gap: 24px;

                .btnContainer {
                    width: 100%;
                }

                .use_QA_btn {
                    flex: 1;
                    @include chatbot_btn;
                    width: 100%;
                    background: var(--background-white);
                    border: 1px solid var(--borderMain);
                    color: var(--activation_text);

                    &.check_answer {
                        color: var(--textWhite);
                        background: var(--icon_green);
                        border-color: var(--icon_green);

                        &:hover {
                            background: var(--bg_deep_green);
                            border-color: var(--bg_deep_green);
                        }
                    }

                    &.notClickable {
                        opacity: 1;
                    }

                    &.saveSlide {
                        color: var(--textWhite);
                        background: var(--cpbutton);
                        border: 1px solid var(--cpbutton);

                        &:hover {
                            background: var(--btnhover);
                            border: 1px solid var(--btnhover);
                        }
                    }

                    &:hover {
                        color: var(--textWhite);
                    }

                    &.active {
                        color: var(--textWhite);
                        background: var(--btnhover);
                        border: 1px solid var(--btnhover);
                    }

                    &.animating {
                        background: var(--btnhover);
                        border: 1px solid var(--btnhover);
                        opacity: 1;
                        cursor: default;
                        pointer-events: none;
                    }

                    &.saved {
                        color: var(--textWhite);
                        background: var(--cpbutton);
                        border: 1px solid var(--cpbutton);
                        cursor: default;
                        pointer-events: none;
                    }

                    &.generateQuestionBtn {
                        color: var(--textWhite);
                        background: var(--bg_orange);
                        border: 1px solid var(--bg_orange);

                        &:hover {
                            background: var(--bg_deep_orange);
                            border: 1px solid var(--bg_deep_orange);
                        }

                        &.Mui-disabled {
                            color: var(--textWhite);
                        }
                    }
                }
            }
        }

        .chat_bot_sec_wrapper {
            // min-height: 420px;
            width: 100%;
        }
    }
}

// chat_bot_area
.chat_bot_area {
    width: 100%;
    max-width: 720px;
    margin-inline: auto;
    position: relative;

    .questionAreaContainer {
        border-radius: 24px;

        .question_area {
            background: none !important;
        }
    }

    .question_area {
        @include displayFlex(column, flex-start, flex-start);
        background: var(--background-white);
        border: 1px solid var(--borderMain);
        border-radius: 24px;
        padding-inline: 66px 8px;
        padding-block: 30px;
        min-height: 128px;
        // max-height: 356px;
        width: 100%;

        &.alternateFlow {
            border: 3px solid rgb(0 194 255 / 50%);
            background: linear-gradient(90deg, var(--background-white), var(--background-white)),
                linear-gradient(90deg, rgb(99 120 255 / 100%), rgb(0 194 255 / 100%)) !important;
            background-clip: padding-box, border-box !important;
            background-origin: padding-box, border-box !important;
            padding-inline-start: 8px;
            padding-block-start: 50px;

            .innerQuestion_area {
                padding-inline: 58px;
                padding-block-end: 45px;

                .option {
                    padding-block: 1px;
                }
            }
        }

        &.hideCursor {
            .Typewriter {
                .Typewriter__cursor {
                    display: none;
                }
            }
        }

        &.mouseLeaveAnimation {
            overflow: auto;
            max-height: 300px;
        }

        .innerQuestion_area {
            overflow: auto;
            max-height: 320px;
            width: 100%;
            padding-inline-end: 58px;
        }

        &.single_participant {
            .question_area_response_content {
                width: 100%;
                overflow: auto;
                max-height: 380px;
                padding-inline-end: 56px;
            }
        }

        .Typewriter {
            display: inline-block;
            width: 100%;

            .Typewriter__wrapper {
                font-size: 20px;
                font-weight: 400;
                color: var(--activation_text);
                line-height: 27px;
            }

            .Typewriter__cursor {
                color: transparent;
            }
        }

        & > * {
            font-family: var(--font-stack);
        }

        .option {
            font-size: 18px;
            line-height: 27px;
            font-weight: 400;
            color: var(--activation_text);
            margin-block-start: 5px;
            display: inline-block;
            position: relative;
            padding-block: 6px;
            // padding-inline: 15px;

            transition: 0.1s all ease-out;

            .tickLottie {
                position: absolute;
                top: -35px;
                left: -80px;
                width: 100px;
                opacity: 0;
            }

            &.fullWidth {
                width: 100%;
                // padding-block: 5px;
                // padding-inline: 15px;
                border-radius: 5px;
            }

            // &:not(.showAnswer) {
            //     &:hover {
            //         background: rgba(99, 120, 255, 0.05);
            //     }

            //     &.fullWidth {
            //         cursor: pointer;
            //     }
            // }

            &:nth-last-of-type(1) {
                + br {
                    display: none;
                }
            }

            &.selected {
                // padding-block: 5px;
                background: rgba(99, 120, 255, 0.14);
            }

            &.correct {
                // padding-block: 5px;
                // background: var(--correct);

                .tickLottie {
                    opacity: 1;
                }
            }

            &.wrong {
                // padding-block: 5px;
                background: var(--wrong);
            }
        }

        .border_line {
            width: 100%;
            height: 1px;
            border: 1px solid var(--borderMain);
            margin-block: 20px 15px;
            display: block;
        }

        .correct_ans {
            color: var(--green_btn_text);
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            @include displayFlex(row, flex-start, flex-start);
            gap: 5px;
            margin-block-start: 35px;

            .answer {
                font-weight: 400;

                .bold {
                    font-weight: 500;
                }
            }

            .icon {
                display: flex;
                margin-block-start: 2px;
            }

            .Typewriter__wrapper {
                color: var(--green_btn_text);
                font-size: 18px;
                line-height: 28px;
            }

            .bold {
                font-weight: 500;
            }
        }

        .short_answer {
            color: var(--green_btn_text);
            font-size: 18px;
            line-height: 28px;
            // margin-block-start: 6px;

            .Typewriter__wrapper {
                color: var(--green_btn_text);
                font-size: 18px;
                line-height: 28px;
            }
        }

        .Typewriter__cursor {
            background: var(--background_black);
        }

        .contentWrapper {
            width: 100%;

            .question_section {
                font-size: 20px;
                font-weight: 400;
                color: var(--activation_text);
                line-height: 27px;
            }
        }
    }

    .ai_icon {
        position: absolute;
        top: 0;
        left: 0%;
        transform: translateX(-50%);
        width: 80px;

        img {
            max-width: 100%;
        }
    }

    .refresh_icon {
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(50%, -50%);
        @include displayFlex(column, center, center);
        background: var(--textWhite);
        width: 44px;
        height: 44px;
        border: 1px solid var(--borderMain);
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background: var(--light-bg);
        }
    }
}

// participent_picker_sec
.participant_picker_sec {
    width: 100%;
    height: 100%;

    .inner_participant_picker {
        width: 100%;
        height: 100%;
        padding: 40px 60px;
        @include displayFlex(row, flex-start, flex-start);

        .participant_content {
            width: 100%;
            @include displayFlex(row, flex-start, flex-start);
            gap: 66px;
            margin-block-start: 40px;

            .card_wrapper {
                width: 200px;

                .profile_wrapper {
                    background: #fff;
                    width: 200px;
                    height: 200px;
                    border: 1px solid #cfd5ff;
                    box-sizing: border-box;
                    border-radius: 12px;
                    @include displayFlex(column, center, center);
                    gap: 8px;
                    position: relative;
                    cursor: pointer;
                    padding: 18px 24px;
                    margin-block-start: 40px;

                    .profile {
                        @include displayFlex(column, center, center);
                        position: relative;

                        & .avatar_image {
                            position: relative;
                            z-index: 100;
                        }

                        .MuiAvatar-root {
                            width: 100px;
                            height: 100px;
                            font-size: 34px;
                            font-weight: 500;
                            line-height: 39px;

                            svg {
                                font-size: 30px;
                            }

                            img {
                                width: 100px;
                                height: 100px;
                            }
                        }

                        span {
                            font-family: var(--font-stack);
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 29px;
                            color: var(--activation_text);
                        }

                        & .status {
                            width: 20px;
                            height: 20px;
                            background-color: var(--offlineClass);
                            border: 4px solid var(--borderWhite);
                            border-radius: 50%;
                            position: absolute;
                            right: 4px;
                            top: 4px;
                            z-index: 100;

                            &.online {
                                background-color: var(--onlineClass);
                            }
                        }
                    }

                    .point_show_box {
                        @include displayFlex(column, center, flex-start);
                        width: 100%;
                        gap: 12px;

                        .name {
                            font-size: 16px;
                            line-height: 19px;
                            font-family: var(--font-stack);
                            color: var(--text_color);
                            font-weight: 500;
                        }

                        .point_count_box {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            img {
                                height: 20px;
                            }

                            p {
                                font-size: 16px;
                                line-height: 19px;
                                font-family: var(--font-stack);
                                color: var(--text_color);
                                font-weight: 500;
                            }
                        }
                    }
                }

                .info_answer {
                    width: 100%;
                    margin-block-start: 24px;
                    text-align: center;

                    .correct_ans {
                        font-size: 20px;
                        line-height: 27px;
                        font-weight: 500;
                        color: var(--certified);
                        font-family: var(--font-stack);
                    }

                    .wrong_ans {
                        font-size: 20px;
                        line-height: 27px;
                        font-weight: 500;
                        color: var(--danger);
                        font-family: var(--font-stack);
                    }

                    .details {
                        font-size: 16px;
                        line-height: 21px;
                        font-weight: 400;
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        margin-block-start: 6px;
                    }
                }
            }

            .chat_bot_area {
                flex: 1;

                .question_area {
                    padding-block: 40px;
                }

                .question_area_response_content {
                    width: 100%;
                    max-width: 590px;
                    margin-inline: auto;

                    .qa_heading {
                        width: 100%;
                        border-bottom: 1px solid var(--parple);
                        padding-block: 0px 20px;

                        .title {
                            font-size: 20px;
                            line-height: 29px;
                            font-weight: 400;
                            color: var(--activation_text);
                        }
                    }

                    .option_sec {
                        width: 100%;
                        padding-block-start: 8px;

                        .option_btn {
                            padding-inline: 40px 16px;
                            padding-block: 12px;
                            position: relative;
                            border-radius: 12px;
                            transition: 0.1s all linear;

                            &:not(.submissionClosed) {
                                cursor: pointer;

                                &:hover {
                                    background: rgba(99, 120, 255, 0.05);
                                }
                            }

                            &::after {
                                content: attr(data-Alphabet);
                                position: absolute;
                                top: 12px;
                                left: 15px;
                                font-size: 18px;
                                line-height: 27px;
                                font-weight: 400;
                                color: var(--activation_text);
                            }

                            &.selected {
                                // background: var(--selected);
                                background: rgba(99, 120, 255, 0.14);
                            }

                            &.correct {
                                background: var(--correct);
                            }

                            &.wrong {
                                background: var(--wrong);
                            }
                        }

                        .option_list {
                            font-size: 18px;
                            line-height: 27px;
                            font-weight: 400;
                            color: var(--activation_text);
                        }
                    }
                }
            }

            .btn_group {
                width: auto;
                margin-inline: auto;
                @include displayFlex(row, center, center);
                margin-block-start: 30px;
                gap: 20px;

                .btn {
                    @include chatbot_btn;
                    min-width: 210px;

                    &.outline {
                        color: var(--parple);
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
                        border: 1px solid var(--borderMain);
                    }

                    &.animating {
                        opacity: 1;
                        cursor: default;
                        pointer-events: none;
                    }

                    &.saved {
                        color: var(--textWhite);
                        background: var(--icon_green);
                        border-color: var(--icon_green);
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

// free_credits
.free_credits {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--discount_bg);
    text-align: center;
    min-height: 40px;
    cursor: pointer;
    @include displayFlex(row, center, center);

    .text {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: var(--text_color);
        @include displayFlex(row, center, center);
        gap: 8px;
    }
}

// .selected_participant_wrapper {
//     width: 100%;
//     height: 100%;
//     margin-inline: auto;
//     @include displayFlex(row, center, center);

//     .card_wraper {
//         width: 100%;
//         max-width: 780px;
//         margin-inline: auto;
//         width: fit-content;
//         @include displayFlex(row, center, flex-start);
//         gap: 20px;
//         flex-wrap: wrap;

//         &.size_one {
//             gap: 30px;

//             .profile_wrapper {
//                 width: 210px;
//                 height: 180px;

//                 .levelBadge {
//                     width: 46px;
//                 }

//                 .profile {
//                     .MuiAvatar-root {
//                         width: 78px;
//                         height: 78px;
//                         font-size: 38px;
//                         line-height: 49px;

//                         svg {
//                             font-size: 24px;
//                         }

//                         img {
//                             width: 78px;
//                             height: 78px;
//                         }
//                     }

//                     .status {
//                         width: 20px;
//                         height: 20px;
//                         border: 3px solid var(--borderWhite);
//                     }
//                 }

//                 .small_card_box {
//                     .name {
//                         font-size: 21px;
//                         line-height: 29px;
//                     }
//                 }

//                 .point_show_box {
//                     .point_count_box {
//                         img {
//                             height: 27px;
//                         }

//                         p {
//                             font-size: 21px;
//                             line-height: 29px;
//                         }
//                     }
//                 }
//             }
//         }

//         &.size_two {
//             gap: 25px;

//             .profile_wrapper {
//                 width: 175px;
//                 height: 150px;

//                 .levelBadge {
//                     width: 38px;
//                 }

//                 .profile {
//                     .MuiAvatar-root {
//                         width: 65px;
//                         height: 65px;
//                         font-size: 28px;
//                         line-height: 39px;

//                         svg {
//                             font-size: 24px;
//                         }

//                         img {
//                             width: 65px;
//                             height: 64px;
//                         }
//                     }

//                     .status {
//                         width: 17px;
//                         height: 17px;
//                         border: 3px solid var(--borderWhite);
//                     }
//                 }

//                 .small_card_box {
//                     .name {
//                         font-size: 18px;
//                         line-height: 25px;
//                     }
//                 }

//                 .point_show_box {
//                     .point_count_box {
//                         img {
//                             height: 22px;
//                         }

//                         p {
//                             font-size: 18px;
//                             line-height: 25px;
//                         }
//                     }
//                 }
//             }
//         }

//         .profile_wrapper {
//             background: var(--background-white);
//             width: 140px;
//             height: 120px;
//             border: 1px solid var(--borderMain);
//             box-sizing: border-box;
//             border-radius: 12px;
//             @include displayFlex(column, center, center);
//             gap: 8px;
//             position: relative;
//             cursor: pointer;
//             padding: 18px 24px;
//             transition: all 0.12s ease-in;
//             z-index: 10;

//             &.active {
//                 transform: scale(1.5);
//                 z-index: 11;

//                 &:before {
//                     content: '';
//                     z-index: -1;
//                     position: absolute;
//                     top: 0;
//                     right: 0;
//                     bottom: 0;
//                     left: 0;
//                     background: linear-gradient(-45deg, #ff7aff 0%, var(--parple) 100%);
//                     filter: blur(4px);
//                     opacity: 1;
//                     transition: opacity 0.3s;
//                     border-radius: inherit;
//                 }

//                 &::after {
//                     content: '';
//                     z-index: -1;
//                     position: absolute;
//                     top: 0;
//                     right: 0;
//                     bottom: 0;
//                     left: 0;
//                     background: inherit;
//                     border-radius: inherit;
//                 }
//             }

//             .levelBadge {
//                 position: absolute;
//                 top: 11px;
//                 left: 9px;
//                 width: 31px;
//             }

//             .profile {
//                 @include displayFlex(column, center, center);
//                 position: relative;

//                 & .avatar_image {
//                     position: relative;
//                     z-index: 100;
//                 }

//                 .MuiAvatar-root {
//                     width: 52px;
//                     height: 52px;
//                     font-size: 24px;
//                     font-weight: 500;
//                     line-height: 39px;

//                     svg {
//                         font-size: 24px;
//                     }

//                     img {
//                         width: 52px;
//                         height: 52px;
//                     }
//                 }

//                 & .status {
//                     width: 14px;
//                     height: 14px;
//                     background-color: var(--offlineClass);
//                     border: 3px solid var(--borderWhite);
//                     border-radius: 50%;
//                     position: absolute;
//                     right: 0px;
//                     top: 0px;
//                     z-index: 100;

//                     &.online {
//                         background-color: var(--onlineClass);
//                     }
//                 }
//             }

//             .small_card_box {
//                 .name {
//                     font-size: 14px;
//                     line-height: 19px;
//                     font-family: var(--font-stack);
//                     color: var(--text_color);
//                     font-weight: 500;
//                     text-align: center;
//                     white-space: nowrap;
//                     display: block;
//                     -webkit-line-clamp: 1;
//                     -webkit-box-orient: vertical;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                     margin-block: auto;
//                     width: 120px;
//                 }
//             }

//             .point_show_box {
//                 @include displayFlex(column, center, flex-start);
//                 width: 100%;
//                 gap: 12px;

//                 .point_count_box {
//                     display: flex;
//                     align-items: center;
//                     gap: 6px;

//                     img {
//                         height: 18px;
//                     }

//                     p {
//                         font-size: 14px;
//                         line-height: 19px;
//                         font-family: var(--font-stack);
//                         color: var(--text_color);
//                         font-weight: 500;
//                     }
//                 }
//             }
//         }
//     }
// }

// selected_participant_wrapper
.selected_participant_wrapper {
    width: 100%;
    height: 100%;
    margin-inline: auto;
    @include displayFlex(row, center, center);

    .card_wraper {
        width: 780px;
        height: 400px;
        margin-inline: auto;

        position: relative;

        &.size_one {
            gap: 30px;

            .profile_wrapper {
                width: 210px;
                height: 180px;

                .levelBadge {
                    width: 46px;
                }

                .profile {
                    .MuiAvatar-root {
                        width: 78px;
                        height: 78px;
                        font-size: 38px;
                        line-height: 49px;

                        svg {
                            font-size: 24px;
                        }

                        img {
                            width: 78px;
                            height: 78px;
                        }
                    }

                    .status {
                        width: 20px;
                        height: 20px;
                        border: 3px solid var(--borderWhite);
                    }
                }

                .small_card_box {
                    .name {
                        font-size: 21px;
                        line-height: 29px;
                    }
                }

                .point_show_box {
                    .point_count_box {
                        img {
                            height: 27px;
                        }

                        p {
                            font-size: 21px;
                            line-height: 29px;
                        }
                    }
                }
            }
        }

        &.size_two {
            gap: 25px;

            .profile_wrapper {
                width: 175px;
                height: 150px;

                .levelBadge {
                    width: 38px;
                }

                .profile {
                    .MuiAvatar-root {
                        width: 65px;
                        height: 65px;
                        font-size: 28px;
                        line-height: 39px;

                        svg {
                            font-size: 24px;
                        }

                        img {
                            width: 65px;
                            height: 64px;
                        }
                    }

                    .status {
                        width: 17px;
                        height: 17px;
                        border: 3px solid var(--borderWhite);
                    }
                }

                .small_card_box {
                    .name {
                        font-size: 18px;
                        line-height: 25px;
                    }
                }

                .point_show_box {
                    .point_count_box {
                        img {
                            height: 22px;
                        }

                        p {
                            font-size: 18px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

        .cardPositionHolder {
            position: absolute;

            &.active {
                z-index: 100;
            }

            .profile_wrapper {
                background: var(--background-white);
                width: 100%;
                height: 100%;
                border: 1px solid var(--borderMain);
                box-sizing: border-box;
                border-radius: 12px;
                @include displayFlex(column, center, center);
                gap: 8px;
                position: relative;
                cursor: pointer;
                padding: 18px 24px;
                transition: all 0.12s ease-in;
                z-index: 10;

                &.active {
                    // transform: scale(1.5);

                    &:before {
                        content: '';
                        z-index: -1;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: linear-gradient(-45deg, #ff7aff 0%, var(--parple) 100%);
                        filter: blur(4px);
                        opacity: 1;
                        transition: opacity 0.3s;
                        border-radius: inherit;
                    }

                    &::after {
                        content: '';
                        z-index: -1;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: inherit;
                        border-radius: inherit;
                    }
                }

                .levelBadge {
                    position: absolute;
                    top: 11px;
                    left: 9px;
                    width: 31px;
                }

                .profile {
                    @include displayFlex(column, center, center);
                    position: relative;

                    & .avatar_image {
                        position: relative;
                        z-index: 100;
                    }

                    .MuiAvatar-root {
                        width: 52px;
                        height: 52px;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 39px;

                        svg {
                            font-size: 24px;
                        }

                        img {
                            width: 52px;
                            height: 52px;
                        }
                    }

                    & .status {
                        width: 14px;
                        height: 14px;
                        background-color: var(--offlineClass);
                        border: 3px solid var(--borderWhite);
                        border-radius: 50%;
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        z-index: 100;

                        &.online {
                            background-color: var(--onlineClass);
                        }
                    }
                }

                .small_card_box {
                    .name {
                        font-size: 14px;
                        line-height: 19px;
                        font-family: var(--font-stack);
                        color: var(--text_color);
                        font-weight: 500;
                        text-align: center;
                        white-space: nowrap;
                        display: block;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-block: auto;
                        width: 120px;
                    }
                }

                .point_show_box {
                    @include displayFlex(column, center, flex-start);
                    width: 100%;
                    gap: 12px;

                    .point_count_box {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        img {
                            height: 18px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 19px;
                            font-family: var(--font-stack);
                            color: var(--text_color);
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

// aiQuizClosedFooter
.aiQuizClosedFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .foot_btn_blue {
        &.saved {
            background-color: var(--icon_green) !important;
        }
    }
}

// ai_Icon
.ai_Icon {
    width: 200px;
    height: 200px;
    position: relative;
    z-index: 1;

    :is(.blueTriangle_one, .redTriangle_one) {
        position: absolute;
        inset: 0 0 0 0;
        margin: auto;
        mix-blend-mode: multiply;
        z-index: 2;
        border-radius: 30px;
        @include displayFlex(row, center, center);
    }

    :is(.happyTriangle_Two, .redTriangle_Two) {
        position: absolute;
        inset: 0 0 0 0;
        margin: auto;
        mix-blend-mode: multiply;
        z-index: 1;
        border-radius: 20px;
        @include displayFlex(row, center, center);
    }

    .round {
        position: absolute;
        inset: 0 0 0 0;
        margin: auto;
        background: var(--round_clr);
        box-shadow: var(--box_shadow_round);
        width: 128px;
        height: 128px;
        z-index: 2;
        border-radius: 50%;
    }

    .eye {
        position: absolute;
        inset: -32px 62px 0 0;
        margin: auto;
        width: 18px;
        height: 18px;
        z-index: 3;
    }

    .eye_two {
        position: absolute;
        inset: -32px 0 0 62px;
        margin: auto;
        width: 18px;
        height: 18px;
        z-index: 3;
    }

    .smile {
        position: absolute;
        inset: 50px 0 0 0px;
        margin: auto;
        width: 46px;
        height: 19px;
        z-index: 4;
    }

    .eye_close {
        position: absolute;
        inset: -32px 0 0 62px;
        margin: auto;
        width: 28px;
        height: 18px;
        z-index: 3;
    }

    .eye_upward_left {
        position: absolute;
        inset: -32px 0 0 62px;
        margin: auto;
        width: 28px;
        height: 18px;
        z-index: 3;
    }

    .eye_upward_right {
        position: absolute;
        inset: -32px 62px 0 0;
        margin: auto;
        width: 28px;
        height: 18px;
        z-index: 3;
    }

    .dal_face {
        position: absolute;
        inset: 50px 0 0 0px;
        margin: auto;
        width: 45px;
        height: 11px;
        z-index: 4;
    }

    .full_sad {
        position: absolute;
        inset: 50px 0 0 0px;
        margin: auto;
        width: 45px;
        height: 19px;
        z-index: 4;
    }

    .half_sad {
        position: absolute;
        inset: 50px 0 0 0px;
        margin: auto;
        width: 45px;
        height: 19px;
        z-index: 4;
    }
}

:is(.notEnoughTextError, .limitReached) {
    .Typewriter {
        .Typewriter__wrapper {
            color: var(--danger) !important;
        }
    }
}

.indexHighlight {
    color: var(--text_deep_purple) !important;
    font-weight: 500;
}

.staticAiIcon {
    position: absolute;
    inset: 97px 0 auto 0;
    margin-inline: auto;

    transform: scale(0.6);

    .ai_Icon {
        margin-inline: auto;
    }
}

.animatedAiIcon {
    position: absolute;
    inset: -60px auto auto -60px;

    transform: scale(0.4);
}

.language_change {
    position: relative;

    .language_change_wrapper {
        display: flex;
        width: 100%;

        .searchArrow {
            position: absolute;
            inset: 30px 10px auto auto;

            color: var(--activation_text);
            transition: 0.1s all linear;

            &.open {
                top: 26px;
                transform: rotate(180deg);
            }
        }

        .inputField {
            width: 100%;
            margin-block-start: 5px;

            .MuiOutlinedInput-root {
                font-size: 14px;
                line-height: 19px;
                color: var(--activation_text);
                font-family: var(--font-stack);

                height: 37px;
                border-radius: 10px !important;

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }

                &.Mui-focused {
                    background: none !important;
                }
            }
        }

        .dropdownContainer {
            position: absolute;
            inset: auto 0 42px 0;

            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 98%;
            max-height: 200px;
            margin-inline: auto;
            padding-block: 10px;
            background: var(--background-white);
            box-shadow: 10px 0 20px rgba(0, 0, 0, 0.1);
            overflow: auto;

            .listItem {
                display: flex;
                justify-content: flex-start;

                color: var(--activation_text);
                text-transform: none;

                width: 100%;
                padding-inline: 20px;
                border-radius: 0;

                &:hover {
                    background: hsl(0, 0%, 96%);
                }

                &.active {
                    background: hsl(0, 0%, 85%);
                }
            }
        }
    }
}

.languageOverlay {
    position: absolute;
    inset: 0 0 0 0;

    width: 100%;
    height: 100%;
}

.aiQuotaCount {
    position: absolute;
    inset: 10px 10px auto auto;
}
