.leaderBoardFooter {
    .showGroupRankBtn {
        color: var(--textWhite) !important;
        background: var(--backgroundcolor) !important;
    }
}

.leaderboardGroupRankModal {
    .leaderboardGroupRankModalViewport {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        pointer-events: none;

        .overallGroupsContainer {
            width: max-content;
            max-width: 650px;
            height: max-content;
            max-height: 320px;
            border-radius: 16px;
            background: var(--background-white);
            pointer-events: all;
        }

        .currentGroupsContainer {
            width: max-content;
            max-width: 650px;
            height: max-content;
            max-height: 390px;
            border-radius: 16px;
            background: var(--background-white);
            pointer-events: all;
        }
    }
}

.leaderBoardOverallGroupsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 52px;
        padding-inline: 30px;
        background: rgba(99, 120, 255, 0.08);

        .text {
            font-size: 20px;
            font-weight: 500;
            color: var(--cpbutton);
            text-align: center;
        }
    }

    .leaderBoardRankBody {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        flex-wrap: wrap;

        width: 100%;
        height: calc(100% - 52px);
        max-height: calc(320px - 52px);
        padding-block: 25px;
        padding-inline: 35px;
        overflow: auto;

        .leaderboardGroupCard {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 180px;
            height: 100px;
            border-radius: 12px;
            box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            overflow: hidden;

            .bodyHeader {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;
                height: 25px;

                .text {
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--textWhite);
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    width: max-content;
                    max-width: 100px;
                    overflow: hidden;
                }
            }

            .content {
                position: relative;

                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                width: 100%;
                height: calc(100% - 25px);
                padding-block-end: 5px;

                .starIcon {
                    width: 25px;
                }

                .starCount {
                    font-size: 24px;
                    font-weight: 500;
                    color: var(--activation_text);
                }

                .medalIcon {
                    position: absolute;
                    inset: auto 3px 3px auto;

                    width: 35px;
                }

                .rankCount {
                    position: absolute;
                    inset: auto 3px 3px auto;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: var(--cpbutton);

                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    background: rgba(99, 120, 255, 0.11);
                }
            }
        }
    }

    .leaderboardNoGroupView {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 500px;
        height: 100%;
        margin: auto;
        padding-block-end: 45px;

        .lottieContainer {
            max-width: 250px;
        }

        .noGroupText {
            font-size: 15px;
            line-height: 22px;
            color: var(--activation_text);
            text-align: center;

            max-width: 350px;
        }
    }
}

.leaderBoardCurrentGroupsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 650px;
    height: 100%;

    .header {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 52px;
        padding-inline: 30px;
        background: rgba(99, 120, 255, 0.08);

        .text {
            font-size: 20px;
            font-weight: 500;
            color: var(--cpbutton);
            text-align: center;
        }
    }

    .leaderBoardRankBody {
        width: 100%;
        height: 100%;
        padding-block: 15px;
        padding-inline: 15px;

        .currentRankContent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 16px;

            width: 100%;
            height: max-content;
            max-height: 300px;
            padding-block: 10px;
            padding-inline: 20px;
            overflow: auto;

            .leaderboardGroupRankBar {
                position: relative;

                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;

                .positionCount {
                    position: absolute;
                    inset: 0 auto 0 6px;
                    z-index: 1;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 16px;
                    font-weight: 700;

                    width: 28px;
                    height: 28px;
                    margin-block: auto;
                    border-radius: 100%;
                    background: rgb(239, 241, 255);
                }

                .bar {
                    position: relative;

                    height: 40px;
                    border-radius: 1000px;

                    .groupName {
                        position: absolute;
                        inset: 0 16px 0 auto;

                        font-size: 15px;
                        color: var(--textWhite);
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        width: max-content;
                        max-width: 80px;
                        height: max-content;
                        margin-block: auto;
                        overflow: hidden;
                    }
                }

                &.outside {
                    .bar {
                        .groupName {
                            inset: 0 auto 0 50px;
                            color: var(--activation_text);
                        }
                    }

                    .currentClassGroupDetails {
                        margin-inline-start: 85px;
                    }
                }

                .currentClassGroupDetails {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    width: max-content;
                    height: 40px;
                    padding-inline: 15px;
                    border-radius: 100px;
                    background: rgba(99, 120, 255, 0.05);

                    .starIcon {
                        width: 20px;
                    }

                    .starCount {
                        font-size: 16px;
                        font-weight: 500;
                        color: var(--activation_text);
                    }
                }
            }
        }

        .leaderboardNoGroupView {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            max-width: 350px;
            height: 100%;
            margin: auto;
            padding-block-end: 50px;

            .noGroupText {
                font-size: 15px;
                line-height: 22px;
                color: var(--activation_text);
                text-align: center;
            }
        }
    }
}
