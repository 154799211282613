@use '../../../Style.scss' as *;

.settingsUserProfileDetails {
    max-width: 468px;
    height: 516px;
    border-radius: 12px;
    background: var(--background-white);
    overflow: auto;

    > .modalHead {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 50px;
        border-radius: 12px 12px 0 0;
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.08) 0%, rgba(99, 120, 255, 0.08) 100%), #ffffff;

        .text {
            font-size: 16px;
            font-weight: 500;
            color: var(--activation_text);
        }
    }
}

.subject_sec {
    width: 100%;
    padding-inline-start: 8px;

    .error_span {
        padding-inline-start: 16px;
    }

    .subject_head {
        width: 100%;
        margin-block-end: 7px;

        h5 {
            color: var(--user_emailtext);
            font-family: var(--font-stack);
            font-size: 11px;
            font-weight: 400;

            margin-block-start: 2px;
        }
    }

    .subject_checkbox {
        width: 100%;

        // padding-inline-start: 5px;
        .MuiFormControl-root {
            margin: 0;
        }

        .MuiFormControlLabel-root {
            margin: 0;
        }

        .MuiFormGroup-root {
            flex-direction: row;
        }

        .checkbox {
            width: 50%;
            float: left;
        }
    }
}

.profile_form {
    height: calc(100% - 50px);
    padding: 24px 32px;
    overflow: hidden;

    .profile_left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        .row {
            gap: 0 14px;

            :is(.col6, .col12) {
                padding-inline: 0;
            }

            .col6 {
                width: calc(50% - 7px);
            }

            .subject_sec {
                padding-inline: 0;
            }
        }

        .progressContainer {
            width: 100%;
            margin-block-start: auto;
            padding: 0;
        }

        .profile_update_btn {
            width: 100%;
            padding-block-start: 18px;

            &:is(.done5, .editMode) {
                margin-block-start: auto;
            }

            &.flex {
                display: flex;
                gap: 8px;
            }
            &.row2 {
                @include displayFlex(column, flex-start, flex-start);
                padding-block-start: 16px;
                gap: 15px;
                .link {
                    color: var(--parple);
                    font-size: 14px;
                    line-height: 21px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    cursor: pointer;
                    @include displayFlex(row, center, center);
                    gap: 9px;
                    margin-inline-start: 16px;

                    &:hover {
                        text-decoration: underline !important;
                    }

                    &.red {
                        color: var(--danger) !important;

                        a {
                            color: var(--danger) !important;
                        }
                    }
                }
            }
            & + .profile_update_btn {
                padding-block-start: 16px;
                button {
                    &.danger_btn {
                        background: none;
                        color: var(--danger);

                        &:hover {
                            background: none;
                        }
                    }
                }
            }

            button {
                @include profile_update_btn;
                background: var(--btnbgcolor);
                max-width: fit-content;
                min-width: 180px !important;
                padding: 8px 16px !important;

                .MuiButton-startIcon {
                    margin-left: 0;
                    margin-right: 0;
                    margin-inline-end: 6px;
                }

                &.btn_no_bg {
                    background: transparent;
                    color: var(--activation_text);
                    cursor: default;
                    pointer-events: none;
                    width: fit-content;
                    padding: 0 !important;
                    min-width: auto !important;

                    .MuiLoadingButton-loadingIndicator {
                        padding-inline-end: 8px;
                    }

                    &:hover {
                        background: none;
                    }
                }

                &:hover {
                    background: var(--btnhover);
                }

                .MuiLoadingButton-startIconLoadingStart {
                    display: none;
                }

                .MuiLoadingButton-loadingIndicator {
                    position: relative;
                    left: 0;
                    display: flex;
                    padding-inline: 0 5px;

                    .MuiCircularProgress-root svg {
                        height: auto !important;
                    }
                }

                &.danger_btn {
                    background: var(--footer_btn);

                    &:hover {
                        background: var(--no_more_red);
                    }
                }

                &.cancel {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
                    min-width: 120px;
                    max-width: fit-content;
                    color: var(--parple);

                    &:hover {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                    }
                }

                &.reset {
                    background: var(--reset_light_bg);
                    min-width: 120px;
                    max-width: fit-content;
                    color: var(--reset_color);

                    &:hover {
                        background: var(--reset_hover_bg);
                    }

                    .MuiButton-startIcon {
                        margin: 0;
                        margin-inline-end: 2px;
                    }
                }
            }
        }
    }
}
