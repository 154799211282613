@use '../../Style.scss' as *;

:root {
    --padding-inline: 140px;
}

.Quizesummary_main {
    width: 100%;
    height: var(--activitycontentHeight);
    background: var(--leaderboardbg);
}

.participent_points_table {
    width: 100%;
    height: 100%;

    .quizResponseContainer {
        width: 100%;
        height: 100%;
        margin-inline: auto;
        margin-block: 0;
        padding: 0 8px;
    }

    .ans_table_inner {
        width: 100%;
        margin-inline: auto;
        margin-block: 0;
        // height: 450px;
        display: flex;
        align-items: flex-start;
        // padding: 44px 0;
        padding-block-start: 36px;
        justify-content: center;
        position: relative;
        position: relative;

        &::after {
            content: '';
            background: url(../../../public/assets/logo/curve-01.png) no-repeat;
            position: absolute;
            top: 34px;
            left: calc(var(--padding-inline) - 1px);
            width: 16px;
            height: 16px;
            background-color: var(--borderMain);
        }

        &::before {
            content: '';
            background: url(../../../public/assets/logo/curve-01.png) no-repeat;
            position: absolute;
            top: 34px;
            right: calc(var(--padding-inline) + 2px);
            width: 16px;
            height: 16px;
            background-color: var(--borderMain);
        }

        &.timepoints {
            table {
                tbody {
                    tr {
                        td {
                            &.star {
                                p {
                                    // color: var(--avg_time_gold_text);

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 6.5px;

                                    img {
                                        width: 16px;
                                        margin-bottom: 3px;
                                    }
                                }
                            }
                        }

                        &:nth-child(-n + 3) {
                            td {
                                .avg_time_medal {
                                    display: flex;
                                }

                                &:last-child {
                                }
                            }
                        }
                    }
                }
            }
        }

        &.showresponce {
            table {
                thead {
                    tr {
                        th {
                            width: 10%;
                            transition: var(--transition);
                            padding-block: 9px;

                            .questionGroup {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 4px;
                            }

                            .questionLink {
                                color: var(--parple);
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline;
                                    color: var(--parple);
                                }
                            }

                            .percentage {
                                color: var(--userSubText);
                                font-size: 13px;
                                line-height: 16px;
                            }

                            .MuiRating-root {
                                width: 100%;
                                justify-content: center;
                                margin-block-start: 5px;

                                svg {
                                    font-size: 16px;
                                }
                            }

                            .MuiRating-icon {
                                font-size: 16px;

                                .MuiRating-iconFilled {
                                    color: var(--response_level_star);
                                }

                                .MuiRating-iconEmpty {
                                    color: var(--light_border);
                                }
                            }

                            &:nth-of-type(1) {
                                width: 10%;
                                cursor: default;
                                color: var(--secondary_text);

                                &:hover {
                                    text-decoration: none;
                                    color: var(--secondary_text);
                                }
                            }

                            &:nth-of-type(2) {
                                text-align: start;
                                width: 36%;
                                cursor: default;
                                color: var(--secondary_text);

                                &:hover {
                                    text-decoration: none;
                                    color: var(--secondary_text);
                                }
                            }
                        }
                    }
                }
            }
        }

        .flex_center {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
        }

        .MuiTableContainer-root {
            // border-radius: 16px 16px 0 0;
            // border-start-start-radius: 16px;
            // border-start-end-radius: 16px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            // overflow: hidden;
            box-shadow: none;
            background: none;
            padding-inline: var(--padding-inline);
        }

        table {
            border-radius: 16px 16px;
            border-collapse: collapse;
            background: var(--background-white);
            margin-block-end: 36px;

            thead {
                tr {
                    border-radius: 16px 16px 0 0;
                    background: #f3f4ff;

                    th {
                        background: #f3f4ff;
                        font-weight: 400;
                        font-size: 15px;
                        font-family: var(--font-stack);
                        color: var(--secondary_text);
                        border-block-end: none;
                        // text-align: center;
                        height: 48px;
                        padding: 0 10px;
                        width: 11%;
                        top: -1px;

                        &:nth-of-type(1) {
                            width: 10%;
                            border-radius: 16px 0px 0 0;
                        }

                        &:nth-of-type(2) {
                            text-align: start;
                            width: 27%;
                        }

                        &:last-child {
                            // border-radius: 0px 16px 0 0;
                            border-top-right-radius: 16px;
                        }
                    }
                }
            }

            tbody {
                border-radius: 0px 0px 16px 16px;

                tr {
                    // cursor: pointer;
                    background: var(--background-white);

                    &:nth-of-type(even) {
                        background: var(--parple_08);
                    }

                    &:hover {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                    }

                    &:last-child {
                        // border-radius: 0px 0px 16px 16px;
                        border-bottom-left-radius: 16px;
                        border-bottom-right-radius: 16px;

                        td {
                            &:last-child {
                                border-radius: 0px 0px 16px 0;
                                // border-end-end-radius: 16px;
                            }

                            &:first-child {
                                // border-end-start-radius: 16px;
                                border-radius: 0px 0px 0 16px;
                            }
                        }
                    }

                    td {
                        height: 42px;
                        border-block-end: none;
                        text-align: center;
                        font-weight: 500;
                        font-size: 15px;
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                        padding: 0 10px;

                        .flex_align_center {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        p {
                            font-weight: 500;
                            font-size: 15px;
                            font-family: var(--font-stack);
                            color: var(--activation_text);
                            word-break: keep-all;
                            line-height: 19px;

                            .blue {
                                font-weight: 500;
                                font-size: 11px;
                                font-family: var(--font-stack);
                                color: var(--parple);
                            }
                        }

                        .green {
                            color: var(--user-sucess);
                        }

                        &:nth-of-type(2) {
                            text-align: start;
                        }

                        .profile {
                            display: flex;
                            align-items: center;
                            gap: 12px;

                            .MuiAvatar-root {
                                width: 28px;
                                height: 28px;
                                font-size: 14px;
                            }
                        }

                        .number {
                            color: var(--activation_text);
                            font-weight: 400;
                            font-size: 15px;
                            font-family: var(--font-stack);
                        }

                        .low {
                            color: var(--danger);
                            font-weight: 500;
                            font-size: 15px;
                            font-family: var(--font-stack);
                        }

                        .mid {
                            color: var(--minus);
                            font-weight: 500;
                            font-size: 15px;
                            font-family: var(--font-stack);
                        }

                        .high {
                            color: var(--user-sucess);
                            font-weight: 500;
                            font-size: 15px;
                            font-family: var(--font-stack);
                        }
                    }
                }
            }

            .avg_time {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;

                .avg_time_medal {
                    display: flex;
                    display: none;
                }

                &.flex_start {
                    justify-content: flex-start;
                }
            }
        }
    }

    .arrow_left {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        cursor: pointer;
        max-height: 40px;
        max-width: 40px;
        width: 40px;
        height: 40px;
        min-width: auto;
        border-radius: 50%;
        color: var(--activation_text);
        background: var(--background-white);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        left: 50px;
        opacity: 0.7;

        &:hover {
            opacity: 1;
            color: var(--activation_text);
            background: var(--background-white);
        }

        svg {
            fill: var(--activation_text);
            transition: fill var(--transition);
            font-size: 24px;
            display: flex;
        }
    }

    .arrow_right {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        cursor: pointer;
        max-height: 40px;
        max-width: 40px;
        width: 40px;
        height: 40px;
        min-width: auto;
        border-radius: 50%;
        color: var(--activation_text);
        background: var(--background-white);
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        right: 50px;
        opacity: 0.7;

        &:hover {
            opacity: 1;
            color: var(--activation_text);
            background: var(--background-white);
        }

        svg {
            fill: var(--activation_text);
            transition: fill var(--transition);
            font-size: 24px;
            display: flex;
        }
    }
}

.tempBtn {
    position: absolute;
    inset: 75px 0 0 5px;
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 140px;
    height: 40px;
    border-radius: 5px;
    background: #fff;
}
