@import '../../../../Style.scss';

.questionList {
    width: 100%;
    height: 100%;
    padding-inline: 8px;

    .inner_question_list {
        width: 100%;
        height: 100%;
        overflow: auto;

        .inner_question_wrapper {
            width: 100%;
            max-width: 640px;
            margin-inline: auto;
            @include displayFlex(column, flex-start, flex-start);
            gap: 8px;
            padding-block: 24px;
            padding-inline: 20px;
        }
    }
}
