@use '/src/Style.scss' as *;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// font-family: 'Poppins', sans-serif;
// font-family: 'SFProDisplay';
// font-family: 'Inter Tight', sans-serif;
// font-family: 'Inter', sans-serif;

:root {
    // ========== Font Weight ==========
    --fw_light: 300;
    --fw_regular: 400;
    --fw_medium: 500;
    --fw_semiBold: 600;
    --fw_bold: 700;
    --fw_extraBold: 800;
    --fw_black: 900;

    --activitycontentHeight: 484px;
    --borderMain: #cfd5ff;
    --textWhite: #fff;
    --background-white: #fff;
    --background_black: #000;
    --textblack: #000;
    --userSubText: #757575;
    --user_emailtext: #9ca3af;
    --borderWhite: #fff;
    --backgroundcolor: #6378ff;
    --font-stack: 'Roboto', sans-serif !important;
    // --SFProDisplay: 'SFProDisplay' !important;
    // --poppin_stack: 'Poppins', sans-serif !important;
    --InterTight: 'Inter Tight', sans-serif !important;
    --Inter: 'Inter', sans-serif !important;
    --primary-color: #6378ff;
    --placeholder-color: #9ca3af;
    --btnbgcolor: #6378ff;
    --btnbgcolor_light: hsla(231.9, 100%, 69.4%, 0.08);
    --btnhover: #5363ff;
    // --userActiveColor: #44b700;
    --user_pro: #3461ff;
    --tabcolor: #6378ff;
    // --markbgcolor: #ff9800;
    --textColor: #555;
    --certified: #2e9145;
    --info: #0788ff;
    --danger: #b3261e;
    --goldbg: #fdbb3e;
    --darkgoldbg: #e58923;
    --transition: 0.2s ease-in-out;
    --gradientbg: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, #e4e7ff 100%);
    --lightbackground: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
    --lightdeepbg: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
    --lightdeepbg2: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)), #ffffff;
    --lightDeepbg3: linear-gradient(0deg, rgba(99, 120, 255, 0.12), rgba(99, 120, 255, 0.12)), #ffffff;

    --borderlight: #cfd5ff;

    --submission_btn: #d96367;
    --border-color: #e0e0e0;
    --box3: #ef476f;
    --parple: #6277ff;

    --gray_text_color: #b0afb1;
    --footer_bg_second: #f1edf2;
    --chip_background: #ebeffa;

    --checkboxGreen: #3bba59;

    --red: #b3261e;
    --red_hover: #f9dedc;
    --secondary_clr_2: #e6e6e6;
    --secondary_text: #79747e;
    --icon_green: #3bba59;
    --dropdown: #49454f;
    --footer_btn: #d96367;
    --addingPoint_bg: #ffe994;

    --error_span_color: #d32f2f;
    --error_span_color_light: hsla(0, 65.1%, 50.6%, 0.12);

    --upgrade: #6378ff;
    --cpbutton: #6378ff;
    --cpButton_18: rgba(99, 120, 255, 0.18);
    --cpButton_14: rgba(99, 120, 255, 0.14);
    --cpButton_20: rgba(99, 120, 255, 0.2);
    --text_color: #1c1b1f;
    --redishbrown_color: #905;

    // tooltip bg
    --tooltip_bg_color: #1c1b1f;

    // user profile
    --user-sucess: #3bba59;
    --user-error: #e4aa31;
    --trialbtn: #f6a404;
    --defaul_btn: #d7d8d5;
    --subject_chipbg: #e7e9fe;
    --text-gray: #939094;
    --lightbg: #edf0ff;

    // my class
    --greytext_color: #79747e;
    --light-bg: #cfd5ff;
    --no_more_red: #dc362e;
    --redspan-color: #b3261e;
    --warning: #ffc107;
    --offlineClass: #c0c0c0;
    --onlineClass: #00e517;

    // leaderboard
    // --golder_color: #fba429;
    --textlead_color: #3b454b;
    --footer_defult_btn: #cccccc;
    --pagination_border: #b5b5b5;
    --csvUpload_grey: #f2f3f7;
    --leaderboardbg: linear-gradient(180deg, rgba(217, 201, 252, 0) 0%, #d9c9fc 100%), #cfd5ff;
    --first: #ffee96;
    --second: #edece8;
    --thired: #ffceb2;

    --activation_text: #49454f;
    --graybtn: #e6e1e5;
    --grayBtnHover: #b4b4b4a6;
    --mali: 'Mali', cursive !important;
    --lightparple: #edf0ff;
    --luckydrawbg: radial-gradient(50% 45.78% at 50% 51.41%, #f9a7a7 8.85%, #f9a7a7 47.4%, rgba(251, 235, 153, 0) 100%),
        linear-gradient(180deg, #fcff5a 0%, rgba(255, 116, 116, 0.5) 50.54%, rgba(255, 116, 116, 0) 84.77%),
        linear-gradient(180deg, rgba(217, 201, 252, 0) 0%, #d9c9fc 100%), #cfd5ff;
    --popin: 'Poppins', sans-serif !important;
    --luckydrawboxbg: radial-gradient(188% 188% at 50% 92.5%, rgba(255, 248, 210, 0.8) 0%, rgba(193, 178, 96, 0) 47.4%),
        radial-gradient(58% 58% at 50% 87.5%, #ffe792 28.32%, rgba(255, 231, 146, 0) 100%), #fff7d9;
    --luckydrawTextColor: #d07711;
    --pointbg: linear-gradient(180deg, rgba(255, 112, 32, 0) 34.38%, rgba(255, 112, 32, 0.4) 100%);

    // addingclass color
    --addClassIcon: #ff9264;
    --inter: 'Inter', sans-serif !important;
    --menuliborder: #eef0ff;
    --editview_chip_bg: #c6f1d0;
    --minus: #ff871e;
    --csvcrossbg: #ec928e;
    --info_color: #0788ff;
    --graybar: #c9c5ca;
    --avg_time_gold_text: #ff871e;
    --response_level_star: #e4aa31;

    --lightBoxMax-width: 800px;
    --lightBoxMin-width: 400px;

    --lightBoxMax-height: 420px;
    --lightBoxMin-height: 264px;

    --thumnailMin-height: 72px;
    --thumnailMax-height: 300px;

    --qpSelectedBg: #cfd5ff90;
    --Ripplebg_color: #d6dbff;
    --RippleBg_color2: rgba(99, 120, 255, 0.8);
    --light_green_bg: #e5ffec;
    --green_btn: #e6ffec;
    --green_btn_text: #2e9145;
    --green_btn_hover: #d6fddf;
    --table_hover: #e9ecff;
    --leader_btn_view: #dee2ff;
    --light_border: #cfd5ff;
    --text_gray: #79747e;
    --dashboard_url_text: #787579;
    --reset_color: #da7f14;
    --reset_light_bg: #ffeedb;
    --reset_hover_bg: #ffe3c1;
    // --delete_btn_hover: #fceeee;
    // --light_loss_bg: #fad2d3;
    --disable_bg_AEAAAE: #aeaaae;
    --switch_diable: #e6e1e5;

    // movable object
    --border_color_btn: #d7d6d9;
    --folder_color: #fdcd61;

    // browser
    --color_f1f3f4: #f1f3f4;
    --icon_color: #6b6c6e;
    --border_D7D6D7: #d7d6d7;
    --tab_bg_E8E8E8: #e8e8e8;
    --active_tab_color_F2F2F2: #f2f2f2;
    --border_color_DFDFDF: #dfdfdf;
    --pin_bg_color_AEAAAE: #aeaaae;
    --pin_bg_hover_color: #f4eff4;
    --bg_49454f_80: rgba(73, 69, 79, 0.8);
    --parple_08: rgba(99, 120, 255, 0.08);
    --background_black_06: rgba(0, 0, 0, 0.6);

    // new subcription
    --background_blue: linear-gradient(180deg, #9daafb 0%, #9daafb 100%);
    --background_grad_gold: linear-gradient(180deg, #ffc700 0%, #f1a208 100%);
    --background_grad_01: linear-gradient(90deg, #6378ff 24.31%, #e963ff 105.79%);
    --grad_text_01: linear-gradient(75.66deg, #6378ff 17.84%, #8652f4 69.88%);
    --background_light_red: rgba(247, 111, 127, 0.15);
    --Setting_gamification_modal_back_drop: url('../public/assets/setting/setting-gamification-blurredoverlay.webp');
    --background_primary_05: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;

    // new welcome
    --New_Welcome_bg: linear-gradient(180deg, #d7e8ff 0%, #ffffff 43.1%);
    --New_welcome_bg2: radial-gradient(50% 50% at 50% 50%, #ffffff 46.87%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(180deg, #d7e8ff 0%, #ffffff 43.1%), #ffffff;
    --light_blue: rgba(99, 120, 255, 0.4);
    --light_blue_05: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
    --leaderBoard_btn: linear-gradient(180deg, rgba(244, 247, 254, 0) 0%, #d8e3ff 100%), #ffffff;
    --tooltip_black_800: #424242;

    // gradient
    --clr_grad_text_01: linear-gradient(75.66deg, #6378ff 17.84%, #8652f4 69.88%);

    // gradient_bg
    --clr_grad_bg_02: radial-gradient(186.11% 104.69% at 50% 50%, #ffffff 0%, #dadeff 100%);
    --clr_grad_bg_03: radial-gradient(52.66% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(225, 196, 255, 0.6) 100%),
        radial-gradient(186.11% 104.69% at 50% 50%, #ffffff 0%, #dadeff 100%);
    // discount
    --discount_bg: #ffcd4b;
    --green_100: #eafbff;
    --new_user_discount_btn_bg: radial-gradient(97.93% 50% at 50% 100%, #6378ff 27.08%, rgba(65, 183, 250, 0) 100%),
        linear-gradient(360deg, #41b7fa -5.35%, rgba(138, 171, 255, 0.6) 51.06%, rgba(247, 248, 255, 0) 103.75%),
        #6378ff;

    --Shoppee_bg: #f1592a;
    --fb_bg: #4866b0;
    --share_bg: rgba(237, 111, 39, 0.15);

    --templateLibrary_bg: #2a326b;

    // ai
    --bar_color_green: #06d6a0;
    --bar_color_blue: #2aa4e3;
    --bar_color_yellow: #ffba00;
    --selected: #fff2d7;
    --correct: #d9f5e0;
    --wrong: #f9dedc;
    --tooltip_bg: #121212;
    --bg_red: #f25240;
    --bg_deep_purple: #7d29c0;
    --text_deep_purple: #7a4bde;

    // Ai icon
    --bg_orange: #ed6f27;
    --bg_light_orange: #ff7a00;
    --bg_deep_orange: #d96220;
    --bg_clr_01: linear-gradient(180deg, #ff7aff 0%, #09deff 100%);
    --bg_clr_02: linear-gradient(90deg, #7b61ff 9.5%, #6378ff 90.5%);
    --round_clr: #f6f7ff;
    --box_shadow_round: 2px 2px 4px 2px rgba(255, 255, 255, 0.2), -2px -2px 4px 2px rgba(255, 255, 255, 0.2);
    --bg_deep_green: #179f37;
    --bg_light_green: #c6f1d0;

    // QandA
    --bg_clr_gray_10: rgba(121, 116, 126, 0.1);
    --max_width: 480px;
    --min_height: 100px;
    --max_height: 250px;

    // box-shadow
    --shadow_01: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --shadow_02: 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 4px 8px 3px rgba(0, 0, 0, 0.05);

    // border
    --border_01: rgba(168, 168, 168, 0.5);

    --settings_premium_ui: #fbe652;
    --premium_black: #0f1c42;
}

body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    //     'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    //     'Helvetica Neue', sans-serif;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button:disabled,
button.disabled:hover,
button:disabled,
button:disabled:hover {
    cursor: not-allowed;
}

// universal style
*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: var(--font-stack);
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: transparent;
}

p {
    padding: 0px;
    margin: 0px;
}

a {
    padding: 0px;
    margin: 0px;
    outline: none;
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

a:hover {
    outline: none !important;
    text-decoration: none;
}

a:active {
    outline: none !important;
    text-decoration: none;
}

a {
    text-decoration: none !important;
    font-family: var(--font-stack);
}

label {
    list-style: none;
    padding: 0px;
    margin: 0px;
    font-family: var(--font-stack);
}

ul li {
    padding: 0px;
    margin: 0px;
    font-family: var(--font-stack);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0px;
    margin: 0px;
    font-family: var(--font-stack);
}

#content_select_off {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently supported by Chrome and Opera */
    & :is(*, *::after, *::before) {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome and Opera */
    }
}

.media_icon.crossalt {
    margin-block-start: 6px;
}

.font_weight_500 {
    font-weight: 500 !important;

    &.success {
        font-size: 16px !important;
        color: var(--user-sucess) !important;

        margin-block-start: 20px !important;
    }

    &.error {
        font-size: 16px !important;
        color: var(--user-error) !important;
        margin-block-start: 20px !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    width: 6px;
    height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aea9b4;
    width: 6px;
    height: 6px;
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
    width: 6px;
    height: 6px;
}

// :is(.whiteboard_inner)::-webkit-scrollbar-track {
//     background: transparent;
// }

:is(.participent_table, .participent_grid)::-webkit-scrollbar-track {
    background: var(--background-white);
    width: 6px;
    height: 6px;
}

:is(
        .whiteboard_inner,
        .leader_board_inner,
        .participent_table,
        .participent_grid,
        .MuiTableContainer-root,
        .short_ans_wrap,
        .shortmodal_content,
        .inner_whiteboard_img,
        .pickNumber_card,
        .pickname_wrap,
        .fill_blank_sec .inner_fillblank .tbody
    )::-webkit-scrollbar-thumb:hover {
    width: 6px;
    height: 6px;
}

.pointer {
    cursor: pointer;
}

// empty_modal
.empty_modal {
    &.MuiModal-root {
        .MuiBackdrop-root {
            background: none;
        }
    }

    .empty_box {
        position: absolute;
        top: 0% !important;
        left: 0% !important;
        right: 0% !important;
        bottom: 0% !important;
        margin: auto !important;
        height: fit-content !important;
        background-color: transparent;
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }
}

.viewport {
    margin: 0;
    transform-origin: left top;
    overflow: hidden;
    outline: none;
    box-shadow: none;
    background: var(--background-white);
}

.wh100 .modal_viewport {
    transform-origin: left top;
}

.modal_viewport {
    margin-inline: auto;
    margin-block: 0;
    position: relative;
    outline: none;
    box-shadow: none;
}

#root,
body,
html {
    height: 100%;
    background: rgb(255, 255, 255);
    scroll-behavior: smooth;
}

.black {
    // background: var(--background_black);
    width: 100%;
    height: 100%;
}

.hidden {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mt0 {
    margin-block-start: 0 !important;
}

.wh100 {
    width: 100%;
    height: 100%;

    .close_modal {
        position: fixed;
    }
}

.wheel_name.single {
    .wheel-container {
        &::after {
            background: none;
        }

        .wheel .wheel-item span {
            margin: 0;
            overflow: visible;
        }
    }
}

.short_ans_modal .MuiBox-root .video_upload_modal .big_sh_box .fullscreen-enabled .big_sh_content {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    .react-player.video_upload {
        max-width: 100%;
        max-height: 100%;
    }
}

.sh_box .content_box .video_button {
    padding: 20px;
    opacity: 0;
    transition: 0.2s all ease-out;

    &.show {
        opacity: 1;
    }
}

.sh_box .content_box .video_button .playPauseContainer {
    margin: 20px;
    position: relative;

    .circularProgress {
        position: absolute;
        inset: 0;
        z-index: 1;
        color: var(--background-white);
        width: 100% !important;
        height: 100% !important;
    }

    button {
        border: 0 !important;
        padding: 8px !important;
    }
}

.crop_center {
    .cropper-drag-box.cropper-modal {
        opacity: 0.8;
    }

    .cropper-view-box {
        outline: none;
        border: 1px solid var(--background-white);
    }

    .cropper-center {
        display: none;
    }

    .cropper-point.point-se {
        height: 5px !important;
        width: 5px !important;
    }
}

.givePointButtonContainer {
    position: relative;

    .givePointButton {
        // border-radius: 0 !important;
        background: none;

        &:hover {
            background: none;
        }
    }

    .buttonBackground {
        position: absolute;
        inset: 0 0 0 0;
        border-radius: 40px;
        background: var(--backgroundcolor);
    }
}

// modal
.modal_box {
    position: absolute;
    top: 0% !important;
    left: 0% !important;
    right: 0% !important;
    bottom: 0% !important;
    margin: auto !important;
    height: fit-content !important;
    background-color: var(--background-white);
    box-shadow: none !important;
    border: none !important;
    outline: none !important;

    &.myclass_qr_modal {
        width: fit-content;
        border-radius: 12px;
        padding: 0 !important;
    }
}

.modal {
    &.update_top_pro {
        position: fixed;
        z-index: 13;
        inset: 0px;
    }

    .back_drop {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        inset: 0px;
        background: var(--background_black_06);
        backdrop-filter: blur(4px);
        z-index: -1;

        &.settingsGamification {
            backdrop-filter: none;
            background: rgba(0, 0, 0, 0.75) !important;
            // background: var(--Setting_gamification_modal_back_drop) no-repeat center !important;
            background-size: contain !important;
        }
    }

    &.comming_soon_modal {
        position: absolute;
        z-index: 13;
        inset: 0px;
    }

    .modal_box {
        position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        max-width: 360px;
        background-color: var(--background-white);
        border-radius: 16px;
        overflow: hidden;
    }

    .modal_title {
        background: var(--parple_08);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;

        h4 {
            display: flex;
            align-items: center;
            color: var(--parple);
            font-family: var(--font-stack);
            font-size: 16px;
            font-weight: 500;
            gap: 10px;

            & > span.pro {
                border-radius: 16px;
                background: var(--parple);
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 18px;
                padding-inline: 6px;
                font-weight: 500;
                font-size: 10px;
                line-height: 11px;
                font-family: var(--font-stack);
                width: auto;
                margin: 0;
                color: var(--textWhite);
                text-transform: uppercase;
                letter-spacing: 0.5px;
            }
        }
    }

    .modal_body {
        padding-inline: 32px;
        padding-block: 26px;
        width: 100%;
        text-align: center;

        &.pad_bt_0 {
            padding-block-end: 0;
        }

        p {
            color: var(--activation_text);
            font-family: var(--font-stack);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .gaming_pro_btn {
            @include displayFlex(row, center, center);
            padding-block-start: 24px;

            button {
                min-width: 120px !important;
                max-width: fit-content !important;
                min-height: 40px !important;
            }
        }
    }
}
