.singleCard {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 280px;
    height: 120px;
    padding-inline: 16px;
    border: 1px solid var(--light_border);
    border-radius: 12px;
    background: var(--background-white);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.15s ease-in-out;

    &:hover {
        background: var(--btnbgcolor_light);
    }

    .stripe {
        position: absolute;
        inset: 0 0 auto 0;

        width: 100%;
        height: 8px;
    }

    .topPart {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        :is(.groupDetailsText, .totalStarsText) {
            font-size: 16px;
            font-weight: 500;
            color: var(--activation_text);
            white-space: nowrap;
        }

        .groupDetailsText {
            text-align: start;

            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .totalStarsText {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            width: 70px;

            .starIcon {
                width: 18px;
                height: 18px;
                margin-block-end: 2px;
                margin-inline-end: 5px;
            }
        }
    }

    .divider {
        width: 70px;
        height: 2px;
        margin-inline-start: auto;
        margin-block: 8px 5px;
        background: rgba(99, 120, 255, 0.1);
    }

    .bottomPart {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        width: 100%;

        .MuiAvatar-root {
            font-size: 11px;

            width: 28px;
            height: 28px;
            border: 1px solid var(--borderWhite);

            &:nth-of-type(1) {
                position: relative;
                z-index: 5;
            }

            &:nth-of-type(2) {
                position: relative;
                z-index: 4;

                margin-inline-start: -5px;
            }

            &:nth-of-type(3) {
                position: relative;
                z-index: 3;

                margin-inline-start: -5px;
            }

            &:nth-of-type(4) {
                position: relative;
                z-index: 2;

                margin-inline-start: -5px;
            }

            &:nth-of-type(5) {
                position: relative;
                z-index: 1;

                margin-inline-start: -5px;
            }

            &:nth-of-type(6) {
                margin-inline-start: -5px;
            }
        }

        .participantCount {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            font-size: 16px;
            color: var(--greytext_color);

            width: 70px;
            margin-inline-start: auto;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
