@use '../../../../Style.scss' as *;

.templateLibraryUpgradeToProModal {
    .viewport {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        .modalContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            width: 360px;
            height: 300px;
            padding-inline: 48px;
            border-radius: 16px;
            background: var(--background-white);
            pointer-events: all;

            .imageContainer {
                width: 150px;
                height: 150px;

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .text {
                font-size: 14px;
                color: var(--activation_text);
                line-height: 20px;
                text-align: center;
            }

            .upgradeToProBtn {
                font-weight: 500 !important;
                color: var(--textWhite) !important;
                line-height: 19px;
                text-transform: none !important;
                white-space: nowrap;

                width: max-content;
                max-width: max-content !important;
                height: 40px;
                margin-block-end: 27px;
                padding-inline: 35px !important;
                padding-block-start: 7px !important;
                border: none !important;
                border-radius: 1000px !important;
                background: linear-gradient(90deg, var(--primary-color) 24.31%, #e963ff 105.79%);
            }
        }
    }
}
