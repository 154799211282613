@use '../../../../Style.scss' as *;

.browserSection {
    background: var(--background-white);
    width: 100%;
    height: 100%;

    .controlUiSection {
        width: 100%;

        .address-bar {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .controlUiSection_top {
            display: flex;
            align-items: center;
            padding-inline: 12px;
            padding-block: 6px 5px;
            width: 100%;
            gap: 10px;
            justify-content: space-between;

            .address-bar-container {
                display: flex;
                align-items: center;
                gap: 6px;
                width: 100%;
                justify-content: center;

                .address-bar-container-bookmark:hover {
                    cursor: pointer;
                }
            }
        }

        .controlUiSection_top_middle {
            display: flex;
            align-items: center;
            gap: 6px;
            flex: 1;

            svg {
                color: var(--icon_color);
            }
        }

        .urlsearch {
            width: 100%;
            max-width: 700px;
            margin-block: 0;

            .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
                background: var(--color_f1f3f4) !important;

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: var(--border_D7D6D7);

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            .MuiOutlinedInput-root {
                .MuiOutlinedInput-input {
                    box-shadow: none;
                }

                &.Mui-focused {
                    border-color: var(--border_D7D6D7);
                }

                border-radius: 4px;
                height: 32px;
                background: var(--color_f1f3f4) !important;
                padding: 0;
                padding-inline: 12px 3px;

                .MuiInputAdornment-root {
                    .MuiAvatar-root {
                        width: 20px;
                        height: 20px;
                    }

                    svg {
                        color: var(--icon_color);
                        font-size: 17px;

                        &.qrcodeicon {
                            margin: 0;
                            margin-inline-end: 8px;
                        }
                    }
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: var(--border_D7D6D7);
            }

            input {
                padding: 6px 0px;
                font-size: 14px;
                line-height: 14px;
                font-family: var(--font-stack);
                color: var(--activation_text);

                &::placeholder {
                    color: var(--placeholder-color);
                    opacity: 1;
                }
            }

            label {
                -webkit-transform: translate(14px, 11px) scale(1);
                -moz-transform: translate(14px, 11px) scale(1);
                -ms-transform: translate(14px, 11px) scale(1);
                transform: translate(14px, 11px) scale(1);
            }

            label.Mui-focused {
                -webkit-transform: translate(14px, -6px) scale(0.75);
                -moz-transform: translate(14px, -6px) scale(0.75);
                -ms-transform: translate(14px, -6px) scale(0.75);
                transform: translate(14px, -6px) scale(0.75);
            }

            .MuiInputLabel-shrink {
                -webkit-transform: translate(14px, -6px) scale(0.75);
                -moz-transform: translate(14px, -6px) scale(0.75);
                -ms-transform: translate(14px, -6px) scale(0.75);
                transform: translate(14px, -6px) scale(0.75);
            }
        }
    }

    .controls-group {
        display: flex;
        align-items: center;
        gap: 5px;

        button {
            display: flex;
            color: var(--icon_color);

            svg {
                color: var(--icon_color);
            }

            &:hover {
                background: var(--color_f1f3f4);
            }
        }
    }

    .controlUiSection_top_right {
        button {
            display: flex;
            color: var(--textWhite);
            border: 1px solid var(--border_color_DFDFDF);
            font-size: 12px;
            font-weight: 500;
            font-family: var(--font-stack);
            padding: 2px 12px;
            border-radius: 8px;
            min-height: 32px;
            min-width: auto;
            text-transform: none;
            background-color: var(--btnbgcolor) !important;

            svg {
                color: var(--icon_color);
            }

            &:hover {
                background-color: var(--btnhover) !important;
            }
        }
    }

    .controlUiSection_bottom {
        width: 100%;
        padding-block-start: 0px;
        background: var(--tab_bg_E8E8E8);
        border-top: 1px solid var(--border_D7D6D7);
        border-bottom: 1px solid var(--border_D7D6D7);

        .tab-sections {
            // padding-inline: 10px;
            display: flex;
            align-items: center;
            height: 26px;
            overflow: hidden;
            padding-inline-end: 5px;

            .tab-item {
                background: var(--tab_bg_E8E8E8);
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                width: 100%;
                max-width: 240px;
                padding: 6px 9px;
                border-radius: 4px 4px 0 0;
                position: relative;
                flex-shrink: 1;
                overflow: hidden;
                min-height: 24px;
                isolation: isolate;

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    z-index: 1;
                    background: var(--tab_bg_E8E8E8);
                    width: 20px;
                    height: 100%;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 1px;
                    background: var(--border_D7D6D7);
                    height: 100%;
                    z-index: 1;
                }

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    line-height: 17px;
                    font-family: var(--font-stack);
                    color: var(--text_color);
                    font-weight: 400;
                    padding-inline-end: 8px;
                }

                .cross {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    position: absolute;
                    right: 4px;
                    z-index: 2;

                    svg {
                        font-size: 12px;
                        color: var(--icon_color);
                    }

                    &:hover {
                        background: #e3e4e7;
                    }
                }

                &:hover {
                    background: var(--active_tab_color_F2F2F2);

                    // &::after{
                    //     display: none;
                    // }
                    &::before {
                        background: var(--active_tab_color_F2F2F2);
                    }
                }

                // & + .tab-item:has(.active){
                //     &::after{
                //         display: none;
                //     }
                // }
                &.active {
                    background: var(--active_tab_color_F2F2F2);
                    // -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.18);
                    // -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.18);
                    // box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.18);
                    z-index: 2;

                    // &::after{
                    //     display: none;
                    // }
                    &::before {
                        background: var(--active_tab_color_F2F2F2);
                    }
                }

                // &.noborder{
                //     &::after{
                //         display: none;
                //     }
                // }
                .title_sec {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    .favicon_box {
                        width: 14px;
                        height: 14px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 100%;
                            object-fit: contain;
                            max-height: 14px;
                        }
                    }
                }
            }

            button {
                display: flex;
                color: var(--icon_color);
                padding: 2px;
                margin-inline: 6px 0;
                z-index: 2;

                svg {
                    color: var(--icon_color);
                }

                &:hover {
                    background: var(--color_f1f3f4);
                }
            }
        }
    }

    .favIcon {
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background: rgba(0, 0, 0, 0.12);
        }
    }
}

.boorkmarkmenu {
    width: fit-content;
    position: absolute;
    z-index: 11;
    top: 2px;
    right: 36px;
    cursor: default;

    .inner_bookmarkbox {
        width: fit-content;
        padding-block: 12px;
        padding-inline: 12px 0px;
        background: var(--background-white);
        box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        display: flex;
        align-items: flex-start;

        .rename_folder {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
                font-size: 12px;
                line-height: 17px;
                font-family: var(--font-stack);
                color: var(--activation_text);
                font-weight: 400;
                padding-inline-end: 4px;
            }

            .MuiFormControl-root {
                width: 100%;
                max-width: 236px;

                .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
                    background: var(--background-white) !important;

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: var(--parple);

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                .MuiOutlinedInput-root {
                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }

                    &.Mui-focused {
                        border-color: var(--parple);
                    }

                    border-radius: 4px;
                    height: 26px;
                    background: var(--background-white) !important;
                    padding-inline: 12px 8px;

                    .MuiInputAdornment-root {
                        svg {
                            color: var(--icon_color);
                            font-size: 20px;

                            &.qrcodeicon {
                                margin-inline-end: 8px;
                            }
                        }
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: var(--borderlight);
                }

                input {
                    padding: 2px 0px;
                    font-size: 12px;
                    line-height: 14px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    font-weight: 500;

                    &::placeholder {
                        color: var(--placeholder-color);
                        opacity: 1;
                    }
                }
            }

            button {
                display: flex;
                color: var(--textWhite);
                border: 1px solid var(--border_color_DFDFDF);
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                font-family: var(--font-stack);
                padding: 2px 16px;
                border-radius: 4px;
                min-height: 26px;
                min-width: auto;
                background: var(--parple);
                text-transform: none;

                svg {
                    color: var(--icon_color);
                }

                &:hover {
                    background-color: var(--btnhover);
                }
            }
        }

        .close_book_modal {
            width: 36px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: -5px;
        }
    }
}

// tab
// .control_tab_sec{
//     .tabs {
//         flex-grow: 1;
//         display: flex;
//         justify-content: flex-start;
//         align-items: flex-end;
//         flex-wrap: nowrap;
//         width: 420px;
//         padding-right: 10px;
//         li {
//             border-radius: 5px;
//             border-bottom-left-radius: 0;
//             border-bottom-right-radius: 0;
//             width: 100%;
//             padding: 10px 15px;
//             position: relative;
//             background: white;
//             cursor: pointer;
//             height: 24px;
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             flex: 1;
//             min-width: 0;
//             overflow: hidden;
//             position: relative;
//             user-select: none;
//             span {
//                 flex-shrink: 1;
//                 flex-grow: 1;
//                 line-height: 18px;
//                 white-space: nowrap;
//                 display: block;
//                 min-width: 0;
//                 padding-right: 30px;
//                 mask-image: linear-gradient(to left, transparent 20px, #fff 40px);
//                 -webkit-mask-image: linear-gradient(to left, transparent 20px, #fff 40px);
//               }
//               .close {
//                 position: absolute;
//                 top: 0;
//                 bottom: 0;
//                 right: 10px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: flex-end;
//                 flex-shrink: 0;
//               }
//               button {
//                 width: 20px;
//                 height: 20px;
//                 border: 0;
//                 background: #fff;
//                 border-radius: 3px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 stroke: #000;
//                 margin-left: 10px;
//                 cursor: pointer;
//                 flex-shrink: 0;
//               }
//           }
//       }
//       .background {
//         position: absolute;
//         top: 0;
//         left: 0;
//         bottom: 0;
//         width: 300px;
//         background: #fff;
//       }

//       .add-item {
//         width: 30px;
//         height: 30px;
//         background: #eee;
//         border-radius: 50%;
//         border: 0;
//         cursor: pointer;
//         align-self: center;
//       }

//       .add-item:disabled {
//         opacity: 0.4;
//         cursor: default;
//         pointer-events: none;
//       }

// }
