.myClassTabSwitch {
    width: max-content;
    height: max-content;

    .savedClasses.tabContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: max-content;
        height: max-content;

        .MuiTabs-root {
            width: max-content;
            height: max-content;

            .MuiTabs-scroller {
                width: max-content;
                height: max-content;

                .MuiTabs-flexContainer {
                    width: max-content;
                    height: max-content;

                    .tabButton {
                        color: var(--activation_text);
                        text-transform: none;

                        width: 160px;
                        height: 40px;
                        min-height: 0;
                        padding: 0;

                        .participantPanel .count {
                            color: #2a326b;

                            margin-inline-start: 2px;
                            padding-inline: 8px;
                            border-radius: 100px;
                            background: #cfd5ff;
                        }
                    }
                }
            }
        }

        .MuiTabs-indicator {
            width: 160px !important;
            height: 3px;
            border-radius: 100px;
            background-color: var(--tabcolor);
        }

        &.groupTab {
            .MuiTabs-indicator {
                left: 160px !important;
            }
        }
    }
}
