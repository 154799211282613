@use '../../Style.scss' as *;

.dashboard_main {
    width: 100%;
    height: 100%;
    background: var(--background-white);

    .background_sec {
        width: 100%;
        height: 112px;
        // background: url(../../../public/assets/dashboard/hello-page-header-image.png) no-repeat center;
        background: linear-gradient(
            92.34deg,
            #7a4bde -7.17%,
            rgba(204, 194, 253, 0.9) 30.52%,
            #e3e3fd 55.42%,
            rgba(194, 180, 249, 0.8) 74.03%,
            #535dd8 101.2%
        );
    }

    .dashboard_content {
        width: 100%;
        height: calc(100% - 32px);
        position: relative;
        margin-top: -80px;

        .version {
            position: absolute;
            bottom: 10px;
            right: 16px;
            width: fit-content;
            height: fit-content;

            p {
                font-size: 13px;
                line-height: 19px;
                font-weight: 400;
                color: var(--dashboard_url_text);
            }
        }

        .inner_db_content {
            padding: 0 36px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 34px;
        }

        .dashboard_left {
            width: 204px;

            .user_card {
                width: 100%;
                padding-block: 20px 16px;
                padding-inline: 16px;
                min-height: 250px;
                box-shadow: 0px 1px 4px rgba(99, 120, 255, 0.15);
                border-radius: 12px;
                background: var(--background-white);
                border: 1px solid rgba(99, 120, 255, 0.11);

                .profile_image_avater {
                    width: 100%;
                    text-align: center;

                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                    }

                    .MuiAvatar-root {
                        width: 80px;
                        height: 80px;
                        margin: 0 auto;
                        font-size: 38px;
                    }
                }

                .chip_user {
                    padding: 2px 6px;
                    min-height: 20px;
                    min-width: 54px;
                    border-radius: 100px;
                    margin-block-start: 8px;
                    margin-inline: auto;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.basic {
                        background: var(--user-sucess);
                    }

                    &.pro {
                        background: var(--user_pro);
                    }

                    &.trial {
                        background: var(--trialbtn);
                    }

                    p {
                        font-family: var(--font-stack);
                        font-weight: 500;
                        font-size: 10px;
                        text-transform: uppercase;
                        color: var(--textWhite);
                        line-height: 11px;
                    }
                }

                .profile_name_url {
                    width: 100%;
                    margin-block-start: 8px;
                    text-align: center;

                    h2 {
                        color: var(--text_color);
                        font-family: var(--font-stack);
                        font-size: 16px;
                        line-height: 1.2;
                        font-weight: 500;
                        word-break: break-word;
                    }

                    p {
                        color: var(--dashboard_url_text);
                        font-family: var(--font-stack);
                        font-size: 12px;
                        line-height: 17px;
                        font-weight: 400;
                        margin-top: 5px;
                        word-break: break-word;
                    }
                }

                .divider {
                    border: none;
                    border-block-start: 1px solid var(--light_border);
                    margin-block-start: 12px;
                    margin-block-end: 8px;
                }

                .class_name {
                    width: 100%;
                    text-align: center;

                    h3 {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 14px;
                        line-height: 17px;
                        font-weight: 400;
                        word-break: break-word;
                    }
                }

                .certified_tainner {
                    border: 1px solid var(--light_border);
                    padding: 8px 11px;
                    border-radius: 12px;
                    @include displayFlex(row, center, center);
                    gap: 6px;
                    min-width: 136px;
                    width: fit-content;
                    margin-block-start: 14px;
                    margin-inline: auto;
                    cursor: pointer;

                    img {
                        height: 20px;
                    }

                    h4 {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 12px;
                        line-height: 24px;
                        font-weight: 400;
                    }
                }

                .certified_tainner_badge {
                    border: 1px solid var(--light_border);
                    padding: 8px 11px;
                    border-radius: 12px;
                    margin-block-start: 14px;
                    @include displayFlex(row, center, center);
                    gap: 12px;
                    width: fit-content;
                    margin-inline: auto;
                    cursor: pointer;
                    img {
                        height: 20px;
                    }
                }
            }
        }

        .dashboard_right {
            flex: 1;

            .inner_db_right {
                width: 100%;

                .hello {
                    width: 100%;
                    min-height: 80px;

                    h1 {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 1;
                        font-weight: 400;
                    }

                    h2 {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 1;
                        font-weight: 600;
                    }
                }

                // .sessions_wapper {
                //     width: 100%;
                //     display: flex;
                //     margin-block-start: 18px;
                //     align-items: flex-start;
                //     gap: 8px;

                //     .sessions_card {
                //         background: #e3eeff;
                //         border-radius: 12px;
                //         width: 100%;
                //         min-height: 96px;
                //         padding: 6px;
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         flex-direction: column;

                //         .number {
                //             display: flex;
                //             align-items: center;
                //             gap: 6.5px;
                //             justify-content: center;

                //             span {
                //                 color: #3461ff;
                //                 font-family: var(--Inter);
                //                 font-size: 22px;
                //                 line-height: 24px;
                //                 font-weight: 600;
                //             }
                //         }

                //         p {
                //             color: var(--activation_text);
                //             font-family: var(--font-stack);
                //             font-size: 12px;
                //             line-height: 12px;
                //             font-weight: 400;
                //             text-align: center;
                //             margin-block-start: 6px;
                //         }

                //         &.awarded {
                //             background: #fff2d7;

                //             span {
                //                 color: #e4aa31;
                //             }
                //         }

                //         &.responses {
                //             background: #fdf0f1;

                //             span {
                //                 color: #d96367;
                //             }
                //         }
                //     }
                // }

                .quick_link {
                    width: 100%;
                    margin-block-start: 28px;

                    .quick_head {
                        width: 100%;

                        h4 {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 16px;
                            line-height: 14px;
                            font-weight: 500;
                        }
                    }

                    .activited_pro_btn {
                        width: 100%;
                        background: var(
                            --new_user_discount_btn_bg
                        ); // background-image: url(../../../public/assets/dashboard/activateProBannerImage.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        border-radius: 12px;
                        min-height: 94px;
                        color: var(--background-white);
                        text-transform: none;
                        padding-inline: 4px 16px;
                        padding-block: 0;
                        font-size: 14px;
                        line-height: 21px;
                        font-family: var(--font-stack);
                        margin-block-end: 10px;
                        text-align: start;
                        font-weight: 500;
                        gap: 8px;

                        .redBox {
                            width: 144px;
                        }

                        .MuiButton-startIcon {
                            margin: 0;
                            margin-inline-end: 8px;

                            svg {
                                width: 90px !important;
                            }
                        }
                    }

                    .quick_link_url {
                        width: 100%;
                        margin-top: 15px;
                        min-height: 82px;

                        ul {
                            li {
                                margin-top: 5px;

                                .info {
                                    display: inline;
                                    cursor: pointer;
                                    color: var(--reset_color);
                                    font-family: var(--font-stack);
                                    font-size: 14px;
                                    line-height: 16px;
                                    font-weight: 400;
                                    cursor: default;
                                }

                                a {
                                    display: inline;
                                    cursor: pointer;
                                    color: var(--parple);
                                    font-family: var(--font-stack);
                                    font-size: 14px;
                                    line-height: 16px;
                                    font-weight: 400;
                                    @include displayFlex(row, center, flex-start);
                                    gap: 6px;
                                    width: max-content;

                                    img {
                                        max-width: 100%;
                                    }

                                    &:hover {
                                        text-decoration: underline !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .sign_out_user {
                    width: 100%;
                    margin-block-start: 13px;
                    text-align: start;

                    a {
                        display: inline;
                        cursor: pointer;
                        color: var(--danger);
                        font-family: var(--font-stack);
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 400;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }
            }
        }
    }
}

.welcomeComeUser {
    opacity: 1;
    width: 100%;
    margin-inline: auto;
    margin-block: 0;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    // .MuiGrid-container {
    //     margin-block-start: -2vh;
    //     width: calc(100% + 6.8vh);
    //     margin-inline-start: -6.8vh;
    // }
    // .MuiGrid-container > .MuiGrid-item {
    //     padding-inline-start: 6.8vh;
    //     padding-block-start: 2.4vh;
    //     display: grid;
    // }
    .welcomeComeUser_inner {
        text-align: center;
        margin: 0 auto;

        .welcome_img {
            svg {
                width: 175px !important;
                height: 175px !important;
            }
        }
    }

    .welcomeComeUser_content {
        width: 100%;
        text-align: center;
        padding-inline: 15%;
        position: relative;
        margin-block-start: -20px;

        h2 {
            font-family: var(--font-stack);
            color: var(--text_color);
            font-size: 24px;
            font-weight: 500;
        }

        p {
            font-size: 14px;
            line-height: 1.4;
            font-family: var(--font-stack);
            color: var(--activation_text);
            margin-block-start: 12px;

            a {
                font-size: 14px;
                line-height: 1.4;
                color: var(--cpbutton);
                font-family: var(--font-stack);
                text-decoration: underline;
                padding: 0;
                display: inline;
                font-weight: 500;
            }
        }

        button {
            margin-block-start: 20px;
            @include welcome_btn;
            background-color: var(--btnbgcolor);

            .MuiButton-startIcon {
                margin: 0;
                margin-inline-end: 8px;
            }

            &:hover {
                background-color: var(--btnhover);
            }

            &.Mui-disabled {
                opacity: 0.5;
                cursor: default;
                color: var(--textWhite);
            }

            &.margin0 {
                margin-block-start: 8px;
            }

            &.defaultbtn {
                background-color: transparent;
                color: var(--parple);

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
                }
            }
        }

        // .welcome_check {
        //     margin-block-start: 2.8vh;
        //     label {
        //         margin: 0;
        //         margin-inline-start: -2vh;
        //         position: relative;
        //         .MuiCheckbox-root {
        //             padding: 2vh;
        //             // padding-inline-start: 0;
        //             &.Mui-checked {
        //                 color: var(--parple);
        //             }
        //         }
        //         .MuiSvgIcon-fontSizeMedium {
        //             // width: 5.5vh;
        //             // height: 4.5vh;
        //             font-size: 5.6vh;
        //         }
        //         .MuiTypography-body1 {
        //             font-size: 3.9vh;
        //             line-height: 3.9vh;
        //         }
        //     }
        // }
    }
}
