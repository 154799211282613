@use '../../Style.scss' as *;

#scrolldiv {
    scroll-behavior: smooth;
}

#scrolldiv1 {
    scroll-behavior: smooth;
}

.activity_leaderboard_main {
    width: 100%;
    height: 100%;
    background: var(--leaderboardbg);

    &.leaderloading {
        background: rgb(255, 255, 255);
        background: var(--gradientbg);

        .loading {
            background: none;
        }
    }

    .leaderBoardDefault_sec {
        width: 100%;
        height: var(--activitycontentHeight);

        .leader_board_card_body {
            width: 100%;
            height: 100%;
        }

        .leaderBoardContainer {
            width: 100%;
            height: 100%;
            margin-inline: auto;
            margin-block: 0;
            padding: 0 8px;

            &.empty {
                padding: 0 8px;
            }
        }

        .leader_board_inner {
            width: 100%;
            margin-inline: auto;
            margin-block: 0;
            height: var(--activitycontentHeight);
            position: relative;

            &.current {
                overflow: auto;
                padding-block-end: 26px;
                padding-block-start: 10px;
            }

            .leaderboard_card {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: center;

                .leaderboard_left {
                    width: 500px;
                    height: 444px;
                    padding-block: 22px;

                    .leader_board_rank {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        isolation: isolate;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            background: radial-gradient(
                                50% 50% at 50% 50%,
                                rgba(246, 243, 255, 0.6) 54.17%,
                                rgba(246, 243, 255, 0) 100%
                            );
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                        }
                    }
                }

                .leaderboard_left + .leaderboard_right {
                    padding-inline-end: 40px;

                    .card_warp {
                        min-width: 410px;
                    }

                    ul {
                        margin-inline-start: auto;
                        margin-inline-end: 0;
                    }
                }

                .leaderboard_right {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-block: 22px 16px;
                    overflow: overlay;
                    height: 484px;

                    ul {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 13.5px;
                        margin-inline: auto;
                        margin-block: 0;

                        li {
                            position: relative;
                            z-index: 111;
                            width: 100%;
                        }
                    }
                }
            }

            .card_warp {
                width: 100%;
                background: #fff;
                display: flex;
                align-items: center;
                padding: 8px 14px;
                background: linear-gradient(90deg, rgba(218, 222, 255, 0.5) 0%, rgba(218, 222, 255, 0) 50%), #fafbff;
                width: 100%;
                min-width: 520px;
                margin-inline: auto;
                margin-block: 0;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 6px 2px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                isolation: isolate;

                .card_number {
                    padding-inline-end: 8px;
                    position: relative;
                    z-index: 0;

                    .number_box {
                        min-width: 28px;
                        text-align: start;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 28px;
                        width: fit-content;
                        margin-inline: auto;
                        margin-block: 0;

                        h1 {
                            font-size: 16px;
                            line-height: 21px;
                            color: var(--activation_text);
                            font-weight: 500;
                            font-family: var(--font-stack);
                        }

                        img {
                            width: 36px;
                        }
                    }
                }

                .card_body {
                    -webkit-box-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    padding-inline-start: 0;

                    .profileimg {
                        display: flex;

                        .MuiAvatar-root {
                            width: 36px;
                            height: 36px;
                            font-size: 15px;
                        }
                    }

                    .profileimg img {
                        width: 36px;
                        height: 36px;
                    }

                    .body_box {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        h4 {
                            display: inline-block;

                            font-family: var(--font-stack);
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 19px;
                            color: var(--activation_text);

                            width: 170px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .card_level_point {
                    padding-inline-start: 5px;
                    gap: 20px;
                    display: flex;
                    align-items: center;

                    .points {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .point_icon {
                            display: flex;
                            width: 20px;
                        }

                        img {
                            max-width: 100%;
                        }

                        p {
                            font-size: 16px;
                            line-height: 17px;
                            color: var(--activation_text);
                            font-weight: 500;
                            font-family: var(--font-stack);
                        }
                    }

                    .level {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        justify-content: center;
                        min-width: 55px;

                        .level_icon {
                            display: flex;
                            height: 32px;
                        }

                        img {
                            max-width: 100%;
                        }

                        p {
                            font-size: 16px;
                            line-height: 17px;
                            color: var(--activation_text);
                            font-weight: 500;
                            font-family: var(--font-stack);
                        }
                    }
                }
            }
        }
    }
}

.confetti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: auto !important;
        height: 475px !important;
    }
}

.leader_board_chart {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-block: auto;
    margin-inline: 0;
    padding-inline: 85px;
    height: 100%;
    gap: 22px;

    li {
        position: relative;
        padding-block-start: 0px;
        display: flex;
        align-items: center;
        margin-block-start: 24px;

        .tooltip_wrap {
            position: relative;
            display: flex;
            align-items: center;
            padding-inline-start: 14px;
            gap: 16px;

            .chart_tooltip {
                color: var(--activation_text);
                background: rgba(255, 255, 255, 0.5);
                font-weight: 500;
                font-family: var(--font-stack);
                padding-block: 5.5px;
                padding-inline: 10px 12px;
                font-size: 16px;
                line-height: 17px;
                border-radius: 60px;
                min-width: 58px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                img {
                    width: 24px;
                }
            }

            .profile_name {
                padding-inline-end: 0;
                display: none;
                max-width: 120px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .leader_board_number {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        height: 28px;
        padding: 0 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        left: 6px;
        background: var(--background-white);
        border-radius: 50%;

        &.no_background {
            background: none;
        }

        p {
            color: var(--activation_text);
            font-weight: 700;
            font-family: var(--font-stack);
            font-size: 16px;
            line-height: 21px;
        }
    }

    .bargraph {
        position: relative;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        min-width: 2px;
        display: flex;
        align-items: center;
        cursor: pointer;
        min-width: 92px;
        width: 720px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 6px 2px rgba(0, 0, 0, 0.05);
        background: linear-gradient(90deg, rgba(218, 222, 255, 0.5) 0%, rgba(218, 222, 255, 0) 50%), #fafbff;

        &.min_width {
            & > .profile_name {
                display: none;
            }
        }

        &.min_width + .tooltip_wrap {
            .profile_name {
                display: block;
            }
        }

        &.first {
            background: linear-gradient(90deg, #e9b500 0%, rgba(255, 235, 132, 0) 50%), #fafbff;
        }

        &.second {
            background: linear-gradient(90deg, #cfcfcf 0%, rgba(233, 233, 233, 0) 50%), #fafbff;
        }

        &.third {
            background: linear-gradient(90deg, #ff8340 0%, rgba(255, 209, 184, 0) 50%), #fafbff;
        }

        .profile_name {
            display: inline-block;

            font-family: var(--font-stack);
            font-size: 15px;
            font-weight: 500;
            line-height: 19px;
            color: var(--activation_text);

            // width: 145px;
            width: fit-content;
            text-align: end;
            padding-inline: 40px 68px;
            margin-inline-start: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .Lbprofile_img {
            display: flex;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            right: 0px;

            .MuiAvatar-root {
                width: 56px;
                height: 56px;
                font-size: 24px;
            }

            img {
                width: 56px;
                height: 56px;
                font-size: 15px;
            }
        }
    }
}

.leaderboard_left {
    .leader_board_rank_sec {
        height: 100%;

        .leader_board_rank_barchart {
            width: 100%;
            height: 100%;

            .leader_board_rank_chart {
                display: table;
                table-layout: fixed;
                padding-top: 16px;
                margin: 0 auto;
                height: 100%;
                background-size: 100% 50px;
                background-position: left top;

                li {
                    position: relative;
                    display: table-cell;
                    vertical-align: bottom;
                    width: 140px;

                    .level_display {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        margin-block-start: 12px;
                        padding-inline: 8px 9px;
                        padding-block: 4px;
                        background: rgba(255, 255, 255, 0.7);
                        border-radius: 100px;

                        img {
                            width: 36px;
                        }

                        p {
                            font-size: 18px;
                            line-height: 18px;
                            color: var(--activation_text);
                            font-weight: 500;
                            font-family: var(--font-stack);
                            position: relative;
                            z-index: 1;
                        }
                    }

                    &:first-child {
                        &::after {
                            width: 100%;
                            height: 44px;
                            position: absolute;
                            bottom: -2px;
                            left: 0px;
                            content: '';
                            background: url(../../../public/assets/leaderboard/cup2.svg) no-repeat center;
                        }

                        & > div {
                            background: #ebebeb;
                            box-shadow: 0px 0.635542px 1.27108px rgba(0, 0, 0, 0.05),
                                0px 0.635542px 3.81325px 1.27108px rgba(0, 0, 0, 0.05),
                                inset -12px 0px 0px rgba(0, 0, 0, 0.25);
                            border-radius: 20px 20px 12px 12px;
                            height: 142px;

                            &::after {
                                width: 100%;
                                height: 65%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                font-size: 40px;
                                line-height: 40px;
                                content: '2';
                                font-weight: 900;
                                font-family: var(--font-stack);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #8b8b8b;
                                z-index: 2;
                            }
                        }
                    }

                    &:nth-of-type(2) {
                        &::after {
                            width: 100%;
                            height: 64px;
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            content: '';
                            background: url(../../../public/assets/leaderboard/cup1.svg) no-repeat center;
                        }

                        & > div {
                            background: #ffdc69;
                            box-shadow: 0px 0.635542px 1.27108px rgba(0, 0, 0, 0.05),
                                0px 0.635542px 3.81325px 1.27108px rgba(0, 0, 0, 0.05);
                            border-radius: 20px 20px 12px 12px;
                            height: 178px;

                            &::after {
                                width: 100%;
                                height: 65%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                font-size: 48px;
                                line-height: 48px;
                                content: '1';
                                font-weight: 900;
                                font-family: var(--font-stack);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #f59300;
                                z-index: 2;
                            }
                        }
                    }

                    &:nth-of-type(3) {
                        &::after {
                            width: 100%;
                            height: 42px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: '';
                            background: url(../../../public/assets/leaderboard/cup3.svg) no-repeat center;
                        }

                        & > div {
                            background: #ffd1b8;
                            box-shadow: 0px 0.635542px 1.27108px rgba(0, 0, 0, 0.05),
                                0px 0.635542px 3.81325px 1.27108px rgba(0, 0, 0, 0.05), inset 12px 0px 0px #ff9f6a;
                            border-radius: 20px 20px 12px 12px;
                            height: 128px;

                            &::after {
                                width: 100%;
                                height: 65%;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                font-size: 40px;
                                line-height: 40px;
                                content: '3';
                                font-weight: 600;
                                font-family: var(--font-stack);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #d8601d;
                                overflow: hidden;
                                z-index: 2;
                            }
                        }
                    }

                    &.no_place {
                        .bar {
                            .level_display {
                                display: none;
                            }
                        }

                        .chart_tooltip {
                            display: none;

                            .crown_img {
                                display: none;
                            }

                            .point_star_box {
                                display: none;
                            }

                            & > p {
                                display: none;
                            }
                        }
                    }
                }

                .bar {
                    margin: 0 0%;
                    display: block;
                    position: relative;
                    border-radius: 12px 12px 0 0;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    i {
                        display: none;

                        svg {
                            display: flex;
                            font-size: 2rem;
                        }
                    }
                }

                .chart_tooltip {
                    position: relative;
                    width: 100%;
                    color: #fff;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 14px;
                    border-radius: 5px;
                    font-size: 20px;
                    color: var(--textWhite);
                    font-weight: 500;
                    font-family: var(--font-stack);
                    text-align: center;
                    padding-block-end: 20px;
                    display: block;

                    p {
                        font-size: 16px;
                        line-height: 18px;
                        text-align: center;
                        color: var(--activation_text);
                        font-weight: 500;
                        font-family: var(--font-stack);
                        padding-inline: 5px;
                        word-break: break-word;
                    }

                    .crown_avater {
                        position: relative;

                        &.double {
                            .MuiAvatar-root {
                                position: relative;
                                left: 10%;
                                z-index: 2;

                                &:nth-of-type(2) {
                                    left: inherit;
                                    right: 10%;
                                    z-index: 1;
                                }
                            }
                        }

                        .crown_img {
                            position: absolute;
                            top: -34px;
                            right: 16px;
                        }

                        .images {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .left_image {
                            position: absolute;
                            top: 42%;
                            transform: translateY(-50%);
                            left: 8%;
                            z-index: 0;
                            width: 45px;
                            height: 45px;
                            font-size: 19px;
                            margin-block-end: 0;
                            opacity: 1;
                            border: 4px solid var(--textWhite);
                        }

                        .right_image {
                            position: absolute;
                            top: 42%;
                            transform: translateY(-50%);
                            right: 8%;
                            z-index: 0;
                            width: 45px;
                            height: 45px;
                            font-size: 19px;
                            margin-block-end: 0;
                            opacity: 1;
                            border: 4px solid var(--textWhite);
                        }
                    }

                    .MuiAvatar-root {
                        width: 56px;
                        height: 56px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: var(--font-stack);
                        border: 4px solid var(--textWhite);
                        font-size: 24px;
                        font-weight: 500;
                        margin-block-end: 12px;
                        margin-inline: auto;
                        position: relative;
                        z-index: 3;

                        &.noAvatar {
                            border: none;
                        }
                    }

                    .point_star_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 6px;
                        margin-block-start: 16px;

                        img {
                            width: 24px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 16px;
                            color: var(--activation_text);
                            font-weight: 500;
                            font-family: var(--font-stack);
                            position: relative;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

.leaderboard_wating {
    height: var(--activitycontentHeight);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-block-start: 30px;
    flex-direction: column;
    width: 100%;
    background: var(--gradientbg);

    // img {
    //     width: 64px;
    //     height: 84px;
    //     box-sizing: content-box;
    //     padding-block-end: 41px;
    // }

    svg {
        height: 270px !important;
    }

    h4 {
        color: var(--activation_text);
        font-size: 22px;
        font-weight: 400;
    }

    h5 {
        color: var(--cpbutton);
        font-family: var(--font-stack);
        font-size: 22px;
        font-weight: 400;
        // margin-block-start: 30px;
    }
}

// update_top_pro
.modal {
    &.update_top_pro {
        .back_drop {
            background-image: url(../../../public/assets/leaderboard/LeaderBoardTotalstarsrank.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .modal_box {
            .modal_body {
                .foot_modal {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-block-start: 24px;

                    button {
                        min-width: 136px;
                        min-height: 40px;
                        width: auto;
                        height: auto;
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
                        border-radius: 40px;
                        font-size: 16px;
                        font-family: var(--font-stack);
                        color: var(--parple);
                        text-transform: none;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
