.savedClassGroupView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;

    &.myClassView {
        height: max-content;

        .ungroupedParticipantCount {
            position: static;
        }
    }

    .noGroupText {
        max-width: 330px;
        padding-block: 26px 40px;
    }

    .autoGroupBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        color: var(--textWhite);
        text-transform: none;

        margin-top: 20px;
        padding-block: 8px;
        padding-inline: 20px;
        border-radius: 5px;
        background: var(--backgroundcolor);

        &:hover {
            background: var(--btnhover);
        }
    }

    .ungroupedParticipantCount {
        position: absolute;
        inset: auto 0 12px 0;

        font-size: 14px;
        color: var(--greytext_color);

        margin-inline: auto;
    }
}

.addOrEditGroupModal {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .modal_viewport {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bgOverlay {
        position: absolute;
        inset: 0;
        z-index: 2;

        width: 100%;
        height: 100%;
    }

    .modalContent {
        position: relative;
        z-index: 3;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 648px;
        height: 420px;
        border-radius: 16px;
        background: var(--background-white);

        .body {
            position: relative;

            width: 100%;
            height: calc(100% - 160px);
            padding-inline: 25px;
            flex-shrink: 0;

            &.showOverflow {
                padding-inline: 0px;
            }

            .errorText {
                position: absolute;
                inset: auto 0 10px 0;
                z-index: 100;

                font-size: 14px;
                color: var(--textWhite);
                text-align: center;

                width: max-content;
                margin-inline: auto;
                padding-block: 3px;
                padding-inline: 10px;
                border-radius: 3px;
                background: var(--error_span_color);
            }
        }
    }
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    width: 70%;
    height: 100%;
    margin-inline: auto;
    // padding-block-end: 50px;

    .imageContainer {
        width: 150px;
        height: 150px;

        .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .text {
        font-size: 14px;
        color: var(--activation_text);
        line-height: 20px;
        text-align: center;
    }

    .upgradeToProBtn {
        font-weight: 500 !important;
        color: var(--textWhite) !important;
        line-height: 19px;
        text-transform: none !important;
        white-space: nowrap;

        width: max-content;
        max-width: max-content !important;
        height: 40px;
        padding-inline: 35px !important;
        padding-block-start: 2px !important;
        border: none !important;
        border-radius: 1000px !important;
        background: linear-gradient(90deg, var(--primary-color) 24.31%, #e963ff 105.79%);
    }
}
