@import '../../../../Style.scss';

.noQuestion {
    width: 100%;
    height: 100%;

    .inner_noQuestion {
        width: 100%;
        height: 100%;
        @include displayFlex(column, center, center);
        gap: 40px;

        .icon {
            width: 100%;
            max-width: 380px;
            text-align: center;
            margin-inline: auto;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .footer {
            width: 100%;
            min-height: 88px;
        }

        .text {
            font-size: 21px;
            // font-weight: var(--fw_medium);
            color: var(--activation_text);
        }
    }
}
