@use '../../../Style.scss' as *;

.verifyEmailWarning {
    width: 100%;
    padding-inline: 30px;

    .text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        font-size: 14px;
        line-height: 18px;
        color: #49454f;

        width: 100%;

        padding-block: 8px;
        padding-inline: 16px;
        border-radius: 4px;
        background: #fff2d7;

        .email {
            display: inline-block;

            width: min-content;
            max-width: 260px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .link {
            color: #da7f14;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.noBackDrop .MuiBackdrop-root {
    background: transparent !important;
    background-color: transparent !important;
}
