.groupModalHeader {
    position: relative;

    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 60px;

    .leftSide {
        position: absolute;
        inset: 0 auto 0 20px;

        height: max-content;
        margin-block: auto;
    }

    .editGroup {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 7px;

        .groupName {
            font-size: 18px;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;

            width: max-content;
            max-width: 160px;
            overflow: hidden;
        }

        .groupColor {
            display: inline-block;

            width: 15px;
            height: 15px;
            margin-inline-end: 2px;
            border-radius: 100%;
        }

        .editIcon {
            font-size: 24px;
            color: var(--btnbgcolor);

            cursor: pointer;
        }
    }

    :is(.backIcon, .closeIcon) {
        position: absolute;

        width: 40px;
        height: 40px;
        margin-block: auto;

        border-radius: 100%;
        cursor: pointer;

        .icon {
            font-size: 25px;
            color: var(--activation_text);

            height: 100%;
        }
    }

    .closeIcon {
        inset: 0 20px 0 auto;
        padding: 5px;
    }

    .heading {
        font-size: 16px;
        font-weight: 400;
        color: var(--activation_text);
    }

    .starCount {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        font-size: 15px;
        color: var(--text_gray);

        padding-block: 6px;
        padding-inline: 16px;
        border-radius: 100px;
        background: var(--btnbgcolor_light);

        .starIcon {
            width: 17px;
            height: 17px;
            margin-block-end: 2px;
            margin-inline-start: 3px;
        }

        .highlight {
            font-size: 18px;
            font-weight: 500;
            color: var(--activation_text);
        }
    }
}
