@use '../../Style.scss' as *;
// whiteboard

.whiteBoard_setting_sec {
    width: 100%;
    height: 100%;

    .body_head {
        padding: 17px 36px;
        background: var(--background-white);
        display: flex;
        align-items: center;
        min-height: 66px;

        &.space_between {
            justify-content: space-between;
        }

        &.box_shadow {
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
        }

        h2 {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            font-family: var(--font-stack);
            color: var(--activation_text);
        }

        .addbtn {
            margin-inline-start: 16px;

            button {
                @include step_btn;
                // padding: 5.5px 13px 5.5px 8px;
                padding-block: 5.5px;
                padding-inline-end: 13px;
                padding-inline-start: 8px;
                line-height: 15px;
                min-height: 32px;

                .MuiButton-startIcon {
                    margin-inline-end: 2px;
                }

                &:hover {
                    background-color: var(--btnhover);
                }
            }
        }
    }

    .inner_whiteboard_langing {
        @include displayFlex(column, center, center);
        height: 100%;
    }

    .whiteBoard_landingpage {
        width: 100%;
        // padding-block-start: 35px;

        .whiteboardContainer {
            width: 100%;
            margin-inline: auto;
            margin-block: 0;
            padding: 0 8px;
        }

        .inner_whiteBoard_landingpage {
            width: 100%;
            text-align: center;
        }

        .info_content {
            width: 100%;
            margin-inline: auto;
            margin-block: 0;
            max-width: 400px;

            .title {
                font-size: 24px;
                line-height: 29px;
                font-weight: 500;
                font-family: var(--font-stack);
                color: var(--parple);
            }

            p {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: var(--font-stack);
                color: var(--activation_text);
                margin-block-start: 12px;
            }
        }
    }

    .whiteboard_Image_sec {
        width: 100%;

        .whiteboardContainer {
            width: 100%;
            padding: 0 8px;

            .inner_whiteboard_img {
                width: 100%;
                overflow: hidden auto;
                height: 376px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding-inline-start: 10px;
                padding-block-start: 12px;

                .fallBackImage {
                    min-height: 104px;
                }

                .MuiMasonry-root {
                    align-content: flex-start;
                }

                .images_boxs {
                    border: 1px solid var(--borderlight);
                    box-shadow: none !important;
                    border-radius: 8px;

                    .big_sh_body {
                        height: calc(100% - 32px);
                        cursor: default;
                        border-start-start-radius: 8px;
                        border-start-end-radius: 8px;
                    }

                    .big_sh_body + img {
                        height: 128px;
                        border-radius: 0;
                        border-start-start-radius: 8px;
                        border-start-end-radius: 8px;
                        object-fit: cover;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--background-white);
                        width: 100%;
                    }

                    .loading_img {
                        height: 128px;
                        width: 100%;
                        border-radius: 0;
                        border-start-start-radius: 12px;
                        border-start-end-radius: 12px;
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            height: 24px;
                            color: var(--textWhite);
                            display: flex;
                        }
                    }
                }

                .sh_footer {
                    min-height: 32px;
                    padding-block: 3.5px;
                    padding-inline-start: 12px;
                    padding-inline-end: 4px;
                    border-top: 1px solid var(--borderlight);
                    border-radius: 0 0 8px 8px;

                    .profile_foot {
                        p {
                            line-height: 19px;
                        }

                        .MuiInput-underline {
                            input {
                                color: var(--activation_text);
                                font-family: var(--font-stack);
                                font-size: 13px;
                                font-weight: 400;
                                padding-block-end: 2px;
                                padding-inline-end: 0px;
                                padding-inline-start: 0px;
                                padding-block-start: 0;

                                &::placeholder {
                                    color: var(--placeholder-color);
                                    opacity: 1;
                                }
                            }

                            &.Mui-error {
                                &::after {
                                    border-block-end: 1px solid var(--danger);
                                }
                            }

                            &::after {
                                border-block-end: 1px solid var(--parple);
                            }

                            &::before {
                                border-block-end: 1px solid var(--secondary_text);
                            }
                        }
                    }
                }
            }
        }
    }
}

// watch_tt_btn
.watch_tt_btn {
    margin-block-start: 40px;
    @include displayFlex(row, center, center);

    .watchTutorialContainer {
        &.blue {
            .watchTutorialBtn {
                color: var(--textWhite);
                background-color: var(--btnbgcolor);

                &:hover {
                    background-color: var(--btnhover);
                }
            }
        }
    }
}

.whiteBoard_landingpage_btn_group {
    display: flex;
    align-items: center;
    margin-block-start: 5px;
    padding: 0 20px;
    justify-content: center;
    margin-block-start: 32px;
    gap: 16px;
    width: 100%;

    .imagesbtn {
        width: 100%;
        max-width: 180px;

        input {
            display: none;
        }
    }

    button {
        @include whiteBoard_landingpage_btn;
        flex-direction: column;
        gap: 18px;
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;

        &:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
        }

        svg {
            height: 24px;
        }

        .MuiButton-startIcon {
            transform: rotate(-45deg) translateX(1px);
        }

        .MuiTouchRipple-ripple {
            .MuiTouchRipple-child {
                background-color: var(--Ripplebg_color);
            }
        }

        &.Mui-disabled {
            color: var(--activation_text);
        }
    }
}

.whiteboardupload_modal {
    padding: 0 !important;
    position: absolute;
    top: 0%;
    left: 0%;
    bottom: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    // transform: translate(-50%, -50%);
    width: fit-content;
    max-width: 465px;
    min-width: 352px;
    background-color: #fff;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
        0px 9px 46px 8px rgb(0 0 0 / 12%);
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    border-radius: 16px;

    &.wdth_400 {
        max-width: 400px;
        min-width: 400px;
    }

    .whiteBoard_landingpage_btn_group {
        margin-block-start: 0;
        padding: 32px;
    }

    .upload_image_modal {
        padding: 16px;

        .closebtn {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            svg {
                font-size: 18px;
                cursor: pointer;
            }
        }

        .img_upload_warp {
            height: 234px;
            margin-block-start: 6px;
            background: var(--background-white);
            border-radius: 0px;
            overflow: hidden;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 320px;
            max-width: 420px;
            border: 1px solid var(--borderlight);

            img {
                object-fit: cover;
                min-height: 234px;
                max-height: 234px;
                width: 100%;
            }
        }
    }

    .whiteboard_field {
        width: 100%;
        margin-block-start: 7px;
        min-height: 62px;

        .MuiFormControl-root {
            width: 100%;

            p.Mui-error {
                padding-inline-start: 0px;
                color: var(--danger);
                font-size: 12px;
                line-height: 16px;
                font-family: var(--font-stack);
                font-weight: 400;
            }

            .MuiInputLabel-root {
                color: var(--text-gray);
                -webkit-transform: translate(0px, 18px) scale(1);
                -moz-transform: translate(0px, 18px) scale(1);
                -ms-transform: translate(0px, 18px) scale(1);
                transform: translate(0px, 18px) scale(1);
                font-size: 14px;
                font-family: var(--font-stack);

                &.Mui-focused {
                    color: var(--parple);
                }

                &.MuiInputLabel-shrink {
                    -webkit-transform: translate(0px, 3px) scale(0.75);
                    -moz-transform: translate(0px, 3px) scale(0.75);
                    -ms-transform: translate(0px, 3px) scale(0.75);
                    transform: translate(0px, 3px) scale(0.75);
                }
            }

            .MuiInput-underline {
                input {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 400;
                    padding-block-end: 6px;
                    padding-inline-end: 6px;
                    padding-inline-start: 0px;
                    padding-block-start: 0;

                    &::placeholder {
                        color: var(--placeholder-color);
                        opacity: 1;
                    }
                }

                &.Mui-error {
                    &::after {
                        border-block-end: 1px solid var(--danger);
                    }
                }

                &::after {
                    border-block-end: 1px solid var(--parple);
                }

                &::before {
                    border-block-end: 1px solid var(--secondary_text);
                }
            }
        }
    }

    .button_sec {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        margin-block-start: 6px;

        &.justify_center {
            justify-content: center;
        }

        button {
            @include theme_btn;
            padding: 7px 20px !important;
            font-size: 14px;
            color: var(--btnbgcolor);
            min-height: 35px !important;
            min-width: 118px !important;

            &.MuiButton-containedPrimary {
                color: var(--textWhite);
                background-color: var(--btnbgcolor) !important;

                &:hover {
                    background-color: var(--btnhover) !important;
                }
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: 0;
                display: flex;
                padding-inline: 0 5px;
            }
        }
    }
}
