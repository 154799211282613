@import '../../../Style.scss';

.quickPoll_score {
    width: 100%;
}
.quickPoll_sec {
    width: 100%;
    margin-inline: auto;
    margin-block: 0;
    .quickPool_container {
        height: var(--activitycontentHeight);
        background: radial-gradient(circle, var(--background-white) 0%, #dadeff 100%);
        .inner_barchart {
            width: 100%;
            // height: calc(100vh - 157px);
            height: var(--activitycontentHeight);

            .chart {
                &.qpTrueFalse {
                    gap: 24px;
                }
                &.qpYesNoUnsure {
                    gap: 24px;
                    .wrong {
                        .quickpool_img {
                            img {
                                width: 56px;
                            }
                            .typeA {
                                p {
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
                &.qpRating {
                    gap: 24px;
                    .wrong {
                        .quickpool_img {
                            img {
                                width: 48px;
                            }
                            .typeA {
                                p {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
                &.qpMultiple {
                    gap: 24px;

                    .wrong {
                        .quickpool_img {
                            .typeA {
                                p {
                                    font-size: 24px;
                                    width: 45px;
                                }
                            }
                        }
                    }
                    &.qpMultipleFour {
                        gap: 20px;
                    }
                    &.qpMultipleFive {
                        gap: 20px;
                    }
                    &.qpMultipleSix {
                        gap: 16px;
                    }
                    &.qpMultipleSeven {
                        gap: 12px;
                    }
                    &.qpMultipleEight {
                        gap: 10px;
                    }
                }
                &.checking {
                    li {
                        &.wrong {
                            div {
                                background: #e4e0e5 !important;
                            }
                        }

                        div {
                            span {
                                background: #e4e0e5;
                                color: #4e4951;

                                &::after {
                                    border-block-start-color: #e4e0e5;
                                }
                            }

                            i {
                                display: block;
                                color: var(--textWhite);
                            }

                            div.no_height {
                                position: absolute;
                                top: -86px;

                                i {
                                    display: block;
                                    color: rgb(13, 172, 61);
                                }
                            }
                        }
                    }
                }

                display: table;
                table-layout: fixed;
                // padding-block-start: 16px;
                width: 100%;
                // max-width: 900px;
                margin-block: auto;
                margin-inline: 0;
                padding-block-end: 45px;
                padding-block-start: 35px;
                padding-inline: 50px;
                // min-height: 450px;
                height: 100%;

                background-size: 100% 50px;
                background-position: left top;

                display: flex;
                flex-direction: column;
                // gap: 24px;
                justify-content: center;

                li {
                    position: relative;
                    // display: table-row;
                    // vertical-align: bottom;
                    padding-block-start: 0px;
                    display: flex;
                    align-items: center;
                }

                div {
                    i {
                        display: none;

                        svg {
                            display: flex;
                            font-size: 48px;
                        }
                    }

                    .chart_tooltip {
                        position: absolute;
                        top: 50%;
                        right: -76px;
                        transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        color: var(--textWhite);
                        background: var(--activation_text);
                        font-weight: 600;
                        padding: 4px;
                        font-size: 18px;
                        line-height: 28px;
                        border-radius: 36px;
                        width: 58px;
                        text-align: center;
                    }
                }
                .quickpool_img {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-inline-end: 10px;
                    max-width: 150px;
                    .img_icon {
                        width: fit-content;
                        height: fit-content;
                        display: flex;
                    }

                    p {
                        color: var(--activation_text);
                        font-weight: 500;
                        font-family: var(--font-stack);
                        line-height: 20px;
                        width: 84px;
                        word-break: break-word;
                    }

                    & .typeA p {
                        font-size: 28px;
                    }

                    & .typeB p {
                        font-size: 24px;
                    }

                    & .typeC p {
                        font-size: 18px;
                    }

                    & .typeD p {
                        font-size: 24px;
                        width: 24px;
                    }
                }
                .bargraph {
                    // flex: 1;
                    // margin-inline-end: 70px;
                    position: relative;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 56px;
                    // width: 80%;
                    min-width: 2px;
                    // background: aqua;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &.quickpoll_noresponse {
                        cursor: default;
                    }
                }
            }
        }
    }
}

//Keyframes

// html.rtl{
// .quickPoll_sec {
//     .quickPool_container {
//         .inner_barchart {
//             .chart {
//                 div {
//                     .chart_tooltip {
//                         left: -76px;
//                         right: inherit;
//                     }
//                 }
//             }
//         }
//     }
// }
// }
