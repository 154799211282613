@import '../../Style.scss';

.participant_content_sec {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;

    margin-inline: auto;
    margin-block: 0;

    .participant_sec_container {
        width: 100%;
        margin-inline: auto;
        margin-block: 0;
    }

    .participant_card_container {
        width: 100%;
        // max-width: 916px;
        // padding: 0 20px;
        margin-inline: auto;
        margin-block: 0;

        display: flex;
    }

    .participant_head_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3vh;
        flex-wrap: wrap;

        height: 52px;
        // padding: 20px 0;
        // padding-block-start: 20px;
        padding-block-start: 8px;

        .participant_joined_wrapper {
            display: flex;
            gap: 0.7vh;
            align-items: center;

            .classFull {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            span {
                padding: 4px 8px;
                background: #cfd5ff;
                color: #2a326b;
                // @include  head_h33;
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                font-family: var(--font-stack);
                border-radius: 20px;

                &.red_span {
                    background: var(--red)-span-bg;
                    color: var(--redspan-color);
                }
            }
        }

        .sort_wrapper {
            width: 182px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 8px;
            position: relative;

            & > span {
                font-family: var(--font-stack);
                font-size: 13px;
                font-weight: 500;
                line-height: 13px;
                color: var(--text_color);
                white-space: nowrap;
            }

            .MuiOutlinedInput-root {
                border-radius: 18px !important;
                padding: 5px 8px !important;
                font-size: 13px !important;
                min-width: auto;
                color: var(--parple) !important;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff !important;
                font-weight: 500;
                height: 28px;
                display: flex;
                align-items: center;
                text-transform: none;

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }

                // &:hover {
                //     border: 1px solid #8293ff ;
                // }
                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid #cfd5ff;
                }

                .MuiSelect-select {
                    padding: 9px 0;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    line-height: 20px;

                    svg {
                        font-size: 20px;
                    }
                }

                .MuiButton-startIcon {
                    margin: 0;
                    margin-inline-end: 0.6vh;
                    display: flex;
                    font-size: 2vh;

                    svg {
                        font-size: 3.1vh;
                    }
                }
            }
        }

        h2 {
            // @include  head_h2;
            font-size: 24px;
            line-height: 24px;
            font-family: var(--font-stack);
            font-weight: 500;
            color: var(--activation_text);
            margin-block-start: 1.4vh;
            margin-block-start: 0;

            .red {
                color: var(--redspan-color);
                font-weight: 500;
                background: none;
            }
        }

        .searchfield {
            width: 100%;
            width: 180px;

            .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
                background: var(--background-white) !important;

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            // margin: 0 auto 18px;
            .MuiOutlinedInput-root {
                border-radius: 30px;
                background: var(--background-white);

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            .MuiOutlinedInput-root {
                &.Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border-color: var(--parple);
                    }
                }

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            svg {
                color: var(--activation_text);
                cursor: pointer;
            }

            input {
                padding: 8px 14px;
                font-size: 14px;
                line-height: 14px;
                font-family: var(--font-stack);
                color: var(--activation_text);

                &::placeholder {
                    color: var(--placeholder-color);
                }
            }

            label {
                -webkit-transform: translate(14px, 11px) scale(1);
                -moz-transform: translate(14px, 11px) scale(1);
                -ms-transform: translate(14px, 11px) scale(1);
                transform: translate(14px, 11px) scale(1);
            }

            label.Mui-focused {
                -webkit-transform: translate(14px, -6px) scale(0.75);
                -moz-transform: translate(14px, -6px) scale(0.75);
                -ms-transform: translate(14px, -6px) scale(0.75);
                transform: translate(14px, -6px) scale(0.75);
            }

            .MuiInputLabel-shrink {
                -webkit-transform: translate(14px, -6px) scale(0.75);
                -moz-transform: translate(14px, -6px) scale(0.75);
                -ms-transform: translate(14px, -6px) scale(0.75);
                transform: translate(14px, -6px) scale(0.75);
            }
        }

        .show_response {
            display: none;
        }
    }

    .participent_card {
        display: flex;
        align-items: flex-start;
        gap: 5px 0px;
        flex-wrap: wrap;

        width: 100%;
        height: min-content;
        max-height: 418px;
        // max-height: 300px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        .level_count_box {
            position: absolute;
            left: 9px;
            top: 12px;

            width: 30px;
            height: 30px;

            display: flex;
            transform-origin: center;
            transition: var(--transition);

            .level_icon {
                img {
                    width: 100%;
                    display: flex;
                }
            }
        }

        .profile {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;

            & .avatar_image {
                position: relative;
                z-index: 100;
            }

            .MuiAvatar-root {
                width: 52px;
                height: 52px;
                border: 2px solid var(--background-white);
                text-transform: uppercase;
                -webkit-backface-visibility: hidden;

                svg {
                    font-size: 30px;
                }

                img {
                    width: 52px;
                    height: 52px;
                }
            }

            & .status {
                width: 10px;
                height: 10px;
                background-color: var(--offlineClass);
                border-radius: 50%;
                position: absolute;
                right: 2px;
                top: 2px;
                outline: 3px solid var(--textWhite);
                z-index: 100;

                &.online {
                    background-color: var(--onlineClass);
                }
            }
        }

        span.profile_name {
            font-family: var(--font-stack);
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            color: var(--activation_text);
            text-align: center;
            padding-block-start: 5px;
            width: 108px;
            white-space: nowrap;
            display: block;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-inline: auto;

            &.smallFont {
                font-size: 9px;

                span {
                    font-size: 9px;
                }
            }
        }

        .card_wraper {
            // background: var(--background-white);
            width: 145.5px;
            min-height: 128px;
            padding: 8px 4px;

            border: 1px solid transparent;
            box-sizing: border-box;
            border-radius: 12px;
            display: flex;
            align-items: center;
            flex-direction: column;
            // justify-content: center;
            gap: 4px;
            position: relative;
            isolation: isolate;
            // cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                top: 48px;
                left: 0;
                right: 0;
                width: 112px;
                margin-inline: auto;
                height: 68px;
                z-index: -1;
                border-radius: 8px;
            }

            .starbtn img {
                width: 24px;
            }

            .small_card_box {
                position: relative;
                isolation: isolate;
                width: 100%;
                text-align: center;
                // display: none;

                .button_wraper {
                    margin-inline: auto;
                    margin-block-start: 7px;
                    display: none;
                    -webkit-backface-visibility: hidden;

                    &.border_r_none {
                        .starbtn {
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                        }
                    }

                    &.myclassBtn {
                        -webkit-backface-visibility: hidden;
                        display: none;
                        align-items: center;
                        border-radius: 40px;
                        background-color: var(--parple);
                        min-width: 100px;
                        margin-inline: auto;
                        visibility: hidden;
                        opacity: 0;

                        .decrement {
                            width: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)),
                                #ffffff;
                            min-height: 28px;
                            border-radius: 0 40px 40px 0;
                            transition: var(--transition);
                            cursor: pointer;

                            &.disable {
                                cursor: default;
                                background: var(--pin_bg_hover_color);
                                pointer-events: none;

                                svg {
                                    path {
                                        stroke: var(--graybar);
                                        fill: none;
                                    }
                                }
                            }

                            svg {
                                path {
                                    stroke: var(--submission_btn);
                                    fill: none;
                                }
                            }

                            &:hover {
                                background: var(--red_hover);

                                svg {
                                    path {
                                        stroke: var(--submission_btn);
                                        fill: var(--submission_btn);
                                    }
                                }
                            }
                        }

                        .starbtn {
                            padding-block: 0;
                            // border-radius: 40px 0px 0px 40px;
                            flex: 1;
                        }
                    }

                    .point_box2 {
                        left: 11px;
                    }
                }
            }

            .close {
                position: absolute;
                top: 6px;
                right: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                background: var(--borderlight);
                visibility: hidden;
                opacity: 0;
                // transition: var(--transition);
                // -webkit-transition: var(--transition);
                // -moz-transition: var(--transition);
                cursor: pointer;

                svg {
                    display: flex;
                    font-size: 16px;
                    color: var(--textWhite);
                }
            }

            .starbtn {
                visibility: hidden;
                opacity: 0;
                min-width: 28px;
                min-height: 28px;
                transition: var(--transition);
                -webkit-transition: var(--transition);
                -moz-transition: var(--transition);
                padding: 6px 12px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;

                p {
                    font-size: 14px;
                    line-height: 15px;
                }

                img {
                    height: 16px;
                    width: 16px;
                }
            }

            &:hover {
                &::after {
                    // box-shadow: 0px 8px 20px -12px rgba(99, 120, 255, 0.2);
                    display: none;
                    // width: 100%;
                    // height: 100%;
                    // top: 0;
                }

                .level_count_box {
                    transform: scale(1.3);
                }

                .small_card_box {
                    display: block;

                    .button_wraper {
                        display: flex;
                        visibility: visible;
                        opacity: 1;
                    }
                }

                .point_show_box {
                    display: none;
                }

                .close {
                    visibility: visible;
                    opacity: 1;
                    // transition: var(--transition);
                    // -webkit-transition: var(--transition);
                    // -moz-transition: var(--transition);
                }

                .starbtn {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .point_show_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 12px;
            margin-block-start: 7px;

            .point_count_box {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    height: 18px;
                }

                p {
                    font-size: 14px;
                    line-height: 17px;
                    font-family: var(--font-stack);
                    color: var(--text_color);
                    font-weight: 500;
                }
            }

            .level_icon {
                display: flex;
            }

            .level_count_box {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                    height: 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 17px;
                    font-family: var(--font-stack);
                    color: var(--text_color);
                    font-weight: 500;
                }
            }
        }
    }
}

.MuiOutlinedInput-root.MuiInputBase-colorPrimary {
    border-radius: 200px;
    height: 32px;
    color: var(--btnbgcolor);

    .MuiOutlinedInput-input {
        box-shadow: none;
    }

    .MuiSvgIcon-root {
        color: var(--btnbgcolor);
        cursor: pointer;
    }

    &:hover {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--backgroundcolor);
        }
    }

    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--backgroundcolor);
        }
    }

    &:active {
        background: var(--light-bg) !important;

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid var(--backgroundcolor);
        }
    }

    &:focus {
        outline: 0;
        border: 1px solid var(--btnbgcolor);
    }
}

.MuiList-padding.MuiMenu-list {
    padding: 0vh 0;
    background: var(--background-white);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 6px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    li {
        font-size: 14px;
        line-height: 14px;
        padding: 5px 10px;
        min-height: 4vh;
        color: var(--activation_text);
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
            font-size: 18px;
            color: var(--parple);
        }

        &.Mui-selected:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)), #ffffff !important;
        }

        &.Mui-selected {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)), #ffffff !important;
        }

        &:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)), #ffffff !important;
        }

        .MuiTouchRipple-child {
            background-color: var(--Ripplebg_color);
        }

        // &:active {
        //     background: linear-gradient(
        //             0deg,
        //             rgba(99, 120, 255, 0.2),
        //             rgba(99, 120, 255, 0.2)
        //         ),
        //         #ffffff !important;
        // }
    }
}

.sort {
    min-width: 180px;

    .content {
        position: relative;

        // cursor: pointer;
        .visible {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: fit-content;
            gap: 8px;

            & > p {
                font-family: var(--font-stack);
                font-size: 13px;
                font-weight: 400;
                color: var(--text_color);
                white-space: nowrap;
            }

            & > button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 6px;
                width: 96px;
                height: 32px;
                padding: 6px 10px;
                color: var(--backgroundcolor);
                // border: 1px solid #cfd5ff;
                border-radius: 20px;
                background: var(--background-white);

                .MuiTouchRipple-root {
                    .MuiTouchRipple-child {
                        background-color: rgba(0, 0, 0, 0.31);
                    }
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:nth-of-type(2) {
                        margin-inline-start: auto;
                    }

                    svg {
                        font-size: 20px;
                        font-weight: 500;
                        color: var(--parple);
                    }
                }

                p {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    text-transform: none;
                    color: var(--activation_text);
                    width: 74px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .listItemContainer {
            display: none;
            border-radius: 4px;
            padding-block: 4px;

            &.show {
                position: absolute;
                inset: 48px 0 auto auto;
                z-index: 10001;

                display: inline-block;
                width: max-content;
                height: fit-content;
                background: var(--background-white);
                box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);

                & > button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 6px;
                    color: var(--backgroundcolor);
                    width: 100%;
                    min-width: 147px;
                    height: 36px;
                    padding: 10px 10px;
                    background: var(--background-white);
                    border-radius: 0;

                    &:hover {
                        background: var(--parple_08);
                    }

                    &:nth-of-type(1) {
                        border-radius: 0;
                        border-start-start-radius: 4px;
                        border-start-end-radius: 4px;
                    }

                    &:nth-last-of-type(1) {
                        border-radius: 0;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    &.active {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;

                        .icon {
                            opacity: 1;
                        }
                    }

                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;

                        svg {
                            font-size: 20px;
                            font-weight: 500;
                            color: var(--parple);
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: none;
                        color: var(--activation_text);
                        text-align: start;
                    }
                }
            }

            .MuiSwitch-root {
                width: 28px;
                height: 16px;
                padding: 0;
            }

            .online_control {
                border-top: 1px solid var(--borderMain);

                label {
                    margin: 0;
                }
            }

            label {
                .MuiTypography-root {
                    font-size: 14px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    line-height: 20px;
                    font-weight: 400;
                    margin-inline-start: 8px;
                    text-transform: none;
                }
            }

            .MuiSwitch-thumb {
                width: 14px;
                height: 14px;
            }

            .Mui-checked + .MuiSwitch-track {
                background: var(--parple) !important;
                opacity: 1 !important;
            }

            .MuiSwitch-track {
                border-radius: 24px;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                opacity: 1;
            }

            .MuiSwitch-switchBase {
                padding: 1px;

                &.Mui-checked {
                    color: var(--background-white);
                    -webkit-transform: translateX(12px);
                    -moz-transform: translateX(12px);
                    -ms-transform: translateX(12px);
                    transform: translateX(12px);
                }
            }
        }
    }

    .bgOverlay {
        display: none;

        &.show {
            position: absolute;
            inset: 0 0 0 0;
            z-index: 10000;

            display: block;

            width: 100%;
            height: 100%;
            background-color: transparent;
        }
    }
}

// switch_classes
.modal_box.myclass_alert {
    .media {
        position: relative;

        &.flex_col {
            &.switch_classes_body {
                padding: 24px 8px 8px;

                & > p {
                    padding-inline: 14px;
                }
            }
        }
    }
}

.saveClassOption {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .menu_checkbox {
        flex-direction: row;
        justify-content: flex-start;
        gap: 15px;
        width: 100%;
        flex-wrap: nowrap;

        label {
            margin: 0;

            &:first-child {
                min-width: 120px;
            }

            &.Mui-disabled {
                .MuiTypography-root {
                    color: var(--disable_bg_AEAAAE);
                }

                .MuiCheckbox-root {
                    color: var(--disable_bg_AEAAAE);
                }
            }
        }

        .MuiTypography-root {
            font-size: 14px;
            color: var(--activation_text);
            font-family: var(--font-stack);
            font-weight: 400;
            line-height: 20px;
        }

        .MuiCheckbox-root {
            color: var(--placeholder-color);
            padding: 5px;

            &.Mui-checked {
                color: var(--parple);
            }

            svg {
                position: relative;
                top: 0px;
                display: flex;
            }
        }
    }
}

.switch_classes {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
    max-height: 250px;
    overflow: auto;
    padding-inline: 14px;

    .switch_participent_box {
        width: auto;
        min-width: 234px;
        min-height: 110px;
        border: 2px solid var(--borderMain);
        border-radius: 12px;
        padding: 16px;
        cursor: pointer;
        background: var(--background-white);

        &.demo {
            .total_participent {
                .p_class_code {
                    .MuiChip-root {
                        background: var(--editview_chip_bg);
                    }
                }
            }

            .participent_grid_top {
                .profile {
                    .profile_text {
                        .title {
                            color: var(--parple);
                        }
                    }
                }
            }
        }

        &:hover {
            background: var(--parple_08);
        }

        &.active {
            border: 2px solid var(--parple);
        }

        &.center {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        h4 {
            font-size: 16px;
            font-family: var(--font-stack);
            font-weight: 400;
            text-align: center;
            line-height: 22px;
            color: var(--activation_text);
        }

        .ramdom_code {
            width: auto;
            padding-block: 0px;
            padding-inline: 8px;
            display: flex;
            align-items: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--editview_chip_bg);
            border-radius: 22px;
            margin-block-start: 8px;
            min-height: 24px;

            span {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                padding: 0;
            }
        }

        .participent_grid_top {
            min-height: auto;

            .profile {
                display: flex;
                align-items: flex-start;
                gap: 10px;

                .profile_img {
                    width: auto;

                    .MuiAvatar-root {
                        height: 40px;
                        width: 40px;
                        // background: var(--light-bg);
                        outline: 1px solid #cfd5ff7a;
                        font-size: 19px;

                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                .profile_text {
                    flex: 1;

                    .title {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        text-align: start;
                        word-break: break-word;
                        max-width: 146px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .total_participent {
            width: 100%;
            display: flex;
            align-items: center;
            margin-block-start: 6px;

            svg {
                color: var(--secondary_text);
                font-size: 20px;
            }

            span {
                display: inline-block;
                color: var(--secondary_text);
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 17px;
                font-weight: 400;
                margin-inline-start: 4px;
            }

            .p_class_code {
                width: auto;
                text-align: start;
                margin-inline-end: 10px;

                .MuiChip-root {
                    background: var(--light-bg);
                    height: 24px;
                    padding: 0px 8px;
                }

                .MuiChip-label {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 500;
                    padding: 0;
                }
            }
        }
    }
}

.myClassParentContainer {
    position: relative;

    .levelImage {
        position: absolute;
        inset: 12px auto auto 9px;
        z-index: 1;

        width: 30px;
        height: 30px;
    }
}

.participant_content_sec {
    &:not(.aiQuizSingleResponse) {
        height: var(--activitycontentHeight);
        padding: 0 8px;
        background: var(--gradientbg);

        .participant_sec_container {
            max-width: 876px;
        }

        .participent_card {
            padding-inline-start: 55px;
            padding-inline-end: 50px;
            padding-block-end: 12px;
            padding-block-start: 20px;

            &.showSidebar {
                padding-inline-start: 40px;
                padding-inline-end: 30px;
            }
        }

        .card_wraper {
            &::after {
                background: var(--background-white);
                border: 1px solid #6378ff25;
            }

            &:hover {
                background: var(--background-white);
                border: 1px solid var(--borderlight);
            }
        }
    }

    &.aiQuizSingleResponse {
        .participent_card {
            overflow: visible;
            justify-content: center;

            .myClassParentContainer {
                width: 100%;

                .levelUpAnimation {
                    position: absolute;
                    left: 5px;

                    .levelBadge {
                        width: 35px;
                        height: 35px;
                    }
                }

                .card_wraper {
                    width: 100%;
                    height: 200px;
                    padding: 18px 24px;
                    background: var(--background-white);
                    border: 1px solid var(--borderlight);

                    .profile {
                        .avatar_image {
                            .MuiAvatar-root {
                                width: 100px;
                                height: 100px;
                                font-size: 34px;
                                font-weight: 500;
                                line-height: 39px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }

                        .status {
                            width: 20px;
                            height: 20px;
                            border: 4px solid var(--borderWhite);
                            outline: none;
                            right: 4px;
                            top: 4px;
                        }
                    }

                    .small_card_box {
                        .profile_name {
                            font-size: 16px;
                            line-height: 19px;
                            color: var(--text_color);
                            font-weight: 500;
                        }

                        .point_show_box {
                            margin-block-start: 12px;

                            .point_count_box {
                                gap: 6px;

                                img {
                                    height: 20px;
                                }

                                .MuiTypography-root {
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: var(--text_color);
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
