@use '../../Style.scss' as *;

.sharepdf_sec {
    width: 100%;
    background: var(--background-white);
    height: 100%;
    .generate_sec {
        width: 100%;
        height: 100%;
        padding: 24px;
        .generate_head {
            width: 100%;
            text-align: center;
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                font-family: var(--font-stack);
                color: var(--text_color);
            }
        }
    }
    .MuiDivider-root {
        border-color: var(--light-bg);
        width: 100%;
        margin-block: 24px 14px;
        margin-inline: 0;
    }
    .annotaion {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .MuiFormControl-root {
            margin: 0;
        }
    }
    .checkbox {
        .Mui-disabled {
            .MuiCheckbox-root {
                opacity: 0.4;
            }
        }
        .MuiTypography-body1 {
            font-family: var(--font-stack);
            color: var(--activation_text) !important;
            line-height: 16px;
            font-size: 14px;
            font-weight: 400;
            padding-inline-start: 0px;
        }

        .caption {
            font-family: var(--font-stack);
            color: var(--greytext_color);
            line-height: 13px;
            font-size: 13px;
            font-weight: 400;
            display: block;
            padding-inline-start: 29px;
        }

        .MuiCheckbox-root {
            color: var(--placeholder-color);
            padding: 5px;

            &.Mui-checked {
                color: var(--parple);
            }

            svg {
                position: relative;
                top: -1px;
            }
        }
    }
    .profile_update_btn {
        width: 100%;
        padding-block-start: 18px;
        text-align: center;
        button {
            @include profile_update_btn;
            background-color: var(--btnbgcolor) !important;

            &:hover {
                background-color: var(--btnhover) !important;
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: 0;
                display: flex;
                padding-inline: 0 5px;
            }

            &.danger_btn {
                background-color: var(--footer_btn) !important;

                &:hover {
                    background-color: var(--no_more_red) !important;
                }
            }
        }
    }
    .generatingpdfloading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        .MuiCircularProgress-root {
            color: var(--cpbutton);
        }
        svg {
            width: 300px !important;
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            font-family: var(--font-stack);
            color: var(--parple);
            padding-block-end: 40px;
        }
    }
    .qrcodeGenerated {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        .qrcodebbox {
            width: 244px;
            height: 244px;
            margin-inline: auto;
            // canvas{
            //     width: 100% !important;
            // height: 100% !important;
            // }
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            font-family: var(--font-stack);
            color: var(--parple);
            a {
                cursor: pointer;
                text-decoration: underline !important;
                color: var(--parple);
            }
        }
        .profile_update_btn {
            padding-block-start: 10px;
        }
    }
}

.Shareimg_btn_group {
    display: flex;
    align-items: center;
    margin-block-start: 5px;
    justify-content: center;
    margin-block-start: 20px;
    gap: 16px;

    .imagesbtn {
        width: 100%;
        max-width: 180px;

        input {
            display: none;
        }
    }

    button {
        @include whiteBoard_landingpage_btn;
        flex-direction: column;
        gap: 18px;
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
        max-width: 168px;
        &:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
        }

        svg {
            height: 24px;
        }

        .MuiButton-SPartIcon {
            transform: rotate(-45deg) translateX(1px);
        }

        .MuiTouchRipple-ripple {
            .MuiTouchRipple-child {
                background-color: var(--Ripplebg_color);
            }
        }
        &.active {
            outline: 2px solid var(--parple);
        }
    }
}
