@use '../../Style.scss' as *;

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

.helpdeskBody {
    width: 100%;
    min-width: 345px;
    height: 100%;
    background: var(--background-white);
    min-height: 400px;

    .helpdeskHead {
        background: var(--parple_08);
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            color: var(--parple);
            font-size: 16px;
            line-height: 16px;
            font-family: var(--font-stack);
            font-weight: 500;
        }

        .helpdesk_right {
            width: auto;

            svg {
                color: var(--parple);
                display: flex;
                font-size: 20px;
            }
        }
    }

    .helpdeskForm_sec {
        width: 100%;

        .helpdeskfrom_inner {
            padding: 20px;
            position: relative;

            .error {
                position: absolute;
                bottom: 2px;

                p {
                    color: var(--danger);
                    font-size: 12px;
                    line-height: 12px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                }
            }

            .helpFromLable {
                width: 100%;

                h4 {
                    color: var(--text_color);
                    font-size: 14px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                }
            }

            .pf_form {
                width: 100%;
                min-height: 60px;
                position: relative;
                margin-block-start: 18px;

                input {
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                }

                .MuiFormControl-root {
                    width: 100%;
                    margin: 0;

                    p.Mui-error {
                        padding-inline-start: 0px;
                        color: var(--danger);
                        font-size: 12px;
                        line-height: 16px;
                        font-family: var(--font-stack);
                        font-weight: 400;
                        text-align: start;
                    }

                    .MuiInputLabel-root {
                        font-family: var(--font-stack);
                        font-size: 14px;
                        color: var(--user_emailtext);
                        -webkit-transform: translate(16px, 10px) scale(1);
                        -moz-transform: translate(16px, 10px) scale(1);
                        -ms-transform: translate(16px, 10px) scale(1);
                        transform: translate(16px, 10px) scale(1);

                        &.Mui-focused {
                            color: var(--parple);
                        }

                        &.MuiInputLabel-shrink {
                            -webkit-transform: translate(16px, -7px) scale(0.75);
                            -moz-transform: translate(16px, -7px) scale(0.75);
                            -ms-transform: translate(16px, -7px) scale(0.75);
                            transform: translate(16px, -7px) scale(0.75);
                        }
                    }

                    .Mui-disabled {
                        &.MuiInput-underline {
                            &::after {
                                display: none;
                            }

                            &::before {
                                display: none;
                            }
                        }

                        input {
                            -webkit-text-fill-color: var(--activation_text);

                            & + .MuiInputAdornment-root {
                                display: none;
                            }
                        }
                    }

                    .MuiOutlinedInput-root {
                        border-radius: 4px;
                        height: 38px;

                        &:active {
                            background: var(--background-white) !important;
                        }

                        input {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 14px;
                            font-weight: 400;
                            padding: 0;
                            padding-block: 8px;
                            padding-inline: 16px;

                            &::placeholder {
                                color: var(--placeholder-color);
                                opacity: 1;
                            }
                        }

                        &.Mui-error {
                            &::after {
                                border-block-end: 1px solid var(--danger);
                            }
                        }

                        &::after {
                            border-block-end: 1px solid var(--parple);
                        }

                        &::before {
                            border-block-end: 1px solid var(--secondary_text);
                        }

                        .MuiOutlinedInput-input {
                            box-shadow: none;
                        }
                    }
                }
            }

            .testField {
                width: 100%;
                border: 1px solid rgba(121, 116, 126, 0.5);
                border-radius: 4px;
                padding: 16px;
                max-width: 480px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                height: 170px;
                // margin-block-start: 20px;

                textarea {
                    resize: none;
                    height: 100% !important;
                    border: none;
                    outline: none;
                    width: 100%;
                    padding: 0;
                    // margin-block-end: 10px;
                    overflow-y: auto !important;
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 400;

                    &::placeholder {
                        color: var(--placeholder-color);
                    }
                }

                .images_upload_area {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex-wrap: wrap;
                    margin-block-start: 10px;

                    .upload_imagesbox {
                        width: auto;
                        position: relative;

                        .MuiAvatar-root {
                            background: linear-gradient(0deg, rgba(99, 120, 255, 0.12), rgba(99, 120, 255, 0.12)),
                                #ffffff;
                        }

                        .cross {
                            position: absolute;
                            top: -4px;
                            right: -4px;
                            width: 14px;
                            height: 14px;
                            background: #bbbbbb;
                            border-radius: 50%;
                            display: grid;
                            place-items: center;

                            svg {
                                color: var(--textWhite);
                                font-size: 11px;
                                display: flex;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .helper_btn_group {
        display: flex;
        align-items: center;
        margin-block-start: 5px;
        padding: 0 20px;
        gap: 11px;

        .imagesbtn {
            width: 100%;
            max-width: 235px;

            button {
                color: var(--parple);
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;

                &:hover {
                    background-color: rgba(25, 118, 210, 0.04);
                    color: var(--parple);
                }

                .MuiButton-startIcon {
                    transform: rotate(-45deg) translateX(1px);
                }
            }

            input {
                display: none;
            }
        }

        button {
            @include helper_btn;
            color: var(--textWhite);
            background-color: var(--btnbgcolor);
            gap: 4px;
            align-items: center;
            line-height: 14px;

            &.MuiLoadingButton-loading {
                .MuiButton-startIcon {
                    display: none;
                }
            }

            .MuiButton-startIcon {
                display: inherit;
                margin-inline-end: 0px;
                margin-inline-start: 0px;
                transform: rotate(-45deg) translateX(4px);

                svg {
                    font-size: 16px;
                }
            }

            &:hover {
                background-color: var(--btnhover);
            }
        }
    }

    .helpDeskMessage {
        width: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        padding: 0 28px;

        & > div svg {
            width: 160px;
            margin-inline: auto;
            margin-block: 0;
        }

        & + .helper_btn_group {
            justify-content: center;
            margin-block-start: 28px;

            .imagesbtn {
                text-align: center;
            }

            button {
                max-width: 124px !important;
            }
        }

        p {
            color: var(--text_color);
            font-size: 14px;
            line-height: 17px;
            font-family: var(--font-stack);
            font-weight: 400;
        }
    }
}
