@import '../../../Style.scss';

:root {
    --imageUrl: '';
}

// short_answer_sec
.short_answer_sec {
    width: 100%;
    // padding-block-start: 16px;
    // height: 77vh;
    // background: var(--lightbg);
    background: rgb(255, 255, 255);
    background: var(--gradientbg);
    // height: calc(100vh - 157px);
    height: var(--activitycontentHeight);

    &.activity_short_ans,
    &.activity_image_upload,
    &.activity_slide_drawing,
    &.activity_audio_record {
        padding-block-start: 0;

        .searchfield {
            padding-block-start: 16px;
        }

        .short_ans_wrap {
            margin-block-start: 0;
            height: var(--activitycontentHeight);
            padding-inline: 18px;
            padding-block-start: 15px;
            padding-block-end: 5px;
        }
    }

    &.activity_video_upload {
        .short_ans_wrap {
            margin-block-start: 0;
            height: var(--activitycontentHeight);
            overflow: hidden;
            padding-inline: 18px 0;
            padding-block-start: 0px;
            padding-block-end: 0px;
        }
    }

    &.activity_image_upload {
        .short_ans_wrap {
            .images_boxs {
                box-shadow: none;

                &.shadow {
                    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                    &:hover {
                        -webkit-transition: var(--transition);
                        -moz-transition: var(--transition);
                        -o-transition: var(--transition);
                        transition: var(--transition);
                        box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
                    }
                }
            }
        }
    }

    .shortAnswerContainer {
        // max-width: 976px;
        width: 100%;
        margin-inline: auto;
        margin-block: 0;
        padding: 0 8px;

        .search_area + .short_ans_wrap {
            height: 416px;
            margin-block-start: 16px;
            padding-block-start: 0;
        }
    }

    .short_ans_wrap {
        width: 100%;
        overflow: hidden auto;
        margin-block-start: 16px;
        height: 420px;
        margin-block-end: 0px;
        padding: 0 18px;

        .loading {
            background: none;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 11;
            cursor: pointer;
        }

        .MuiMasonry-root {
            align-content: flex-start;

            &.shortans_300 {
                .content_box {
                    max-height: 310px;
                    overflow: auto;
                    padding-inline-start: 0;
                    padding-inline-end: 4px;
                    padding-block-end: 0;
                    overflow: hidden;
                    min-height: 56px;

                    .shortmodal_content {
                        overflow: auto;
                        max-height: 270px;
                        padding-block: 6px 10px;
                        padding-inline-end: 4px;
                        padding-inline-start: 10px;
                    }
                }
            }
        }
    }

    .search_area {
        position: relative;
        min-height: 52px;
    }

    .searchfield {
        width: 100%;

        width: 240px;
        margin-inline: auto;
        margin-block: 0;

        .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
            background: none !important;

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: var(--parple);

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }

        .MuiOutlinedInput-root {
            &.Mui-focused {
                border-color: var(--parple);
            }

            border-radius: 30px;
            height: 36px;

            .MuiInputAdornment-root {
                svg {
                    color: var(--activation_text);
                }
            }

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: var(--borderMain);
        }

        input {
            padding: 6px 14px;
            font-size: 14px;
            line-height: 14px;
            font-family: var(--font-stack);
            color: var(--activation_text);

            &::placeholder {
                color: var(--placeholder-color);
                opacity: 1;
            }
        }

        label {
            -webkit-transform: translate(14px, 11px) scale(1);
            -moz-transform: translate(14px, 11px) scale(1);
            -ms-transform: translate(14px, 11px) scale(1);
            transform: translate(14px, 11px) scale(1);
        }

        label.Mui-focused {
            -webkit-transform: translate(14px, -6px) scale(0.75);
            -moz-transform: translate(14px, -6px) scale(0.75);
            -ms-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
        }

        .MuiInputLabel-shrink {
            -webkit-transform: translate(14px, -6px) scale(0.75);
            -moz-transform: translate(14px, -6px) scale(0.75);
            -ms-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
        }
    }

    .searchfield + .show_response {
        text-align: end;
        right: 24px;
        left: inherit;
    }

    .show_response {
        position: absolute;
        top: 25px;
        text-align: center;
        left: 0;
        right: 0;
        margin-inline: auto;

        p {
            font-size: 15px;
            line-height: 20px;
            font-family: var(--font-stack);
            color: var(--greytext_color);
            font-weight: 400;
        }
    }

    .inner_short_answer {
        width: 100%;

        .sh_box {
            background: var(--textWhite);
            /* CP2/Elevation Light/3 */
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            border-radius: 12px;

            &:hover {
                -webkit-transition: var(--transition);
                -moz-transition: var(--transition);
                -o-transition: var(--transition);
                transition: var(--transition);
                box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
            }

            .content_box {
                padding: 15px 10px;
                width: 100%;
                text-align: start;
                position: relative;
                cursor: pointer;

                .like_count {
                    width: auto;
                    padding: 4px 9px;
                    border: 2px solid var(--textWhite);
                    background: #ef476f;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                    position: absolute;
                    top: -14px;
                    right: 10px;

                    svg {
                        color: var(--textWhite);
                        font-size: 13px;
                        display: flex;
                    }

                    span {
                        color: var(--textWhite);
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                p {
                    font-family: var(--mali);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    // line-height: 20px;
                    color: var(--text_color);
                    word-break: break-word;

                    * {
                        font-family: var(--mali);
                        font-size: 15px;
                        // line-height: 20px;
                    }

                    strong,
                    b {
                        font-weight: bold;
                    }

                    ul {
                        padding-inline-start: 20px;

                        li {
                            list-style: disc;
                        }
                    }

                    ol {
                        padding-inline-start: 20px;
                        margin: 0;

                        li {
                            list-style: decimal;
                        }
                    }
                }

                .shortAnswerResponseText {
                    height: max-content;
                    max-height: 300px;
                    overflow: auto;
                }
            }
        }
    }
}

ul.sky-slider__img-container.short_modal {
    width: 480px;
    margin-inline: auto;
    margin-block: 0;

    .big_sh_box {
        .big_sh_content {
            padding-inline-start: 0;
            padding-inline-end: 8px;
            padding-block-end: 0;
            overflow: hidden;
            flex-direction: column;

            .shortmodal_content {
                padding-block-start: 6px;
                padding-block-end: 20px;
                padding-inline: 20px;
                height: 326px;
                overflow: auto;
            }
        }
    }
}

.short_ans_modal {
    .MuiBox-root {
        position: absolute;
        top: 0%;
        left: 0%;
        bottom: 0;
        right: 0;
        margin: auto;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        // min-width: 700px;
        border: none !important;
        outline: none !important;
        // background: rgba(255, 255, 255, 0.274);
        padding: 30px;

        .big_sh_box {
            background: var(--textWhite);
            /* CP2/Elevation Light/3 */
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            border-radius: 12px;
            width: 100%;
            height: fit-content;
            margin-inline: auto;
            margin-block: 0;
            position: relative;

            .big_sh_content {
                padding: 20px;
                text-align: center;
                min-height: 280px;
                border-radius: 0;
                border-start-start-radius: 12px;
                border-start-end-radius: 12px;

                .quote {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-block-end: 30px;
                    margin-block-start: 10px;

                    svg {
                        width: 50px;
                        display: flex;
                        // transform: rotate(180deg);
                    }
                }

                p,
                .shortmodal_paragraph {
                    font-family: var(--mali);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 28px;
                    // line-height: 1.32;
                    color: var(--text_color);
                    text-align: center;
                    // padding: 0 40px;
                    text-align: start;
                    word-break: break-word;

                    &.size26 {
                        font-size: 26px;

                        * {
                            font-size: 26px;
                        }
                    }

                    &.size24 {
                        font-size: 24px;

                        * {
                            font-size: 24px;
                        }
                    }

                    &.size22 {
                        font-size: 22px;

                        * {
                            font-size: 22px;
                        }
                    }

                    &.size20 {
                        font-size: 20px;

                        * {
                            font-size: 20px;
                        }
                    }

                    &.size18 {
                        font-size: 18px;

                        * {
                            font-size: 18px;
                        }
                    }

                    &.size16 {
                        font-size: 16px;

                        * {
                            font-size: 16px;
                        }
                    }

                    * {
                        font-family: var(--mali);
                        word-break: break-word;
                        font-size: 28px;
                    }

                    strong,
                    b {
                        font-weight: bold;
                    }

                    ul {
                        padding-inline-start: 28px;

                        li {
                            list-style: disc;
                        }
                    }

                    ol {
                        padding-inline-start: 28px;
                        margin: 0;

                        li {
                            list-style: decimal;
                        }
                    }
                }
            }

            .big_sh_head {
                width: 100%;
                padding: 15px 10px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .like_count {
                    width: auto;
                    padding: 4px 8px;
                    border: 3px solid var(--textWhite);
                    background: #ef476f;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                    svg {
                        color: var(--textWhite);
                        font-size: 18px;
                        display: flex;
                    }

                    span {
                        color: var(--textWhite);
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }

        /*Sky Slider Styles */
    }
}

.loading_img {
    height: 104px;
    width: 100%;
    border-radius: 0;
    border-start-start-radius: 12px;
    border-start-end-radius: 12px;
    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        height: 24px;
        color: var(--textWhite);
        display: flex;
    }
}

// images

.images_boxs {
    position: relative;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

    .overlayAnimationGlobal.imageUpload {
        height: 100%;
    }

    // cursor: pointer;
    .overflow_hidden {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: column;

        .images_content {
            bottom: 0;
        }
    }

    &:hover {
        -webkit-transition: var(--transition);
        -moz-transition: var(--transition);
        -o-transition: var(--transition);
        transition: var(--transition);
        box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
    }

    .paperImage {
        border-radius: 0;
        border-start-start-radius: 12px;
        border-start-end-radius: 12px;
        border: none;
    }

    .thumbnail_images {
        border-radius: 0 !important;
        border-start-start-radius: 12px !important;
        border-start-end-radius: 12px !important;
        // height: 100px;
        height: fit-content;
        min-height: var(--thumnailMin-height);
        max-height: var(--thumnailMax-height);
        object-fit: cover;
        cursor: pointer;
        background: var(--background_black);
        width: 100%;

        &.fallBackImage {
            width: 100%;
            min-height: 84px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
            border-radius: 0;
            border-start-start-radius: 12px;
            border-start-end-radius: 12px;
            position: relative;
            position: relative;
            max-height: fit-content;
            background-size: inherit;
            object-fit: none;
            height: 84px;
        }
    }

    .images_content {
        width: 100%;
        position: absolute;
        bottom: 40px;
        z-index: 10;
        padding: 6px 8px;
        background: rgba(0, 0, 0, 0.7);

        p {
            font-family: var(--mali);
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            // line-height: 22px;
            color: var(--textWhite);
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                font-family: var(--mali);
            }
        }
    }

    .big_sh_body {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: calc(100%);
        z-index: 8;
        cursor: pointer;
        border-radius: 0;
        border-start-start-radius: 12px;
        border-start-end-radius: 12px;
    }

    .big_sh_head {
        .like_count {
            width: auto;
            padding: 4px 9px;
            border: 2px solid var(--textWhite);
            background: #ef476f;
            border-radius: 40px;
            display: flex;
            align-items: center;
            gap: 0.3rem;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            position: absolute;
            top: -14px;
            right: 10px;

            svg {
                color: var(--textWhite);
                font-size: 13px;
                display: flex;
            }

            span {
                color: var(--textWhite);
                font-family: var(--font-stack);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}

// images modal
.short_ans_modal {
    .MuiBox-root {
        .images_upload_modal {
            .big_sh_box {
                /* CP2/Elevation Light/3 */
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                position: relative;
                border-radius: 12px;
                // width: 450px;
                // height: 450px;
                margin-inline: auto;
                margin-block: 0;
                position: relative;
                background: none;

                .big_sh_content {
                    text-align: center;
                    padding: 0;
                    display: flex;
                    max-height: var(--lightBoxMax-height);
                    min-height: var(--lightBoxMin-height);

                    img {
                        width: 100%;
                        max-height: var(--lightBoxMax-height);
                        min-height: var(--lightBoxMin-height);
                        max-width: var(--lightBoxMax-width);
                        min-width: var(--lightBoxMin-width);
                        object-fit: contain;
                        background: var(--background_black);
                        border-radius: 0 !important;
                        border-start-start-radius: 12px !important;
                        border-start-end-radius: 12px !important;
                    }

                    .quote {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-block-end: 20px;

                        svg {
                            font-size: 50px;
                            display: flex;
                            transform: rotate(180deg);
                        }
                    }

                    p {
                        font-family: var(--mali);
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        // line-height: 30px;
                        color: var(--text_color);
                        text-align: center;
                        padding: 0 40px;
                    }
                }

                .big_sh_head {
                    width: 100%;
                    padding: 15px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: absolute;
                    z-index: 1;
                    top: 0;

                    .like_count {
                        width: auto;
                        padding: 4px 8px;
                        border: 3px solid var(--textWhite);
                        background: #ef476f;
                        border-radius: 40px;
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                        svg {
                            color: var(--textWhite);
                            font-size: 18px;
                            display: flex;
                        }

                        span {
                            color: var(--textWhite);
                            font-family: var(--font-stack);
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }
                }

                .images_content {
                    width: 100%;
                    position: absolute;
                    bottom: 52px;
                    z-index: 1;

                    padding: 12px 16px;
                    background: rgba(0, 0, 0, 0.7);

                    p {
                        font-family: var(--mali);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 15px;
                        // line-height: 22px;
                        color: var(--textWhite);
                        word-break: break-word;

                        span {
                            font-family: var(--mali);
                        }
                    }
                }
            }

            .sh_footer {
                .foot_icon {
                    > ul {
                        li {
                            .menu {
                                transform: translateY(calc(-100% - 40px)) scale(0);
                                transform-origin: right bottom 0px;

                                &.active {
                                    transform-origin: right bottom 0px;
                                    transform: translateY(calc(-100% - 40px)) scale(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// slide drawing
.flexslide_drawing {
    padding-block-end: 15px;

    .inner_short_answer.slidedrawing {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 20px 16px;
        flex-wrap: wrap;
        width: 950px;
        margin-inline: auto;
        margin-block: 0;

        .slidewrap {
            width: 224px;
        }
    }
}

.inner_short_answer.slidedrawing {
    width: 100%;

    .sh_box {
        background: #fafbff;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        // cursor: pointer;

        &:hover {
            -webkit-transition: var(--transition);
            -moz-transition: var(--transition);
            -o-transition: var(--transition);
            transition: var(--transition);
            box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
        }

        .content_box {
            padding: 0px;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: 0;
            border-start-start-radius: 12px;
            border-start-end-radius: 12px;
            display: flex;

            .like_count {
                width: auto;
                padding: 4px 9px;
                border: 2px solid var(--textWhite);
                background: #ef476f;
                border-radius: 40px;
                display: flex;
                align-items: center;
                gap: 0.3rem;
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                position: absolute;
                top: -14px;
                right: 10px;

                svg {
                    color: var(--textWhite);
                    font-size: 13px;
                    display: flex;
                }

                span {
                    color: var(--textWhite);
                    font-family: var(--font-stack);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            img {
                border-radius: 0 !important;
                border-start-start-radius: 12px !important;
                border-start-end-radius: 12px !important;
                height: fit-content;
                min-height: var(--thumnailMin-height);
                max-height: var(--thumnailMax-height);
                object-fit: cover;
                width: 100%;
            }

            p {
                font-family: var(--mali);
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                // line-height: 20px;
                color: var(--text_color);
            }
        }
    }
}

// slidedrawing modal
.short_ans_modal {
    .MuiBox-root {
        .slidedrawing_modal {
            .big_sh_box {
                background: var(--background-white);
                /* CP2/Elevation Light/3 */
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                width: 100%;
                height: fit-content;
                margin-inline: auto;
                margin-block: 0;
                position: relative;

                .big_sh_content {
                    padding: 0px;
                    text-align: center;
                    max-height: var(--lightBoxMax-height);
                    min-height: var(--lightBoxMin-height);
                    display: flex;

                    // padding: 0;
                    img {
                        width: 100%;
                        // height: var(--lightBoxMax-height);
                        max-height: var(--lightBoxMax-height);
                        min-height: var(--lightBoxMin-height);
                        max-width: var(--lightBoxMax-width);
                        min-width: var(--lightBoxMin-width);
                        object-fit: contain;
                        background: var(--background_black);
                        border-radius: 0 !important;
                        border-start-start-radius: 12px !important;
                        border-start-end-radius: 12px !important;
                    }

                    p {
                        font-family: var(--mali);
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        // line-height: 30px;
                        color: var(--text_color);
                        text-align: center;
                        padding: 0 40px;
                    }
                }

                .big_sh_head {
                    width: 100%;
                    padding: 15px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .like_count {
                        width: auto;
                        padding: 4px 8px;
                        border: 3px solid var(--textWhite);
                        background: #ef476f;
                        border-radius: 40px;
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                        svg {
                            color: var(--textWhite);
                            font-size: 18px;
                            display: flex;
                        }

                        span {
                            color: var(--textWhite);
                            font-family: var(--font-stack);
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }
                }
            }

            .sh_footer {
                .foot_icon {
                    > ul {
                        li {
                            .menu {
                                transform: translateY(calc(-100% - 40px)) scale(0);
                                transform-origin: right bottom 0px;

                                &.active {
                                    transform-origin: right bottom 0px;
                                    transform: translateY(calc(-100% - 40px)) scale(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// video upload
.inner_short_answer.videoupload {
    width: 100%;

    .sh_box {
        background: #fafbff;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        cursor: pointer;
        // overflow: hidden;
        position: relative;

        &:hover {
            -webkit-transition: var(--transition);
            -moz-transition: var(--transition);
            -o-transition: var(--transition);
            transition: var(--transition);
            box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
        }

        .content_box {
            // padding: 20px 20px;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;

            .video_player {
                background: var(--background_black);
                border-radius: 0;
                border-start-start-radius: 12px;
                border-start-end-radius: 12px;
            }

            .react-player.video_upload {
                width: 100% !important;
                height: 100% !important;
                display: flex;
                min-height: var(--thumnailMin-height);
                max-height: var(--thumnailMax-height);

                video {
                    border-radius: 0;
                    border-start-start-radius: 12px;
                    border-start-end-radius: 12px;
                    height: auto !important;
                }
            }

            .video_button {
                width: auto;
                position: absolute;
                z-index: 11;
                top: 50%;
                margin: auto;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: start;
                padding: 0 10px;

                button {
                    border-radius: 50%;
                    background: rgba(73, 69, 79, 0.5);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    padding: 4px;
                    cursor: pointer;

                    svg {
                        color: var(--textWhite);
                        font-size: 20px;
                    }
                }
            }

            p {
                font-family: var(--mali);
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                // line-height: 20px;
                color: var(--text_color);
            }
        }

        .big_sh_head {
            .like_count {
                width: auto;
                padding: 4px 9px;
                border: 2px solid var(--textWhite);
                background: #ef476f;
                border-radius: 40px;
                display: flex;
                align-items: center;
                gap: 0.3rem;
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                position: absolute;
                top: -14px;
                right: 10px;

                svg {
                    color: var(--textWhite);
                    font-size: 13px;
                    display: flex;
                }

                span {
                    color: var(--textWhite);
                    font-family: var(--font-stack);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
    }
}

// video upload modal
.short_ans_modal {
    .MuiBox-root {
        .video_upload_modal {
            .big_sh_box {
                background: #000000;
                /* CP2/Elevation Light/3 */
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                // width: 600px;
                height: fit-content;
                margin-inline: auto;
                margin-block: 0;
                position: relative;
                border-radius: 12px;
                // overflow: hidden;

                .big_sh_content {
                    // padding: 10px;
                    text-align: center;
                    // padding-block-start: 20px;
                    // padding-block-end: 60px;
                    max-height: var(--lightBoxMax-height);
                    min-height: var(--lightBoxMin-height);
                    padding: 0;

                    .quote {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-block-end: 20px;

                        svg {
                            font-size: 50px;
                            display: flex;
                            transform: rotate(180deg);
                        }
                    }

                    p {
                        font-family: var(--mali);
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        // line-height: 30px;
                        color: var(--text_color);
                        text-align: center;
                        padding: 0 40px;
                    }
                }

                .big_sh_content {
                    .react-player.video_upload {
                        width: 100% !important;
                        height: 100% !important;
                        display: flex;
                        max-height: var(--lightBoxMax-height);
                        min-height: var(--lightBoxMin-height);

                        video {
                            border-radius: 0;
                            border-start-start-radius: 12px;
                            border-start-end-radius: 12px;
                            height: auto !important;
                        }
                    }

                    .video_button {
                        width: 100%;
                        position: absolute;
                        z-index: 11;
                        bottom: 50px;
                        left: 0;
                    }

                    p {
                        font-family: var(--mali);
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        // line-height: 30px;
                        color: var(--text_color);
                        text-align: center;
                        padding: 0 40px;
                    }

                    .videoControls {
                        position: absolute;
                        inset: auto 0 4% 0;
                        z-index: 2;
                        // opacity: 1;
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        gap: 17px;

                        width: 100%;
                        padding: 0 25px;
                        transition: 0.2s all ease-out;
                        pointer-events: none;

                        &.hide {
                            opacity: 0;
                        }

                        .slider {
                            cursor: pointer;
                            color: var(--textWhite);
                            pointer-events: all;
                        }

                        p.time {
                            font-family: var(--font-stack);
                            font-size: 17px;
                            font-weight: 400;
                            color: var(--textWhite);

                            width: 80px;
                            padding: 0;
                        }

                        .controlIcon {
                            font-size: 27px;
                            color: #ffffff;

                            cursor: pointer;
                            pointer-events: all;
                        }

                        .volumeSlider {
                            position: relative;

                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            gap: 20px;

                            .slider {
                                height: 80px;
                                cursor: pointer;
                                transition: 0.2s all ease-out;

                                &.hide {
                                    opacity: 0;
                                    pointer-events: none;
                                }
                            }
                        }

                        .fullScreen {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .big_sh_head {
                    width: 100%;
                    padding: 15px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: absolute;
                    top: 0;
                    z-index: 1;

                    .like_count {
                        width: auto;
                        padding: 4px 8px;
                        border: 3px solid var(--textWhite);
                        background: #ef476f;
                        border-radius: 40px;
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                        svg {
                            color: var(--textWhite);
                            font-size: 18px;
                            display: flex;
                        }

                        span {
                            color: var(--textWhite);
                            font-family: var(--font-stack);
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }
}

// audio
.flexslide_audio {
    padding-block-end: 15px;

    .inner_short_answer.audio_upload {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 18px 16px;
        flex-wrap: wrap;
        width: 950px;
        margin-inline: auto;
        margin-block: 0;

        .slidewrap {
            width: 304px;
        }
    }
}

.inner_short_answer.audio_upload {
    width: 100%;

    .sh_box {
        background: #fafbff;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        // cursor: pointer;
        // overflow: hidden;

        &:hover {
            -webkit-transition: var(--transition);
            -moz-transition: var(--transition);
            -o-transition: var(--transition);
            transition: var(--transition);
            box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
        }

        .content_box {
            padding: 18px 12px;
            align-items: center;
            justify-content: center;
            background: #ef476f;
            border-radius: 0;
            border-start-start-radius: 12px;
            border-start-end-radius: 12px;
            position: relative;

            .like_count {
                width: auto;
                padding: 4px 9px;
                border: 2px solid var(--textWhite);
                background: #ef476f;
                border-radius: 40px;
                display: flex;
                align-items: center;
                gap: 0.3rem;
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                position: absolute;
                top: -14px;
                right: 10px;

                svg {
                    color: var(--textWhite);
                    font-size: 13px;
                    display: flex;
                }

                span {
                    color: var(--textWhite);
                    font-family: var(--font-stack);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 12px;
                }
            }

            .audio_player {
                display: flex;
                align-items: center;
                width: 100%;
                gap: 15px;

                .audio_timer {
                    padding: 4.9px 8px;
                    background: var(--background-white);
                    border-radius: 24px;
                    width: fit-content;
                    display: flex;

                    span {
                        display: inline-block;
                        color: var(--activation_text);
                        line-height: 12px;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .xs10 {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex: 1;

                    & > .audio_wave {
                        flex: 1;
                        position: relative;

                        &.cursorlineHide {
                            wave {
                                wave {
                                    border-inline-end: none !important;
                                }
                            }
                        }
                    }
                }

                wave {
                    height: 50px !important;
                    overflow: hidden !important;

                    // border-inline-end: none !important;
                    wave {
                        border-inline-end-width: 2px !important;
                    }
                }

                .button_sec {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    button {
                        background-color: var(--background-white);
                        padding: 5px;
                        color: var(--activation_text);
                        opacity: 0.7;

                        &:hover {
                            opacity: 1;
                        }

                        &.stop_btn {
                            background: var(--background-white);
                            border-radius: 3px;
                            padding: 0;

                            svg {
                                color: #fafbff;
                                font-size: 16px;
                            }
                        }

                        svg {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

.loading_text {
    width: 100%;
    text-align: center;

    // padding: 5px 0 5px;
    p {
        color: var(--parple);
        line-height: 14px;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        max-height: 18px;

        svg {
            position: absolute;
            width: 100px !important;
            height: 100px !important;
            top: 10px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

// video upload modal
.short_ans_modal {
    .MuiBox-root {
        .audio_upload_modal {
            .big_sh_box {
                background: #fafbff;
                /* CP2/Elevation Light/3 */
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                width: 600px;
                height: fit-content;
                margin-inline: auto;
                margin-block: 0;
                position: relative;
                // overflow: hidden;
            }

            .big_sh_content {
                padding: 20px;
                text-align: center;

                p {
                    font-family: var(--mali);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    // line-height: 30px;
                    color: var(--text_color);
                    text-align: center;
                    padding: 0 40px;
                }
            }

            .big_sh_content {
                padding: 10px;
                text-align: center;
                padding-block-start: 10px;
                padding-block-end: 10px;
                background: #ef476f;
                border-radius: 0;
                border-start-start-radius: 12px;
                border-start-end-radius: 12px;
                min-height: auto;

                img {
                    width: 260px;
                }

                .audio_player {
                    align-items: center;

                    .button_sec {
                        padding-block-start: 10px;
                        display: flex;
                        align-items: center;
                        gap: 0.7rem;

                        button {
                            background-color: var(--textWhite);
                            padding: 8px;

                            &.stop_btn {
                                background: var(--textWhite);
                                border-radius: 3px;
                                padding: 0;

                                svg {
                                    color: #fafbff;
                                    font-size: 19px;
                                }
                            }

                            svg {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }

            .big_sh_head {
                width: 100%;
                padding: 15px 10px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                top: 0;
                z-index: 1;

                .like_count {
                    width: auto;
                    padding: 4px 8px;
                    border: 3px solid var(--textWhite);
                    background: #ef476f;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                    svg {
                        color: var(--textWhite);
                        font-size: 18px;
                        display: flex;
                    }

                    span {
                        color: var(--textWhite);
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}

.sh_footer {
    width: 100%;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--background-white);
    gap: 4px;
    border-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    min-height: 40px;
    // max-height: 40px;

    .star_point_btn {
        .star_img img {
            max-width: 100%;
            width: 20px;
            padding: 0.5px;
        }
    }

    &.footmodal {
        padding: 14px 16px;
    }

    .profile_foot {
        flex: 1;
        display: flex;
        align-items: center;

        p {
            text-align: start;
            font-family: var(--font-stack);
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: var(--activation_text);
            text-overflow: ellipsis;
            overflow: hidden;
            width: 40px;
            white-space: nowrap;
            flex: 1;
        }

        .largeFontText {
            text-align: start;
            font-family: var(--font-stack);
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 17px;
            color: var(--activation_text);
            text-overflow: ellipsis;
            overflow: hidden;
            width: 40px;
            white-space: nowrap;
            flex: 1;
        }

        .smallFontText {
            text-align: start;
            font-family: var(--font-stack);
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 15px;
            color: var(--activation_text);
            text-overflow: ellipsis;
            overflow: hidden;
            width: 40px;
            white-space: nowrap;
            flex: 1;
        }
    }

    .like_count {
        width: auto;
        padding: 4px 9px;
        border: 2px solid var(--textWhite);
        background: #ef476f;
        border-radius: 40px;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

        svg {
            color: var(--textWhite);
            font-size: 13px;
            display: flex;
        }

        span {
            color: var(--textWhite);
            font-family: var(--font-stack);
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
        }
    }

    .avater_box {
        margin-inline-end: 8px;

        .MuiAvatar-root {
            height: 28px;
            width: 28px;
            font-size: 14px;
        }
    }

    .foot_icon {
        & > ul {
            display: flex;
            gap: 0.2rem;
            align-items: center;

            li {
                display: inline-block;
                position: relative;

                &.nostarview {
                    display: none;
                }

                .menu {
                    bottom: inherit;
                    top: 30px;
                    right: 5px;
                    position: absolute;
                    left: inherit;
                }

                & > button {
                    min-width: 24px;
                    height: 24px;
                    border-radius: 4px;
                    padding: 0;

                    &:hover {
                        background: var(--lightbackground);
                    }

                    svg {
                        color: var(--activation_text);
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.button_wraper {
    position: relative;
    width: fit-content;
    isolation: isolate;

    .point_box2 {
        position: absolute;
        width: fit-content;
        height: fit-content;
        padding: 4px 9.5px;
        background-color: var(--user-sucess);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: -1;
        top: 0;
        left: 21px;
        right: 0;

        p {
            color: var(--textWhite);
            font-family: var(--font-stack);
            margin: 0;
            font-size: 14px;
            line-height: 14px;
            font-weight: 700;
            white-space: nowrap;
        }
    }

    .starbtn {
        @include starBtn;

        &.big {
            padding: 6px 20px;
        }

        &:hover {
            background-color: var(--parple);
        }

        img {
            width: 24px;
            height: 24px;
            // -webkit-transition: 0.1s ease-out;
            // -moz-transition: 0.1s ease-out;
            // -o-transition: 0.1s ease-out;
            // transition: 0.1s ease-out;
            position: relative;
            z-index: 2;
            filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.25));
        }

        p {
            color: var(--textWhite);
            font-family: var(--font-stack);
            font-size: 16px;
            font-weight: 500;
            line-height: 17px;
            margin-inline-start: 8px;
            position: relative;
            z-index: 22;
        }

        .staricon_box {
            position: relative;
            display: flex;
            // margin-right: 0.5px;
        }
    }
}

.pickNameSparkleLottie .pickname_star {
    .button_wraper {
        .MuiButton-root.givePointButton {
            width: 30px !important;
            height: 30px !important;
            min-width: 30px !important;
            min-height: 30px !important;
        }

        .point_box2 {
            border-radius: 100px;
            top: -10px;
            left: 0px;

            &.double {
                left: -5px;
            }

            p {
                color: var(--textWhite);
                padding: 0 4px 4px 4px;
            }
        }
    }
}

.sh_box,
.images_boxs,
.sky-slider__img-container {
    &:hover {
        .star_point_btn {
            right: 0;
            opacity: 1;
        }
    }
}

.star_point_btn {
    display: flex;
    align-items: center;
    position: relative;
    right: -20px;
    transform-origin: right;
    opacity: 0;

    // -webkit-transition: 0.2s ease-out;
    // -moz-transition: 0.2s ease-out;
    // -o-transition: 0.2s ease-out;
    // transition: 0.2s ease-out;
    // transform: translate3d(100%, 0, 0);
    &.nostarhover {
        pointer-events: none;

        button.star_img {
            background: none;
            padding-inline: 0;
        }

        .star_point {
            background: none;
        }
    }

    &.pointactive {
        right: 0px;
        opacity: 1;

        .star_point {
            display: flex;
        }

        .star_img {
            // background: none;
            &:hover {
                background: var(--backgroundcolor);
            }
        }
    }

    button.star_img {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 50%;
        background: var(--backgroundcolor);
        color: var(--textWhite);
        cursor: pointer;
        width: fit-content;
        // border: 2px solid var(--background-white);
        position: relative;
        right: -2px;
        text-transform: none;
        min-width: auto;
        height: auto;
        min-height: auto;

        .star_img_box {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            max-width: 100%;
        }

        // &:active {
        //     background: var(--darkgoldbg);
        // }
    }

    .star_point {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 7px;
        border-radius: 20px;
        background: #dde1ff;
        color: var(--textWhite);
        cursor: pointer;
        width: fit-content;
        min-width: 24px;
        min-height: 24px;
        display: none;

        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        font-family: var(--font-stack);
        color: var(--activation_text);
    }
}

.pickname_star .star_point_btn.pointactive {
    margin-right: 5px;

    img {
        width: 19px;
    }
}

.MuiList-padding.MuiMenu-list li:hover {
    background: var(--lightbackground) !important;
}

.MuiList-padding.MuiMenu-list li:active {
    background: var(--lightdeepbg) !important;
}

// video_upload new
.video_upload_sec {
    display: flex;
    align-items: flex-start;
    gap: 28px;
    margin-block-start: 16px;

    .video_upload_left {
        flex: 1;
        margin-top: 18px;

        .video_display_section {
            position: relative;
            z-index: 1;

            &.fullScreenVideo {
                position: absolute;
                z-index: 6120;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0;

                .big_sh_content {
                    border-radius: 0;

                    .sh_content_video {
                        border-radius: 0;

                        .react-player.video_upload {
                            width: 100% !important;
                            height: 100% !important;
                            border-radius: 0;
                        }
                    }
                }

                // .video_title_sec, .description{
                //     display: none;
                // }
            }
        }

        .video_profile_sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-block: 15px 0;

            .video_left {
                width: auto;
                display: flex;
                align-items: center;
                gap: 16px;

                .avater_img {
                    img {
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                    }

                    .MuiAvatar-root {
                        height: 40px;
                        width: 40px;
                        font-size: 20px;
                    }
                }

                .video_content {
                    h4 {
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: var(--activation_text);
                    }

                    p {
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        color: var(--text_gray);
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                }
            }

            .video_right {
                width: auto;

                .star_img {
                    min-height: 32px;
                    min-width: 32px;
                }

                .star_point_btn {
                    opacity: 1;
                    right: 0;

                    img {
                        width: 22px;
                    }

                    .star_point {
                        font-size: 14px;
                        line-height: 13px;
                    }
                }

                &.nostarview {
                    display: none;
                }
            }
        }

        .big_sh_content {
            text-align: center;
            isolation: isolate;
            background: var(--background_black);
            border-radius: 12px;

            &:hover {
                .videoControls {
                    opacity: 1;
                }
            }

            .sh_content_video {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 12px;
                position: relative;

                .video_loader {
                    position: absolute;
                    inset: 0 0 0 0;
                    width: fit-content;
                    height: fit-content;
                    margin: auto;

                    svg {
                        width: 240px !important;
                    }
                }

                .unsupported_video {
                    position: absolute;
                    inset: 0 0 0 0;
                    width: fit-content;
                    height: fit-content;
                    margin: auto;

                    p {
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        color: var(--textWhite);
                    }
                }

                .center_play {
                    position: absolute;
                    inset: 0 0 0 0;
                    width: 90px;
                    height: 90px;
                    margin: auto;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(0, 0, 0, 0.7);
                    color: var(--textWhite);
                    opacity: 0;
                    transform: scale(0);

                    &.show {
                        animation: pulse 0.6s alternate;
                    }

                    @keyframes pulse {
                        from {
                            transform: scale(0);
                            opacity: 0;
                        }

                        50% {
                            opacity: 1;
                        }

                        /* ignored */
                        to {
                            transform: scale(1);
                            opacity: 0;
                            display: none;
                        }
                    }

                    svg {
                        font-size: 50px;
                        display: flex;
                    }

                    z-index: 11;
                }
            }

            .react-player.video_upload {
                width: 100% !important;
                height: 360px !important;
                border-radius: 12px;
                z-index: 0;
                position: relative;
                display: flex;

                // &.videoFullView {
                //     height: 100% !important;
                // }
            }

            .video_button {
                width: auto;
                position: absolute;
                z-index: 11;
                bottom: 50px;
                left: 0;
                text-align: start;
                padding: 0 10px;

                button {
                    border-radius: 50%;
                    border: 1px solid var(--textWhite);
                    padding: 4px;
                    cursor: pointer;

                    svg {
                        color: var(--textWhite);
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .video_upload_right {
        width: 328px;

        .show_response {
            position: relative;
            top: inherit;
            right: inherit;
            text-align: center;
            margin-block-start: 6px;
        }

        .searchfield + .show_response + .inner_upload_right {
            max-height: 394px;
        }

        .show_response + .inner_upload_right {
            max-height: 430px;
        }

        .inner_upload_right {
            width: 100%;
            overflow: auto;
            padding-inline-end: 18px;
            display: flex;
            flex-direction: column;
            margin-top: 12px;
            gap: 4px;
            // max-height: 394px;
            padding-bottom: 15px;

            .video_participant_card {
                border: 1px solid var(--light_border);
                background: var(--background-white);
                padding: 12px 16px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                gap: 16px;
                cursor: pointer;

                &.active {
                    border: 2px solid var(--parple);
                }

                .avater_box {
                    width: auto;

                    img {
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                    }

                    .MuiAvatar-root {
                        height: 36px;
                        width: 36px;
                        font-size: 20px;
                    }
                }

                .video_content {
                    flex: 1;

                    h4 {
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--activation_text);
                    }

                    p {
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--text_gray);
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                    }
                }

                .participent_points {
                    display: flex;
                    align-items: center;
                    width: auto;
                    gap: 4px;

                    img {
                        width: 16px;
                    }

                    p {
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 13px;
                        color: var(--text_gray);
                    }
                }
            }
        }
    }

    .videoControls {
        position: absolute;
        inset: auto 0 0 0;
        z-index: 2;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
        padding: 0 25px;
        transition: 0.2s all ease-out;
        opacity: 0.5;
        isolation: isolate;
        padding-block-end: 13.5px;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 108.65%);
            bottom: 0px;
            left: 0;
            height: 55px;
            z-index: -1;
            border-radius: 0 0 12px 12px;
        }

        svg {
            color: var(--textWhite);
        }

        &.hide {
            opacity: 0;
            pointer-events: none;
            display: none;
        }

        .slider {
            cursor: pointer;
            color: var(--textWhite);
            pointer-events: all;
            padding: 11px 0;
        }

        p.time {
            font-family: var(--font-stack);
            font-size: 17px;
            font-weight: 400;
            color: var(--textWhite);
            line-height: 25px;
            width: 200px;
            padding: 0;
        }

        .overlay {
            display: none;
        }

        .MuiSlider-thumbColorPrimary {
            width: 12px;
            height: 12px;

            &:is(.Mui-focusVisible, :hover) {
                box-shadow: 0px 0px 0px 8px rgb(99 120 255 / 11%);
            }
        }

        .centerIcon {
            position: relative;
            top: inherit;
            z-index: 2;
            padding: 1px 0;
            opacity: 0;
            transition: 0.2s all ease-out;
            cursor: pointer;
            display: flex;

            svg {
                font-size: 24px;
                color: var(--textWhite);
                padding: 0px;
                background: transparent;
                border-radius: 0;
                border: none;
            }

            &.show {
                opacity: 1;
            }
        }

        .volumeSlider {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 13px;
            cursor: pointer;
            padding-inline: 5px;
            padding-block: 13px 6px;
            border-radius: 30px;
            bottom: -5px;

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }

            &:hover {
                background: var(--bg_49454f_80);

                .slider {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            .slider {
                height: 48px;
                cursor: pointer;
                transition: 0.2s all ease-out;
                padding: 0;
                opacity: 0;
                pointer-events: none;
            }

            .controlIcon {
                display: flex;
            }
        }

        .option {
            position: relative;
            padding-block-end: 1px;

            & > button {
                width: auto;
                min-width: auto;
                padding: 0;
            }

            .menu {
                position: absolute;
                left: inherit;
                right: 0;
            }
        }
    }
}

.fullScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-block-end: 1px;
}

.big_sh_content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .loading {
        background: none;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
    }

    .overlay {
        position: absolute;
        inset: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .centerIcon {
        position: absolute;
        top: 33%;
        z-index: 2;

        padding: 30px;
        opacity: 0;
        transition: 0.2s all ease-out;
        cursor: pointer;

        svg {
            font-size: 70px;
            color: #ffffff;

            padding: 4px;
            background: hsla(0, 0%, 0%, 0.3);
            border-radius: 50%;
            border: 3px solid #ffffff;
        }

        &.show {
            opacity: 1;
        }
    }
}
