.modal_head {
    background: #edf0ff;
    color: var(--parple);
    text-align: center;
    min-height: 50px;
    padding: 4px 10px;
    display: grid;
    place-items: center;

    .title {
        font-size: 16px;
        font-weight: 500;
        font-family: var(--font-stack);
    }
}
