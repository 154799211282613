@use '../../../Style.scss' as *;

.option_modal_box {
    position: absolute;
    inset: 0 0 0 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.option_modal {
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    width: 660px;
    border-radius: 16px;
    position: relative;
    background: var(--background-white);
    overflow: hidden;

    .option_heading {
        width: 100%;
        background: var(--lightdeepbg2);
        min-height: 44px;
        @include displayFlex(row, center, center);

        .head {
            font-size: 16px;
            line-height: 21px;
            color: var(--parple);
            font-family: var(--font-stack);
            font-weight: 500;
        }
    }

    .option_body {
        width: 100%;
        padding: 24px 60px;
        padding-block-end: 32px;

        .qs_type {
            width: 100%;
            text-align: center;

            .title {
                font-size: 14px;
                line-height: 19px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-weight: 500;
                text-align: start;
            }

            .qs_type_chip_wrapper {
                width: 100%;
                @include displayFlex(row, center, center);
                gap: 12px;
                margin-block-start: 10px;

                .qs_type_chip {
                    width: 172px;
                    min-height: 90px;
                    padding: 10px;
                    border-radius: 12px;
                    border: 2px solid var(--borderMain);
                    @include displayFlex(column, center, center);
                    gap: 12px;
                    text-align: center;
                    position: relative;
                    cursor: pointer;

                    &.notClickable {
                        cursor: default;
                    }

                    &.active {
                        border: 2px solid var(--parple);

                        .check {
                            border: 1px solid var(--parple);
                            background: var(--parple);
                        }
                    }

                    img {
                        max-width: 100%;
                    }

                    .item_name {
                        font-size: 14px;
                        line-height: 19px;
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-weight: 400;
                    }

                    .check {
                        @include displayFlex(row, center, center);
                        position: absolute;
                        top: 6px;
                        right: 6px;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        border: 1px solid var(--borderMain);
                        color: var(--textWhite);

                        svg {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .bloom_level {
            width: 100%;
            text-align: center;
            margin-block-start: 28px;

            .title {
                font-size: 14px;
                line-height: 19px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-weight: 500;
                text-align: start;
                @include displayFlex(row, center, flex-start);
                gap: 7px;

                img {
                    position: relative;
                    top: -2px;
                }
            }

            .bloom_level_chip_wrapper {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 172px);
                gap: 10px 12px;
                margin-block-start: 12px;
                justify-content: center;

                .bloom_level_chip {
                    min-height: 32px;
                    padding: 4px 8px;
                    border-radius: 40px;
                    border: 1px solid var(--borderMain);
                    @include displayFlex(row, center, flex-start);
                    gap: 10px;
                    text-align: center;
                    position: relative;
                    cursor: pointer;

                    &.notClickable {
                        cursor: default;
                    }

                    // &:nth-of-type(2) {

                    //     &.active {
                    //         border-color: var(--bg_light_orange);

                    //         .check {
                    //             border-color: var(--bg_light_orange);

                    //             background: var(--bg_light_orange);
                    //         }
                    //     }
                    // }

                    // &:nth-of-type(3) {

                    //     &.active {
                    //         border-color: var(--bar_color_blue);

                    //         .check {
                    //             background: var(--bar_color_blue);
                    //             border-color: var(--bar_color_blue);

                    //         }
                    //     }
                    // }

                    // &:nth-of-type(4) {

                    //     &.active {
                    //         border: 1px solid var(--bg_red);

                    //         .check {
                    //             background: var(--bg_red);
                    //             border: 1px solid var(--bg_red);
                    //         }
                    //     }
                    // }

                    // &:nth-of-type(5) {

                    //     &.active {
                    //         border: 1px solid var(--bg_deep_purple);

                    //         .check {
                    //             background: var(--bg_deep_purple);
                    //             border: 1px solid var(--bg_deep_purple);
                    //         }
                    //     }
                    // }

                    // &:nth-of-type(6) {

                    //     &.active {
                    //         border: 1px solid var(--box3);

                    //         .check {
                    //             border: 1px solid var(--box3);
                    //             background: var(--box3);
                    //         }
                    //     }
                    // }

                    &.active {
                        border: 1px solid var(--parple);

                        .check {
                            background: var(--parple);
                            border: 1px solid var(--parple);
                        }
                    }

                    .item_name {
                        font-size: 14px;
                        line-height: 19px;
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-weight: 400;
                    }

                    .check {
                        @include displayFlex(row, center, center);
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid var(--borderMain);
                        color: var(--textWhite);

                        svg {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .language_change {
            width: 100%;
            margin-block-start: 28px;

            .title {
                font-size: 14px;
                line-height: 19px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-weight: 500;
                text-align: start;
            }

            .field_group {
                margin-block-start: 8px;

                .MuiFormControl-root {
                    .MuiInputBase-root {
                        border-radius: 12px;
                        padding: 0 0;
                        height: 36px;

                        input {
                            padding: 6px 20px;
                            font-size: 14px;
                            line-height: 19px;
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                        }

                        .MuiSvgIcon-root {
                            color: var(--activation_text);
                        }

                        &:active {
                            background: var(--background-white) !important;
                        }
                    }
                }

                .MuiAutocomplete-popper {
                    .MuiList-root {
                        .MuiListItem-root {
                            &.Mui-selected {
                                background: var(--parple_08);

                                &:hover {
                                    background: var(--parple_08);
                                }
                            }
                        }
                    }
                }
            }

            .language_change_wrapper {
                .searchArrow {
                    cursor: pointer;
                }
            }
        }

        // .option_btn_group {
        //     width: 100%;
        //     @include displayFlex(row, center, center);
        //     margin-block-start: 40px;
        //     padding-block-end: 10px;

        //     .blue_btn {
        //         @include chatbot_btn;
        //         min-width: 132px;
        //         min-height: 32px;

        //         .MuiTouchRipple-root {
        //             z-index: -1;
        //         }
        //     }
        // }
    }
}
