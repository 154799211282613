@use '../../Style.scss' as *;

.none {
    display: none;
}

:is(.savedClassTrialFlowModal, .subscriptionTrialFlowModal) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: min-content !important;
    max-width: min-content !important;
    height: min-content !important;

    .trialActivationMainContainer {
        width: 604px;
        max-width: 604px;
        height: 472px;
    }
}

.subscriptionTrialFlowModal.smallModal {
    width: 400px !important;
    height: 400px !important;
}

.trialFlowContentContainer {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    border-radius: 16px;

    .trialFlowBack {
        position: absolute;
        inset: auto auto 162px 315px;

        .backBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            color: var(--parple);

            cursor: pointer;
        }
    }
}

.trialActivationMainContainer {
    &.no_Radius {
        border-radius: 0;

        .imageContainer {
            .image {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
    }

    &.default {
        .imageContainer {
            width: 100%;
            aspect-ratio: 604/260;
        }

        .textContainer {
            width: 100%;
            height: calc(100% - 260px);

            .sliderContainer {
                width: 100%;
                height: calc(100% - 68px);
            }

            .btnContainer {
                width: 100%;
                height: 68px;

                .navigationContainer2 {
                    display: none;
                }

                .ctaBtn {
                    width: 216px;
                    height: 40px;
                }
            }
        }
    }

    &:is(.leaderBoardTrialActivation, .gamificationTrialActivation) {
        width: 420px;
        min-height: 400px;
        height: fit-content;
        background: var(--background-white);
        padding-block-end: 15px;

        .imageContainer {
            height: 182px;
        }
    }

    &.leaderBoardTrialActivation {
        margin-block-start: 68.36px;

        &.margin_top_0 {
            margin-block-start: 0;
        }
    }

    &.centerNavigation {
        .textContainer {
            .btnContainer {
                justify-content: center;
            }
        }
    }

    &.new_slideVideo {
        .imageContainer {
            min-height: 340px;
        }

        .line_progress {
            width: 100%;

            .MuiLinearProgress-root {
                height: 4px;
                border-radius: 4px;
                background: var(--secondary_clr_2);

                &.regular .MuiLinearProgress-bar {
                    background: var(--primary-color);
                }

                span {
                    transition: all 1s linear;
                }
            }
        }

        .textContainer {
            .sliderContainer {
                .slideItem {
                    .slideContent {
                        .title {
                            min-height: 66px;
                            position: relative;

                            &::after {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 40px;
                                height: 2px;
                                background: var(--borderlight);
                            }
                        }
                    }
                }
            }

            .btnContainer {
                .navigationContainer {
                    display: none;
                }

                :is(.trialActivationButton, .new_video_btn) {
                    display: none;
                }

                .checkbox {
                    display: block;

                    .MuiFormControlLabel-root {
                        margin: 0;
                        margin-inline-start: -5px;
                    }
                }

                .navigationContainer2 {
                    display: block;

                    .nav_arrow_group {
                        @include displayFlex(row, center, center);
                        gap: 15px;

                        .nav_previous {
                            color: var(--parple) !important;
                            text-transform: none !important;
                            white-space: nowrap;
                            width: max-content;
                            height: 40px;
                            padding-inline: 25px !important;
                            border: none !important;
                            border-radius: 1000px !important;
                            background: none;
                            max-width: max-content !important;
                            gap: 6px;

                            .text {
                                font-size: 14px;
                                font-weight: 500 !important;
                            }

                            .icon {
                                @include displayFlex(row, center, center);
                                font-size: 22px;
                            }
                        }

                        .nav_forward {
                            font-weight: 500 !important;
                            color: var(--textWhite) !important;
                            text-transform: none !important;
                            white-space: nowrap;
                            width: max-content;
                            height: 40px;
                            padding-inline: 25px !important;
                            border: none !important;
                            border-radius: 1000px !important;
                            background: linear-gradient(90deg, var(--primary-color) 24.31%, #e963ff 105.79%);
                            max-width: max-content !important;
                            gap: 6px;
                            font-size: 14px;

                            .text {
                                font-size: 14px;
                                font-weight: 500 !important;
                            }

                            .icon {
                                @include displayFlex(row, center, center);
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.trialActivationMainContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    border-radius: 16px;

    &.centerDiv {
        align-items: center;
        justify-content: center;
    }

    .closeModalIcon {
        position: absolute;
        inset: 12px 12px auto auto;

        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--textWhite);

        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        transition: 0.1s all linear;

        &:hover {
            background: var(--primary-color);
        }

        .icon {
            font-size: 20px;
        }
    }

    .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .image {
            width: 100%;
            height: 100%;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .sliderContainer {
            .slideItem {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;
                height: 100%;

                .slideContent {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 28px;

                    width: 100%;
                    height: min-content;
                    padding-inline: 28px;
                    padding-block: 15px;

                    .title {
                        flex-shrink: 0;

                        font-size: 18px;
                        font-weight: 600;
                        color: var(--primary-color);
                        line-height: 25.2px;

                        width: 196px;
                    }

                    .description {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--activation_text);
                        line-height: 22.4px;
                    }
                }
            }
        }

        .btnContainer {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            width: 100%;
            padding-inline: 28px;

            .navigationContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 12px;

                :is(.previous, .forward) {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 32px;
                    height: 32px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    cursor: pointer;

                    .icon {
                        font-size: 20px;
                        color: var(--activation_text);
                    }
                }

                .slideCount {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--text_gray);
                }
            }

            .ctaBtn {
                color: var(--textWhite);
                text-transform: none;

                background: linear-gradient(90deg, #6378ff 24.31%, #e963ff 105.79%);
                border-radius: 100px;

                .icon {
                    font-size: 23px;
                    padding-inline-start: 7px;
                }
            }
        }
    }

    &.verticalText {
        .textContainer {
            .sliderContainer {
                .slideItem {
                    .slideContent {
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 14px;

                        .title {
                            width: 100%;
                        }

                        .description {
                            padding-block-end: 10px;
                        }
                    }
                }
            }
        }
    }
}
