.myClassViewGroupModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    overflow: auto;

    .myClassGroupViewBody {
        width: 100%;
        height: max-content;
        padding: 0 0 10px 0;

        &.participant_content_sec {
            background: var(--background-white);

            .participant_card_container {
                height: max-content;

                .participent_card {
                    height: max-content;
                    max-height: max-content;
                    padding: 5px 30px;
                }
            }
        }
    }
}
