@use '../../../../../Style.scss' as *;
.bookmark_sec {
    height: 100%;
    width: 100%;
    // height: 100vh;
    background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
    -webkit-transition: background-image 0.8s ease-in-out;
    -o-transition: background-image 0.8s ease-in-out;
    -moz-transition: background-image 0.8s ease-in-out;
    transition: background-image 0.8s ease-in-out;
    .inner_bookmark_sec {
        width: 100%;
        padding-block-start: 32px;
        padding-inline: 8px;
        .header_title_area {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: 688px;
            margin-inline: auto;
            p {
                font-size: 16px;
                line-height: 21px;
                font-family: var(--font-stack);
                color: var(--activation_text);
                font-weight: 500;
            }
            .urlsearch {
                width: 100%;
                max-width: 280px;

                .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
                    background: var(--background-white) !important;

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                    border-color: var(--light_border);

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                .MuiOutlinedInput-root {
                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }

                    &.Mui-focused {
                        border-color: var(--parple);
                    }
                    border-radius: 30px;
                    height: 32px;
                    background: var(--background-white) !important;
                    padding-inline: 12px 8px;
                    .MuiInputAdornment-root {
                        svg {
                            color: var(--icon_color);
                            font-size: 18px;
                            &.qrcodeicon {
                                margin-inline-end: 8px;
                            }
                        }
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: var(--light_border);
                }

                input {
                    padding: 6px 0px;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);

                    &::placeholder {
                        color: var(--placeholder-color);
                        opacity: 1;
                    }
                }

                label {
                    -webkit-transform: translate(14px, 11px) scale(1);
                    -moz-transform: translate(14px, 11px) scale(1);
                    -ms-transform: translate(14px, 11px) scale(1);
                    transform: translate(14px, 11px) scale(1);
                }

                label.Mui-focused {
                    -webkit-transform: translate(14px, -6px) scale(0.75);
                    -moz-transform: translate(14px, -6px) scale(0.75);
                    -ms-transform: translate(14px, -6px) scale(0.75);
                    transform: translate(14px, -6px) scale(0.75);
                }

                .MuiInputLabel-shrink {
                    -webkit-transform: translate(14px, -6px) scale(0.75);
                    -moz-transform: translate(14px, -6px) scale(0.75);
                    -ms-transform: translate(14px, -6px) scale(0.75);
                    transform: translate(14px, -6px) scale(0.75);
                }
            }
        }
        .bookmark_area {
            width: 100%;
            margin-block-start: 14px;
            max-height: calc(100vh - 78px);
            overflow: auto;
            ul {
                max-width: 688px;
                margin-inline: auto;
                &:last-child {
                    margin-block-end: 20px;
                }
                li {
                    margin-block-start: 4px;
                    .bookmark_card {
                        padding: 8px;
                        background: var(--background-white);
                        display: flex;
                        align-items: center;
                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 6px 2px rgba(0, 0, 0, 0.05);
                        border-radius: 4px;
                        gap: 10px;
                        &:hover {
                            .pinbtn {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                    .bookmark_name_area {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        gap: 8px;
                        img {
                            width: 16px;
                            max-height: 16px;
                            object-fit: contain;
                        }
                        p {
                            font-size: 12px;
                            line-height: 16px;
                            font-family: var(--font-stack);
                            color: var(--icon_color);
                            font-weight: 500;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                            -webkit-line-clamp: 1; /* after 1 line show ... */
                            -webkit-box-orient: vertical;
                        }
                    }
                    .bookmark_url {
                        width: clamp(180px, 100%, 288px);
                        a {
                            font-size: 12px;
                            line-height: 16px;
                            font-family: var(--font-stack);
                            color: var(--icon_color);
                            font-weight: 500;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* after 1 line show ... */
                            -webkit-box-orient: vertical;
                        }
                    }
                    .remove_bookmark {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border-radius: 4px;
                        svg {
                            color: var(--user_emailtext);
                            font-size: 20px;
                        }
                        &:hover {
                            background: var(--active_tab_color_F2F2F2);
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .bookmark_btn_group {
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .pinbtn {
                visibility: hidden;
                opacity: 0;
                button {
                    min-height: 32px;
                    min-width: 32px;
                    width: 32px;
                    border-radius: 4px;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 13px;
                    line-height: 17px;
                    color: var(--pin_bg_color_AEAAAE);
                    font-weight: 400;
                    font-family: var(--font-stack);
                    text-transform: none;
                    padding: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    svg {
                        display: flex;
                        path {
                            fill: var(--pin_bg_color_AEAAAE);
                        }
                        &.unpushpin {
                            display: none;
                        }
                    }
                    &:hover {
                        background: var(--pin_bg_hover_color);
                        svg {
                            path {
                                fill: var(--pin_bg_color_AEAAAE);
                            }
                        }
                    }
                }
                &.active {
                    opacity: 1;
                    visibility: visible;
                    button {
                        color: var(--parple);
                        &:hover {
                            background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)),
                                #ffffff;
                            svg {
                                path {
                                    fill: var(--parple);
                                }
                            }
                        }
                    }
                    &:hover {
                        svg {
                            display: none;
                            &.unpushpin {
                                display: block;
                            }
                        }
                    }
                    & svg {
                        path {
                            fill: var(--parple);
                        }
                    }
                }
                &.disable {
                    button {
                        pointer-events: none;
                        opacity: 0;
                    }
                    & svg {
                        path {
                            fill: var(--graybar);
                        }
                    }
                }
            }
            .alreadypin {
                padding-block: 20px 16px;
                &.notreachedlimit {
                    padding: 10px 16px;
                }
                p {
                    text-align: center;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    color: var(--icon_color);
                    font-weight: 400;
                }
            }
            .bookmarkEmptyText {
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                font-family: var(--font-stack);
                color: var(--icon_color);
                font-weight: 400;
                margin-block-start: 40px;
            }
        }
    }
}
