@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%; /*#FFFFFF */
        --foreground: 222.2 84% 4.9%; /*#0C0E1F */
        --card: 0 0% 100%; /*#FFFFFF */
        --card-foreground: 222.2 84% 4.9%; /*#0C0E1F */
        --popover: 0 0% 100%; /*#FFFFFF */
        --popover-foreground: 222.2 84% 4.9%; /*#0C0E1F */
        --primary: 243 75% 59%; /*#4F46E5 indigo-600*/
        --primary-foreground: 210 40% 98%; /*#F7F9FA */
        --secondary: 220 14% 96%; /*#f3f4f6 gray-100*/
        --secondary-foreground: 222.2 47.4% 11.2%; /*#1D1F2A */
        --muted: 220 14% 96%; /*#f3f4f6 gray-100*/
        --muted-foreground: 220 9% 46%; /*#6b7280 gray-500*/
        --midway: 218 11% 65%; /*#9ca3af gray-400*/
        --accent: 214 32% 91%; /*#e2e8f0 slate-200*/
        --accent-foreground: 222.2 47.4% 11.2%; /*#1D1F2A */
        --destructive: 0 72% 51%; /*#dc2626 red-600*/
        --destructive-foreground: 210 40% 98%; /*#F7F9FA */
        --warning: 25 95% 53%; /*#f97316 orange-500*/
        --warning-foreground: 210 40% 98%; /*#F7F9FA */
        --success: 142 76% 36%; /*#16a34a green-600*/
        --success-foreground: 210 40% 98%; /*#F7F9FA */
        --border: 214.3 31.8% 91.4%; /*#e2e8f0 slate-200*/
        --input: 214.3 31.8% 91.4%; /*#e2e8f0 slate-200*/
        --subtle: 214.3 31.8% 91.4%; /*#e2e8f0 slate-200*/
        --ring: 216 12% 84%; /*#d1d5db gray-300*/
        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%; /*#0C0E1F*/
        --foreground: 210 40% 98%; /*#F7F9FA*/
        --card: 222.2 84% 4.9%; /*#0C0E1F*/
        --card-foreground: 210 40% 98%; /*#F7F9FA*/
        --popover: 222.2 84% 4.9%; /*#0C0E1F*/
        --popover-foreground: 210 40% 98%; /*#F7F9FA*/
        --primary: 239 84% 67%; /*#6366f1 indigo-500*/
        --primary-foreground: 210 40% 98%; /*#F7F9FA*/
        --secondary: 217.2 32.6% 17.5%; /*#1e293b slate-800*/
        --secondary-foreground: 210 40% 98%; /*#F7F9FA*/
        --muted: 217.2 32.6% 17.5%; /*	#1e293b slate-800*/
        --muted-foreground: 218 11% 65%; /*#9ca3af gray-400*/
        --midway: 215 14% 34%; /*#4b5563 gray-600*/
        --accent: 217.2 32.6% 17.5%; /*	#1e293b slate-800*/
        --accent-foreground: 210 40% 98%; /*#F7F9FA*/
        --destructive: 0 84% 60%; /*#ef4444 red-500*/
        --destructive-foreground: 210 40% 98%; /*#F7F9FA*/
        --success: 142 71% 45%; /*#22c55e green-500*/
        --border: 217.2 32.6% 17.5%; /*	#1e293b slate-800*/
        --input: 217.2 32.6% 17.5%; /*	#1e293b slate-800*/
        --subtle: 217.2 32.6% 17.5%; /*	#1e293b slate-800*/
        --ring: 217 19% 27%; /*#374151 gray-300*/
    }
}

@layer base {
    * {
        @apply border-subtle;
    }

    body {
        @apply min-h-screen bg-background text-foreground;
    }

    h2 {
        @apply mt-4 text-2xl font-semibold leading-9 tracking-tight text-foreground;
    }

    h3 {
        @apply text-base font-semibold leading-5 tracking-tight text-foreground;
    }

    input {
        --tw-ring-shadow: 0 0 #000 !important;
    }
}

@layer utilities {
    .dashboard-main {
        @apply mx-auto max-w-5xl px-6 pt-14 lg:px-8;
    }
    .app-input-default {
        @apply block w-full rounded-md border-0 bg-background py-1.5 text-sm leading-6 text-foreground shadow-sm ring-1 ring-inset ring-ring placeholder:text-ring focus:ring-2 focus:ring-inset focus:ring-primary disabled:cursor-not-allowed disabled:bg-muted disabled:text-midway;
    }
    .app-input-error {
        @apply block w-full rounded-md border-0 bg-background py-1.5 text-sm leading-6 text-destructive shadow-sm ring-1 ring-inset ring-destructive placeholder:text-ring focus:ring-2 focus:ring-inset focus:ring-destructive;
    }

    .app-text-muted {
        @apply text-sm text-muted-foreground;
    }

    .app-card-shadow {
        @apply transition-shadow hover:shadow-md;
    }

    .pulse-muted-bg::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        @apply animate-pulse bg-muted; /* Example background color */
    }
}

:is(html, body) {
    height: 100%;
    min-height: auto;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: hsl(var(--subtle));
}

::-webkit-scrollbar-thumb {
    background: hsl(var(--ring));
}

::-webkit-scrollbar-thumb:hover {
    background: hsl(var(--midway));
}

.aspect-video {
    aspect-ratio: 16/9;
}
