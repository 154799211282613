@use '../../../Style.scss' as *;

.discount {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--discount_bg);
    @include displayFlex(row, center, center);
    gap: 8px;
    height: 32px;
    cursor: pointer;

    &.top {
        bottom: inherit;
        top: 0;
    }

    &.noClickable {
        cursor: default;

        // &:hover {
        //     .text {
        //         text-decoration: none;
        //     }
        // }
    }

    // &:hover {
    //     .text {
    //         text-decoration: underline;
    //     }
    // }

    .text {
        color: var(--text_color);
        font-family: var(--font-stack);
        font-size: 14px;
        font-weight: 500;
    }
}
