.autoCreateGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 385px;
    height: 240px;
    border-radius: 10px;
    background: var(--background-white);

    .auto_pick_modal {
        width: 100% !important;
        height: 100% !important;

        .modal_head {
            .MuiTypography-root {
                font-size: 16px;
            }
        }

        .bodyContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            width: 100%;
            height: calc(100% - 44px);
            padding-block-end: 15px;

            // .groupCreationText {
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;

            //     font-size: 14px;
            //     text-align: center;
            //     line-height: 18px;

            //     height: 55px;
            //     padding-inline: 40px;
            // }

            .autoGroupMessage {
                font-size: 14px;
                color: var(--secondary_text);
                text-align: center;

                height: 20px;
                margin-block: -10px;
            }

            .auto_pick_modal_foot {
                padding-block: 7px 12px;
            }
        }
    }
}
