@use '../../../Style.scss' as *;
.shareContainer {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    .shareModal_content {
        position: relative;
        width: 100%;

        .lottieAnimation {
            position: absolute;
            inset: 0;
            z-index: 10;

            display: flex;
            align-items: center;
            justify-content: center;

            padding: 100px;
            background: rgba(0, 0, 0, 0.2);
        }

        .modal_head {
            background: #edf0ff;
            color: var(--parple);
            text-align: center;
            min-height: 50px;
            padding: 4px 10px;
            display: grid;
            place-items: center;

            .title {
                font-size: 16px;
                font-weight: 500;
                font-family: var(--font-stack);
            }
        }
        .share_modal_content_body {
            width: 100%;
            padding: 24px;
            .text {
                width: 100%;
                text-align: center;
                font-size: 14px;
                line-height: 21px;
                font-family: var(--font-stack);
                color: var(--text_color);
                font-weight: 400;
            }

            .icon_list {
                @include displayFlex(row, center, center);
                gap: 24px;

                width: 100%;
                margin-block: 20px;
                // margin-block-start: 16px;

                .btn {
                    @include displayFlex(row, center, center);

                    &.link {
                        margin-inline-end: -5px;
                    }
                }

                .icon {
                    @include displayFlex(row, center, center);
                }

                // button {
                //     @include displayFlex(row, center, center);

                //     img {
                //         width: 28px;
                //     }
                // }
            }

            .share_check {
                margin-block-start: 24px;
                text-align: center;

                label {
                    margin: 0;

                    .MuiCheckbox-root {
                        // padding: 1vh;
                        padding: 0;
                        margin-inline-end: 7.5px;

                        &.Mui-checked {
                            color: var(--parple);
                        }
                    }

                    .MuiSvgIcon-fontSizeMedium {
                        width: 24px;
                        height: 24px;
                    }

                    .MuiTypography-body1 {
                        // @include head_h4;
                        font-weight: 400;
                        color: var(--text_color);
                        // line-height: 3.5vh;
                        font-size: 14px;
                        letter-spacing: 0;
                    }
                }
            }

            .copy_url {
                width: 100%;
                max-width: 358px;
                margin-inline: auto;
                min-height: 36px;
                @include displayFlex(row, center, center);
                // background: var(--background-white);
                // border: 1px solid var(--borderlight);
                border: 1px solid transparent;
                background: linear-gradient(to right, white, white),
                    linear-gradient(to right, rgba(0, 194, 255, 1), rgba(99, 120, 255, 1));
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                border-radius: 36px;
                padding-inline-start: 16px;
                margin-block-start: 20px;

                .copyurl_tooltip {
                    position: absolute;
                    background: var(--tooltip_black_800);
                    background-blend-mode: overlay;
                    border-radius: 4px;
                    top: -5px;
                    left: auto;
                    width: 90px;
                    padding-block: 5px;
                    padding-inline: 8px;
                    @include displayFlex(row, center, center);
                    gap: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: var(--font-stack);
                    color: var(--textWhite);
                    transition: var(--transition);
                    transform: translateY(-100%);
                    opacity: 1;
                    pointer-events: none;
                    z-index: 0;

                    svg {
                        font-size: 16px;
                    }
                }

                input {
                    border: none;
                    box-shadow: none;
                    outline: none;
                    flex: 1;
                    line-height: 19px;
                    font-size: 14px;
                    color: var(--parple);

                    &::placeholder {
                        color: var(--placeholder-color);
                    }
                }

                .btn {
                    width: 100px;
                    padding: 6px 10px;
                    line-height: 19px;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--textWhite);
                    border-radius: 40px;
                    background: var(--background_grad_01);
                    gap: 6px;
                    min-height: 36px;
                    box-shadow: none;
                    text-transform: none;
                    position: relative;
                    left: 1px;

                    .MuiButton-endIcon {
                        margin: 0;
                    }

                    &:hover {
                        background: var(--background_grad_01);
                    }
                }
            }
        }
    }
}
