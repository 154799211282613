@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @use "sass:math";
// @debug "math.tan";

:root {
    --wheel-font: 'Poppins', sans-serif !important;
    --wheel-size: 400px;
    --wheel-slice-spacing: 0px;
    --wheel-border-size: 00px;
    --wheel-color: rgba(207, 213, 255, 1);
    --wheel-bg_color: #edf0ff;
    --wheel-name-bg: #0c63be;
    --neutral-color: white;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 6;
    --reset-duration: 0.25s;
    --color1: #ef476f;
    --color2: #ffba00;
    --color3: #06d6a0;
    --color4: #7d29c0;
    --color5: #2aa4e3;
    --color6: #ff871e;
    // --tan: 0;
    // --fontSze: 16px;
}

$tan: var(--tan);
$pi: 3.14159265358979;
$wheelsizeHalf: calc(var(--wheel-size) / 2);
// $slice-width: calc($wheelsizeHalf * tan($pi/var(--nb-item)));

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 10px;
    margin: auto;
    background-color: var(--wheel-bg_color);
    border: solid var(--wheel-color) 8px;
    border-radius: 50%;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
}

.wheel-container::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: none;
    background: url(../../../assets/images/wheelNeedle.svg) no-repeat;
    border-left-width: 0;
    width: 69px;
    height: 51px;
    right: -52px !important;
    background-size: 67px;
}

.wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
}

.wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    border: solid transparent 0;
    background-color: var(--wheel-bg_color);
    cursor: pointer;
}

.wheel.spinning {
    transform: rotate(var(--rotationValue));

    -webkit-transition: all var(--spinning-duration) cubic-bezier(0, 0.99, 0.55, 0.99);
    -moz-transition: all var(--spinning-duration) cubic-bezier(0, 0.99, 0.55, 0.99);
    -o-transition: all var(--spinning-duration) cubic-bezier(0, 0.99, 0.55, 0.99);
    -ms-transition: all var(--spinning-duration) cubic-bezier(0, 0.99, 0.55, 0.99);
    transition: all var(--spinning-duration) cubic-bezier(0, 0.99, 0.55, 0.99);
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
    display: block;
    position: absolute;
    content: '';
    background-color: var(--wheel-name-bg);
    width: 25px;
    height: 25px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: none;
}

.wheel-item.fullzone {
    width: 100%;
    height: 100%;
    background-color: var(--dynamic-color);
    display: -ms-grid;
    display: grid;
    place-items: center;
    position: inherit;
    top: inherit;
    left: inherit;
    padding: 0px;
    transform-origin: inherit;
    transform: inherit;

    span {
        margin-inline-start: auto;
        margin-inline-end: 35px;
        max-width: 115px;
    }
}

.wheel-item.fullzone::before {
    display: none !important;
}

.wheel-item.halfzone:nth-of-type(1) .angle {
    background: var(--dynamic-color);
}

.wheel-item.halfzone:nth-of-type(2) .angle {
    background: var(--dynamic-color);
}

/* Element sur la roue */
.wheel-item {
    display: flex;
    position: absolute;
    box-sizing: border-box;

    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    color: var(--neutral-color);
    text-align: end;
    padding-block: 0;
    padding-left: 47px;
    padding-right: 8px;
    font-family: var(--wheel-font);
    font-size: 1em;

    span {
        text-align: end;

        display: inline-block;

        width: 145px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.center {
            width: max-content;
            max-width: 100%;
        }
    }
}

.wheel-item .angle {
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-inline-start: 0px;
    opacity: 1;
    border-top: calc(var(--tan)) solid transparent;
    border-bottom: calc(var(--tan)) solid transparent;
    border-right: calc($wheelsizeHalf) solid blue;
    // border-inline-start: 116px solid transparent;
    // border-inline-end: 116px  solid blue;
}

// @keyframes rotate {
//     0% {
//         transform: rotate(0deg);
//     }
//     10% {
//         transform: rotate(500deg);
//     }
//     20% {
//         transform: rotate(1000deg);
//     }
//     30% {
//         transform: rotate(1500deg);
//     }
//     40% {
//         transform: rotate(2000deg);
//     }
//     50% {
//         transform: rotate(2500deg);
//     }
//     60% {
//         transform: rotate(3000deg);
//     }
//     70% {
//         transform: rotate(4000deg);
//     }
//     80% {
//         transform: rotate(5000deg);
//     }
//     90% {
//         transform: rotate(600deg);
//     }
//     100% {
//         transform: rotate(8000deg);
//     }
//   }
