html.rtl {
    // :lang(ar) {
    //     --font-stack: 'Roboto', 'IBM Plex Sans Arabic', sans-serif !important;
    //     --InterTight: 'Roboto', 'IBM Plex Sans Arabic', sans-serif !important;
    // }
    .sendCodeText {
        .tickIcon {
            inset: 0px 24px 0 auto;
        }
    }

    .viewport {
        transform-origin: right top;
    }

    .wh100 .modal_viewport {
        transform-origin: right top;
    }

    .pickWheel_sec .pickwheel_right .pickwheel_name .pickname_arrow {
        right: initial;
        left: 233px;
    }

    .pickWheel_sec .pickwheel_right.close .pickwheel_name .pickname_arrow {
        right: initial;
        left: 20px;
    }

    .wheel-item {
        text-align: right;

        span {
            text-align: start;
        }
    }

    .sort .content .listItemContainer.show {
        inset: 36px auto auto 0;
    }

    .participant_content_sec {
        .participent_card {
            .profile {
                .avatar_image {
                    .status {
                        right: initial;
                        left: 0;
                    }
                }
            }

            .card_wraper {
                .small_card_box {
                    .button_wraper.myclassBtn {
                        .decrement {
                            border-radius: 40px 0 0 40px;
                        }

                        .starbtn {
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-top-right-radius: inherit;
                            border-bottom-right-radius: inherit;
                        }

                        &.border_r_none {
                            .starbtn {
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                border-top-right-radius: inherit;
                                border-bottom-right-radius: inherit;
                            }
                        }
                    }
                }
            }
        }
    }

    .sparkleLottieGlobal {
        .sparkle {
            &:nth-of-type(1) {
                inset: -95px 0 0 -10px;
            }

            &:nth-of-type(2) {
                inset: -80px -45px 0 0;
            }

            &:nth-of-type(3) {
                inset: -55px -55px 0 0;
            }

            &:nth-of-type(4) {
                inset: -40px 0 0 -5px;
            }
        }
    }

    .profile_form {
        .pf_form {
            .MuiFormControl-root {
                .MuiInputLabel-root {
                    transform-origin: top right;
                    left: initial;
                    right: 0;
                }
            }
        }
    }

    .setting_body {
        .checkbox {
            .MuiFormControlLabel-root {
                margin-left: 16px;
                margin-right: -10px;
            }
        }
    }

    .profile_form .profile_right .changes_profile_images {
        right: initial;
        left: 2px;
    }

    .timer_sec {
        .time_display {
            direction: ltr;
        }

        // .startTimer_sec {
        //     .number {
        //         h4 {
        //             display: flex;
        //             align-items: baseline;
        //         }
        //     }
        // }
    }

    .footer_sec {
        .foot_menu {
            right: initial;
            left: 20px;

            .menu {
                right: initial;
                left: 0 !important;
                transform-origin: left bottom;
            }

            .menu.active {
                transform-origin: left bottom;
            }
        }

        .footer_left,
        .footer_right {
            .music_box {
                .volumeslider {
                    left: initial;
                    right: 0;
                }
            }
        }
    }

    .sh_footer {
        .foot_icon > ul li .menu {
            right: initial;
            left: 5px;
        }

        .star_point_btn {
            button.star_img {
                right: 2px;
            }
        }
    }

    .menu {
        transform-origin: left top;

        &.active {
            transform-origin: left top;
            // left: initial !important;
        }
    }

    .user_form {
        .user_profile_name_id .upgrade {
            transform: translate(0vh, 0.3vh) scale(1);
            margin-inline-end: 0vh;
        }

        .MuiGrid-container > .MuiGrid-item {
            padding-left: 0;
            // padding-right: 3vh;
        }
    }

    .editprofile_sec label {
        transform-origin: top right;
        left: initial;
        right: 0;
    }

    .user_form label + .MuiInput-underline svg {
        right: initial;
        left: 0;
    }

    .editprofile_sec .MuiFormHelperText-root.Mui-error {
        text-align: right;
    }

    .rtl_chart_tooltip {
        right: initial !important;
        left: -76px;
    }

    .participent_points_table {
        .ans_table_inner {
            &::before {
                right: calc(var(--padding-inline) + -1px);
            }

            table {
                thead {
                    tr {
                        th {
                            top: -1px;
                        }
                    }
                }
            }
        }
    }

    .change_product_key_sec .planradio {
        margin-left: 16px;
        margin-right: -10px;
    }

    .csv_upload_modal {
        .csvmodal_box {
            .modal_head.close {
                right: initial;
                left: 18px;
            }

            .participent_input {
                .MuiFormControl-root {
                    .MuiInputLabel-root {
                        transform-origin: top right;
                        left: initial;
                        right: 0;
                        transform: translate(-6px, 20px) scale(1);
                        -webkit-transform: translate(-6px, 20px) scale(1);
                        -moz-transform: translate(-6px, 20px) scale(1);
                        -ms-transform: translate(-6px, 20px) scale(1);

                        &.MuiInputLabel-shrink {
                            transform: translate(0px, -1px) scale(0.75);
                            -webkit-transform: translate(0px, -1px) scale(0.75);
                            -moz-transform: translate(0px, -1px) scale(0.75);
                            -ms-transform: translate(0px, -1px) scale(0.75);
                        }
                    }
                }
            }

            &.addparticipent_modal_box {
                .addparticipent_modal_sec {
                    .addparticipent_head {
                        .import_download_csv {
                            min-width: fit-content;
                        }
                    }
                }
            }
        }
    }

    .joining_sec {
        .right_side {
            .innerContainer {
                .form {
                    .pf_form {
                        .MuiFormControl-root {
                            .MuiInputLabel-root {
                                transform-origin: top right;
                                left: initial;
                                right: 0;
                                // transform: translate(-6px, 20px) scale(1);
                                // -webkit-transform: translate(-6px, 20px) scale(1);
                                // -moz-transform: translate(-6px, 20px) scale(1);
                                // -ms-transform: translate(-6px, 20px) scale(1);

                                // &.MuiInputLabel-shrink {
                                //     transform: translate(0px, -1px) scale(0.75);
                                //     -webkit-transform: translate(0px, -1px) scale(0.75);
                                //     -moz-transform: translate(0px, -1px) scale(0.75);
                                //     -ms-transform: translate(0px, -1px) scale(0.75);
                                // }
                            }
                        }
                    }
                }
            }
        }
    }

    .whiteboardupload_modal {
        .whiteboard_field {
            .MuiFormControl-root {
                .MuiInputLabel-root {
                    transform-origin: top right;
                    left: initial;
                    right: 0;
                    transform: translate(0px, 20px) scale(1);
                    -webkit-transform: translate(0px, 20px) scale(1);
                    -moz-transform: translate(0px, 20px) scale(1);
                    -ms-transform: translate(0px, 20px) scale(1);

                    &.MuiInputLabel-shrink {
                        transform: translate(0px, -1px) scale(0.75);
                        -webkit-transform: translate(0px, -1px) scale(0.75);
                        -moz-transform: translate(0px, -1px) scale(0.75);
                        -ms-transform: translate(0px, -1px) scale(0.75);
                    }
                }
            }
        }
    }

    .addingClass {
        .stepPanel {
            .participent_footer {
                .step_btn button {
                    .MuiButton-startIcon {
                        margin-right: -4px;
                        margin-left: 8px;
                    }
                }
            }

            .participent_set_sec {
                .participent_input {
                    .MuiFormControl-root {
                        .MuiInputLabel-root {
                            transform-origin: top right;
                            left: initial;
                            right: 0;
                            transform: translate(-6px, 20px) scale(1);
                            -webkit-transform: translate(-6px, 20px) scale(1);
                            -moz-transform: translate(-6px, 20px) scale(1);
                            -ms-transform: translate(-6px, 20px) scale(1);

                            &.MuiInputLabel-shrink {
                                transform: translate(-6px, -1px) scale(0.75);
                                -webkit-transform: translate(-6px, -1px) scale(0.75);
                                -moz-transform: translate(-6px, -1px) scale(0.75);
                                -ms-transform: translate(-6px, -1px) scale(0.75);
                            }
                        }
                    }
                }
            }

            .steps_sec {
                .MuiStepLabel-iconContainer {
                    padding-right: 0;
                    padding-left: 8px;
                }
            }
        }
    }

    .lottieAnimationGlobal {
        inset: 50% auto auto 50%;
        transform: translate(-50%, -50%) !important;
    }

    .addingClass {
        .view_edit_sec {
            .participent_table {
                &::after {
                    left: initial;
                    right: 0;
                }
            }
        }

        .stepPanel {
            .participent_set_sec {
                .profile_upload {
                    .changes_profile_images {
                        right: initial;
                        left: 10px;
                    }
                }
            }
        }

        .manageClass_src {
            .watch_tutorial_btn {
                button {
                    .MuiButton-startIcon {
                        margin-right: 0px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .participent_points_table .ans_table_inner {
        .MuiTableContainer-root {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        table {
            thead {
                tr {
                    border-radius: 16px 16px 0 0;

                    th {
                        &:nth-of-type(1) {
                            border-radius: 0px 16px 0 0;
                        }

                        &:nth-of-type(2) {
                            text-align: start;
                            width: 36%;
                        }

                        &:last-child {
                            // border-radius: 0px 16px 0 0;
                            border-top-right-radius: 0px;
                            border-top-left-radius: 16px;
                        }
                    }
                }
            }

            tbody {
                border-radius: 0px 0px 16px 16px;

                tr {
                    &:last-child {
                        border-bottom-left-radius: 16px;
                        border-bottom-right-radius: 16px;

                        td {
                            &:last-child {
                                border-radius: 0px 0px 0px 16px;
                            }

                            &:first-child {
                                border-radius: 0px 0px 16px 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .word_cloud_main .searchfield + .show_response {
        right: initial;
        left: 24px;
    }

    .short_answer_sec {
        .searchfield + .show_response {
            right: initial;
            left: 24px;
        }
    }

    .leader_board_chart {
        .leader_board_number {
            left: initial;
            right: 6px;
        }

        // div{
        //     .tooltip_wrap{
        //         right: initial;
        //         left: -124%;
        //     }
        // }

        .bargraph {
            // .min_width{
            //     .tooltip_wrap{
            //         right: initial;
            //         left: -76px;
            //     }
            // }
            .Lbprofile_img {
                right: initial;
                left: 0;
            }

            &.first {
                background: linear-gradient(270deg, #e9b500 0%, rgba(255, 235, 132, 0) 50%), #fafbff;
            }

            &.second {
                background: linear-gradient(270deg, #cfcfcf 0%, rgba(233, 233, 233, 0) 50%), #fafbff;
            }

            &.third {
                background: linear-gradient(270deg, #ff8340 0%, rgba(255, 209, 184, 0) 50%), #fafbff;
            }
        }
    }

    .table_edit_icon {
        .lastRowData {
            .menu {
                transform-origin: left bottom 0px;

                &.active {
                    transform-origin: left bottom 0px;
                }
            }
        }

        .menu {
            left: 0;
            right: initial;
        }
    }

    :is(.flipcard_modal, .autoCreateGroup) {
        .auto_pick_modal {
            .auto_pick_modal_body {
                .pickNumber_card {
                    .pickNumber_service {
                        .flip-box-back {
                            .single_point_btn {
                                .button_wraper {
                                    .starbtn.big {
                                        p {
                                            margin-inline-start: 10px;
                                        }
                                    }

                                    .point_box2 {
                                        right: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .leaderboard_left {
        .leader_board_rank_sec {
            .leader_board_rank_barchart {
                .leader_board_rank_chart {
                    .chart_tooltip {
                        .crown_avater {
                            .crown_img {
                                right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .button_wraper {
        .point_box2 {
            right: 13px;
        }
    }

    .barchart_sec {
        .inner_barchart {
            .chart {
                direction: ltr;
            }
        }
    }

    .video_upload_sec {
        .videoControls {
            .option {
                .menu {
                    transform-origin: left top;
                    left: 0;
                    right: initial;

                    &.active {
                        transform-origin: left top;
                    }
                }
            }
        }
    }

    .fill_blank_sec {
        .inner_fillblank {
            .rest-columns {
                td.last_child,
                th.last_child {
                    .star_point_btn {
                        .star_img {
                            right: 2px;
                        }
                    }
                }
            }
        }
    }

    .switches-container {
        /* slide the switch box from left to right */
        &.switch_box .switch-wrapper {
            transform: translateX(-100%);
        }
    }

    .videoTutorial_sec {
        .right_VcTutorial_sec {
            .videoControls {
                .option {
                    .menu {
                        right: initial;
                        left: 0;
                        transform-origin: right top;

                        ul {
                            li {
                                a {
                                    .tick {
                                        left: initial;
                                        right: 8px;
                                    }
                                }
                            }
                        }

                        &.active {
                            transform-origin: right top;
                        }
                    }
                }
            }
        }
    }

    .modal_box.video_tutorial_modal_box {
        .videoControls {
            .option {
                .menu {
                    right: initial;
                    left: 0;
                    transform-origin: right top;

                    ul {
                        li {
                            a {
                                .tick {
                                    left: initial;
                                    right: 8px;
                                }
                            }
                        }
                    }

                    &.active {
                        transform-origin: right top;
                    }
                }
            }
        }
    }

    .browserSection {
        .favIcon {
            margin-left: 0;
            margin-inline-start: 8px;
        }

        .controlUiSection {
            .urlsearch {
                .MuiOutlinedInput-root {
                    .MuiInputAdornment-root:nth-child(1) {
                        margin: 0;
                        margin-inline-end: 8px;
                    }

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }
            }
        }

        .controlUiSection_bottom {
            .tab-sections {
                .tab-item {
                    .cross {
                        right: initial;
                        left: 4px;
                    }

                    &::before {
                        right: initial;
                        left: 0;
                    }
                }
            }
        }
    }

    .short_ans_modal {
        .MuiBox-root {
            .slidedrawing_modal,
            .images_upload_modal {
                .sh_footer {
                    .foot_icon {
                        > ul {
                            li {
                                .menu {
                                    transform-origin: left bottom 0px;

                                    &.active {
                                        transform-origin: left bottom 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .new_subscription {
        .new_sb_card_sec {
            .new_card {
                .inner_card {
                    .pro_title {
                        .big_sub .sub {
                            right: initial;
                            left: -5px;
                            transform: translateX(-100%);
                        }
                    }
                }
            }
        }
    }
    .chat_bot_area {
        .question_area {
            .option {
                .tickLottie {
                    left: inherit;
                    right: -80px;
                }
            }
        }
    }
    .random_Qa_sec {
        .inner_random_Qa {
            .level_chip_wrapper {
                right: inherit;
                left: 12px;
            }
            .nav_bar {
                &.alternative_nav_bar {
                    :is(.previous, .forward) {
                        transform: rotate(180deg);
                    }
                }
            }
            .use_Qa_group {
                .btn_group {
                    .use_QA_btn {
                        gap: 6px;
                        .MuiButton-startIcon {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .contextualTutorial {
        .textContainer {
            .btnContainer {
                :is(.nav_previous, .nav_forward) {
                    .icon {
                        rotate: 180deg;
                    }
                }
            }
        }
    }

    .setting_body {
        .backto_page {
            svg {
                rotate: 180deg;
            }
        }
    }
}
