@use '../../../Style.scss' as *;

.free_cr_modal_box {
    position: absolute;
    inset: 0 0 0 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
}

.free_cr_modal {
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0;
    width: 400px;
    border-radius: 16px;
    position: relative;
    background: var(--background-white);
    overflow: hidden;

    .free_cr_heading {
        width: 100%;
        background: var(--lightdeepbg2);
        min-height: 50px;
        @include displayFlex(row, center, center);

        .head {
            font-size: 16px;
            line-height: 21px;
            color: var(--text_color);
            font-family: var(--font-stack);
            font-weight: 500;
            @include displayFlex(row, center, center);
            gap: 8px;
        }
    }

    .free_cr_body {
        width: 100%;
        padding: 24px;
        text-align: center;

        .number_of_credit {
            width: 100%;
            margin-block: 5px 28px;
            position: relative;
            isolation: isolate;

            .confettiAI {
                position: absolute;
                inset: 0 0 0 0;
                margin: auto;
                z-index: -1;
                transform: scale(2);
            }

            .circle {
                text-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                width: 106px !important;
                height: 106px !important;

                .MuiCircularProgress-circle {
                    stroke-width: 2px;
                    color: var(--text_deep_purple);
                }
            }

            .number {
                background: var(--background_primary_05);
                position: absolute;
                inset: 0 0 0 0;
                margin: auto;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
                @include displayFlex(column, center, center);

                .big {
                    font-size: 52px;
                    line-height: 51px;
                    font-weight: 500;
                    font-family: var(--font-stack);
                    color: var(--text_deep_purple);
                }

                .sub_text {
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 400;
                    font-family: var(--font-stack);
                    color: var(--secondary_text);
                }
            }
        }

        .free_cr_content {
            width: 100%;

            .details {
                font-size: 14px;
                line-height: 21px;
                font-family: var(--font-stack);
                color: var(--activation_text);
            }

            .free_btn_group {
                margin-block-start: 22px;
            }
        }
    }
}
