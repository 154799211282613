@import '../../../../Style.scss';

.templateLibraryContentModal {
    .viewport {
        .closeModal {
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 0;

            width: 100%;
            height: 100%;
        }

        :is(.templateEditForm, .contentModalViewImageContainer) {
            position: absolute;
            inset: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 700px;
            height: 393px;
            margin-inline: auto;
            margin-block-start: 80px;
            border-radius: 10px;
            overflow: hidden;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .proIcon {
                position: absolute;
                inset: 8px 8px auto auto;

                font-size: 12px;
                font-weight: 500;
                color: var(--textWhite);
                letter-spacing: 1px;

                width: max-content;
                height: max-content;
                padding: 4px 10px;
                background: var(--user_pro);
                border-radius: 100px;
            }
        }

        .templateEditForm {
            padding-block: 50px;
            padding-inline: 50px;
            background: var(--background-white);
        }

        :is(.prevBtn, .nextBtn) {
            position: absolute;

            display: inline-block;

            color: var(--activation_text);

            width: 40px;
            min-width: auto;
            max-width: 40px;
            height: 40px;
            max-height: 40px;
            margin-block: auto;
            padding: 10px;
            border: none;
            border-radius: 50%;
            background: var(--background-white);
            background-size: 2.4rem 2.4rem;

            cursor: pointer;
            opacity: 0.7;

            &:hover {
                background: var(--background-white);
                opacity: 1;
            }

            .chevron {
                display: flex;

                font-size: 24px;
                fill: var(--activation_text);

                height: 100%;
                transition: fill 0.3s ease-in;
            }

            &:hover {
                .chevron {
                    fill: var(--activation_text);
                    transition: fill 0.3s ease-in-out;
                }
            }
        }

        .prevBtn {
            inset: 0 auto 60px 50px;
            rotate: 180deg;
        }

        .nextBtn {
            inset: 0 50px 60px auto;
            margin-block: auto;
        }

        .currentSlideIndex {
            position: absolute;
            inset: auto 0 87px 0;

            color: var(--textWhite);
            text-align: center;

            width: max-content;
            margin-inline: auto;
        }

        .btnContainer {
            position: absolute;
            inset: auto 0 35px 0;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            width: max-content;
            height: max-content;
            margin-inline: auto;

            :is(.insertBtn, .editBtn, .unpublishBtn, .deleteBtn) {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;

                font-size: 14px;
                color: var(--textWhite);
                text-transform: none;

                width: max-content;
                height: max-content;
                margin-inline: auto;
                padding-inline: 20px;

                :global(.MuiButton-startIcon) {
                    margin-inline: 0;
                }
            }

            .insertBtn {
                background: var(--backgroundcolor);
            }

            .editBtn {
                background: var(--checkboxGreen);
            }

            .unpublishBtn {
                background: var(--darkgoldbg);
            }

            .deleteBtn {
                background: var(--submission_btn);
            }
        }
    }
}
