@use './../../Style.scss' as *;

.my_section {
    background-color: rgb(224, 247, 25);
    // background-image: url(../../assets/images/cpp_myclass2.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    // min-width: 516px;
}

.myclass_body {
    width: 100%;
    margin-inline: auto;
    margin-block: 0;
    display: flex;
    opacity: 1;
}

.left_sidebar {
    width: 36%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0.8rem;
    padding: 34px 20px;
    height: 482px;
    background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;

    .qr_sec {
        padding: 14px;
        background: var(--background-white);
        width: 132px;
        margin-inline: auto;
        margin-block: 0;

        &:hover {
            cursor: pointer;
        }

        &:active {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1));
        }

        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.main_content_sec {
    width: 64%;
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    gap: 30px;
    padding: 32px;
    height: 482px;
    background: var(--parple_08);

    .participants_sec {
        width: 100%;
        height: auto;
        max-height: 340px;
        overflow: auto;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .MuiChip-root {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
            height: auto;
            padding: 3px 0;
            border: 1px solid var(--light-bg);
            border-radius: 20px;
            font-size: 14px;
            line-height: 15px;

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.01), rgba(99, 120, 255, 0.01)), #ffffff;
            }

            .MuiAvatar-circular {
                width: 24px;
                height: 24px;
                margin-inline-start: 6px;
                font-size: 2.5vh;
                line-height: 2.5vh;
                margin-inline-end: 0;
            }

            .MuiChip-label {
                padding: 0 6px;
                color: var(--text_color);
            }

            .MuiSvgIcon-root {
                margin-inline-end: 6px;
                margin-inline-start: 0;
                font-size: 15px;
                color: var(--light-bg);
            }
        }
    }
}

.myclass_qr_modal {
    width: fit-content;
    border-radius: 12px;
    padding: 0 !important;

    &.bgTransparent {
        background: transparent;
    }

    .modal_head {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;

        svg {
            cursor: pointer;
            font-size: 18px;
        }
    }

    .qr_popup {
        width: 572px;
        height: 362px;
        text-align: center;

        &.joinActivity {
            width: 520px;
            height: 304px !important;

            .qr_tiltle {
                display: flex;
                align-items: center;
                justify-content: center;

                height: 50px;
                padding-block: 0;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.08) 0%, rgba(99, 120, 255, 0.08) 100%);

                h4 {
                    color: var(--primary-color);
                }
            }

            .qr_pop_sec {
                padding-block-start: 10px;
                padding-inline: 22px;

                .qr_pop_left {
                    max-width: 220px !important;
                }
            }

            .qr_pop_right {
                .url_sec {
                    padding-inline: 12px 0;

                    .url_content {
                        .divider {
                            margin-block: 10px;
                        }
                    }
                }
            }
        }

        &.open {
            height: 314px;
        }

        &.trialActivation {
            display: flex;
            flex-direction: column;

            height: 362px !important;

            .modalContent {
                border-radius: 12px;
                background-color: var(--background-white);
            }

            .classFull {
                margin-block-start: auto;

                .qr_pop_sec {
                    height: 220px !important;
                }

                .qr_footer {
                    display: flex !important;
                    align-items: center !important;
                    justify-content: center !important;

                    height: 76px;
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff !important;
                }
            }
        }

        .classFullTrialActivation {
            display: flex;
            align-items: center;
            justify-content: center;

            width: min-content !important;
            height: min-content !important;

            .trialActivationModalContent {
                width: 572px !important;
                height: 362px !important;

                .content {
                    .description {
                        margin-block-start: 0;
                    }
                }
            }
        }

        .qr_tiltle {
            width: 100%;
            text-align: center;
            padding-block-start: 32px;
            padding-block-end: 0;
            padding-inline-start: 42px;
            padding-inline-end: 40px;

            h4 {
                color: var(--activation_text);
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                font-family: var(--font-stack);
            }
        }

        .openClass {
            .qr_pop_sec {
                height: 204px;
            }

            .qr_footer {
                align-items: flex-start;
                background: transparent;
            }
        }

        .qr_pop_sec {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 248px;
            padding: 0 40px;

            .class_locked_sec {
                text-align: center;
                width: 100%;
                max-width: 360px;
                margin-inline: auto;
                margin-block: 0;
                min-width: 168px;

                img {
                    width: 48px;
                }

                &.blue {
                    .MuiSvgIcon-root {
                        color: var(--parple);
                    }

                    h3 {
                        color: var(--parple);
                    }
                }

                &.red {
                    .MuiSvgIcon-root {
                        color: var(--no_more_red);
                    }

                    h3 {
                        color: var(--no_more_red);
                    }
                }

                .MuiSvgIcon-root {
                    font-size: 48px;
                }

                h3 {
                    font-size: 18px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                    margin-block-start: 21px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    margin-block-start: 14px;
                    color: var(--activation_text);
                    margin-block-end: 8px;

                    a {
                        color: var(--parple);
                        font-size: 14px;
                        line-height: 22px;
                        font-family: var(--font-stack);
                        font-weight: 400;
                        text-decoration: none !important;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }
            }

            .qr_pop_left {
                width: auto;
                width: 100%;
                max-width: 190px;
                padding-inline-end: 10px;
            }

            .qr_pop_right {
                flex: 1;
            }

            .url_sec {
                padding: 20px 20px;
                background: #fff;
                border-radius: 12px;
                width: 100%;
                // max-width: 280px;
                margin-inline: auto;
                margin-block: 0;

                .url_content {
                    position: relative;
                    width: 100%;
                    text-align: center;

                    span {
                        // @include para_p2;
                        font-size: 12px;
                        line-height: 14px;
                        font-family: var(--font-stack);
                        font-weight: 400;
                        color: var(--greytext_color);
                    }

                    h3 {
                        // @include head_h3;
                        font-size: 20px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--parple);
                        margin-block-start: 4px;
                    }

                    .divider {
                        border: none;
                        border-block-start: 1px solid var(--light_border);
                        margin-block-start: 14px;
                        margin-block-end: 12px;
                    }

                    h4 {
                        font-size: 22px;
                        line-height: 22px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--parple);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;

                        .copyIconContainer {
                            position: relative;

                            .icon {
                                color: var(--parple);
                            }

                            .MuiSvgIcon-root {
                                font-size: 32px;
                                display: flex;
                                cursor: pointer;
                                padding: 5px;
                                border-radius: 6px;

                                &:hover {
                                    background: linear-gradient(
                                            0deg,
                                            rgba(99, 120, 255, 0.11),
                                            rgba(99, 120, 255, 0.11)
                                        ),
                                        #ffffff;
                                }

                                &:active {
                                    background: linear-gradient(
                                            0deg,
                                            rgba(99, 120, 255, 0.11),
                                            rgba(99, 120, 255, 0.11)
                                        ),
                                        #ffffff;
                                }
                            }

                            .copyurl_tooltip {
                                position: absolute;
                                background: var(--tooltip_black_800);
                                background-blend-mode: overlay;
                                border-radius: 4px;
                                top: -5px;
                                left: -27px;
                                width: 80px;
                                padding-block: 2px;
                                padding-inline: 8px;
                                @include displayFlex(row, center, center);
                                gap: 0;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                font-family: var(--font-stack);
                                color: var(--textWhite);
                                transition: var(--transition);
                                transform: translateY(-100%);
                                opacity: 1;
                                pointer-events: none;
                                z-index: 0;

                                svg {
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .qr_footer {
            width: 100%;
            height: 60px;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;

            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
            border-radius: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding: 0 40px;

            .qr_learmore {
                justify-content: center;
                display: flex;
                align-items: center;
                width: 100%;

                a {
                    color: var(--parple);
                    font-size: 18px;
                    line-height: 28px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                    text-decoration: none !important;
                    cursor: pointer;
                    padding: 6px;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            .qr_foot_left {
                width: 100%;

                .switch_sec {
                    width: 100%;
                    text-align: center;
                    min-width: 128px;

                    .switchinner {
                        align-items: center;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        gap: 15px;
                        width: 100%;

                        .swtichlable {
                            font-size: 2.5vh;
                            line-height: 2.5vh;
                            color: var(--parple);
                            padding-inline-end: 2vh;
                            font-weight: 500;
                        }
                    }

                    Label {
                        margin: 0;

                        &:first-child {
                            min-width: 126px;
                        }

                        .MuiSwitch-root {
                            width: 28px;
                            height: 16px;
                            padding: 0;
                        }

                        .MuiSwitch-thumb {
                            width: 14px;
                            height: 14px;
                        }

                        .MuiTypography-body1 {
                            font-size: 14px;
                            line-height: 20px;
                            color: var(--activation_text);
                            margin-inline-start: 8px;
                            font-weight: 400;
                            text-align: start;
                        }

                        .Mui-checked + .MuiSwitch-track {
                            background: var(--parple);
                        }

                        .MuiSwitch-track {
                            border-radius: 24px;
                            background: var(--light-bg);
                            opacity: 1;
                        }

                        .MuiSwitch-switchBase {
                            padding: 1px;

                            &.Mui-checked {
                                color: var(--background-white);
                                -webkit-transform: translateX(12px);
                                -moz-transform: translateX(12px);
                                -ms-transform: translateX(12px);
                                transform: translateX(12px);
                            }
                        }

                        &.Mui-disabled {
                            .MuiSwitch-track {
                                opacity: 1;
                                background: var(--switch_diable);
                            }

                            .MuiTypography-root {
                                color: var(--disable_bg_AEAAAE);
                            }
                        }
                    }
                }
            }

            .qr_foot_right {
                // flex: 1;
                p {
                    color: var(--activation_text);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    margin: 0;
                    text-align: start;
                }
            }
        }

        canvas {
            width: 100% !important;
            height: 100% !important;
        }

        p {
            // @include para_p2;
            font-size: 20px;
            line-height: 20px;
            font-family: var(--font-stack);
            font-weight: 500;
            color: var(--text_color);
            margin-block-start: 28px;
        }
    }
}

// qrCode_single_modal
.qrCode_single_modal {
    width: fit-content;
    @include displayFlex(row, center, center);
    padding: 32px;
}

// &.myClass_waitting{
//     background: none;
//     height: 55vh;
// }

.waiting_animation {
    width: 100%;
    text-align: center;

    svg {
        height: 270px !important;
    }

    h4 {
        font-size: 22px;
        font-family: var(--font-stack);
        font-weight: 400;
        color: var(--parple);
        margin-block-start: 1.4vh;
    }

    h5 {
        color: var(--activation_text);
        font-family: var(--font-stack);
        font-size: 22px;
        font-weight: 400;
    }
}

.small {
    .modal_box {
        &.myclass_alert {
            width: 320px;
        }

        &.switch_participent {
            width: fit-content;
        }

        &.isGamificationModal {
            left: 250px !important;
        }
    }
}

.modal_box {
    &.myclass_alert {
        padding: 0 !important;
        width: 95%;
        max-width: 420px;
        border-radius: 16px;

        &.shareActivityModal {
            max-width: 460px;
        }

        &.quizModePro {
            width: fit-content;
            max-width: 605px;
        }

        &.restartalertmodal {
            width: fit-content;
            max-width: 480px;
        }

        &.switch_participent {
            width: fit-content;
            max-width: 786px;
        }

        &.askCpAi {
            max-width: 600px;
            overflow: hidden;
        }

        .switch_class {
            height: 60px;
            width: 100%;
        }

        .isproUser_participantCount {
            @include displayFlex(row, center, center);
            gap: 12px;
            border-radius: 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            padding-block: 16px;
            min-height: 72px;
            padding-inline: 15px 30px;

            .classLimitWarning {
                font-size: 13px;
                color: var(--danger);
            }

            .trialActivationButton {
                min-height: 40px;
            }
        }

        .button_sec {
            @include displayFlex(row, center, flex-end);
            gap: 6px;
            padding: 12px 20px;
            background: var(--parple_08);
            border-radius: 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            &.switch_participent_button {
                background: var(--background-white);
                justify-content: space-between;
                padding-block: 16px;
                min-height: 72px;
                padding-inline: 15px 30px;

                &.oneSaveClasses {
                    flex-direction: column;
                    justify-content: center;
                    gap: 10px;

                    .saveClassOption {
                        .menu_checkbox {
                            justify-content: center;
                        }
                    }
                }

                .classLimitWarning {
                    font-size: 13px;
                    color: var(--danger);
                }

                button {
                    min-width: 160px !important;
                    min-height: 40px !important;
                    width: auto;
                    height: auto;
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
                    border-radius: 40px;
                    font-size: 16px;
                    font-family: var(--font-stack);
                    color: var(--parple);
                    text-transform: none;
                    text-decoration: none;
                }
            }

            button {
                @include theme_btn;
                padding: 7px 20px !important;
                font-size: 14px;
                color: var(--btnbgcolor);

                .MuiLoadingButton-startIconLoadingStart {
                    display: none;
                }

                .MuiLoadingButton-loadingIndicator {
                    position: relative;
                    left: 0;
                    display: flex;
                    padding-inline: 0 5px;
                }

                &.MuiButton-containedPrimary {
                    color: var(--textWhite);
                    background-color: var(--btnbgcolor) !important;

                    &:hover {
                        background-color: var(--btnhover) !important;
                    }

                    &.highlightWarning {
                        background-color: var(--submission_btn) !important;

                        &:hover {
                            background-color: var(--danger) !important;
                        }
                    }
                }
            }
        }

        .media {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding: 24px 22px;

            &.flex_col {
                flex-direction: column;
                gap: 12px;
                padding-block-end: 16px;

                p {
                    font-size: 14px;
                    line-height: 20px;
                    font-family: var(--font-stack);
                    font-weight: 400;

                    &.Mui-error {
                        margin-inline-start: 0;
                    }
                }
            }

            .media_icon {
                // width: 10vh;
                .warning {
                    font-size: 28px;
                    margin-block-end: 0;
                    margin-inline-end: 18px;
                    color: #ffc107;
                }

                .info {
                    font-size: 28px;
                    margin-block-end: 0;
                    margin-inline-end: 18px;
                    color: var(--info_color);
                }

                img {
                    margin-inline-end: 18px;
                }
            }

            .media_body {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;

                .alert_content {
                    width: 100%;
                    word-break: break-word;

                    p {
                        // @include head_h4;
                        font-size: 14px;
                        line-height: 21px;
                        font-family: var(--font-stack);
                        color: var(--text_color);
                        text-align: start;
                        font-weight: 400;
                        letter-spacing: 0;

                        .link {
                            color: var(--cpbutton);
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline !important;
                            }
                        }
                    }

                    .alert_check {
                        margin-block-start: 14px;

                        label {
                            margin: 0;

                            .MuiCheckbox-root {
                                // padding: 1vh;
                                padding: 0;
                                margin-inline-end: 7.5px;

                                &.Mui-checked {
                                    color: var(--parple);
                                }
                            }

                            .MuiSvgIcon-fontSizeMedium {
                                width: 18px;
                                height: 18px;
                            }

                            .MuiTypography-body1 {
                                // @include head_h4;
                                font-weight: 400;
                                color: var(--text_color);
                                // line-height: 3.5vh;
                                font-size: 14px;
                                letter-spacing: 0;
                            }
                        }
                    }
                }
            }
        }

        // .MuiSvgIcon-root{
        //     color: var(--cpbutton);
        // }
        .MuiSelect-select {
            padding: 8px 12px;

            .select_item {
                display: flex;
                align-items: center;
                // justify-content: space-between;
                gap: 15px;
            }

            h5 {
                font-size: 14px;
                font-family: var(--font-stack);
                color: var(--activation_text);
                font-weight: 500;
                line-height: 15px;
            }

            .participent {
                display: flex;
                align-items: center;
                gap: 9px;

                .chip {
                    border-radius: 16px;
                    padding: 5px 8px;
                    background: var(--light-bg);
                    font-size: 12px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    font-weight: 500;
                    line-height: 12px;
                }

                .number_of_participent {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    display: none;

                    svg {
                        color: var(--secondary_text);
                        font-size: 18px;
                    }

                    p {
                        color: var(--secondary_text);
                        font-family: var(--font-stack);
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 400;
                    }
                }
            }
        }

        .MuiOutlinedInput-root {
            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border-color: var(--parple);
                    border-width: 1px;
                }
            }

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }
    }

    &.voting_modal {
        padding: 0vh !important;
        width: 74vh;

        .MuiInput-underline:after {
            border-block-end: 2px solid var(--parple);
        }

        .MuiInput-underline.Mui-focused,
        .MuiInputLabel-shrink.Mui-focused {
            color: var(--parple);
        }

        .media {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            padding: 4vh;

            .media_body {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;

                ul li {
                    .MuiInput-root.MuiInput-underline {
                        width: 17vh;
                        margin-inline-start: 2vh;
                    }
                }
            }
        }

        .button_sec {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1vh;
            padding: 12px 20px;
            background: var(--parple_08);

            button {
                @include theme_btn;
                padding: 1vh 3.7vh !important;

                &.MuiButton-containedPrimary {
                    background-color: var(--cpbutton) !important;

                    &:hover {
                        background-color: var(--btnhover) !important;
                    }
                }
            }
        }

        label {
            margin: 0;

            .MuiCheckbox-root {
                padding: 1vh;
                padding-inline-start: 0;

                &.Mui-checked {
                    color: var(--parple);
                }
            }

            .MuiSvgIcon-fontSizeMedium {
                font-size: 4vh;
            }

            .MuiTypography-body1 {
                @include head_h4;
                font-weight: 400;
                color: var(--text_color);
                line-height: 3.5vh;
            }
        }
    }
}

.close_modal {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.MuiList-padding.MuiMenu-list li {
    &.custom_dropdown {
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 12px;
        min-height: 45px;
        gap: 4px;
        border-block-end: 1px solid var(--menuliborder);

        &.justi_center {
            justify-content: center;

            &:first-child {
                display: none;
            }
        }

        .select_item {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            display: flex;
        }
    }

    h5 {
        font-size: 14px;
        font-family: var(--font-stack);
        color: var(--activation_text);
        font-weight: 500;
        line-height: 16px;
    }

    .participent {
        display: flex;
        align-items: center;
        gap: 13px;

        .chip {
            border-radius: 16px;
            padding: 4px 8px;
            background: var(--light-bg);
            font-size: 12px;
            font-family: var(--font-stack);
            color: var(--activation_text);
            font-weight: 500;
            line-height: 14px;
        }

        .number_of_participent {
            width: 100%;
            display: flex;
            gap: 7px;
            align-items: center;

            svg {
                color: var(--secondary_text);
                font-size: 18px;
            }

            span {
                color: var(--secondary_text);
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 14px;
                font-weight: 400;
            }
        }
    }
}

.my_class_wating {
    position: relative;
    height: 385px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background: white;
    // background: var(--lightparple);
    width: 100%;

    img {
        width: 64px;
        height: 84px;
        box-sizing: content-box;
        padding-block-end: 41px;
    }

    svg {
        height: 40vh !important;
    }

    h4 {
        color: var(--activation_text);
        font-size: 22px;
        font-weight: 400;
    }

    h5 {
        position: absolute;
        bottom: 78px;

        color: var(--cpbutton);
        font-family: var(--font-stack);
        font-size: 22px;
        font-weight: 400;
    }
}

.switchClass {
    padding-block-start: 12px;
    padding-block-end: 4px;
    padding-inline: 0;
    min-height: 79px;

    .content {
        position: relative;
        cursor: pointer;

        .visible {
            width: fit-content;
            height: fit-content;

            & > button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;

                width: 380px;
                height: 40px;
                padding: 8px 12px;
                color: transparent;
                border: 1px solid var(--gray_text_color);
                border-radius: 4px;
                background: var(--background-white);
                transition: none;

                &:is(:hover, :focus) {
                    border: 1px solid var(--cpbutton);
                }

                p:nth-of-type(1) {
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    color: var(--text_color);
                }

                p:nth-of-type(2) {
                    font-size: 12px;
                    font-weight: 400;
                    text-transform: none;
                    color: var(--activation_text);

                    padding: 2px 8px;
                    border-radius: 22px;
                    background-color: var(--light-bg);

                    &.green {
                        background-color: var(--editview_chip_bg);
                    }
                }

                p:nth-of-type(3) {
                    font-size: 12px;
                    font-weight: 400;
                    text-transform: none;
                    color: var(--activation_text);
                }

                svg {
                    font-size: 20px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-inline-start: auto;

                    svg {
                        font-size: 20px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }

            &.error > button {
                border: 1px solid #b3261e;
            }
        }

        .listItemContainer {
            display: none;
            max-height: 160px;
            overflow: auto;

            &.show {
                position: absolute;
                inset: 40px 0 auto 0;
                z-index: 2;

                display: inline-block;

                width: 100%;
                height: fit-content;
                background: var(--background-white);
                box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);

                & > button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;

                    color: rgba(99, 120, 255, 0.3);

                    width: 100%;
                    height: 40px;
                    padding: 8px 12px;
                    background: var(--background-white);
                    border-block-start: 1px solid rgba(99, 120, 255, 0.11);

                    &:hover {
                        background: rgba(99, 120, 255, 0.1);
                    }

                    &:nth-of-type(1) {
                        height: 40px;
                        border-block-start: 0;

                        // .details {
                        //     display: none;
                        // }

                        p {
                            font-weight: 400;
                        }
                    }

                    .green_chip {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.2;
                        color: var(--activation_text);
                        padding: 3px 8px;
                        border-radius: 22px;
                        background: var(--editview_chip_bg);
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: none;
                        color: var(--activation_text);
                    }

                    .details {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        p:nth-of-type(1) {
                            font-size: 12px;
                            font-weight: 400;
                            text-transform: none;
                            color: var(--activation_text);

                            padding: 1px 8px;
                            border-radius: 22px;
                            background-color: #cfd5ff;
                        }

                        svg {
                            font-size: 18px;
                            color: var(--secondary_text);
                            margin-inline-start: 10px;
                        }

                        p:nth-of-type(2) {
                            font-size: 12px;
                            font-weight: 400;
                            text-transform: none;
                            color: var(--secondary_text);

                            margin-inline-start: 6px;
                        }
                    }
                }
            }
        }
    }

    .bgOverlay {
        display: none;

        &.show {
            position: absolute;
            inset: 0 0 0 0;
            z-index: 1;

            display: block;

            width: 100%;
            height: 100%;
            background-color: transparent;
        }
    }
}
