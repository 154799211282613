@use '../../Style.scss' as *;

// .footer_sec {
//     text-align: center;
//     padding: 24px 15px;
//     background-color: #fff;
//     border-block-start: 0px solid #e0e0e0;
//     box-shadow: inset 0 0 44px rgb(0 0 0 / 2%);
//     display: flex;
//     width: 100%;
//     margin-inline:auto;
//     margin-block: 0;
//     .default_btn{
//         @include activationbtn;
//         width: auto;
//         color: var(--parple);
//         padding: 6px 28px !important;
//     }
//     .delete_btn{
//         @include activationbtn;
//         width: auto;
//         color: var(--submission_btn);
//         padding: 6px 28px !important;
//     }
//     &.participant_sec_foot {
//         height: auto;
//         // background: var(--footer_bg_second);
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         gap: 1.4rem;
//     }
//     h6 {
//         font-size: 12px;
//         font-family: var(--font-stack);
//         a {
//             color: var(--activation_text);
//         }
//     }
//     .footer_left,
//     .footer_right {
//         display: flex;
//         align-items: center;
//         gap: 0.6rem;
//         min-width: 200px;
//         h4 {
//             font-size: 18px;
//             color: var(--activation_text);
//             font-family: var(--font-stack);
//             display: flex;
//             align-items: flex-end;
//             gap: 0.4rem;
//             line-height: 18px;
//             font-weight: 500;
//             cursor: pointer;
//             span {
//                 display: flex;
//                 font-size: 18px;
//                 line-height: 18px;
//                 &.red {
//                     color: var(--red);
//                     font-weight: 500;
//                 }
//                 &.prime {
//                     color: var(--tabcolor);
//                     cursor: pointer;
//                 }
//                 svg {
//                     fill: var(--tabcolor);
//                     width: 20px;
//                     height: 20px;
//                 }
//             }
//         }
//         a {
//             cursor: pointer;
//         }
//     }
//     .footer_left {
//         justify-content: end;
//     }
//     .footer_right h4 {
//         cursor: pointer;
//     }
//     .submision {
//         height: 40px;
//         background-color: var(--submission_btn) !important;
//         width: auto;
//         line-height: 40px !important;
//         color: var(--textWhite);
//         padding: 0 30px !important;
//         box-shadow: none !important;
//     }
//     .leader_foot_btn {
//         height: 40px;
//         background-color: var(--footer_defult_btn) !important;
//         width: auto;
//         line-height: 40px !important;
//         color: var(--textWhite);
//         padding: 0 50px !important;
//         color: var(--textlead_color);
//         text-transform: capitalize;
//     }
// }
// .footer_middle {
//     float: left;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     &.gap{
//         gap: 20px;
//     }
//     .foot_btn {
//         @include activationbtn;
//         background-color: var(--footer_btn) !important;

//         &:hover {
//             background-color: var(--no_more_red) !important;
//         }
//     }
//     .foot_btn_blue {
//         @include activationbtn;
//         background-color: var(--btnbgcolor) !important;
//         &:hover {
//             background-color: var(--btnhover) !important;
//         }
//     }
//     .foot_btn_gray {
//         @include activationbtn;
//         background-color: var(--graybtn) !important;
//         color: var(--activation_text);
//         &:hover {
//             background-color: var(--grayBtnHover) !important;
//         }

//     }
//     a {
//         cursor: pointer;
//     }
// }

.foot_menu {
    position: absolute;
    right: 20px;
    // top: 0;
    & > button {
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        min-height: auto;
        min-width: auto;
        cursor: pointer;
        isolation: isolate;
        // background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #FFFFFF;
        &:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
        }
        &.active {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
        }
        svg {
            color: var(--activation_text);
        }
        .MuiTouchRipple-root {
            z-index: -1;
            .MuiTouchRipple-child {
                background-color: var(--Ripplebg_color);
            }
        }
    }
    .menu {
        position: absolute;
        top: inherit;
        right: 0;
        left: inherit;
        &.active {
            transform-origin: right bottom 0px;
        }
        .menu_border {
            border: none;
            border-top: 1px solid var(--light_border);
            margin-block: 5px;
        }
    }
}
