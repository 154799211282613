@use '../../../Style.scss' as *;

.profileSummary {
    position: relative;

    display: flex;
    align-items: center;
    justify-self: center;

    width: 100%;
    height: max-content;

    > .changes_profile_images {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 20px;
        box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
            0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
        background: var(--background-white);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        input,
        .MuiInput-underline {
            display: none;
        }

        label {
            line-height: 0px;
            cursor: pointer;
        }

        .MuiSvgIcon-root {
            font-size: 18px;
            color: var(--backgroundcolor);
        }
    }

    .profile_right {
        margin-inline: auto;

        .profile_top {
            @include displayFlex(column, center, center);
            width: 212px;
            height: max-content;
            padding-block: 25px;
            padding-inline: 20px;
            border-radius: 12px;

            :is(.name, .email, .organization) {
                display: inline-block;

                text-align: center;

                width: 100%;
                white-space: wrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .name {
                font-size: 16px;
                font-weight: 500;

                padding-block-start: 12px;
            }

            .email {
                font-size: 12px;
                color: #787579;
                padding-block-start: 5px;
            }

            .organization {
                font-size: 14px;
                color: var(--activation_text);

                padding-block: 8px 2px;
            }

            .chip_user {
                border-radius: 100px;

                p {
                    font-size: 12px;
                    line-height: 12px;
                    color: #ffffff;

                    padding-block: 6px 5px;
                    padding-inline: 12px;
                }

                &.basic {
                    background: var(--user-sucess);
                }

                &.pro {
                    background: var(--user_pro);
                }

                &.trial {
                    background: var(--trialbtn);
                }

                &.premium {
                    background: var(--settings_premium_ui);
                    padding-inline: 10px;

                    .image {
                        width: 12px;
                        height: 12px;
                        margin-block-end: 2px;
                    }

                    p {
                        letter-spacing: 1px;
                        color: var(--premium_black);
                    }
                }
            }

            .MuiAvatar-root {
                height: 80px;
                width: 80px;
                outline: 1px solid #cfd5ff7a;
                font-size: 24px;
                cursor: pointer;
            }

            .upload_profile_images {
                height: 80px;
                width: 80px;
                background: var(--light-bg);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 50%;
                position: relative;

                input {
                    display: none;
                }

                label {
                    line-height: 0px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 4px;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                }

                span {
                    color: var(--parple);
                    font-size: 14px;
                    line-height: 24px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                }

                .MuiSvgIcon-root {
                    font-size: 30px;
                    color: var(--backgroundcolor);
                }
            }

            .changes_profile_images {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 2;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                font-size: 20px;
                box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
                    0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
                background: var(--background-white);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                input,
                .MuiInput-underline {
                    display: none;
                }

                label {
                    line-height: 0px;
                    cursor: pointer;
                }

                .MuiSvgIcon-root {
                    font-size: 18px;
                    color: var(--backgroundcolor);
                }
            }
        }

        .certified_tainner {
            padding: 8px 15px;
            border: 1px solid var(--borderlight);
            border-radius: 12px;
            @include displayFlex(row, center, center);
            gap: 6px;
            width: max-content;
            margin-inline: auto;
            cursor: pointer;
            margin-block: -15px 25px;

            img {
                height: 20px;
            }

            h4 {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 12px;
                font-weight: 400;
            }
        }

        .profileSummaryBadgeContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            margin-block-end: 25px;
        }

        .certified_tainner_badge {
            padding: 0;
            border-radius: 12px;
            @include displayFlex(row, center, center);
            gap: 12px;
            cursor: pointer;
            margin-block-start: -15px;

            img {
                height: 20px;
            }
        }

        .chip_user {
            padding: 2px 6px;
            min-height: 22px;
            min-width: 58px;
            border-radius: 100px;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            margin-block-start: -16px;

            &.basic {
                background: var(--user-sucess);
            }

            &.pro {
                background: var(--user_pro);
            }

            &.trial {
                background: var(--trialbtn);
            }

            &.premium {
                background: var(--settings_premium_ui);
                padding-inline: 10px;

                .image {
                    width: 12px;
                    height: 12px;
                    margin-block-end: 2px;
                }

                p {
                    letter-spacing: 0.5px;
                    color: var(--premium_black);
                }
            }

            p {
                font-family: var(--font-stack);
                font-weight: 500;
                font-size: 12px;
                text-transform: uppercase;
                color: var(--textWhite);
                line-height: 13px;
            }
        }
    }
}
