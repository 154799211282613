@use '../../../Style.scss' as *;

/* width */

.word_cloud_main {
    // height: calc(100vh - 157px);
    margin: 0;
    padding: 0;
    // background-color: #f4f4ff;
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(218, 222, 255, 1) 100%);
    overflow: auto hidden;
    // min-height: 500px;
    height: var(--activitycontentHeight);

    .word_cloud {
        width: 100%;
        // height: calc(100vh - 157px);
        height: 426px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-block-start: 6px;

        svg {
            text {
                font-family: var(--popin);
                outline: none;
                transition: var(--transition);

                &:is(:focus) {
                    outline: none;
                }

                &[fill='rgb(230, 225, 229)'] {
                    filter: grayscale(100%);
                }
            }
        }
    }

    .tag-cloud {
        width: 600px;
        height: 600px;
    }

    .cloud_input_field {
        display: flex;
        align-items: center;
        width: 281px;
        height: 40px;
        border-radius: 5px;
        overflow: hidden;
        background: var(--textWhite);
        padding: 5px;
        margin-block-end: 60px;
        border: 1px solid #d1deff;
    }

    .cloud_input_field input[type='text'] {
        flex: 1;
        border: none;
        outline: none;
        padding: 0 10px;
    }

    .cloud_input_field button {
        background: var(--parple);
        color: var(--textWhite);
        border: none;
        outline: none;
        padding: 8px 24px;
        border-radius: 5px;
    }

    .word_cloud_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 10px;
        background-color: var(--textWhite);
    }

    .nav-right {
        color: var(--parple);
    }

    .nav-center {
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // ul.cloud {
    //     list-style: none;
    //     padding-inline-start: 0;
    //     display: flex;
    //     flex-wrap: wrap;
    //     // align-items: center;
    //     justify-content: center;
    //     line-height: 2.75rem;
    //     max-width: 450px;
    // }
    // ul.cloud li {
    //     display: inline-block;
    //     padding: 3px;
    // }

    // ul.cloud a {
    //     --size: 4;
    //     font-family: var(--popin);
    //     color: var(--color);
    //     display: block;
    //     padding: 0.125rem 0.25rem;
    //     position: relative;
    //     text-decoration: none;
    //     line-height: 1;
    // }
    // ul.cloud a.slide_left {
    //     transform: translateX(-200%);
    //     opacity: 0;
    //     animation: slide-in-anim 1s ease-out forwards;
    // }
    // ul.cloud a.slide_right {
    //     transform: translateX(-200%);
    //     opacity: 0;
    //     animation: slide-right 1s ease-out forwards;
    // }

    // ul.cloud a.slide_up {
    //     transform: translateY(-200%);
    //     opacity: 0;
    //     animation: slide-up 1s ease-out forwards;
    // }
    // ul.cloud a.slide_down {
    //     transform: translateY(200%);
    //     opacity: 0;
    //     animation: slide-down 1s ease-out forwards;
    // }

    // @keyframes slide-in-anim {
    //     20% {
    //         opacity: 0;
    //         transform: translateX(-200%);
    //     }
    //     30% {
    //         transform: translateX(-190%);
    //     }
    //     40% {
    //         transform: translateX(-180%);
    //     }
    //     50% {
    //         transform: translateX(-150%);
    //     }
    //     60% {
    //         transform: translateX(-120%);
    //     }
    //     70% {
    //         transform: translateX(-90%);
    //     }
    //     80% {
    //         transform: translateX(-60%);
    //     }
    //     90% {
    //         transform: translateX(-30%);
    //     }
    //     100% {
    //         opacity: 1;
    //         transform: translateX(0%);
    //     }
    // }

    // @keyframes slide-up {
    //     20% {
    //         opacity: 0;
    //         transform: translateY(-200%);
    //     }
    //     30% {
    //         transform: translateY(-190%);
    //     }
    //     40% {
    //         transform: translateY(-180%);
    //     }
    //     50% {
    //         transform: translateY(-150%);
    //     }
    //     60% {
    //         transform: translateY(-120%);
    //     }
    //     70% {
    //         transform: translateY(-90%);
    //     }
    //     80% {
    //         transform: translateY(-60%);
    //     }
    //     90% {
    //         transform: translateY(-30%);
    //     }
    //     100% {
    //         opacity: 1;
    //         transform: translateY(0%);
    //     }
    // }

    // @keyframes slide-down {
    //     20% {
    //         opacity: 0;
    //         transform: translateY(200%);
    //     }
    //     30% {
    //         transform: translateY(190%);
    //     }
    //     40% {
    //         transform: translateY(180%);
    //     }
    //     50% {
    //         transform: translateY(150%);
    //     }
    //     60% {
    //         transform: translateY(120%);
    //     }
    //     70% {
    //         transform: translateY(90%);
    //     }
    //     80% {
    //         transform: translateY(60%);
    //     }
    //     90% {
    //         transform: translateY(30%);
    //     }
    //     100% {
    //         opacity: 1;
    //         transform: translateY(0%);
    //     }
    // }
    // @keyframes slide-right {
    //     20% {
    //         opacity: 0;
    //         transform: translateX(200%);
    //     }
    //     30% {
    //         transform: translateX(190%);
    //     }
    //     40% {
    //         transform: translateX(180%);
    //     }
    //     50% {
    //         transform: translateX(150%);
    //     }
    //     60% {
    //         transform: translateX(120%);
    //     }
    //     70% {
    //         transform: translateX(90%);
    //     }
    //     80% {
    //         transform: translateX(60%);
    //     }
    //     90% {
    //         transform: translateX(30%);
    //     }
    //     100% {
    //         opacity: 1;
    //         transform: translateX(0%);
    //     }
    // }

    // ul.cloud a[data-weight='1'] {
    //     --size: 1;
    // }
    // ul.cloud a[data-weight='2'] {
    //     --size: 2;
    // }
    // ul.cloud a[data-weight='3'] {
    //     --size: 3;
    // }
    // ul.cloud a[data-weight='4'] {
    //     --size: 4;
    // }
    // ul.cloud a[data-weight='5'] {
    //     --size: 6;
    // }
    // ul.cloud a[data-weight='6'] {
    //     --size: 8;
    // }
    // ul.cloud a[data-weight='7'] {
    //     --size: 10;
    // }
    // ul.cloud a[data-weight='8'] {
    //     --size: 13;
    // }
    // ul.cloud a[data-weight='9'] {
    //     --size: 16;
    // }

    // ul[data-show-value] a::after {
    //     content: ' (' attr(data-weight) ')';
    //     font-size: 1rem;
    // }

    // ul.cloud a:focus {
    //     outline: 1px dashed;
    // }

    // ul.cloud a::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     width: 0;
    //     height: 100%;
    //     transform: translate(-50%, 0);
    //     opacity: 0.15;
    //     transition: width 0.25s;
    // }

    // ul.cloud a:focus::before,
    // ul.cloud a:hover::before {
    //     width: 100%;
    // }

    .search_area {
        position: relative;
        min-height: 52px;
    }

    .searchfield {
        width: 100%;
        width: 240px;
        margin-inline: auto;
        padding-block-start: 16px;

        .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
            background: none !important;

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: var(--parple);

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }

        .MuiOutlinedInput-root {
            &.Mui-focused {
                border-color: var(--parple);
            }

            border-radius: 30px;
            height: 36px;

            .MuiInputAdornment-root {
                svg {
                    color: var(--activation_text);
                }
            }

            .MuiOutlinedInput-input {
                box-shadow: none;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: var(--borderMain);
        }

        input {
            padding: 6px 14px;
            font-size: 14px;
            line-height: 14px;
            font-family: var(--font-stack);
            color: var(--activation_text);

            &::placeholder {
                color: var(--placeholder-color);
                opacity: 1;
            }
        }

        label {
            -webkit-transform: translate(14px, 11px) scale(1);
            -moz-transform: translate(14px, 11px) scale(1);
            -ms-transform: translate(14px, 11px) scale(1);
            transform: translate(14px, 11px) scale(1);
        }

        label.Mui-focused {
            -webkit-transform: translate(14px, -6px) scale(0.75);
            -moz-transform: translate(14px, -6px) scale(0.75);
            -ms-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
        }

        .MuiInputLabel-shrink {
            -webkit-transform: translate(14px, -6px) scale(0.75);
            -moz-transform: translate(14px, -6px) scale(0.75);
            -ms-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
        }
    }

    .searchfield + .show_response {
        text-align: end;
        right: 24px;
        left: inherit;
    }

    .show_response {
        position: absolute;
        top: 25px;
        text-align: center;
        left: 0;
        right: 0;
        margin-inline: auto;

        p {
            font-size: 14px;
            line-height: 20px;
            font-family: var(--font-stack);
            color: var(--greytext_color);
            font-weight: 400;
        }
    }
}

// @media (prefers-reduced-motion) {
//     ul.cloud * {
//         transition: none !important;
//     }
// }
.MuiDialog-container {
    .MuiPaper-elevation {
        border-radius: 12px;
    }
}

.MuiBox-root.word_cloud_modal {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    width: 400px !important;
    border-radius: 16px;
    overflow: hidden;
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    background: var(--background-white);

    &.new_word_cloud {
        width: fit-content !important;
        min-width: 364px;
        max-width: 664px;

        .word_cloud_modal_body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px 8px;

            .WC_body_inner {
                max-height: 257px;
                width: fit-content;
                gap: 8px 0;
                padding-inline: 22px 18px;

                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                    min-height: 6px;
                    max-height: 6px;
                }

                &::-webkit-scrollbar-track {
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    height: 6px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    height: 6px;
                }

                .MuiChip-root {
                    border: none;
                    background: none;
                    border-radius: 0;
                    flex-direction: column;
                    width: 100px;
                    min-height: 114px;
                    height: auto;
                    gap: 12px;
                    padding-inline: 5px;
                    padding-block: 12px 0;
                    justify-content: flex-start;

                    .MuiAvatar-circular {
                        width: 52px;
                        height: 52px;
                        margin: 0;
                        font-size: 26px;
                    }

                    .MuiChip-label {
                        padding: 0;
                        color: var(--activation_text);
                        font-weight: 500;
                        overflow: inherit;
                        white-space: initial;
                        text-overflow: inherit;
                        text-align: center;
                        word-break: break-word;
                    }
                }
            }
        }

        .deleteButtonContainer {
            position: absolute;
            bottom: 16px;
            right: 18px;
            min-width: 40px;
            min-height: 40px;
            // border-radius: 50%;
            overflow: hidden;

            .deleteButton {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 40px;
                min-height: 40px;
                border-radius: 0;
                isolation: isolate;
                padding: 0;

                .delete_btn_bg {
                    position: absolute;
                    inset: 0 0 0 0;
                    background: rgba(217, 99, 103, 0.1);
                    border-radius: 50%;
                    z-index: -1;
                }

                &:hover {
                    background: none;

                    .delete_btn_bg {
                        background: rgba(217, 99, 103, 0.2);
                    }
                }

                .MuiTouchRipple-root {
                    border-radius: 0;
                    display: none;

                    .MuiTouchRipple-child {
                        background-color: rgba(217, 99, 103, 0.8);
                    }
                }

                .delete {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 50%;
                    padding: 0;
                    min-width: auto;

                    svg {
                        color: var(--danger);
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .word_cloudhead {
        background: #edf0ff;
        color: var(--parple);
        text-align: center;
        min-height: 50px;
        padding: 4px 10px;
        display: grid;
        place-items: center;

        h2 {
            font-size: 16px;
            font-weight: 500;
            font-family: var(--font-stack);
        }
    }

    .word_cloud_modal_body {
        width: 100%;
        padding: 20px 12px;
        padding-block-end: 0;
        min-height: 130px;
        padding-block-start: 0;

        .WC_body_inner {
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: 1vh;
            flex-wrap: wrap;
            max-height: 130px;
            overflow: auto;
            padding: 10px 0;

            .MuiChip-root {
                border: 1px solid #cfd5ff;
                box-sizing: border-box;
                border-radius: 16px;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;

                .MuiChip-label {
                    font-family: var(--font-stack);
                    color: var(--text_color);
                    font-size: 14px;
                    font-weight: 400;
                    padding-inline-start: 8px;
                }

                .MuiAvatar-circular {
                    width: 24px;
                    height: 24px;
                    font-size: 0.75rem;
                    margin-inline-start: 4px;
                }
            }

            // span {
            //     padding: 6px 12px;
            //     border: 1px solid #cfd5ff;
            //     text-align: center;
            //     color: var(--text_color);
            //     border-radius: 50px;
            //     background: linear-gradient(
            //             0deg,
            //             rgba(99, 120, 255, 0.05),
            //             rgba(99, 120, 255, 0.05)
            //         ),
            //         #ffffff;
            // }
        }
    }

    .modal_footer {
        width: 100%;
        padding-block: 16px;
        padding-inline: 12px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .starbtn.big {
            padding: 4px 20px;
            border-radius: 40px;
        }
    }
}
