@import '../../../Style.scss';

.quickPoll_sec {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100%;
    height: var(--activitycontentHeight);
}

.quickPollContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 40px;
    width: 100%;
    height: var(--activitycontentHeight);
    // max-width: 500px;
    // height: 580px;
    background: radial-gradient(186.11% 104.69% at 50% 50%, #ffffff 0%, #dadeff 100%);
    // background: var(--background-white);
    position: relative;
    & .heading {
        display: none;
    }

    & .option {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 31.4px;
        padding-block-end: 40px;
        border-block-end: 2px solid var(--borderMain);

        & button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;
            background: transparent;
            outline: none;
            border: none;
            cursor: pointer;

            &:is(:hover, :focus) .img_icon {
                background-color: var(--qpSelectedBg);
            }
            &.active .img_icon {
                background-color: var(--qpSelectedBg);
                outline: 4px solid var(--cpbutton);
                outline-offset: -2px;
            }
            & .img_icon {
                // padding: 18px 30px;
                width: 208.5px;
                height: 120px;
                outline: 2px solid var(--cpbutton);
                transition: 0.1s all linear;
                border-radius: 10px;
                display: grid;
                place-items: center;
                img {
                    // width: 60px;
                    // max-width: 100%;
                    width: 125px;
                }
            }

            & .text {
                font-size: 18px;
                font-weight: 400;
                line-height: 18px;
                margin: 0;
                margin-block-start: 16px;
                color: var(--textblack);
                font-family: var(--font-stack);
            }
        }
    }

    & .subHeading {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        font-family: var(--font-stack);
        color: var(--textblack);
        margin: 0;
        margin-block-end: 18px;
        margin-block-start: 28px;
    }

    & .optionBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        & button {
            @include quickpool;
            border: none;
            outline: 2px solid var(--cpbutton);
            &:is(:hover, :focus) {
                background-color: var(--qpSelectedBg);
            }
            &.active {
                background-color: var(--qpSelectedBg);
                outline: 4px solid var(--cpbutton);
                outline-offset: -2px;
                // color: var(--textWhite);
            }
        }
    }
    .error {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 24px;
        p {
            color: var(--danger);
            font-size: 14px;
            line-height: 16px;
            font-family: var(--font-stack);
            font-weight: 400;
        }
    }
}
