@use '../../../Style.scss' as *;

.footer_sec {
    text-align: center;
    padding: 10px 15px;
    background-color: var(--background-white);
    border-block-start: 0px solid #e0e0e0;
    box-shadow: inset 0 0 44px rgb(0 0 0 / 2%);
    display: flex;
    width: 100%;
    margin-inline: auto;
    margin-block: 0;
    min-height: 88px;

    .default_btn {
        @include activationbtn;
        width: auto;
        color: var(--parple);
        padding: 6px 28px !important;
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;

        &.reset_btn {
            min-width: auto;
        }

        &.Mui-disabled {
            color: var(--parple);
            opacity: 0.7;
            cursor: default;
        }
    }

    button {
        &.MuiLoadingButton-root {
            &.Mui-disabled {
                opacity: 1;
                cursor: default;
            }
        }

        .MuiLoadingButton-startIconLoadingStart {
            display: none;
        }

        .MuiLoadingButton-loadingIndicator {
            position: relative;
            left: 0;
            display: flex;
            padding-inline: 0 5px;
        }
    }

    .delete_btn {
        @include activationbtn;
        width: auto;
        color: var(--submission_btn);
        padding: 6px 28px !important;
        background: rgba(217, 99, 103, 0.1);

        &:hover {
            background: rgba(217, 99, 103, 0.2);
        }

        .MuiTouchRipple-ripple {
            .MuiTouchRipple-child {
                background-color: rgba(217, 99, 103, 0.8);
            }
        }

        &.Mui-disabled {
            color: var(--submission_btn);
            opacity: 0.5;
            cursor: default;
        }
    }

    &.participant_sec_foot {
        height: auto;
        // background: var(--footer_bg_second);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.4rem;
    }

    .back {
        position: absolute;
        left: 26px;
        z-index: 22;
        width: fit-content;

        &.hideBtn {
            display: none;
        }

        a {
            color: var(--parple);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            cursor: pointer;

            svg {
                display: flex;
            }

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    h6 {
        font-size: 12px;
        font-family: var(--font-stack);

        a {
            color: var(--activation_text);
        }
    }

    .footer_left,
    .footer_right {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        min-width: 200px;

        &.myClassFooter {
            min-width: fit-content;
            flex: 0 188px;
        }

        h4 {
            font-size: 18px;
            color: var(--activation_text);
            font-family: var(--font-stack);
            display: flex;
            align-items: center;
            gap: 0.4rem;
            line-height: 22px;
            font-weight: 500;

            // cursor: pointer;
            span {
                display: flex;
                font-size: 18px;
                line-height: 22px;

                &.red {
                    color: var(--red);
                    font-weight: 500;
                }

                &.prime {
                    color: var(--tabcolor);
                    cursor: pointer;
                }

                :is(svg, .myClassFooter) {
                    fill: var(--tabcolor);
                    width: 21px;
                    display: flex;
                }
            }
        }

        .music_box {
            position: relative;
            padding-inline-start: 35px;

            &:hover {
                .volumeslider {
                    transition: var(--transition);
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 6px 2px rgba(0, 0, 0, 0.05);

                    .slide_volume {
                        // display: block;
                        opacity: 1;
                        transform: scale(1);
                        height: 55px;
                        transition: var(--transition);
                        margin-top: 5px;
                    }

                    .shuffle {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            .volumeslider {
                border-radius: 16px;
                transition: var(--transition);
                padding-block: 7px;
                border-radius: 16px;
                position: absolute;
                left: 0;
                bottom: -7px;

                .shuffle {
                    color: var(--parple);
                    min-width: 26px;
                    min-height: 26px;
                    opacity: 0;
                    transform: scale(0);
                    transform-origin: bottom;

                    &:hover {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                    }

                    svg {
                        margin-block-start: 0px;
                    }
                }

                svg {
                    color: var(--parple);
                    margin-block-start: 10px;
                    display: flex;
                }

                .slide_volume {
                    // display: none;
                    opacity: 0;
                    transform: scale(0);
                    height: 0;
                    transform-origin: bottom;
                    transition: var(--transition);
                }

                cursor: pointer;

                .MuiSlider-thumbColorPrimary {
                    width: 12px;
                    height: 12px;
                    color: var(--parple);

                    &::after {
                        width: 12px;
                        height: 12px;
                    }
                }

                .MuiSlider-colorPrimary {
                    color: var(--light-bg);
                    padding: 0 14px;
                }

                .MuiSlider-track {
                    background: var(--parple);
                }

                .MuiSlider-rail {
                    opacity: 1;
                    color: rgba(99, 120, 255, 0.14);
                }
            }
        }

        a {
            cursor: pointer;
        }
    }

    .footer_left {
        justify-content: end;

        .foot_btn_blue {
            @include activationbtn;
            width: auto;
            background-color: var(--btnbgcolor) !important;

            &:hover {
                background-color: var(--btnhover) !important;
            }

            &.Mui-disabled {
                opacity: 0.5;
                cursor: default;
                color: var(--textWhite);
            }
        }
    }

    .footer_right h4 {
        cursor: pointer;
    }

    .submision {
        height: 40px;
        background-color: var(--submission_btn) !important;
        width: auto;
        line-height: 40px !important;
        color: var(--textWhite);
        padding: 0 30px !important;
        box-shadow: none !important;
    }

    .leader_foot_btn {
        height: 40px;
        background-color: var(--footer_defult_btn) !important;
        width: auto;
        line-height: 40px !important;
        color: var(--textWhite);
        padding: 0 50px !important;
        color: var(--textlead_color);
        text-transform: capitalize;
    }
}

:is(.footer_left, .footer_middle, .footer_right) {
    .foot_btn {
        @include activationbtn;
        background-color: var(--footer_btn) !important;
        width: auto;

        &.Mui-disabled {
            color: var(--textWhite);
        }

        &:hover {
            background-color: var(--no_more_red) !important;
        }

        &.Mui-disabled {
            cursor: default;
            color: var(--textWhite);
        }
    }

    .foot_btn_blue {
        @include activationbtn;
        background-color: var(--btnbgcolor) !important;
        width: auto;

        &:hover {
            background-color: var(--btnhover) !important;
        }

        &.Mui-disabled {
            opacity: 0.5;
            cursor: default;
            color: var(--textWhite);
        }

        .MuiButton-startIcon {
            margin: 0;
            margin-inline-end: 8px;
        }
    }

    .foot_btn_gray {
        @include activationbtn;
        background-color: var(--graybtn) !important;
        color: var(--activation_text);
        width: auto;

        &:hover {
            background-color: var(--grayBtnHover) !important;
        }

        &.Mui-disabled {
            color: var(--activation_text);
            opacity: 0.5;
            cursor: default;
        }
    }

    .leaderBoard {
        @include leaderBoardbtn;
        background: var(--background-white);
        gap: 8px;
        box-shadow: var(--shadow_01);

        .MuiButton-startIcon {
            margin: 0;
        }

        &:hover {
            background: var(--leaderBoard_btn);
            box-shadow: var(--shadow_01);
        }

        // &:active {
        //     background-color: var(--leaderboardbg_green_900);
        // }
    }
}

.footer_middle {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;

    &.gap {
        gap: 20px;
    }

    button {
        min-height: 40px;
    }

    a {
        cursor: pointer;
    }

    .leaderBoard {
        position: relative;
        box-shadow: var(--shadow_01);

        &::after {
            content: '';
            position: absolute;
            inset: 0 auto 0;
            margin: auto;
            width: 56px;
            height: 56px;
            border: 5px solid transparent;
            background: linear-gradient(to top, white, white), linear-gradient(to bottom, #9e63ff, #355deb);
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            z-index: -1;
            border-radius: 50%;
        }

        .animationContainer {
            // padding-block-end: 10px;

            .cup_container {
                width: 100%;
                @include displayFlex(row, center, center);

                img {
                    // transition: all 0.5s ease;
                    position: relative;
                    width: 35px;
                }

                .starImage {
                    position: absolute;
                    inset: 17px 0 auto 0;
                    margin: auto;
                    max-width: 10px;
                }
            }

            // .barContainer {
            //     display: flex;
            //     align-items: flex-end;
            //     justify-content: center;
            //     gap: 2px;

            //     .singleBar {
            //         display: flex;
            //         flex-direction: column;
            //         align-items: center;
            //         justify-content: flex-end;
            //         gap: 0;

            //         width: 10px;

            //         .starImageContainer {
            //             display: flex;
            //             align-items: center;
            //             justify-content: center;

            //             .starImage {
            //                 width: 11px;
            //                 height: 11px;
            //             }
            //         }

            //         .barImageBorder {
            //             width: 10px;
            //             border-top-left-radius: 2px;
            //             border-top-right-radius: 2px;
            //             background: linear-gradient(180deg, #ffffff, #d3cce2);
            //             padding: 1px;
            //             overflow: hidden;

            //             .barImage {
            //                 width: 100%;
            //                 height: 100%;
            //                 background: #efe8ff;
            //             }
            //         }
            //     }
            // }
        }
    }
}

// insights_button
.insights_button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    margin-block: auto;
    height: fit-content;

    .default_btn {
        display: flex;
        align-items: center;
        gap: 5px;
        min-width: auto;
        background: none;
        padding-inline: 14px 12px !important;
        color: var(--cpbutton);
        min-height: 40px;
        &:hover {
            background: var(--cpButton_18);
        }
    }

    .insightsImage {
        width: 28px;
        height: 28px;
    }
}

// share_button
.share_button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 16px;
    margin-block: auto;
    height: fit-content;
    .default_btn {
        min-width: auto;
        background: none;
        padding-inline: 14px 12px !important;
        color: var(--bg_orange);
        min-height: 40px;
        .MuiButton-startIcon {
            svg {
                path {
                    stroke: var(--bg_orange);
                    fill: var(--bg_orange);
                }
            }
        }
        &:hover {
            background: var(--share_bg);
        }
    }
}

.footer_middle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .qnaFooter {
        &.disabled {
            .MuiSwitch-track {
                background: var(--submission_btn);
            }
        }
    }

    // :is(.qnaCloseBtn, .qnaMarkAllAnsweredBtn) {
    //     text-transform: none;

    //     padding-inline: 24px;
    //     border-radius: 1000px;
    // }

    // .qnaCloseBtn {
    //     color: var(--textWhite);
    //     background: var(--backgroundcolor);

    //     &:hover {
    //         background: var(--btnhover);
    //     }

    //     &.enabled {
    //         color: var(--redspan-color);
    //         background: rgba(217, 99, 103, 0.12);

    //         &:hover {
    //             color: var(--textWhite);
    //             background: var(--submission_btn);
    //         }
    //     }
    // }

    // .qnaMarkAllAnsweredBtn {
    //     color: #216831;
    //     background: var(--editview_chip_bg);

    //     &:hover {
    //         color: var(--textWhite);
    //         background: var(--user-sucess);
    //     }
    // }
}
