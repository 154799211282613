.savedClassParticipantTable {
    &.viewGroup {
        padding-inline: 0;

        .box_shadow_none {
            .MuiTableContainer-root {
                min-height: 0;
            }
        }
    }

    .box_shadow_none {
        box-shadow: none;
        margin-block-end: 39px;

        &.savedClassParticipantTableContent {
            height: 100%;
            margin-block-end: 0;
        }

        .MuiTableContainer-root {
            padding-inline-end: 6px;
            // overflow-x: scroll;
            // min-height: 417px;
            height: 100%;
        }

        table {
            .starCountCell {
                font-size: 16px;
                line-height: 16px;
                font-family: var(--font-stack);
                font-weight: 400;
                color: var(--activation_text);
                display: flex;
                align-items: center;

                &.danges {
                    color: var(--danger);
                }

                &.justify_end {
                    justify-content: end;
                }

                &.plus {
                    color: var(--icon_green);
                }

                &.minus {
                    color: var(--danger);

                    .minus {
                        color: var(--danger);
                    }
                }

                svg {
                    font-size: 16px;

                    &.plus {
                        color: var(--icon_green);
                    }

                    &.minus {
                        color: var(--minus);
                    }
                }
            }

            .number {
                font-size: 14px;
                line-height: 14px;
                font-family: var(--font-stack);
                font-weight: 400;
                color: var(--activation_text);
                padding-inline-start: 5px;
            }

            .MuiTableCell-body {
                border: none;

                &.classLevel {
                    padding-block: 0;
                }
            }

            thead {
                position: relative;

                th {
                    border-block-end: none;
                    z-index: 1;

                    &[aria-sort='descending'] {
                        span {
                            svg {
                                display: block;
                            }
                        }
                    }

                    &[aria-sort='ascending'] {
                        span {
                            svg {
                                display: block;
                                visibility: visible;
                            }
                        }
                    }

                    &:hover {
                        span {
                            color: var(--secondary_text);

                            svg {
                                display: block;
                                visibility: visible;
                            }
                        }
                    }

                    span {
                        color: var(--secondary_text);
                        word-break: keep-all;
                    }
                }
            }

            tbody {
                tr:nth-of-type(odd) {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
                }

                tr:hover {
                    background: var(--lightbackground);

                    .option {
                        svg {
                            visibility: visible;
                            margin-inline-start: auto;
                            position: relative;
                            top: 3px;
                        }
                    }
                }

                .option {
                    svg {
                        color: var(--secondary_text);
                        font-size: 18px;
                        visibility: hidden;
                        cursor: pointer;
                    }
                }
            }

            .table_profile_sec {
                display: flex;
                align-items: center;

                .profile_img {
                    padding-block: 0px;
                    padding-inline: 0;
                    padding-inline-end: 10px;

                    .MuiAvatar-root {
                        width: 28px;
                        height: 28px;
                        font-size: 14px;
                        line-height: 21px;
                    }
                }

                .profile_name {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    width: 100%;

                    h4 {
                        display: inline-block;

                        font-family: var(--font-stack);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        color: var(--activation_text);

                        width: max-content;
                        max-width: 225px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-inline: 0;
                        padding-block: 4px;
                        cursor: default;
                    }

                    .MuiFormControl-root {
                        width: 100%;

                        .MuiInput-underline {
                            input {
                                color: var(--activation_text);
                                font-family: var(--font-stack);
                                font-size: 16px;
                                font-weight: 400;

                                &::placeholder {
                                    color: var(--user_emailtext);
                                    opacity: 1;
                                }
                            }

                            &::after {
                                display: none;
                            }

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }

            .savedClasses {
                &.recentlyEarnedStars {
                    display: flex;
                    align-items: center;

                    font-size: 14px;
                    padding-inline-start: 3px;

                    p {
                        display: flex;
                        align-items: center;

                        :is(.minus, .plus) {
                            margin-block-start: -2px;
                        }
                    }
                }
            }
        }

        .savedClassLevel {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 6px;

            width: 100%;

            img {
                height: 32px;
            }

            p {
                font-size: 15px;
                line-height: 16px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-weight: 400;
            }
        }

        .savedClasses {
            &.participantTab {
                padding-block: 0;
                padding-inline: 30px 20px;
            }
        }
    }
}

.savedClasses.tabContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .MuiTabs-flexContainer {
        .tabButton {
            color: var(--activation_text);
            text-transform: none;

            min-width: 150px;
            border-bottom: 3px solid #e1e4ff;

            .participantPanel .count {
                color: #2a326b;

                margin-inline-start: 2px;
                padding: 4px 8px;
                border-radius: 100px;
                background: #cfd5ff;
            }
        }
    }

    .MuiTabs-indicator {
        height: 3px;
        background-color: var(--tabcolor);
    }
}

.savedClassOptionsBackgroundOverlay {
    position: absolute;
    z-index: 15;

    width: 100%;
    height: 100%;
}

.optionContainer {
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    .optionsIcon {
        color: var(--activation_text);
    }

    .savedClassOptions {
        display: none;
        max-height: 160px;
        overflow: auto;

        &.show {
            position: absolute;
            inset: 60px 43px auto auto;
            z-index: 100;

            display: inline-block;

            width: 200px;
            height: fit-content;
            border-radius: 4px;
            background: var(--background-white);
            box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);

            .button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;

                color: var(--btnbgcolor);

                width: 100%;
                height: 40px;
                padding: 8px 12px;
                background: var(--background-white);
                border-block-start: 1px solid rgba(99, 120, 255, 0.11);

                &:hover {
                    background: rgba(99, 120, 255, 0.1);
                }

                &:nth-of-type(1) {
                    border-block-start: 0;
                }

                &.delete {
                    :is(.icon, .text) {
                        color: var(--danger);
                    }
                }

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .text {
                    font-size: 14px;
                    text-transform: none;
                    color: var(--activation_text);
                }
            }
        }
    }
}

.participent_table {
    .inner_table {
        width: 100%;
        height: 100%;

        .savedClasses {
            &.participantTab {
                height: 100%;
                padding: 0;
            }

            &.groupTab {
                height: 100%;
                padding-block: 0;
            }
        }
    }
}

.savedClassParticipantTable {
    height: calc(100% - 10px);
    padding-inline: 30px 20px;

    &.basicUserTable {
        height: calc(100% - 80px);
    }

    :is(.savedClassParticipantTableHead, .savedClassParticipantTableBody) {
        .savedClassParticipantTableRow {
            :is(th, td) {
                padding-block: 7px;
                padding-inline: 7px;

                &:nth-of-type(1) {
                    width: auto;
                    // width: 8%;
                }

                &:nth-of-type(2) {
                    width: auto;
                    // width: 45%;
                }

                &:nth-of-type(3) {
                    text-align: start;

                    width: auto;
                    // width: 25%;
                }

                &:nth-of-type(4) {
                    text-align: center;

                    width: auto;
                    // width: 14%;
                }

                &:nth-of-type(5) {
                    text-align: center;

                    width: auto;
                    // width: 8%;
                }
            }
        }
    }

    &.premiumUser {
        &:is(.viewGroup, .hideGroup) {
            :is(.savedClassParticipantTableHead, .savedClassParticipantTableBody) {
                .savedClassParticipantTableRow {
                    :is(th, td) {
                        &:nth-of-type(1) {
                            width: auto;
                            // width: 10%;
                        }

                        &:nth-of-type(2) {
                            width: auto;
                            // width: 50%;

                            .profile_name {
                                max-width: 300px;

                                h4 {
                                    width: 100%;
                                }
                            }
                        }

                        &:nth-of-type(3) {
                            text-align: start;

                            width: auto;
                            // width: 25%;
                        }

                        &:nth-of-type(4) {
                            text-align: center;

                            width: auto;
                            // width: 15%;
                        }
                    }
                }
            }
        }

        &:not(.viewGroup, .hideGroup) {
            :is(.savedClassParticipantTableHead, .savedClassParticipantTableBody) {
                .savedClassParticipantTableRow {
                    :is(th, td) {
                        &:nth-of-type(1) {
                            width: auto;
                            // width: 6%;
                        }

                        &:nth-of-type(2) {
                            width: auto;
                            // width: 35%;
                        }

                        &:nth-of-type(3) {
                            text-align: center;

                            width: auto;
                            // width: 20%;
                        }

                        &:nth-of-type(4) {
                            text-align: start;

                            width: auto;
                            // width: 21%;
                        }

                        &:nth-of-type(5) {
                            text-align: center;

                            width: auto;
                            // width: 12%;
                        }

                        &:nth-of-type(6) {
                            text-align: center;

                            width: auto;
                            // width: 6%;
                        }
                    }
                }
            }
        }
    }

    .savedClassParticipantTableHead {
        .savedClassParticipantTableRow {
            .MuiTableCell-head {
                background: var(--background-white) !important;
            }

            th {
                .MuiButtonBase-root {
                    position: relative;

                    .MuiSvgIcon-root {
                        position: absolute;
                        inset: 0 -20px 0 auto;

                        margin-block: auto;
                        margin-inline: 0;
                    }
                }

                &.stars {
                    padding-inline-start: 30px;
                }
            }
        }
    }

    .savedClassParticipantTableBody {
        td {
            &:nth-of-type(1) {
                .MuiTypography-root {
                    padding-inline: 5px 0;
                }
            }

            .centerAlign {
                width: max-content;
                margin-inline: auto;
            }
        }

        .groupNameContainer {
            display: flex;
            align-items: center;
            justify-content: center;

            .groupName {
                display: inline-block;

                font-size: 12px;
                color: var(--textWhite);
                white-space: nowrap;
                text-overflow: ellipsis;

                width: max-content;
                max-width: 100px;
                margin-inline: auto;
                padding-block: 4px;
                padding-inline: 10px;
                border-radius: 100px;
                overflow: hidden;
            }
        }
    }
}
