@use '../../../Style.scss' as *;

.top_header {
    width: 100%;
    position: relative;
    z-index: 1111;
}

.top_header .MuiAppBar-colorPrimary {
    background-color: var(--background-white);
}

.top_header .MuiPaper-elevation4 {
    min-height: 68.38px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 4%), 0px 4px 5px 0px rgb(0 0 0 / 2%), 0px 1px 10px 0px rgb(0 0 0 / 2%);
}

.header_logo img {
    width: 30px;
}

.box_align {
    align-items: center;
    justify-content: space-between;

    .qr_pop {
        width: auto;
        width: 100%;
        max-width: 26px;
        display: flex;
        cursor: pointer;
        margin-inline-start: 10px;
        padding: 3px;

        &:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
        }

        svg {
            display: flex;
        }

        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }

    &.gap_10 {
        gap: 10px;
    }

    &.myclass_head {
        > :is(.header_logo, .live_status, .live_status_Disabled) {
            flex: auto;
        }

        &.centerAlign {
            .header_logo {
                justify-content: center;
            }
        }
    }

    & > :is(.header_logo, .live_status, .live_status_Disabled) {
        flex: 1 1 27%;
    }

    & > .class_code {
        flex: 1 1 68%;
    }
}

.top_header .MuiToolbar-gutters {
    padding-inline: 1.5rem;
    min-height: 68.36px;
}

.header_logo {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-inline-end: 0;

    // border-inline-end: 1px solid #b3afb4;
    span {
        font-size: 20px;
        font-family: var(--font-stack);
        color: var(--activation_text);
        font-weight: 500;
    }

    h3 {
        font-size: 20px;
        font-family: var(--font-stack);
        color: var(--activation_text);
        font-weight: 500;
    }
}

.class_code {
    // cursor: pointer;
    padding: 0.2rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    text-align: center;

    &.basicUser {
        flex: 1 1 67%;

        .switches-container {
            max-width: 525px;
        }
    }

    h1 {
        font-size: 20px;
        font-family: var(--font-stack);
        color: var(--activation_text);
        font-weight: 500;
        text-align: center;
        display: inline-block;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            text-decoration-color: var(--tabcolor);
        }

        b {
            color: var(--tabcolor);
            // cursor: pointer;
            font-weight: 500;
        }
    }

    .MuiSwitch-root {
        width: 40px !important;
        height: 24px !important;
        padding: 0 !important;
    }

    label {
        .MuiTypography-root {
            font-size: 20px;
            font-family: var(--font-stack);
            color: var(--activation_text);
            line-height: 28px;
            font-weight: 500;
            margin-inline-start: 9px;
            text-transform: none;
        }
    }

    .MuiSwitch-thumb {
        width: 22px;
        height: 22px;
    }

    .Mui-checked + .MuiSwitch-track {
        background: var(--parple) !important;
        opacity: 1 !important;
    }

    .MuiSwitch-track {
        border-radius: 24px;
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
        opacity: 1;
    }

    .MuiSwitch-switchBase {
        padding: 1px;

        &.Mui-checked {
            color: var(--background-white);
            -webkit-transform: translateX(16px);
            -moz-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
        }
    }
}

.live_status {
    padding: 0.2rem 0;
    text-align: end;

    &.flex_end {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &.underline {
        h2 {
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    h2 {
        font-size: 20px;
        font-family: var(--font-stack);
        color: var(--tabcolor);
        font-weight: 500;
        display: inline-block;
        cursor: pointer;

        span {
            font-size: 20px;
            font-family: var(--font-stack);
            color: var(--activation_text);
            font-weight: 500;
        }

        b {
            color: var(--tabcolor);

            font-weight: 500;
        }
    }

    h5 {
        font-size: 20px;
        font-family: var(--font-stack);
        color: var(--parple);
        font-weight: 500;
        display: inline-block;
        cursor: pointer;
    }
}

.live_status_Disabled {
    padding: 0.6rem 1.5rem;

    h2 {
        font-size: 20px;
        font-family: var(--font-stack);
        color: var(--tabcolor);
        visibility: hidden;
    }
}

.switchtext {
    color: var(--activation_text);
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-stack);
    cursor: pointer;
}

.switchtextDisabled {
    color: var(--dashboard_url_text);
    opacity: 0.5;
    cursor: default;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    cursor: default;
}

// custom switch
.switches-container {
    width: 100%;
    min-width: 380px;
    max-width: 480px;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
    border-radius: 40px;
    // line-height: 40px;
    margin-inline: auto;
    min-height: 40px;
    cursor: pointer;

    &.qnaModule {
        min-width: 350px;
        max-width: 350px;

        .highlight {
            display: flex;
            align-items: center;

            font-size: 14px;
            color: #2a326b;

            height: 21px;
            background: var(--background-white);
            padding-inline: 8px;
            border-radius: 1000px;
        }

        .switch-wrapper {
            .highlight {
                background: #cfd5ff;
            }
        }
    }

    input {
        visibility: hidden;
        position: absolute;
        top: 0;
        font-weight: 500;
    }

    label {
        width: 50%;
        padding: 0;
        margin: 0;
        text-align: center;
        cursor: pointer;
        color: var(--dashboard_url_text);
        white-space: nowrap;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: center;

        & > span.pro {
            border-radius: 16px;
            background: var(--parple);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 6px;
            font-weight: 500;
            font-size: 9px;
            line-height: 8px;
            font-family: var(--font-stack);
            width: auto;
            margin: 0;
            color: var(--textWhite);
        }
    }

    .switch-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        padding: 2px;
        z-index: 3;
        transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);

        /* transition: transform 1s; */
        .switch {
            border-radius: 36px;
            background: #fafbff;
            height: 100%;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px 1px rgba(0, 0, 0, 0.05);

            div {
                width: 100%;
                text-align: center;
                opacity: 0;
                display: block;
                color: var(--parple);
                transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
                will-change: opacity;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;

                & > span.pro {
                    border-radius: 16px;
                    background: var(--parple);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 4px 6px;
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 8px;
                    font-family: var(--font-stack);
                    width: auto;
                    margin: 0;
                    color: var(--textWhite);
                }
            }
        }
    }
}

/* slide the switch box from right to left */
.switches-container .switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container.switch_box .switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container.switch_box .switch-wrapper .switch div:nth-of-type(1) {
    opacity: 0;
}

.switches-container .switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container.switch_box .switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}

.top_header {
    .aiQuizInsetAsSlideBtn {
        color: var(--parple);
        background: none !important;
        text-transform: none;

        &.animating {
            color: var(--parple);
            cursor: default;
            pointer-events: none;
        }

        &.saved {
            cursor: default;
            pointer-events: none;
        }
    }
}
