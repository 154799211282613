@use '../../Style.scss' as *;

.timer_sec {
    width: 100%;
    height: 100%;
    background: var(--background-white);

    .timer_tab_sec {
        width: 100%;
        height: 100%;

        .MuiTabs-root {
            min-height: 32px;
        }

        .MuiTabs-indicator {
            background-color: var(--parple);
            display: none;
        }

        .MuiTab-root.MuiTab-textColorPrimary {
            color: var(--text-gray);

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 2px;
                width: 0;
            }
        }

        .MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
            color: var(--parple);
            font-weight: 500;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 2px;
                width: 100%;
                background: var(--parple);
            }
        }

        .MuiTabs-scroller {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.075), rgba(99, 120, 255, 0.075)), #ffffff;

            button {
                width: 50%;
                text-transform: capitalize;
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                letter-spacing: 0.5px;
                min-height: 32px;
                padding: 4px;
                flex-direction: row;
                gap: 6px;

                svg {
                    margin: 0;
                    font-size: 18px;
                }
            }
        }

        .MuiTabPanel-root {
            padding: 12px 20px;

            &.timmer {
                padding: 0;
            }

            .time_add_wraper {
                padding-inline: 31px;
                width: 100%;

                &.bigwrapper {
                    .timer_add_inner {
                        min-height: 112px;
                    }
                }
            }

            .timer_add_inner {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 80px;

                .defult_btn {
                    width: 32px;
                    height: 32px;
                    color: var(--parple);
                    padding: 6px 28px !important;
                    min-height: auto;
                    min-width: auto;
                    padding: 0 !important;
                    transition: var(--transition);
                    background: none;

                    svg {
                        font-size: 20px;
                    }

                    &:hover {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                    }

                    &.Mui-disabled {
                        color: var(--parple);
                        opacity: 0.1;
                        cursor: default;
                    }
                }

                h4 {
                    font-family: var(--InterTight);
                    font-size: 52px;
                    line-height: 52px;
                    font-weight: 300;
                    color: var(--activation_text);
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    cursor: pointer;

                    span {
                        font-family: var(--InterTight);
                        font-size: 32px;
                        line-height: 40px;
                        font-weight: 300;
                        color: var(--activation_text);
                        cursor: pointer;
                        margin-inline-end: 10px;

                        &:last-child {
                            margin-inline-end: 0;
                        }
                    }
                }
            }

            .time_display {
                flex: 1;
                text-align: center;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                // gap: 4px;

                .MuiFormControl-root {
                    width: auto;

                    .MuiInput-root {
                        &::after {
                            display: none;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }

                input {
                    font-family: var(--InterTight);
                    font-size: 52px;
                    line-height: 52px;
                    font-weight: 300;
                    color: var(--activation_text);
                    width: 70px;
                    padding: 0;
                    height: 56px;
                    border-bottom: 1px solid var(--parple);
                    text-align: center;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    &[type='number'] {
                        -moz-appearance: textfield;
                    }
                }

                h3 {
                    font-family: var(--InterTight);
                    font-size: 52px;
                    line-height: 52px;
                    font-weight: 300;
                    color: var(--activation_text);
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    justify-content: center;
                    cursor: pointer;
                }

                span {
                    font-family: var(--InterTight);
                    font-size: 32px;
                    line-height: 45px;
                    font-weight: 300;
                    color: var(--activation_text);
                    cursor: pointer;
                    margin-inline-end: 6px;

                    &:last-child {
                        margin-inline-end: 0;
                    }
                }
            }
        }
    }

    .timmer_btn {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        position: relative;

        button {
            @include timmer_btn;
            background-color: var(--btnbgcolor);

            &:hover {
                background-color: var(--btnhover);
            }

            &.Mui-disabled {
                opacity: 0.5;
                cursor: default;
                color: var(--textWhite);
            }

            &.light_btn {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
                color: var(--parple);

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                }
            }

            &.defult_btn {
                width: 28px;
                height: 28px;
                color: var(--parple);
                padding: 6px 28px;
                min-height: auto;
                min-width: auto;
                padding: 0;
                transition: var(--transition);
                background: none;

                & > svg {
                    font-size: 18px;
                }

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                }

                &.float {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .times_up {
            width: auto;
            min-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            h5 {
                font-family: var(--font-stack);
                font-size: 18px;
                line-height: 22px;
                font-weight: 500;
                color: var(--danger);
            }
        }
    }

    .startTimer_sec {
        width: 100%;
        height: 100%;
        padding-block: 12px;
        position: relative;

        &:is(.timerFullScreen, .timerFullScreen) {
            max-width: 618px;
            margin-inline: auto;
        }

        .timmer_btn {
            padding-inline: 20px;

            button.defult_btn.float {
                right: 20px;
            }
        }

        .stopwatch {
            margin-block-end: 15px;
        }

        .number {
            position: relative;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 95px;

            h4 {
                font-family: var(--InterTight);
                font-size: 52px;
                line-height: 52px;
                font-weight: 300;

                span {
                    font-family: var(--InterTight);
                    font-size: 32px;
                    line-height: 36px;
                    font-weight: 300;
                    padding-inline-end: 6px;

                    &:last-child {
                        padding-inline-end: 0;
                    }
                }
            }

            .MuiTypography-h6 {
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 14px;
                font-weight: 400;
                color: var(--parple);
                border-radius: 32px;
                padding: 6px 12px;

                &:hover {
                    background: #e9ecff;
                }
            }

            &.regular :is(h4, span) {
                color: var(--activation_text);
            }

            &.danger :is(h4, span) {
                color: var(--danger);
            }
        }

        .line_progress {
            .MuiLinearProgress-root {
                height: 2px;
                border-radius: 4px;
                background: var(--secondary_clr_2);

                &.regular .MuiLinearProgress-bar {
                    background: var(--primary-color);
                }

                &.danger .MuiLinearProgress-bar {
                    background: var(--danger);
                }

                span {
                    transition: all 1s linear;
                }
            }
        }
    }

    .sound_setting_sec {
        width: 100%;
        height: 100%;
        padding-inline: 18px;
        padding-block: 14px;
        position: relative;

        .inner_sound_sec {
            width: 100%;

            .head {
                display: flex;
                align-items: center;
                gap: 8px;
                width: fit-content;
                cursor: pointer;

                & > svg {
                    color: var(--parple);
                    font-size: 18px;
                }

                p {
                    font-family: var(--font-stack);
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                    color: var(--parple);
                }

                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }

            .sound_list {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 7px;
                overflow: auto;
                max-height: 88px;
                flex-wrap: wrap;
                margin-block-start: 22px;
                width: 332px;

                button {
                    min-height: 40px;
                    max-width: 102px;
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid var(--borderlight);
                    align-items: center;
                    justify-content: space-between;
                    font-size: 13px;
                    line-height: 17px;
                    color: var(--activation_text);
                    font-weight: 400;
                    font-family: var(--font-stack);
                    text-transform: none;
                    text-align: start;

                    & > svg {
                        color: var(--icon_green);
                        display: flex;
                        display: none;
                        font-size: 16px;
                    }

                    &:hover {
                        background: var(--parple_08);
                    }

                    &.active {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;

                        & > svg {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .timeup {
        width: 140px;
        height: 100px;
        margin-inline: auto;
        margin-block: 0;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;

            & > svg {
                width: 75% !important;
                margin: auto;
            }
        }
    }
}

// middle
.middle_btn {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-block-start: 10px;

    .defult_btn {
        width: 32px;
        height: 32px;
        color: var(--parple);
        padding: 6px 28px !important;
        min-height: auto;
        min-width: auto;
        padding: 0 !important;
        transition: var(--transition);
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
        margin-inline: auto;

        svg {
            font-size: 20px;
        }

        &:hover {
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.24), rgba(99, 120, 255, 0.24)), #ffffff;
        }

        &.Mui-disabled {
            color: var(--parple);
            opacity: 0.1;
            cursor: default;
        }
    }
}

.viewport.fullscreen {
    transform-origin: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 0 0 0;
    margin: auto;

    .timer_sec {
        max-width: 620px;
        margin: auto;
    }

    .timer_tab_sec {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .MuiTabPanel-root {
            width: 100%;
        }
    }

    .timmer_btn {
        padding-block-start: 15px;
    }

    .timer_add_inner {
        .time_display {
            h4 {
                font-size: 82px;
                line-height: 82px;

                span {
                    font-size: 42px;
                    line-height: 54px;
                    font-weight: 300;
                }
            }
        }
    }

    .number {
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 92px;

        h4 {
            font-size: 82px;
            line-height: 82px;

            span {
                font-size: 42px;
                line-height: 46px;
                font-weight: 300;
            }
        }
    }

    h4 {
        font-size: 82px;
        line-height: 82px;

        span {
            font-size: 42px;
            line-height: 46px;
            font-weight: 300;
        }
    }

    .time_display {
        input {
            font-size: 82px;
            line-height: 82px;
            font-weight: 300;
            width: 80px;
            height: 82px;
        }

        h3 {
            font-size: 82px;
            line-height: 82px;
        }

        span {
            font-size: 42px;
            line-height: 46px;

            &:last-child {
                margin-inline-end: 0;
            }
        }
    }

    .line_progress {
        margin-block-start: 15px;

        .MuiLinearProgress-root {
            height: 6px;
            border-radius: 6px;
        }
    }

    .timer_sec {
        .timer_tab_sec {
            .MuiTabPanel-root {
                .time_add_wraper.bigwrapper {
                    .timer_add_inner {
                        min-height: 90px;
                    }
                }
            }
        }

        .bigwrapper + .timmer_btn {
            button.defult_btn {
                &.float {
                    right: 130px;
                }
            }
        }

        .timmer_btn button.defult_btn {
            width: 32px;
            height: 32px;

            svg {
                font-size: 20px;
            }

            &.float {
                right: 6px;
            }
        }
    }

    .timeup {
        height: auto;

        & > div {
            & > svg {
                width: 100% !important;
                margin: auto;
            }
        }
    }
}
