@use '../../Style.scss' as *;

.whiteboard_sec {
    width: 100%;
    height: 100%;
    background: var(--gradientbg);
    .whiteboardcard_body {
        width: 100%;
        height: var(--activitycontentHeight);

        .whiteboardContainer {
            width: 100%;
            margin-inline: auto;
            margin-block: 0;
            padding: 0 8px;
            height: 100%;
        }
        .noCustomWhiteBoardMsg {
            width: 60%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-inline: auto;
            p {
                color: var(--activation_text);
                font-size: 18px;
                line-height: 26px;
                font-family: var(--font-stack);
                font-weight: 400;
            }
        }
        .whiteboard_inner {
            width: 100%;
            margin-inline: auto;
            margin-block: 0;
            overflow: auto;
            padding: 40px 0;
            height: var(--activitycontentHeight);
            overflow: auto;
            position: relative;
            .whiteboard_card {
                display: flex;
                align-items: flex-start;
                gap: 20px 25px;
                flex-wrap: wrap;
                width: 910px;
                margin-inline: auto;
                margin-block: 0;
                .whiteboard_card_box {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    max-width: 208px;
                    .whiteboard_img {
                        width: 208px;
                        height: 117px;
                        overflow: hidden;
                        outline: 1px solid var(--light-bg);
                        border-radius: 12px;
                        cursor: pointer;
                        transition: var(--transition);
                        &:is(:hover, .active) {
                            outline: 4px solid var(--cpbutton);
                            outline-offset: -2px;
                        }
                        img {
                            width: 100%;
                            // min-height: 118px;
                            height: 117px;
                            object-fit: cover;
                        }
                    }
                    .whiteboard_title {
                        width: 100%;
                        text-align: center;
                        h4 {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }
    .whiteboarderror {
        width: 100%;
        text-align: center;
        position: fixed;
        bottom: 102px;
        margin-inline-start: -8px;
        p {
            color: var(--danger);
            font-size: 14px;
            line-height: 16px;
            font-family: var(--font-stack);
            font-weight: 400;
        }
    }
}
