@use '../../Style.scss' as *;

// orver all
.profile_sec2 {
    // background-color: rgb(224, 247, 25);
    // background-image: url(../../assets/images/classpointupgradetopro.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;

    .MuiGrid-container {
        margin-block-start: -2vh;
        width: calc(100% + 3vh);
        margin-inline-start: -3vh;
    }
    .MuiGrid-container > .MuiGrid-item {
        padding-inline-start: 3vh;
        padding-block-start: 2.4vh;
        display: grid;
    }
}

.errorBoundary_sec {
    opacity: 1;
    width: 70vh;
    margin-inline: auto;
    margin-block: 0;
    height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5vh;
    .MuiGrid-container {
        margin-block-start: -2vh;
        width: calc(100% + 6.8vh);
        margin-inline-start: -6.8vh;
    }
    .MuiGrid-container > .MuiGrid-item {
        padding-inline-start: 6.8vh;
        padding-block-start: 2.4vh;
        display: grid;
    }
    .errorBound_img {
        width: 100%;
        text-align: center;
        img {
            width: 22vh;
            margin-inline: auto;
            margin-block: 0;
        }
    }
    .error_content {
        text-align: center;
        h2 {
            @include head_h2;
            font-weight: 500;
            font-family: var(--font-stack);
            cursor: default;
            color: var(--user_emailtext);
            &.red {
                color: var(--redspan-color);
            }
        }
        h3 {
            @include head_h3;
            font-weight: 400;
            line-height: 4.5vh;
            font-family: var(--font-stack);
            cursor: default;
            color: var(--text_color);
            margin-block-start: 2vh;
            a {
                @include head_h3;
                font-weight: 400;
                line-height: 4.5vh;
                font-family: var(--font-stack);
                cursor: default;
                color: var(--parple);
                cursor: pointer;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
        button {
            @include error_bound_btn;

            color: var(--textWhite);

            margin-block-start: 4.5vh;
            margin-block-end: 4vh;
            background-color: var(--cpbutton) !important;

            &:hover {
                background-color: var(--btnhover) !important;
            }
        }
    }
}
