.formContent {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;

    width: 100%;
    height: max-content;

    .head {
        font-size: 18px;
        font-weight: 500;
        color: var(--activation_text);
        text-align: center;

        width: 100%;
        padding-block-end: 5px;
    }

    .searchInput {
        width: 100%;

        :global(.MuiOutlinedInput-root) {
            font-size: 16px;
            color: var(--activation_text);

            height: 48px;
            border-radius: 5px;

            &:global(.Mui-focused) {
                :global(.MuiOutlinedInput-notchedOutline) {
                    border-color: #a7a7a7;
                }
            }

            :global(.MuiOutlinedInput-notchedOutline) {
                border: 2px solid #e0e0e0;
            }

            :global(.MuiOutlinedInput-input) {
                box-shadow: none;
            }
        }
    }

    .selectInputContainer {
        flex-shrink: 0;
        width: calc(50% - 10px);

        &.fullWidth {
            width: calc(100% - 5px);
        }

        :global(.MuiFormLabel-root) {
            font-size: 14px;
            color: #a5a5a5;

            padding-block-end: 6px;
        }

        :global(.MuiOutlinedInput-root) {
            width: 100%;
            height: 44px;
            border-radius: 5px;

            :global(.MuiSelect-select) {
                color: var(--activation_text);
            }

            &:global(.Mui-focused) {
                :global(.MuiOutlinedInput-notchedOutline) {
                    border-color: #a7a7a7;
                }
            }

            :global(.MuiOutlinedInput-notchedOutline) {
                border: 2px solid #e0e0e0;

                span {
                    display: none;
                }
            }

            :global(.MuiSvgIcon-root) {
                color: var(--activation_text);
            }

            :global(.MuiOutlinedInput-input) {
                box-shadow: none;
            }
        }
    }

    .btnContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        width: 100%;
        height: max-content;
        padding-block-start: 5px;

        :is(.saveBtn, .saveAndPublishBtn, .deleteBtn) {
            color: var(--textWhite);
            text-transform: none;

            padding-inline: 20px;
        }

        .saveBtn {
            background: var(--backgroundcolor);
        }

        .saveAndPublishBtn {
            background: var(--checkboxGreen);
        }

        .deleteBtn {
            background: var(--submission_btn);
        }
    }

    .error {
        position: absolute;
        inset: auto 0 -32px 0;

        color: var(--bg_red);
        text-align: center;

        margin-inline: auto;
    }
}

.templateSelectOptions {
    font-size: 16px !important;

    height: 40px;
    border-bottom: 2px solid var(--csvUpload_grey);
}
