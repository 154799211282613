// Animation Styles
.overlayAnimationGlobal {
    position: absolute;
    inset: 0 0 40px 0;
    z-index: 5000;

    display: none;

    height: 100%;
    border-radius: 12px 12px 0 0;
    transition: 0.2s all ease-out;

    &.show {
        display: block;
        background-color: hsla(0, 0%, 0%, 0.6);
    }

    &.imageUpload {
        height: calc(100% - 40px);
    }
}

.lottieAnimationGlobal {
    position: absolute;
    inset: -5px -5px 15px -5px;
    z-index: 5001;
    direction: ltr;
    width: 75px;

    > div {
        transform: translateY(-5px);
    }

    & * {
        direction: ltr;
    }
}

.starLottieGlobal {
    position: absolute;
    z-index: 1;
    inset: 50% auto auto 50%;

    display: flex;

    width: 70px;
    height: 70px;
    pointer-events: none;
    transform: translate(-50%, -50%);

    & > div {
        position: relative;
        inset: -6px auto auto auto;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
    }
}

.myClassParticipantCardLottie {
    position: relative;
    overflow: hidden;

    .myClassGivePointsLottie {
        position: absolute;
        inset: -40px 0 0 -45px;

        .lottie {
            width: 225px;
        }
    }

    &.myClassRemovePointAnimation {
        // animation-name: removePoint;
        // animation-duration: 1s;

        background: var(--red_hover);

        &::after {
            display: none;
        }

        &:hover {
            background: var(--red_hover) !important;
        }
    }

    &.myClassAddPointAnimation {
        background: var(--addingPoint_bg);

        &::after {
            display: none;
        }

        &:hover {
            background: var(--addingPoint_bg) !important;
        }
    }
}

@keyframes removePoint {
    0% {
        background: var(--background-white);
    }

    50% {
        background: var(--red_hover);
    }

    100% {
        background: var(--background-white);
    }
}

.sparkleLottieGlobal {
    position: relative;

    width: fit-content;
    margin: 0 auto;
    overflow: visible;

    .sparkle {
        position: absolute;

        &:nth-of-type(1) {
            inset: -95px 0 0 -10px;
            width: 70px;
        }

        &:nth-of-type(2) {
            inset: -80px 0 0 -45px;
            width: 40px;
        }

        &:nth-of-type(3) {
            inset: -55px 0 0 -55px;
            width: 60px;
        }

        &:nth-of-type(4) {
            inset: -40px 0 0 -5px;
            width: 45px;
        }
    }
}

:is(.filleBlanksFullScreenLottie, .wordCloudFullScreenLottie, .multipleChoiceSparkleLottie, .quickPollSparkleLottie) {
    .sparkleLottieGlobal {
        inset: 70px 50px auto auto;
        z-index: -1;
    }
}

:is(.pickNameSparkleLottie, .autoPickNameSparkleLottie) {
    .MuiAvatar-circular {
        z-index: 10;
    }
}

.pickNameSparkleLottie {
    .sparkleLottieGlobal {
        inset: -30px auto auto auto;
    }
}

.autoPickNameSparkleLottie {
    .sparkleLottieGlobal {
        inset: -22px auto auto auto;
        transform: scale(0.8);
    }
}

.fullScreenPointGlobal {
    position: absolute;
    inset: 0;
    z-index: 5000;

    overflow: hidden;
    pointer-events: none;
}

:is(
        .filleBlanksFullScreenLottie,
        .wordCloudFullScreenLottie,
        .multipleChoiceFullScreenLottie,
        .pickNameFullScreenLottie
    ) {
    :is(.close_modal, .word_cloud_modal) {
        z-index: -1;
    }
}

.pickNameFullScreenLottie {
    .footer_sec.participant_sec_foot {
        position: relative;
        z-index: 0;
    }
}

:is(.viewport.myClassPointContainer, .fullScreenPointContainer) {
    &.show {
        .fullScreenPointGlobal {
            background-color: hsla(0, 0%, 0%, 0.75);
        }
    }

    .footer_sec.participant_sec_foot {
        position: relative;
        z-index: 5010;
    }
}

.fullScreenPointContainer {
    position: relative;
    transition: 0.2s all ease-out;

    overflow: hidden;
}
