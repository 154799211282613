@use '../../Style.scss' as *;

.videoTutorial_sec {
    width: 100%;
    height: 100%;
    background: var(--background-white);

    .videoTutorial_inner_sec {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;

        .left_VcTutorial_sec {
            width: 100%;
            max-width: 258px;
            overflow: overlay;
            height: 100%;
            max-height: 580px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.018), rgba(0, 0, 0, 0.018)), #ffffff;

            .MuiAccordion-root {
                border-radius: 0;
                border-bottom: 1px solid var(--graybtn);
                box-shadow: none;

                &::before {
                    display: none;
                }

                &.Mui-expanded {
                    margin: 0;
                }

                .MuiAccordionSummary-root {
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #ffffff;
                    min-height: 44px;
                    padding-inline: 16px;
                }

                .MuiAccordionSummary-content {
                    margin: 0;

                    .video_number {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                        min-width: 18px;
                    }

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                    }
                }

                .MuiAccordionDetails-root {
                    padding: 0;

                    ul {
                        li {
                            cursor: pointer;
                            border-bottom: 1px solid rgba(99, 120, 255, 0.14);

                            &:last-child {
                                border-bottom: none;
                            }

                            &.complete {
                                .single_video_box {
                                    .title {
                                        .icon {
                                            opacity: 1;

                                            svg {
                                                color: var(--icon_green);
                                            }
                                        }
                                    }
                                }
                            }

                            &.active {
                                .single_video_box {
                                    background: var(--parple);

                                    p {
                                        color: var(--textWhite);
                                    }

                                    .title {
                                        .icon {
                                            opacity: 1;
                                            display: flex;

                                            svg {
                                                font-size: 20px;
                                                color: var(--textWhite);
                                            }
                                        }
                                    }

                                    .time {
                                        span {
                                            color: var(--textWhite);
                                        }
                                    }
                                }
                            }

                            .single_video_box {
                                background: linear-gradient(0deg, rgba(0, 0, 0, 0.018), rgba(0, 0, 0, 0.018)), #ffffff;
                                padding-inline: 16px;
                                padding-block: 3px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                gap: 5px;
                                min-height: 44px;

                                p {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                    font-family: var(--font-stack);
                                    color: var(--activation_text);
                                }

                                .title {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    flex: 1;

                                    .icon {
                                        display: flex;
                                        min-width: 18px;
                                        opacity: 0;

                                        svg {
                                            font-size: 18px;
                                            color: var(--activation_text);
                                        }
                                    }
                                }

                                .time {
                                    span {
                                        font-weight: 400;
                                        font-size: 10px;
                                        line-height: 17px;
                                        font-family: var(--font-stack);
                                        color: var(--greytext_color);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .right_VcTutorial_sec {
        flex: 1;
        padding-block: 28px;
        padding-inline: 28px;
        height: 100%;

        .video_backdrop {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 11;
            display: none;
            background: var(--background_black_06);

            &.fullScreenVideo {
                display: block;
            }
        }

        .display_section {
            position: relative;
            z-index: 1;

            &.fullScreenVideo {
                position: absolute;
                z-index: 20;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 85%;
                height: 86%;

                .big_sh_content {
                    background: var(--background_black);
                    align-items: flex-start;
                    justify-content: flex-start;

                    .sh_content_video {
                        margin-block-start: 20px;
                        height: 89%;

                        .react-player.video_upload {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }

                .video_title_sec,
                .description {
                    display: none;
                }
            }
        }

        .big_sh_content {
            text-align: center;
            isolation: isolate;
            // background: var(--background_black);
            border-radius: 0px;

            .video_loader {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 11;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-block-start: 20px;

                & > div {
                    width: 50%;
                    margin: auto;
                }
            }

            &:hover {
                .videoControls {
                    opacity: 1;
                }
            }

            .sh_content_video {
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 0px;
            }

            .react-player.video_upload {
                width: 100% !important;
                height: 341px !important;
                border-radius: 0px;
                z-index: 0;
                position: relative;
                display: flex;
                border: 1px solid rgba(0, 0, 0, 0.08);

                &.videoFullView {
                    height: 100% !important;
                }
            }

            .video_button {
                width: auto;
                position: absolute;
                z-index: 11;
                bottom: 50px;
                left: 0;
                text-align: start;
                padding: 0 10px;

                button {
                    border-radius: 50%;
                    border: 1px solid var(--textWhite);
                    padding: 4px;
                    cursor: pointer;

                    svg {
                        color: var(--textWhite);
                        font-size: 20px;
                    }
                }
            }
        }

        .videoControls {
            position: absolute;
            inset: auto 0 0 0;
            z-index: 15;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
            padding: 0 15px;
            transition: 0.2s all ease-out;
            opacity: 0.5;
            isolation: isolate;
            padding-block-end: 6px;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 100%);
                bottom: 0px;
                left: 0;
                height: 55px;
                z-index: -1;
                // border-radius: 0 0 12px 12px;
            }

            svg {
                color: var(--textWhite);
            }

            &.hide {
                opacity: 0;
            }

            .slider {
                cursor: pointer;
                color: var(--textWhite);
                pointer-events: all;
                padding: 11px 0;
            }

            p.time {
                font-family: var(--font-stack);
                font-size: 17px;
                font-weight: 400;
                color: var(--textWhite);
                line-height: 25px;
                width: 74px;
                padding: 0;
            }

            .overlay {
                display: none;
            }

            .MuiSlider-thumbColorPrimary {
                width: 12px;
                height: 12px;

                &:is(.Mui-focusVisible, :hover) {
                    box-shadow: 0px 0px 0px 8px rgb(99 120 255 / 11%);
                }
            }

            .centerIcon {
                position: relative;
                top: inherit;
                z-index: 2;
                padding: 1px 0;
                opacity: 0;
                transition: 0.2s all ease-out;
                cursor: pointer;
                display: flex;

                svg {
                    font-size: 23px;
                    color: var(--textWhite);
                    padding: 0px;
                    background: transparent;
                    border-radius: 0;
                    border: none;
                }

                &.show {
                    opacity: 1;
                }
            }

            .volumeSlider {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 13px;
                cursor: pointer;
                padding-inline: 5px;
                padding-block: 13px 4px;
                border-radius: 30px;
                bottom: -3px;

                &:hover {
                    background: var(--bg_49454f_80);

                    .slider {
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                .slider {
                    height: 48px;
                    cursor: pointer;
                    transition: 0.2s all ease-out;
                    padding: 0;
                    opacity: 0;
                    pointer-events: none;
                }

                .controlIcon {
                    display: flex;
                }
            }

            .option {
                position: relative;
                padding-block-end: 1px;
                min-height: 24px;

                & > button {
                    width: auto;
                    min-width: auto;
                    padding: 0;
                    color: var(--background-white);

                    &.textCaption {
                        border: 2px solid var(--borderWhite);
                        height: 16px;
                        min-width: 22px;
                        font-weight: 400;
                        font-size: 9px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        padding-inline: 2px;
                    }
                }

                .menu {
                    position: absolute;
                    left: inherit;
                    right: 0;
                    top: -10px;
                    transform: translateY(-100%);
                    display: none;

                    ul {
                        li {
                            a {
                                padding-inline-start: 30px;
                                position: relative;

                                .tick {
                                    display: flex;
                                    position: absolute;
                                    left: 8px;

                                    svg {
                                        display: flex;
                                        color: var(--activation_text);
                                        font-size: 17px;
                                    }
                                }
                            }
                        }
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }

        .video_title_sec {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-block: 12px;
            align-items: center;

            .left_title {
                width: auto;

                h4 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    color: var(--icon_color);
                }
            }

            .share_video {
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                padding-inline: 12px;
                cursor: pointer;

                .icon {
                    // transform: rotateY(180deg);
                    width: fit-content;
                    min-width: fit-content;
                    min-height: 40px;
                    border-radius: 8px;
                    padding: 0;
                    padding-inline: 14px;

                    &:hover {
                        background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                    }

                    svg {
                        font-size: 24px;
                        color: var(--parple);
                        display: flex;
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    color: var(--icon_color);
                }

                .copyurl {
                    position: absolute;
                    background: #f8f8f8;
                    background-blend-mode: overlay;
                    border-radius: 4px;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
                    top: 10px;
                    left: -4px;
                    width: 146px;
                    padding-block: 5px;
                    padding-inline: 8px;
                    transform: translateX(-100%);
                    display: block;

                    h5 {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                    }
                }
            }
        }

        .description {
            padding-block: 16px;
            border-top: 1px solid var(--border-color);

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                font-family: var(--font-stack);
                color: var(--activation_text);
            }

            span {
                color: var(--parple);
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

video::cue {
    // font-style: normal;
    font-weight: 400;
    // font-size: 3vh;
    line-height: 1.388;
    background: rgba(0, 0, 0, 0.7);
    font-family: 'Roboto', sans-serif;
}

.fullScreenVideo {
    video::cue {
        line-height: 1.33;
    }
}

// modal
// .fullScreenVideo {
// .modal_box {
//     &.video_tutorial_modal_box {
//         max-width: 100%;
//         height: 100%;

//         .big_sh_content {

//             .sh_content_video {
//                 .react-player.video_upload {
//                     width: 100% !important;
//                     height: 100% !important;
//                 }
//             }
//         }
//     }
// }
// }

.modal_box {
    &.video_tutorial_modal_box {
        padding: 0px !important;
        width: 85%;
        height: 86% !important;

        .close {
            position: absolute;
            top: -24px;
            right: -24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            background: none;
            visibility: visible;
            opacity: 1;
            z-index: 111;
            cursor: pointer;

            svg {
                display: flex;
                font-size: 16px;
                color: var(--textWhite);
            }
        }

        .display_section {
            position: relative;
            z-index: 1;
            margin-inline: auto;
            height: 100%;
        }

        .big_sh_content {
            text-align: center;
            isolation: isolate;
            align-items: flex-start;
            justify-content: flex-start;
            background: var(--background_black);
            border-radius: 0px;

            &:hover {
                .videoControls {
                    opacity: 1;
                }
            }

            .sh_content_video {
                width: 100%;
                overflow: hidden;
                border-radius: 0px;
                margin-block-start: 20px;
                height: 89%;

                .video_loader {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 11;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-block-start: 20px;

                    & > div {
                        width: 50%;
                        margin: auto;
                    }
                }
            }

            .react-player.video_upload {
                width: 100% !important;
                height: 100% !important;
                border-radius: 0px;
                z-index: 0;
                position: relative;
                display: flex;
                outline: 2px solid rgba(0, 0, 0, 0.397);
                outline-offset: -1px;

                &.videoFullView {
                    height: 100% !important;
                }
            }

            .video_button {
                width: auto;
                position: absolute;
                z-index: 11;
                bottom: 50px;
                left: 0;
                text-align: start;
                padding: 0 10px;

                button {
                    border-radius: 50%;
                    border: 1px solid var(--textWhite);
                    padding: 4px;
                    cursor: pointer;

                    svg {
                        color: var(--textWhite);
                        font-size: 20px;
                    }
                }
            }
        }

        .videoControls {
            position: absolute;
            inset: auto 0 0 0;
            z-index: 2;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
            padding: 0 15px;
            transition: 0.2s all ease-out;
            opacity: 0.5;
            isolation: isolate;
            padding-block-end: 6px;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.5) 100%);
                bottom: 0px;
                left: 0;
                height: 55px;
                z-index: -1;
                // border-radius: 0 0 12px 12px;
            }

            svg {
                color: var(--textWhite);
            }

            &.hide {
                opacity: 0;
            }

            .slider {
                cursor: pointer;
                color: var(--textWhite);
                pointer-events: all;
                padding: 11px 0;
            }

            p.time {
                font-family: var(--font-stack);
                font-size: 17px;
                font-weight: 400;
                color: var(--textWhite);
                line-height: 25px;
                width: 74px;
                padding: 0;
            }

            .overlay {
                display: none;
            }

            .MuiSlider-thumbColorPrimary {
                width: 12px;
                height: 12px;

                &:is(.Mui-focusVisible, :hover) {
                    box-shadow: 0px 0px 0px 8px rgb(99 120 255 / 11%);
                }
            }

            .centerIcon {
                position: relative;
                top: inherit;
                z-index: 2;
                padding: 1px 0;
                opacity: 0;
                transition: 0.2s all ease-out;
                cursor: pointer;
                display: flex;

                svg {
                    font-size: 23px;
                    color: var(--textWhite);
                    padding: 0px;
                    background: transparent;
                    border-radius: 0;
                    border: none;
                }

                &.show {
                    opacity: 1;
                }
            }

            .volumeSlider {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 13px;
                cursor: pointer;
                padding-inline: 5px;
                padding-block: 13px 4px;
                border-radius: 30px;
                bottom: -3px;

                &:hover {
                    background: var(--bg_49454f_80);

                    .slider {
                        opacity: 1;
                        pointer-events: all;
                    }
                }

                .slider {
                    height: 48px;
                    cursor: pointer;
                    transition: 0.2s all ease-out;
                    padding: 0;
                    opacity: 0;
                    pointer-events: none;
                }

                .controlIcon {
                    display: flex;
                }
            }

            .option {
                position: relative;
                padding-block-end: 1px;
                min-height: 24px;

                & > button {
                    width: auto;
                    min-width: auto;
                    padding: 0;
                    color: var(--background-white);

                    &.textCaption {
                        border: 2px solid var(--borderWhite);
                        height: 16px;
                        min-width: 22px;
                        font-weight: 400;
                        font-size: 9px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        padding-inline: 2px;
                    }
                }

                .menu {
                    position: absolute;
                    left: inherit;
                    right: 0;
                    top: -10px;
                    transform: translateY(-100%);
                    display: none;

                    ul {
                        li {
                            a {
                                padding-inline-start: 30px;
                                position: relative;

                                .tick {
                                    display: flex;
                                    position: absolute;
                                    left: 8px;

                                    svg {
                                        display: flex;
                                        color: var(--activation_text);
                                        font-size: 17px;
                                    }
                                }
                            }
                        }
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
}

.center_play {
    position: absolute;
    inset: 0 0 0 0;
    width: 90px;
    height: 90px;
    margin: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--textWhite);
    opacity: 0;
    transform: scale(0);

    &.show {
        animation: pulse 0.6s alternate;
    }

    @keyframes pulse {
        from {
            transform: scale(0);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        to {
            transform: scale(1);
            opacity: 0;
            display: none;
        }
    }

    svg {
        font-size: 50px;
        display: flex;
    }

    z-index: 11;
}
