.bgOverlay {
    position: absolute;
    inset: 0;
    z-index: 1;
}

.groupModalFooter {
    position: relative;

    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    width: 100%;
    height: 60px;
    border-top: 1px solid hsl(231.9, 100%, 69.4%, 0.15);

    :is(.nextStepBtn, .editMembersBtn, .deleteGroupBtn) {
        font-size: 15px;
        font-weight: 500;
        text-transform: none;

        min-width: 125px;
        height: 40px;
        padding-inline: 17px;
        border-radius: 4px;
        box-shadow: none;
    }

    .nextStepBtn {
        color: var(--textWhite);
        background: var(--btnbgcolor);

        &:hover {
            background: var(--btnhover);
        }

        &.MuiLoadingButton-loading {
            color: transparent;
            background: var(--btnbgcolor);

            .MuiCircularProgress-root {
                color: var(--textWhite);
            }
        }
    }

    .editMembersBtn {
        color: var(--cpbutton);
        background: var(--btnbgcolor_light);
        transition: all 0.15s ease-in-out;

        &:hover {
            color: var(--textWhite);
            background: var(--btnbgcolor);
        }
    }

    .editGroup {
        position: absolute;
        inset: 0 10px 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-block: auto;

        .optionsIcon {
            width: 35px;
            height: 35px;
            border-radius: 1000px;
        }

        .savedClassOptions {
            inset: -125px 0 auto auto;
            width: 180px;

            .MuiButton-root {
                padding-inline-start: 14px;
            }

            .text {
                font-weight: 400;
                line-height: normal;
            }

            .iconContainer {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 25px;
                height: 25px;
                margin-block-end: 2px;

                .icon {
                    font-size: 20px;
                }
            }
        }
    }
}
