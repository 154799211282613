@import '../../../../Style.scss';

.questionCard {
    width: 600px;
    height: 420px;
    border-radius: 12px;
    box-shadow: var(--shadow_02);
    background: var(--background-white);
    overflow: hidden;

    .voters {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;

        :is(.header) {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--backgroundcolor);

            width: 100%;
            height: 50px;
            padding-inline: 24px;
            background: var(--btnbgcolor_light);

            .backBtn {
                position: absolute;
                left: 24px;

                .icon {
                    font-size: 23px;
                    color: var(--activation_text);
                }
            }

            .votersCount {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
            }
        }

        .voterContainerWrapper {
            width: 100%;
            height: calc(100% - 50px);
            padding-block-end: 10px;
            padding-inline-end: 5px;

            .voterContainer {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                align-items: flex-start;
                justify-content: flex-start;
                gap: 18px;

                width: 100%;
                height: 100%;
                padding-block: 28px 20px;
                padding-inline: 40px 35px;
                overflow: auto;

                .details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 12px;

                    width: 100%;
                    height: max-content;

                    :global(.MuiAvatar-root) {
                        width: 52px;
                        height: 52px;
                    }

                    .participantName {
                        display: -webkit-box;

                        font-size: 14px;
                        font-weight: 500;
                        text-overflow: ellipsis;
                        text-align: center;

                        width: 100%;

                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .inner_questionCard {
        position: relative;

        width: 100%;
        height: 100%;

        .top_content {
            width: 100%;
            height: calc(100% - 56px);
            padding-block: 20px 25px;
            @include displayFlex(column, center, center);
            gap: 16px;

            .question_area {
                @include displayFlex(row, center, center);

                width: 100%;
                height: calc(100% - 60px);
                overflow: auto;
                padding-inline: 20px;

                .text {
                    text-align: center;
                    color: var(--activation_text);
                    word-break: keep-all;
                }
            }

            .profile_foot {
                display: flex;
                align-items: center;
                gap: 8px;

                width: max-content;
                height: 40px;

                .avater_box {
                    width: auto;

                    :global(.MuiAvatar-root) {
                        height: 32px;
                        width: 32px;
                        font-size: 16px;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }

                .text {
                    font-size: 18px;
                    // font-weight: var(--fw_medium);
                    color: var(--activation_text);
                }
            }

            .upgrade_ans {
                @include displayFlex(row, center, center);
                gap: 5px;

                font-size: 18px;
                font-weight: 400;
                color: var(--primary-color);
                text-transform: none;

                width: fit-content;
                margin-inline: auto;
                padding-block: 2px 1px;
                padding-inline: 15px;
                border-radius: 1000px;

                &:hover {
                    background: rgba(99, 120, 255, 0.11);
                }

                &.disabled {
                    color: var(--greytext_color);
                    pointer-events: none;
                }

                .icon {
                    display: flex;
                    margin-block-end: 3px;

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
}
