@use '../../../../Style.scss' as *;

.sideMenuContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    width: 100%;
    height: 100%;
    padding-block: 16px;

    .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 42px;
        height: 42px;
        padding-block: 5px;
        padding-inline: 5px;
        border-radius: 5px;
        cursor: pointer;

        &.active {
            background: var(--background-white);
        }

        .unpublishedIcon {
            color: var(--textWhite);

            &.active {
                color: var(--activation_text);
            }
        }

        .image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
