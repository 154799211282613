.rhap_container {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.rhap_horizontal-reverse .rhap_controls-section {
    flex: none;
    margin: 0;
}

.rhap_horizontal-reverse .rhap_controls-section .rhap_additional-controls {
    display: none;
}
.rhap_horizontal-reverse .rhap_controls-section .rhap_volume-controls {
    display: none;
}

.rhap_progress-section {
    flex: 1 1 0%;
}

.rhap_time {
    color: var(--activation_text);
    font-size: 14px;
    width: 40px;
    text-align: right;
}

.rhap_progress-bar {
    height: 8px;
    background-color: #d1d5db;
    border-radius: 4px;
    overflow: hidden;
}

.rhap_progress-filled {
    border-radius: 4px;
    background-color: #6378ff;
}

.rhap_progress-indicator {
    display: none;
}

.rhap_main-controls-button {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
}

.rhap_play-pause-button {
    width: max-content;
    height: max-content;
    padding-inline-end: 2px;
}

.iconButton {
    color: var(--background-white) !important;

    width: 30px !important;
    height: 30px !important;
    background: var(--activation_text) !important;
}
