@use '../../../../Style.scss' as *;

.contentArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    height: 100%;

    .noTemplateFound {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        width: 500px;
        height: 500px;
        margin: auto;
        padding-block-end: 175px;

        .noTemplateFoundText {
            font-size: 20px;
            color: var(--activation_text);
        }
    }

    .templateContent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        gap: 20px;

        width: 100%;
        height: max-content;
        padding-inline: 20px 15px;
        overflow: scroll;

        .thumbnailContainer {
            position: relative;

            width: 100%;
            height: 150px;
            border: 1px solid var(--cpButton_18);
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;

            .thumbnail {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .proIcon {
                position: absolute;
                inset: 7px 7px auto auto;

                font-size: 10px;
                font-weight: 500;
                color: var(--textWhite);
                letter-spacing: 1px;

                width: max-content;
                height: max-content;
                padding: 2px 6px;
                background: var(--user_pro);
                border-radius: 100px;
            }

            .downloadsCount {
                position: absolute;
                inset: 7px 7px auto auto;

                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1px;

                width: max-content;
                height: max-content;
                padding: 2px 6px;
                background: var(--settings_premium_ui);
                border-radius: 100px;
            }

            .addIcon {
                position: absolute;
                inset: auto 7px 7px auto;

                color: hsla(0, 100%, 100%, 0.75);

                width: 32px;
                height: 32px;
                padding-block: 3px;
                padding-inline: 3px;
                border: 1px solid var(--cpButton_18);
                border-radius: 1000px;
                background: hsla(0, 0%, 0%, 0.35);

                opacity: 0;

                transition: all 0.15s ease-in-out;
            }

            &:hover {
                .addIcon {
                    opacity: 1;
                    pointer-events: all;

                    &:hover {
                        color: var(--textWhite);
                        background: hsla(0, 0%, 0%, 0.5);
                    }
                }
            }
        }
    }
}
