@use '../../Style.scss' as *;

.Classpoint_activity {
    background: rgb(255, 255, 255);
    background: var(--gradientbg);
    width: 100%;
    height: 100%;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255, 255, 255);
    background: var(--gradientbg);

    .MuiCircularProgress-root {
        color: var(--cpbutton);
    }

    svg {
        width: 240px !important;
    }
}

.waiting_animation2 {
    // height: calc(100vh - 157px);
    height: var(--activitycontentHeight);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgb(255, 255, 255);
    background: var(--gradientbg);

    svg {
        height: 270px !important;
    }

    h4 {
        color: var(--activation_text);
    }

    h5 {
        color: var(--activation_text);
        font-family: var(--font-stack);
        font-size: 22px;
        font-weight: 500;
    }

    .noParticipant {
        font-size: 18px;
        color: var(--activation_text);

        padding-top: 8px;

        .highlight {
            color: var(--btnbgcolor);
            cursor: pointer;
        }
    }
}

.submit_modal {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    width: 400px !important;
    border-radius: 12px;
    overflow: hidden;

    .pad_20 {
        padding: 20px 12px;
    }

    .word_cloudhead {
        background: #edf0ff;
        color: var(--parple);
        text-align: center;
        // padding: 12px;
        min-height: 50px;
        padding: 4px;

        h2 {
            font-size: 20px;
            font-weight: 500;
            font-family: var(--font-stack);
        }
    }

    .name_list_box {
        height: 155px;
    }

    .list_content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 1vh;
        flex-wrap: wrap;
        max-height: 150px;
        overflow: auto;

        .MuiChip-root {
            border: 1px solid #cfd5ff;
            box-sizing: border-box;
            border-radius: 16px;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;

            .MuiChip-label {
                font-family: var(--font-stack);
                color: var(--text_color);
                font-size: 14px;
                font-weight: 400;
                padding-inline-start: 8px;
            }

            .MuiAvatar-circular {
                width: 24px;
                height: 24px;
                font-size: 0.75rem;
                margin-inline-start: 4px;
            }
        }
    }

    .MuiTabs-indicator {
        background-color: var(--parple);
        display: none;
    }

    .MuiTab-root.MuiTab-textColorPrimary {
        color: var(--gray_text_color);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 0;
        }
    }

    .MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        color: var(--parple);

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 100%;
            background: var(--parple);
        }
    }

    .MuiTabs-scroller {
        background: linear-gradient(0deg, rgba(99, 120, 255, 0.075), rgba(99, 120, 255, 0.075)), #ffffff;

        button {
            width: 50%;
            text-transform: capitalize;
        }
    }
}

.MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiModal-root .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
}

.MuiPopover-root .MuiBackdrop-root {
    background-color: transparent;
}

.dailog_modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiDialog-paperScrollPaper {
        background: none;
        box-shadow: none;
        overflow: hidden;
        position: relative;
    }

    .box {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .submit_modal {
        position: relative;
        background: var(--background-white);
        margin-inline: auto;
        margin-block: 0;
    }
}

.close_modal {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    // border-radius: 50%;
    // background: ;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // opacity: 0.8;
    z-index: 0;
}

//end of sky-slider

@media screen and (min-width: 320px) {
    main {
        width: 100%;
    }
}

.sky_slider_container {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0;
    bottom: 0;
    // -webkit-transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    margin: auto;
    height: max-content;
    width: max-content;

    // max-width: var(--lightBoxMax-width);
    // min-width: var(--lightBoxMin-width);
    .sh_footer {
        padding: 11px 16px;
        position: relative;
        top: -1px;

        .profile_foot {
            p {
                font-size: 15px;
                line-height: 17px;
            }

            .largeFontText {
                font-size: 15px;
                line-height: 17px;
            }

            .smallFontText {
                font-size: 15px;
                line-height: 17px;
            }
        }
    }

    .short_modal {
        .big_sh_box .big_sh_content {
            max-height: var(--lightBoxMax-height);
            overflow: auto;
        }
    }
}

button.sky-slider__prev {
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    background-size: 2.4rem 2.4rem;
    padding: 10px;
    max-height: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    min-width: auto;
    border-radius: 50%;
    color: var(--activation_text);
    background: var(--background-white);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    left: 50px;
    opacity: 0.7;

    &:hover {
        opacity: 1;
        color: var(--activation_text);
        background: var(--background-white);
    }

    .chevron-right {
        height: 100%;
        fill: var(--activation_text);
        transition: fill 0.3s ease-in;
        transform: rotateY(180deg);
        font-size: 24px;
        display: flex;
    }

    &:hover {
        .chevron-right {
            fill: var(--activation_text);
            transition: fill 0.3s ease-in-out;
        }
    }

    //end of hover
}

button.sky-slider__next {
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    background-size: 2.4rem 2.4rem;
    padding: 10px;
    max-height: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    min-width: auto;
    border-radius: 50%;
    border-radius: 50%;
    color: var(--activation_text);
    background: var(--background-white);
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    right: 50px;
    opacity: 0.7;

    &:hover {
        opacity: 1;
        color: var(--activation_text);
        background: var(--background-white);
    }

    .chevron-right {
        height: 100%;
        fill: var(--activation_text);
        transition: fill var(--transition);
        font-size: 24px;
        display: flex;
    }

    &:hover,
    &:focus {
        outline: none;

        .chevron-right {
            fill: var(--activation_text);
            transition: fill 0.2s ease-in;
        }
    }

    //end of hover
}

.modalpagination {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    width: fit-content;
    margin-inline: auto;
    margin-block: 0;

    p {
        color: var(--textWhite);
        font-size: 16px;
        font-family: var(--font-stack);
        font-weight: 500;
    }
}

.menu_modal {
    width: 100%;

    .menu_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: none;

        &.active {
            display: block;
        }
    }
}

.menu {
    position: absolute;
    bottom: -50px;
    right: 00px;
    background-color: var(--background-white);
    box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    -webkit-transition: all 0.2s ease;
    opacity: 0;
    -webkit-transform-origin: right top 0px;
    transform-origin: right top 0px;
    -webkit-transform: scale(0);
    transform: scale(0);
    z-index: 1000;

    ul {
        min-width: 147px;
        padding: 2px 0;
        list-style: none;
        position: relative;

        li {
            list-style: none;
            width: 100%;

            a {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 9px 15px;
                font-size: 14px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-weight: 400;
                white-space: nowrap;
                cursor: pointer;

                .icon {
                    display: flex;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    margin-inline-end: 4px;
                }

                img {
                    max-width: 100%;
                }

                svg {
                    font-size: 20px;
                    color: var(--btnbgcolor);
                }

                &:hover {
                    background: var(--parple_08);
                }

                &:active {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                }

                &.noBtn {
                    cursor: default;

                    &:hover {
                        background: none;
                    }

                    &:active {
                        background: none;
                    }
                }
            }

            .menu_checkbox {
                padding-inline: 6.5px;

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)), #ffffff;
                }

                &:active {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                }

                label {
                    margin: 0;
                }

                .MuiTypography-root {
                    font-size: 14px;
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-weight: 400;
                    line-height: 35px;
                    text-align: start;
                    white-space: nowrap;
                }

                .MuiCheckbox-root {
                    color: var(--placeholder-color);
                    padding: 5px;

                    &.Mui-checked {
                        color: var(--parple);
                    }

                    svg {
                        position: relative;
                        top: 0px;
                        display: flex;
                    }
                }
            }

            & > button {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 6px;
                color: var(--backgroundcolor);
                width: 100%;
                min-width: 147px;
                min-height: 36px;
                padding: 8px 12px;
                background: var(--background-white);
                border-radius: 0;

                &:hover {
                    background: var(--parple_08);
                }

                label {
                    margin: 0;

                    &.MuiFormControlLabel-root {
                        width: 100%;
                    }
                }
            }

            .MuiFormControlLabel-root {
                &.Mui-disabled {
                    .MuiSwitch-track {
                        opacity: 1;
                        background: var(--switch_diable);
                    }

                    .MuiTypography-root {
                        color: var(--disable_bg_AEAAAE);
                    }
                }
            }

            .MuiSwitch-root {
                width: 28px;
                height: 16px;
                padding: 0;
            }

            .online_control {
                border-top: 1px solid var(--borderMain);

                label {
                    margin: 0;
                }
            }

            label {
                .MuiTypography-root {
                    font-size: 14px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    line-height: 20px;
                    font-weight: 400;
                    margin-inline-start: 8px;
                    text-transform: none;
                    text-align: start;
                    // white-space: nowrap;
                }
            }

            .MuiSwitch-thumb {
                width: 14px;
                height: 14px;
            }

            .Mui-checked + .MuiSwitch-track {
                background: var(--parple) !important;
                opacity: 1 !important;
            }

            .MuiSwitch-track {
                border-radius: 24px;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                opacity: 1;
            }

            .MuiSwitch-switchBase {
                padding: 1px;

                &.Mui-checked {
                    color: var(--background-white);
                    -webkit-transform: translateX(12px);
                    -moz-transform: translateX(12px);
                    -ms-transform: translateX(12px);
                    transform: translateX(12px);
                }
            }
        }
    }

    .menu_grid_btn {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;

        .grid_box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 4px;
            font-size: 11px;
            color: var(--activation_text);
            font-family: var(--font-stack);
            font-weight: 400;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
            border-radius: 8px;
            cursor: pointer;
            min-width: 92px;
            min-height: 64px;

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
            }
        }
    }
}

.menu.active {
    opacity: 1;
    -webkit-transform-origin: right top 0px;
    transform-origin: right top 0px;
    -webkit-transform: scale(1);
    transform: scale(1);
}
