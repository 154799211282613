@use '../../../Style.scss' as *;
/* Track */

.fill_blank_sec {
    width: 100%;
    padding-block-start: 16px;
    // background: var(--lightparple);
    background: rgb(255, 255, 255);
    background: var(--gradientbg);
    // height: calc(100vh - 157px);
    height: 486px;

    &.show_ans {
        background: linear-gradient(180deg, rgba(217, 201, 252, 0) 0%, #d9c9fc 100%), #cfd5ff;
    }

    .fillBlankContainer {
        width: 976px;
        margin-inline: auto;
        margin-block: 0;
        padding: 0 15px;
    }

    .inner_fillblank {
        width: 100%;

        .switch_wrap {
            margin-block-end: 30px;
        }

        .total-wrapper {
            margin-inline: auto;
            margin-block: 0;
            margin-block-start: 24px;
        }

        .fill_search {
            .search_area {
                position: relative;
                min-height: 36px;
                margin-block-start: 4px;
            }

            .searchfield {
                width: 100%;
                width: 240px;
                margin-inline: auto;
                margin-block: 0;

                // margin: 0 auto 18px;
                .MuiOutlinedInput-notchedOutline {
                    border-color: 1px solid var(--borderMain);
                }

                .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
                    background: none !important;

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                .MuiOutlinedInput-root {
                    border-radius: 30px;
                    background: transparent;
                    height: 36px;

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                .MuiOutlinedInput-root {
                    &.Mui-focused {
                        .MuiOutlinedInput-notchedOutline {
                            border-color: var(--parple);
                        }
                    }

                    .MuiOutlinedInput-input {
                        box-shadow: none;
                    }
                }

                svg {
                    // color: var(--activation_text) !important;
                    cursor: pointer;
                }

                input {
                    padding: 8px 14px;
                    font-size: 14px;
                    line-height: 14px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);

                    &::placeholder {
                        color: var(--placeholder-color);
                        opacity: 1;
                    }
                }

                label {
                    -webkit-transform: translate(14px, 11px) scale(1);
                    -moz-transform: translate(14px, 11px) scale(1);
                    -ms-transform: translate(14px, 11px) scale(1);
                    transform: translate(14px, 11px) scale(1);
                }

                label.Mui-focused {
                    -webkit-transform: translate(14px, -6px) scale(0.75);
                    -moz-transform: translate(14px, -6px) scale(0.75);
                    -ms-transform: translate(14px, -6px) scale(0.75);
                    transform: translate(14px, -6px) scale(0.75);
                }

                .MuiInputLabel-shrink {
                    -webkit-transform: translate(14px, -6px) scale(0.75);
                    -moz-transform: translate(14px, -6px) scale(0.75);
                    -ms-transform: translate(14px, -6px) scale(0.75);
                    transform: translate(14px, -6px) scale(0.75);
                }
            }

            .searchfield + .show_response {
                text-align: end;
                right: 24px;
                left: inherit;
            }

            .show_response {
                position: absolute;
                top: 8px;
                text-align: center;
                left: 0;
                right: 0;
                margin-inline: auto;

                p {
                    font-size: 14px;
                    line-height: 20px;
                    font-family: var(--font-stack);
                    color: var(--greytext_color);
                    font-weight: 400;
                }
            }
        }

        .trow > div {
            display: inline-block;
            width: 100%;
            min-width: 133px;
            max-width: 287px;
            // box-shadow: inset 0 0 1px 0 rgba(0,0,0,.5);
            // line-height: 80px;
            // height: 80px;
            text-align: start;

            // transform: rotateX(180deg);
            min-height: 53px;

            span {
                display: flex;
                align-items: center;
                min-height: 28px;
                white-space: break-spaces;
            }
        }

        .tbody {
            max-height: 360px;
            overflow: hidden auto;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            display: block;
            width: 100%;
            padding-block-end: 20px;
            // width: 938px !important;
        }

        /*.fix-column > .tbody > .trow:last-child {margin-block-end: 50px;}*/

        .fix-column > .tbody > .trow {
            // margin-block-start: -50px;
            // margin-block-end: 50px;
        }

        .fix-column > .tbody > .trow:first-child {
            margin-block-start: 0px;
        }

        .rest-columns {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;

            .trow {
                & td:nth-of-type(1) div {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;

                    .MuiAvatar-root {
                        width: 28px;
                        height: 28px;
                        font-size: 12px;
                    }
                }
            }

            .thead {
                width: calc(100% - 1.1em);
            }

            tbody {
                tr {
                    cursor: default;
                }
            }

            tr {
                &:hover {
                    td {
                        &.last_child {
                            .star_point_btn {
                                visibility: visible;
                                opacity: 1;

                                &.nostarhover {
                                    opacity: 0;
                                    visibility: hidden;
                                    pointer-events: none;
                                }
                            }

                            button.delete {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            td,
            th {
                padding: 12px 10px;
                font-family: var(--font-stack);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: var(--activation_text);
                border-block-end: 1px solid #c9cfda;
                text-align: start;
                word-break: keep-all;

                &.last_child {
                    padding: 5px 0;
                    text-align: center;

                    .response_btn_wrapper {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        justify-content: center;

                        button.delete {
                            color: var(--danger);
                            opacity: 0;
                            visibility: hidden;
                            border-radius: 50%;
                            // background: rgba(217, 99, 103, 0.1);
                            min-width: 30px;
                            min-height: 30px;
                            padding: 0;

                            svg {
                                font-size: 18px;
                            }

                            &:hover {
                                background: rgba(217, 99, 103, 0.2);
                            }

                            .MuiTouchRipple-ripple {
                                .MuiTouchRipple-child {
                                    background-color: rgba(217, 99, 103, 0.8);
                                }
                            }
                        }
                    }

                    &.active {
                        .star_point_btn {
                            opacity: 1;
                            visibility: visible;
                        }

                        button.delete {
                            opacity: 1;
                            visibility: visible;
                        }

                        &.viewStarOnly {
                            .star_point_btn {
                                pointer-events: none;

                                &.nostarhover {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                            button.star_img {
                                background: none;
                            }

                            .star_point {
                                background: none;
                            }

                            button.delete {
                                display: none;
                            }
                        }
                    }

                    .star_point_btn {
                        visibility: hidden;
                        opacity: 0;
                        margin: 0 auto;
                        padding: 0px;
                        justify-content: center;
                        right: 0;

                        .star_img {
                            min-width: 28px;
                            min-height: 28px;
                            padding: 0;

                            img {
                                width: 16px;
                                margin-left: 0.5px;
                            }
                        }

                        .star_point {
                            min-width: 22px;
                            min-height: 22px;
                            padding: 5px;
                            line-height: 13px;
                            white-space: nowrap;
                        }
                    }
                }

                p {
                    font-family: var(--font-stack);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--text_color);
                    text-align: start;
                }

                span {
                    font-family: var(--font-stack);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--text_color);
                    text-align: start;
                    width: inherit;
                    // display: inline-block;
                    // overflow-wrap: break-word;
                    word-break: break-word;

                    &.wrong {
                        * {
                            color: var(--danger);
                        }
                    }

                    &.correct {
                        * {
                            color: #3bba59;
                        }
                    }
                }
            }
        }
    }
}

.fillBlank {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}

.progress_wrap {
    width: 100%;
    text-align: center;

    h5 {
        // text-transform: uppercase;
        font-family: var(--font-stack);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--cpbutton);
        width: 100%;
        margin-block-start: 32px;
    }

    h4 {
        font-family: var(--font-stack);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        color: var(--text_color);
        margin-block-start: 5px;
    }

    .card {
        // margin-block-start: 60px;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        transition: 0.5s ease;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -50%;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.03);
            pointer-events: none;
        }
    }

    .percent {
        position: relative;
        // border-radius: 50%;
        z-index: 1;
        margin-inline: auto;
        margin-block: 0;
        // width: 170px;
        // height: 170px;

        &.val100 {
            width: 230px;
            height: 230px;

            svg {
                width: 230px;
                height: 230px;

                circle {
                    strokewidth: 27px;
                }
            }

            .number h2 {
                font-size: 36px;
            }
        }

        &.val90 {
            width: 210px;
            height: 210px;

            svg {
                width: 210px;
                height: 210px;

                circle {
                    strokewidth: 25px;
                }
            }

            .number h2 {
                font-size: 36px;
            }
        }

        &.val80 {
            width: 190px;
            height: 190px;

            svg {
                width: 190px;
                height: 190px;

                circle {
                    strokewidth: 23px;
                }
            }

            .number h2 {
                font-size: 32px;
            }
        }

        &.val70 {
            width: 170px;
            height: 170px;

            svg {
                width: 170px;
                height: 170px;

                circle {
                    strokewidth: 21px;
                }
            }

            .number h2 {
                font-size: 28px;
            }
        }

        &.val60 {
            width: 150px;
            height: 150px;

            svg {
                width: 150px;
                height: 150px;

                circle {
                    strokewidth: 19px;
                }
            }

            .number h2 {
                font-size: 24px;
            }
        }

        h3 {
            margin-block-start: 16px;
            color: #777;
            font-size: 22px;
            transition: 0.5s ease;
        }

        .number {
            position: absolute;
            top: 0%;
            // transform: translateY(-50%);
            left: 0;
            width: 100%;
            height: 100%;
            // border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                font-family: var(--font-stack);
                font-style: normal;
                font-weight: 600;
                // font-size: 36px;
                line-height: 20px;
                color: #3bba59;
            }
        }

        svg {
            position: relative;
            transform: rotate(-85deg);
            // width: 170px;
            // height: 170px;

            circle {
                width: 100%;
                height: 100%;
                fill: none;
                stroke: #e6e1e5;
                // strokeWidth: 20;
                strokelinecap: round;
                transform: translate(15px, 15px);

                // &:nth-child(2) {
                //     stroke-dasharray: 502;
                //     stroke-dashoffset: 66;
                // }
            }
        }
    }
}

.ans_table_sec {
    width: 100%;

    .ans_table_inner {
        width: 710px;
        margin-inline: auto;
        margin-block: 0;
        height: 450px;
        display: flex;
        align-items: flex-start;
        padding: 44px 0;
        justify-content: center;

        .MuiTableContainer-root {
            border-radius: 16px;
            // overflow: hidden;
            box-shadow: none;
        }

        table {
            thead {
                tr {
                    th {
                        font-weight: 400;
                        font-size: 16px;
                        font-family: var(--font-stack);
                        color: var(--secondary_text);
                        border-block-end: none;
                        text-align: center;
                        height: 56px;
                        padding: 16px 10px;
                        word-break: keep-all;

                        &:nth-of-type(1) {
                            width: 15%;
                        }

                        &:nth-of-type(2) {
                            text-align: start;
                            width: 37%;
                        }

                        &:nth-of-type(3) {
                            text-align: center;
                            width: 18%;
                        }

                        &:nth-of-type(4) {
                            text-align: center;
                            width: 18%;
                        }

                        &:nth-of-type(5) {
                            text-align: center;
                            width: 8%;
                        }
                    }
                }
            }

            tbody {
                tr {
                    // cursor: pointer;
                    &:nth-of-type(odd) {
                        background: var(--parple_08);
                    }

                    // &:hover {
                    //     background: var(--table_hover);
                    // }
                    td {
                        height: 56px;
                        border-block-end: none;
                        text-align: center;
                        font-weight: 400;
                        font-size: 16px;
                        font-family: var(--font-stack);
                        color: var(--text_color);
                        padding: 16px 10px;

                        &:nth-of-type(2) {
                            text-align: start;
                        }

                        .view_btn {
                            @include table_view_btn;
                            width: auto;
                            color: var(--parple);
                            background: rgb(97 118 255 / 8%);
                            white-space: nowrap;

                            &:hover {
                                background: var(--leader_btn_view);
                            }

                            &.Mui-disabled {
                                color: var(--parple);
                                opacity: 0.7;
                                cursor: default;
                            }
                        }

                        .number {
                            color: var(--cpbutton);
                            font-weight: 500;
                            font-size: 16px;
                            font-family: var(--font-stack);
                        }

                        .low {
                            color: var(--danger);
                            font-weight: 500;
                            font-size: 16px;
                            font-family: var(--font-stack);
                        }

                        .mid {
                            color: var(--minus);
                            font-weight: 500;
                            font-size: 16px;
                            font-family: var(--font-stack);
                        }

                        .high {
                            color: var(--user-sucess);
                            font-weight: 500;
                            font-size: 16px;
                            font-family: var(--font-stack);
                        }
                    }
                }
            }
        }
    }
}
