@use './../../Style.scss' as *;

.addingClass {
    width: 100%;
    text-align: center;
    background: var(--background-white);
    background: var(--gradientbg);
    height: 100%;

    .addingClass_inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &.langingSavedClasses {
            .manageClass_src {
                max-width: 565px;

                .participent_grid_inner {
                    @include displayFlex(row, flex-start, space-between);
                    margin-block-start: 30px;
                    gap: 16px;

                    .addClass_box {
                        border: 1px solid #cfd5ff;
                        box-shadow: none;
                        border-radius: 12px;
                        padding: 16px;
                        background: var(--background-white);
                        width: 100%;
                        max-width: 256px;
                        min-height: 144px;
                        @include displayFlex(column, center, center);
                        cursor: pointer;
                        margin-block: 0px;

                        .addClass_icon {
                            width: 100%;
                            text-align: center;

                            svg {
                                font-size: 50px;
                                color: var(--parple);
                            }
                        }

                        .addClass_text {
                            width: 100%;
                            text-align: center;

                            h5 {
                                color: var(--activation_text);
                                font-family: var(--font-stack);
                                font-size: 16px;
                                line-height: 22px;
                                font-weight: 500;
                            }
                        }

                        &:hover {
                            background: var(--parple_08);
                        }
                    }
                }
            }
        }
    }

    .participent_Grid_Card_demo {
        border: 1px solid var(--borderMain);
        box-shadow: none;
        border-radius: 12px;
        padding-inline: 16px;
        padding-block: 12px;
        background: var(--background-white);
        width: 100%;
        max-width: 256px;
        min-height: 144px;
        @include displayFlex(column, flex-start, space-between);
        cursor: pointer;

        &.big_card {
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            max-width: 260px;

            .profile_text {
                flex: 1;

                h4 {
                    min-height: 46px;
                }
            }
        }

        // &.disabled {
        //     opacity: 0.4;
        //     cursor: not-allowed;
        //     pointer-events: none;
        // }

        &:hover {
            background: var(--parple_08);
        }

        &.dragEnabled {
            box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
            cursor: grabbing;
        }

        .participent_grid_top {
            min-height: auto;
            width: 100%;

            .profile {
                display: flex;
                align-items: flex-start;
                gap: 20px;

                .profile_img {
                    width: auto;

                    .MuiAvatar-root {
                        height: 44px;
                        width: 44px;
                        // background: var(--light-bg);
                        outline: 1px solid #cfd5ff7a;
                        font-size: 21px;
                    }
                }
            }
        }

        .profile_text {
            flex: 1;

            h4 {
                color: var(--parple);
                font-family: var(--font-stack);
                font-size: 16px;
                line-height: 21px;
                font-weight: 500;
                text-align: start;
                word-break: break-word;
                min-height: 38px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                /* after 3 line show ... */
                -webkit-box-orient: vertical;
            }
        }

        .p_class_code {
            width: 100%;
            text-align: start;
            margin-block-start: 8px;

            .MuiChip-root {
                background: var(--editview_chip_bg);
                height: 24px;
                padding: 0px 8px;
            }

            .MuiChip-label {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 15px;
                font-weight: 500;
                padding: 0;
                text-transform: uppercase;
            }
        }

        .total_participent {
            width: 100%;
            display: flex;
            gap: 10px;
            align-items: center;
            margin-block-start: 28px;

            svg {
                color: var(--secondary_text);
                font-size: 16px;
            }

            p {
                color: var(--secondary_text);
                font-family: var(--font-stack);
                font-size: 12px;
                line-height: 12px;
                font-weight: 400;
            }

            .groupCount {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                margin-inline-start: auto;

                .groupIcon {
                    font-size: 18px;
                }
            }
        }
    }

    .manageClass_src {
        width: 100%;
        padding: 20px;
        max-width: 500px;
        margin-inline: auto;
        margin-block: 0;

        .manageText {
            width: 100%;
            text-align: center;

            h2 {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 28px;
                font-weight: 500;
            }

            p {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 15px;
                font-weight: 400;
                margin-block-start: 10px;
            }
        }

        .addClass_box {
            padding: 30px 20px;
            width: 100%;
            max-width: 440px;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            background: var(--background-white);
            margin-inline: auto;
            margin-block: 0;
            margin-block-start: 30px;
            cursor: pointer;

            .addClass_icon {
                width: 100%;
                text-align: center;

                svg {
                    font-size: 100px;
                    color: var(--addClassIcon);
                }
            }

            .addClass_text {
                width: 100%;
                text-align: center;

                h5 {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 500;
                }
            }
        }

        .watch_tutorial_btn {
            width: 100%;
            padding-block-start: 24px;

            button {
                @include watch_tutorial;

                .MuiButton-startIcon {
                    margin-inline-start: 0;
                }

                &:hover {
                    background-color: var(--btnhover);
                }
            }
        }
    }

    .stepPanel {
        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;
        background-color: var(--background-white);

        .savedClassContainer {
            width: 100%;
            height: 100%;
            // height: calc(100% - 86px);
            // max-width: 814px;
            margin-inline: auto;
            margin-block: 0;
        }

        .steps_sec {
            @include displayFlex(row, center, flex-start);
            width: 100%;
            max-width: 814px;
            padding-block-start: 30px;
            padding-block-end: 35px;
            margin-inline: auto;
            margin-block: 0;

            &.height_52 {
                height: 56px;
                padding-block: 0;
                justify-content: space-between;
            }

            .stepsContent {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            p {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 16px;
                font-weight: 500;
                text-align: start;
                line-height: 21px;
            }

            .MuiSvgIcon-root {
                color: rgba(99, 120, 255, 0.2);

                .MuiStepIcon-text {
                    fill: var(--text_color);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .MuiStep-root:nth-of-type(1) {
                padding-inline-start: 0;
            }

            .MuiSvgIcon-root.Mui-active {
                font-size: 30px;
                color: var(--parple);

                .MuiStepIcon-text {
                    fill: var(--textWhite);
                }
            }

            .MuiSvgIcon-root.Mui-completed {
                font-size: 30px;
                color: var(--user-sucess);

                .MuiStepIcon-text {
                    fill: var(--textWhite);
                }
            }

            .MuiStepConnector-root {
                width: 40px;
                flex: initial;
            }

            .MuiStepLabel-label {
                color: var(--textblack);
                font-family: var(--font-stack);
                font-size: 14px;
                font-weight: 400;
            }
        }

        .trialFlowBanner {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 11px;

            width: calc(100% - 120px);
            height: 80px;
            margin-block-start: 8px;
            margin-inline: auto;
            background: rgba(99, 120, 255, 0.08);
            border-radius: 12px 12px 0 0;
        }

        .participent_type_head {
            width: 100%;
            max-width: 814px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-inline: auto;
            margin-block: 0;
            margin-block-start: 6px;

            p {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 16px;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 5px;

                svg {
                    font-size: 18px;
                    color: var(--info_color);
                    cursor: pointer;
                }
            }

            .ptype_left {
                p {
                    span {
                        font-size: 16px;
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-weight: 500;
                    }
                }

                .textContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    .maxCount {
                        color: var(--reset_color);
                    }

                    .unlockProBtn {
                        color: var(--primary-color);
                        text-transform: none;

                        padding-block: 5px;
                        padding-inline: 16.5px;
                        border-radius: 100px;
                        border: 1px solid var(--primary-color);
                    }
                }
            }

            .ptype_right {
                p {
                    color: var(--parple);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }

        .participent_table {
            width: 828px;
            margin-block-start: 20px;
            height: 346px;
            overflow: auto;
            padding-inline: 6px 8px;
            margin-inline: auto;

            .infinite-scroll-component {
                padding-inline-end: 10px;
            }

            .participent_table_box {
                display: flex;
                align-items: center;
                padding: 8px 14px;
                height: 46px;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;

                &:nth-of-type(even) {
                    background: var(--background-white);
                }

                .left {
                    flex: 1;
                    display: flex;
                    align-items: center;
                }

                .right {
                    width: auto;
                }

                .points {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    p {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 12px;
                        font-weight: 400;
                        opacity: 0.6;

                        &.danges {
                            color: var(--danger);
                            font-size: 14px;
                            opacity: 1;
                        }
                    }

                    .MuiFormControl-root {
                        // width: 100%;
                        width: 50px;

                        .MuiInput-underline {
                            input {
                                color: var(--activation_text);
                                font-family: var(--font-stack);
                                font-size: 16px;
                                font-weight: 600;
                                text-align: end;

                                &::placeholder {
                                    color: var(--placeholder-color);
                                    opacity: 1;
                                }
                            }

                            &::after {
                                display: none;
                            }

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                .p_number {
                    p {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 14px;
                        font-weight: 400;
                        width: 28px;
                        text-align: start;
                    }
                }

                .profile_img {
                    padding-block: 0;
                    padding-inline-start: 4px;
                    padding-inline-end: 12px;

                    .MuiAvatar-root {
                        width: 28px;
                        height: 28px;
                        font-size: 14px;
                    }
                }

                .profile_name {
                    width: 100%;
                    flex: 1;

                    .MuiFormControl-root {
                        width: 100%;

                        .Mui-error.MuiInput-underline {
                            input {
                                color: var(--danger);
                            }
                        }

                        .MuiInput-underline {
                            input {
                                color: var(--activation_text);
                                font-family: var(--font-stack);
                                font-size: 14px;
                                font-weight: 400;

                                &::placeholder {
                                    color: var(--user_emailtext);
                                    opacity: 1;
                                }
                            }

                            &::after {
                                display: none;
                            }

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .participent_footer {
            width: 100%;
            height: 72px;
            margin-block-start: auto;
            box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);

            .savedClassContainer {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                height: 100%;
                padding: 0;
                max-width: 814px;
                margin-inline: auto;
            }

            .participent_innner_foot {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                width: 100%;
                padding: 16px 0;

                &.center {
                    justify-content: center;
                }

                .addParticipantLaterLink {
                    width: auto;
                    color: var(--parple);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }

                    &.button {
                        text-transform: none;
                        text-decoration: none;

                        padding-block: 10px;
                        padding-inline: 17.5px;
                        background: rgba(99, 120, 255, 0.14);
                    }
                }
            }

            p {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 16px;
                font-weight: 400;
                cursor: pointer;

                &.cursor_default {
                    cursor: default;
                }

                &.danges {
                    color: var(--danger);
                }
            }

            .step_btn {
                display: flex;
                align-items: center;
                gap: 6px;

                button.solid {
                    // min-width: 204px;
                    padding: 10px 48px !important;
                    @include step_btn;

                    &:hover {
                        background-color: var(--btnhover);
                    }

                    .MuiLoadingButton-startIconLoadingStart {
                        display: none;
                    }

                    .MuiLoadingButton-loadingIndicator {
                        position: relative;
                        left: 0;
                        display: flex;
                        padding-inline: 0 5px;
                    }
                }

                button.default_btn {
                    @include step_btn;
                    background: transparent;
                    width: auto;
                    color: var(--parple);

                    &:hover {
                        background: var(--lightbg);
                    }
                }
            }
        }

        .participent_set_sec {
            width: 100%;
            max-width: 814px;
            display: flex;
            align-items: flex-start;
            margin-block-start: 14px;
            padding-block-end: 10px;
            height: 396px;
            // max-width: 814px;
            margin-inline: auto;

            // margin-block: 0;
            .profile_upload {
                width: auto;
                position: relative;

                .MuiAvatar-root {
                    height: 136px;
                    width: 136px;
                    // background: var(--light-bg);
                    outline: 1px solid #cfd5ff7a;
                    font-size: 46px;
                    cursor: pointer;
                }

                .upload_profile_images {
                    height: 136px;
                    width: 136px;
                    background: var(--light-bg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 50%;

                    input {
                        display: none;
                    }

                    label {
                        line-height: 0vh;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        gap: 4px;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                    }

                    span {
                        color: var(--parple);
                        font-size: 14px;
                        line-height: 24px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                    }

                    .MuiSvgIcon-root {
                        font-size: 30px;
                        color: var(--backgroundcolor);
                    }
                }

                .changes_profile_images {
                    position: absolute;
                    bottom: 0;
                    right: 10px;
                    z-index: 2;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    font-size: 20px;
                    box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
                        0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
                    background: var(--background-white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    input {
                        display: none;
                    }

                    label {
                        line-height: 0vh;
                        cursor: pointer;
                    }

                    .MuiSvgIcon-root {
                        font-size: 18px;
                        color: var(--backgroundcolor);
                    }
                }
            }

            .participent_input {
                flex: 1;
                width: auto;
                padding-inline-start: 44px;

                .text_field {
                    height: 65px;
                    margin-block-start: 5px;
                }

                .MuiFormControl-root {
                    width: 100%;

                    p.Mui-error {
                        padding-inline-start: 6px;
                        color: var(--danger);
                        font-size: 12px;
                        line-height: 16px;
                        font-family: var(--font-stack);
                        font-weight: 400;
                        text-align: start;
                    }

                    .MuiInputLabel-root {
                        color: var(--secondary_text);
                        -webkit-transform: translate(6px, 20px) scale(1);
                        -moz-transform: translate(6px, 20px) scale(1);
                        -ms-transform: translate(6px, 20px) scale(1);
                        transform: translate(6px, 20px) scale(1);

                        &.Mui-focused {
                            color: var(--parple);
                        }

                        &.MuiInputLabel-shrink {
                            -webkit-transform: translate(6px, -1.5px) scale(0.75);
                            -moz-transform: translate(6px, -1.5px) scale(0.75);
                            -ms-transform: translate(6px, -1.5px) scale(0.75);
                            transform: translate(6px, -1px) scale(0.75);
                        }
                    }

                    .MuiInput-underline {
                        input {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 16px;
                            font-weight: 400;
                            padding-block-end: 6px;
                            padding-inline-end: 6px;
                            padding-inline-start: 6px;

                            &::placeholder {
                                color: var(--placeholder-color);
                                opacity: 1;
                            }
                        }

                        &.Mui-error {
                            &::after {
                                border-bottom: 1px solid var(--danger);
                            }
                        }

                        &::after {
                            border-bottom: 1px solid var(--parple);
                        }

                        &::before {
                            border-bottom: 1px solid var(--secondary_text);
                        }
                    }
                }
            }
        }

        .participent_grid {
            width: 100%;
            max-width: 828px;
            margin-block-start: 12px;
            margin-block-end: 0px;
            // height: calc(100% - 132px);
            height: calc(100% - 156px);

            overflow: auto;
            padding-inline-end: 8px;
            padding-inline-start: 8px;
            padding-block-end: 15px;
            margin-inline: auto;
            // margin-inline-end: auto;
            // max-width: 814px;

            &.fullHeight {
                height: calc(100% - 68px);
            }

            .participent_grid_inner {
                display: flex;
                align-items: flex-start;
                gap: 13px;
                flex-wrap: wrap;

                width: 100%;
            }

            .addClass_box {
                border: 1px solid #cfd5ff;
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                padding: 16px;
                background: var(--background-white);
                width: 100%;
                max-width: 260px;
                min-height: 152px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;
                margin-block-end: 13px;

                .addClass_icon {
                    width: 100%;
                    text-align: center;

                    svg {
                        font-size: 50px;
                        color: var(--parple);
                    }
                }

                .addClass_text {
                    width: 100%;
                    text-align: center;

                    h5 {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 500;
                    }
                }

                &:hover {
                    background: var(--parple_08);
                }
            }

            .participent_Grid_Card {
                border: 1px solid #cfd5ff;
                box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                padding-inline: 16px;
                padding-block: 12px;
                background: var(--background-white);
                width: 100%;
                max-width: 260px;
                min-height: 144px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                cursor: pointer;

                // margin-block-end: 13px;
                &.disabled {
                    opacity: 0.4;
                    cursor: not-allowed;
                    pointer-events: none;
                }

                &:hover {
                    background: var(--parple_08);
                }

                &.dragEnabled {
                    box-shadow: 0px 28px 20px -12px rgba(0, 0, 0, 0.16), 0px 4px 20px 2px rgba(0, 0, 0, 0.02);
                    cursor: grabbing;
                }

                .profile {
                    display: flex;
                    align-items: flex-start;
                    gap: 20px;
                }

                .profile_img {
                    width: auto;

                    .MuiAvatar-root {
                        height: 44px;
                        width: 44px;
                        // background: var(--light-bg);
                        outline: 1px solid #cfd5ff7a;
                        font-size: 21px;
                    }
                }

                .profile_text {
                    flex: 1;

                    h4 {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 16px;
                        line-height: 21px;
                        font-weight: 500;
                        text-align: start;
                        word-break: break-word;
                        min-height: 46px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* after 3 line show ... */
                        -webkit-box-orient: vertical;
                    }
                }

                .p_class_code {
                    width: 100%;
                    text-align: start;
                    margin-block-start: 8px;

                    .MuiChip-root {
                        background: var(--light-bg);
                        height: 24px;
                        padding: 0px 8px;
                    }

                    .MuiChip-label {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 14px;
                        line-height: 15px;
                        font-weight: 500;
                        padding: 0;
                    }
                }

                .participent_grid_top {
                    min-height: auto;
                }

                .total_participent {
                    width: 100%;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    margin-block-start: 28px;

                    svg {
                        color: var(--secondary_text);
                        font-size: 16px;
                    }

                    p {
                        color: var(--secondary_text);
                        font-family: var(--font-stack);
                        font-size: 12px;
                        line-height: 12px;
                        font-weight: 400;
                    }

                    .groupCount {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;

                        margin-inline-start: auto;

                        .groupIcon {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .view_edit_sec {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-start;

        .edit_side_panal {
            // flex-shrink: 0;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
            width: 254px;
            height: 100%;
            text-align: center;
            padding: 30px 24px;

            .edit_sec_top {
                min-height: 70px;
            }

            .backto_page {
                display: flex;
                align-items: center;
                gap: 8px;
                color: var(--cpbutton);
                cursor: pointer;
                width: fit-content;

                &:hover span {
                    text-decoration: underline;
                }

                span {
                    color: var(--cpbutton);
                    font-size: 14px;
                    font-weight: 400;
                    font-family: var(--font-stack);
                    text-align: start;
                }

                svg {
                    font-size: 16px;
                }
            }

            .editProfile_sec {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                height: calc(100% - 65px);
            }

            .edit_profile_info {
                width: auto;
                position: relative;
                width: 100px;
                margin-inline: auto;
                margin-block: 0;

                .MuiAvatar-root {
                    height: 100px;
                    width: 100px;
                    // background: var(--light-bg);
                    outline: 1px solid #cfd5ff7a;
                    font-size: 40px;
                }

                .upload_profile_images {
                    height: 100px;
                    width: 100px;
                    background: var(--light-bg);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 50%;

                    input {
                        display: none;
                    }

                    label {
                        line-height: 0vh;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        gap: 4px;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                    }

                    span {
                        color: var(--parple);
                        font-size: 14px;
                        line-height: 24px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                    }

                    .MuiSvgIcon-root {
                        font-size: 24px;
                        color: var(--backgroundcolor);
                    }
                }

                .changes_profile_images {
                    position: absolute;
                    bottom: 0;
                    right: 0px;
                    z-index: 2;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    font-size: 20px;
                    box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
                        0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
                    background: var(--background-white);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    input {
                        display: none;
                    }

                    label {
                        line-height: 0vh;
                        cursor: pointer;
                    }

                    .MuiSvgIcon-root {
                        font-size: 18px;
                        color: var(--backgroundcolor);

                        width: 100%;
                        height: 100%;
                        padding: 7px;
                    }
                }
            }

            .profile_info_content {
                width: 100%;
                text-align: center;
                padding-block-start: 20px;

                p {
                    color: var(--secondary_text);
                    font-size: 14px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    opacity: 0.7;
                }

                h4 {
                    color: var(--activation_text);
                    font-size: 16px;
                    line-height: 21px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                    margin-block-start: 6px;
                    padding: 0 10px;
                    word-break: break-word;
                }

                h4 + p {
                    padding-block-start: 15px;
                }

                .chip {
                    background: var(--editview_chip_bg);
                    padding: 5px 12px;
                    border-radius: 30px;
                    color: var(--activation_text);
                    font-size: 14px;
                    line-height: 17px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                    width: fit-content;
                    margin-inline: auto;
                    margin-block: 0;
                    margin-block-start: 8px;
                    min-height: 27px;
                }
            }

            .edit_Delete_sec {
                width: 100%;
            }

            .edit_Delete_sec {
                ul {
                    li {
                        a {
                            display: block;
                            align-items: center;
                            padding-block: 6px;
                            margin-inline: 0;
                            color: var(--cpbutton);
                            font-size: 14px;
                            font-weight: 500;
                            font-family: var(--font-stack);
                            cursor: pointer;

                            &.edit {
                                color: var(--parple);
                            }

                            &:hover {
                                text-decoration: underline !important;
                            }

                            &.editdelete {
                                color: var(--danger);
                            }

                            &.resset {
                                color: var(--reset_color);
                                // border-inline-end: 1px solid var(--borderlight);
                            }

                            &.flex1 {
                                // flex: 1 ;
                                width: 100%;
                            }
                        }

                        // &:first-child {
                        //     border-block-end: 1px solid var(--borderlight);
                        // }
                        &.reset_box {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }

        .view_edit_main {
            width: 100%;
            flex: 1;
            background: var(--background-white);

            &.savedClass {
                height: 100%;
            }

            .loader {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 580px;

                .MuiCircularProgress-root {
                    color: var(--cpbutton);
                }
            }

            .addparticipent_head {
                height: 79px;
                padding-inline: 30px;
                padding-block-start: 22px;
                padding-block-end: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .leftAddparticipent {
                    display: flex;
                    align-items: center;
                }

                .rightAddparticipent {
                    a {
                        display: block;
                        align-items: center;
                        padding: 10px 0;
                        color: var(--cpbutton);
                        font-size: 14px;
                        font-weight: 500;
                        font-family: var(--font-stack);
                        cursor: pointer;
                        color: var(--parple);

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }

                .participant_joined_wrapper {
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    span {
                        padding: 1px 8px;
                        background: #cfd5ff;
                        color: #2a326b;
                        // @include  head_h33;
                        font-size: 14px;
                        line-height: 13px;
                        font-weight: 500;
                        font-family: var(--font-stack);
                        border-radius: 22px;
                        min-height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.red_span {
                            background: var(--red)-span-bg;
                            color: var(--redspan-color);
                        }
                    }
                }

                h2 {
                    // @include  head_h2;
                    font-size: 16px;
                    line-height: 16px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                    color: var(--text_color);
                    margin: 0;
                }

                .addbtn {
                    margin-inline-start: 16px;

                    button {
                        @include step_btn;
                        min-height: 32px;
                        padding-block: 5px;
                        padding-inline-start: 8px;
                        padding-inline-end: 13px;
                        line-height: 15px;

                        .MuiButton-startIcon {
                            margin-inline-end: 2px;
                        }

                        &:hover {
                            background-color: var(--btnhover);
                        }
                    }
                }
            }
        }

        .noParticipantSection {
            width: 100%;
            padding-block-start: 10px;
            padding-block-end: 0;
            height: 506px;
            position: relative;
            background: var(--background-white);

            .noParticipantInnerBox {
                width: 100%;
                padding-block-start: 80px;

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 21px;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                }
            }
        }

        .participent_table {
            width: 100%;
            padding-block-start: 10px;
            padding-block-end: 0;
            height: 506px;
            position: relative;

            &.savedClass {
                height: calc(100% - 79px);
                padding-block: 10px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                width: 96%;
                height: 2px;
                z-index: 11;
                background: var(--background-white);
            }

            &.basicUser {
                padding-block-start: 15px;
            }

            .basicUser {
                min-height: 80px;
                width: 100%;
                @include displayFlex(row, center, center);
                border-top: 1px solid var(--graybar);
                padding-inline-start: 20px;
                padding-inline-end: 20px;
                position: absolute;
                bottom: 0;
                gap: 16px;

                p {
                    font-size: 13px;
                    line-height: 17px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    color: var(--text_color);
                    @include displayFlex(row, center, flex-start);
                    gap: 6px;

                    a {
                        font-size: 13px;
                        line-height: 17px;
                        font-family: var(--font-stack);
                        font-weight: 400;
                        color: var(--parple);
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }

                .unlockProBtn {
                    font-size: 12px;
                    color: var(--parple);
                    text-transform: none;

                    padding-block: 4px;
                    padding-inline: 20px;
                    margin-inline-start: auto;
                    border: 1px solid var(--parple);
                    border-radius: 100px;
                }
            }
        }
    }
}

.csv_upload_modal {
    .csvmodal_box {
        padding: 0 !important;
        position: absolute;
        top: 0%;
        left: 0%;
        bottom: 0;
        right: 0;
        margin: auto;
        height: fit-content;
        // transform: translate(-50%, -50%);
        width: 100%;
        max-width: 340px;
        background-color: #fff;
        box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
            0px 9px 46px 8px rgb(0 0 0 / 12%);
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
        border-radius: 16px;

        &.wdth_400 {
            max-width: 400px;
        }

        &.addparticipent_modal_box {
            max-width: 780px;

            .addparticipent_modal_sec {
                padding-block: 20px 0px;
                // min-height: 380px;

                .addparticipent_head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;

                    .import_download_csv {
                        margin-inline-end: 40px;
                        min-width: 186px;

                        p {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 14px;
                            line-height: 23px;
                            font-weight: 400;
                        }

                        a {
                            color: var(--parple);
                            font-family: var(--font-stack);
                            font-size: 14px;
                            line-height: 23px;
                            font-weight: 500;
                            text-align: end;

                            &.fw400 {
                                font-weight: 400;
                            }

                            &:hover {
                                text-decoration: underline !important;
                            }
                        }
                    }
                }
            }

            .participent_table {
                width: 100%;
                max-height: 240px;
                overflow: auto;
                margin-block: 28px 32px;

                .participent_table_box {
                    margin: 0;
                }
            }
        }

        .addparticipent_modal_sec {
            padding: 20px;
            min-height: 120px;

            p {
                color: var(--text_color);
                font-family: var(--font-stack);
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
            }

            .participent_table_error {
                width: 100%;

                p {
                    padding-inline-start: 6px;
                    color: var(--danger);
                    font-size: 12px;
                    line-height: 16px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                }
            }
        }

        .participent_table_box {
            display: flex;
            align-items: center;
            padding: 8px 14px;
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
            margin-block-end: 4px;
            margin-block-start: 10px;

            &:nth-of-type(even) {
                background: var(--background-white);
            }

            .left {
                flex: 1;
                display: flex;
                align-items: center;
            }

            .right {
                width: auto;

                .adjust_point {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    button {
                        width: 24px;
                        height: 24px;
                        min-width: auto;
                        min-height: auto;
                        background: var(--background-white);
                        text-transform: none;
                        outline: none;
                        box-shadow: none;
                        border: 1px solid var(--parple);
                        padding: 0;
                        color: var(--parple);

                        svg {
                            font-size: 18px;
                        }

                        &:hover {
                            background: var(--parple);
                            color: var(--textWhite);
                        }

                        &.Mui-disabled {
                            opacity: 0.3;
                        }
                    }

                    .new_adjust_point {
                        font-family: var(--font-stack);
                        font-size: 16px;
                        line-height: 18px;
                        color: var(--activation_text);
                        font-weight: 400;
                        min-width: 36px;
                        text-align: center;
                    }
                }

                p {
                    &.danges {
                        color: var(--danger);
                        opacity: 1;
                    }
                }
            }

            .points {
                display: flex;
                align-items: center;
                gap: 6px;

                p {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 12px;
                    font-weight: 400;
                    opacity: 0.6;

                    &.error {
                        color: var(--danger);
                        font-size: 14px;
                        opacity: 1;
                    }
                }

                .MuiFormControl-root {
                    // width: 100%;
                    width: 50px;

                    .MuiInput-underline {
                        input {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 16px;
                            font-weight: 600;
                            text-align: end;

                            &::placeholder {
                                color: var(--placeholder-color);
                                opacity: 1;
                            }
                        }

                        &::after {
                            display: none;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            .p_number {
                p {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 400;
                    width: 28px;
                    text-align: start;
                }
            }

            .profile_img {
                padding: 0px;
                padding-inline-end: 12px;

                .MuiAvatar-root {
                    width: 28px;
                    height: 28px;
                    font-size: 14px;
                }
            }

            .profile_name {
                width: 100%;
                flex: 1;

                .MuiFormControl-root {
                    width: 100%;

                    .MuiInput-underline {
                        input {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 15px;
                            font-weight: 400;

                            &::placeholder {
                                color: var(--user_emailtext);
                                opacity: 1;
                            }
                        }

                        &::after {
                            display: none;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        .participent_input {
            flex: 1;
            width: auto;
            margin-block-start: 10px;
            min-height: 150px;

            .text_field {
                height: 65px;
                margin-block-start: 10px;

                &:first-child {
                    margin-block-start: 5px;
                }
            }

            .MuiFormControl-root {
                width: 100%;

                p.Mui-error {
                    padding-inline-start: 6px;
                    color: var(--danger);
                    font-size: 12px;
                    line-height: 16px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    text-align: start;
                }

                .MuiInputLabel-root {
                    color: var(--secondary_text);
                    -webkit-transform: translate(6px, 20px) scale(1);
                    -moz-transform: translate(6px, 20px) scale(1);
                    -ms-transform: translate(6px, 20px) scale(1);
                    transform: translate(6px, 20px) scale(1);

                    &.Mui-focused {
                        color: var(--parple);
                    }

                    &.MuiInputLabel-shrink {
                        -webkit-transform: translate(0px, -1.5px) scale(0.75);
                        -moz-transform: translate(0px, -1.5px) scale(0.75);
                        -ms-transform: translate(0px, -1.5px) scale(0.75);
                        transform: translate(0px, -1px) scale(0.75);
                    }
                }

                .MuiInput-underline {
                    input {
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 16px;
                        font-weight: 400;
                        padding-block-end: 6px;
                        padding-inline-end: 6px;
                        padding-inline-start: 0px;
                        box-shadow: none;

                        &::placeholder {
                            color: var(--placeholder-color);
                            opacity: 1;
                        }
                    }

                    &.Mui-error {
                        &::after {
                            border-block-end: 1px solid var(--danger);
                        }
                    }

                    &::after {
                        border-block-end: 1px solid var(--parple);
                    }

                    &::before {
                        border-block-end: 1px solid var(--secondary_text);
                    }
                }
            }
        }

        .modal_head {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-inline: 16px;
            padding-block: 8px 8px;
            border-radius: 0;
            border-start-start-radius: 12px;
            border-start-end-radius: 12px;

            &.close {
                position: absolute;
                top: 22px;
                right: 18px;
                padding: 0;
            }

            svg {
                cursor: pointer;
                font-size: 18px;
            }
        }

        .csmodal_content {
            width: 100%;
            padding-inline-start: 24px;
            padding-inline-end: 20px;
            padding-block-start: 24px;
            padding-block-end: 10px;

            p {
                color: var(--text_color);
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;

                a {
                    color: var(--cpbutton);
                }
            }
        }

        .csv_upload_file {
            z-index: 2;
            border-radius: 4px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            cursor: pointer;
            margin-block-start: 20px;
            height: 104px;

            input {
                display: none;
            }

            label {
                line-height: 0vh;
                border-radius: 4px;
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                gap: 8px;
                height: 84px;
                background: linear-gradient(0deg, #f2f3f7, #f2f3f7), #ffffff;

                .MuiInput-underline {
                    display: none;
                }
            }

            p {
                color: var(--text_color);
                font-family: var(--font-stack);
                font-size: 12px;
                line-height: 17px;
                font-weight: 400;
            }

            strong {
                color: var(--primary-color);
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 19px;
                font-weight: 500;
            }

            .sucess_upload {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;

                svg {
                    color: var(--activation_text);
                    font-size: 22px;
                }

                p {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                }

                .cross {
                    width: 20px;
                    height: 20px;
                    background: var(--csvcrossbg);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        font-size: 16px;
                        color: var(--textWhite);
                    }
                }
            }
        }

        .button_sec {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 6px;
            padding: 12px 20px;
            background: var(--background-white);
            border-radius: 0 0;
            border-end-start-radius: 12px;
            border-end-end-radius: 12px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;

            &.justify_center {
                justify-content: center;
            }

            &.justify_space_between {
                justify-content: space-between;
            }

            .participent_table_error {
                width: auto;

                p {
                    padding-inline-start: 6px;
                    color: var(--danger);
                    font-size: 12px;
                    line-height: 16px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                }
            }

            button {
                @include theme_btn;
                padding: 7px 20px !important;
                font-size: 14px;
                color: var(--btnbgcolor);

                &.MuiButton-containedPrimary {
                    color: var(--textWhite);
                    background-color: var(--btnbgcolor) !important;

                    &:hover {
                        background-color: var(--btnhover) !important;
                    }
                }
            }
        }
    }
}

.modal_Box.csvmodal_box {
    padding: 0px !important;
    background-color: #000;
    width: fit-content !important;
    max-width: 736px;
    min-width: 400px;

    .button_sec {
        justify-content: center;

        button.MuiButton-containedPrimary.dark_button {
            color: var(--cpbutton);
            box-shadow: none !important;
            background-color: transparent !important;

            &.reset {
                color: var(--error_span_color);

                &:hover {
                    background: linear-gradient(0deg, rgba(211, 47, 47, 0.2), rgba(211, 47, 47, 0.2)), #ffffff;
                }
            }

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
            }
        }
    }

    .closebtn {
        position: relative;
        width: 100%;
        text-align: end;
        background-color: var(--background-white);
        cursor: pointer;
        padding: 1vh;

        &:hover {
            opacity: 0.3;
        }
    }

    .App {
        background-color: #000;
        height: fit-content;
        min-height: 221px;
        max-height: 341px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 0;
        border-start-start-radius: 16px;
        border-start-end-radius: 16px;
        overflow: hidden;
    }

    .ReactCrop {
        max-height: 320px;
        background-color: #000;
        outline: none;

        .ReactCrop__image {
            width: 100%;
            max-height: 85vh;
            object-fit: contain;
        }
    }
}

.menu_modal {
    width: 100%;

    .menu_overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        display: none;

        &.active {
            display: block;
        }
    }
}

.menu {
    position: fixed;
    top: 50px;
    left: 00px;
    background-color: var(--background-white);
    box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0;
    -webkit-transform-origin: right top 0px;
    transform-origin: right top 0px;
    -webkit-transform: scale(0);
    transform: scale(0);
    z-index: 1000;
    height: fit-content;
    width: fit-content;

    ul {
        li {
            list-style: none;
            width: 100%;

            a {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 9px 15px;
                font-size: 14px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                cursor: pointer;

                img {
                    max-width: 100%;
                }

                svg {
                    font-size: 20px;
                    color: var(--btnbgcolor);
                }

                &:hover {
                    background: var(--parple_08);
                }

                &:active {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                }
            }
        }
    }
}

.table_edit_icon {
    position: relative;

    .lastRowData {
        .menu {
            transform-origin: right bottom 0px;
            top: -12px;
            transform: translateY(-100%) scale(0);

            &.active {
                transform-origin: right bottom 0px;
                transform: translateY(-100%) scale(1);
            }
        }
    }

    .menu {
        position: absolute;
        top: 38px;
        left: inherit;
        right: 0;
    }
}

.menu.active {
    opacity: 1;
    -webkit-transform-origin: right top 0px;
    transform-origin: right top 0px;
    -webkit-transform: scale(1);
    transform: scale(1);
}

button {
    .MuiLoadingButton-startIconLoadingStart {
        display: none;
    }

    .MuiLoadingButton-loadingIndicator {
        position: relative;
        left: 0;
        display: flex;
        padding-inline: 0 5px;
    }
}

.watchTutorialContainer {
    &.white_btn {
        .watchTutorialBtn {
            background: var(--background-white);
            padding-block: 2px;
            padding-inline: 4px 10px;
        }
    }

    .watchTutorialBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        font-family: var(--Inter);
        color: var(--primary-color);
        text-transform: none;

        padding-block: 4px;
        padding-inline: 12px;
        border: 1px solid var(--light-bg);
        border-radius: 100px;
        background: rgba(99, 120, 255, 0.11);
    }

    &:hover {
        .watchTutorialBtn {
            background: var(--light-bg);
        }
    }

    &.savedClassWelcome {
        padding-block-start: 40px;

        .watchTutorialBtn {
            gap: 6px;
            color: var(--textWhite);
            margin: auto;
            border: transparent;
            padding-block: 4px;
            background: var(--primary-color);
        }

        &:hover {
            .watchTutorialBtn {
                background: var(--btnhover);
            }
        }
    }
}

.modal_viewport.savedClassParticipantDetailsEdit {
    .csvmodal_box {
        .modal_head {
            justify-content: space-between;
            padding-block: 18px 8px;
            padding-inline: 22px;
        }

        .addparticipent_modal_sec {
            min-height: 0;

            .participantDetails {
                display: flex;
                align-items: center;
                justify-content: center;

                .profile_img {
                    padding: 0px;
                    padding-block: 8px 18px;
                    padding-inline-end: 15px;
                }

                .participent_table_box {
                    flex-grow: 1;

                    margin-block-end: 20px;
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
                }
            }
        }

        .button_sec {
            background: var(--btnbgcolor_light);

            button {
                min-height: 0 !important;
                padding: 6px 35px !important;
            }
        }
    }
}
