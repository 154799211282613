@use '../../Style.scss' as *;

.tutorialComponent {
    width: 100%;
    height: 100%;
    background: var(--New_Welcome_bg);

    .new_Welcome_inner_sec {
        width: 100%;
        height: 100%;
        @include displayFlex(column, center, center);

        .new_welcome_content {
            width: 100%;
            // max-width: 580px;
            max-width: 700px;
            margin-inline: auto;
            height: fit-content;
            text-align: center;

            .title {
                font-size: 24px;
                font-weight: 500;
                color: var(--text_color);
                font-family: var(--font-stack);
            }

            .description {
                font-size: 16px;
                line-height: 24px;
                color: var(--activation_text);
                font-family: var(--font-stack);
                margin-block-start: 8px;

                padding-inline: 40px;
            }

            .navigation_grid_box {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                // gap: 16px;
                gap: 20px;
                padding-block: 28px 44px;

                .grid_box {
                    position: relative;
                    width: 100%;
                    height: min-content;
                    padding: 0;
                    cursor: pointer;
                    @include displayFlex(column, center, center);
                    text-transform: none;

                    .icon {
                        display: flex;
                        min-height: 40px;
                    }

                    .title {
                        color: var(--text_color);
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 19px;
                        font-family: var(--font-stack);
                        margin-block-start: 20px;
                    }

                    &:hover {
                        background: none;
                    }

                    .MuiTouchRipple-ripple {
                        .MuiTouchRipple-child {
                            background-color: var(--RippleBg_color2);
                        }
                    }

                    .thumbnail_box {
                        position: relative;

                        .videoThumbnail {
                            width: 100%;
                            border-radius: 16px;
                        }

                        .overlay {
                            position: absolute;
                            inset: 0 0 0 0;
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                            background: var(--parple);
                            transition: 0.2s all linear;
                            @include displayFlex(row, center, center);
                            margin: auto;
                            opacity: 0.4;

                            .playIcon {
                                font-size: 20px;
                                color: var(--textWhite);
                                margin: auto;
                            }
                        }
                    }

                    &:hover {
                        .overlay {
                            transform: scale(1.2);
                            opacity: 1;
                        }
                    }
                }
            }

            .link_group {
                width: 100%;
                @include displayFlex(row, center, center);
                gap: 15px;

                .link {
                    color: var(--parple);
                    font-size: 14px;
                    line-height: 21px;
                    font-family: var(--font-stack);
                    margin-block-start: 8px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                .watchAllBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    line-height: 19px;
                    color: var(--parple);
                    text-transform: none;
                    padding: 6px 15px;
                    border: 1px solid var(--light-bg);
                    border-radius: 1000px;
                    background: none;
                }
            }
        }
    }
}
