@use '../../Style.scss' as *;

.row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-block: 0;
    margin-inline: -14px;
}

.col6 {
    width: 50%;
    padding-inline: 14px;
}

.col12 {
    width: 100%;
    padding-inline: 14px;
}

.col12 {
    position: relative;

    &.profile {
        .pf_form {
            .MuiSvgIcon-root {
                margin-inline-start: auto;
            }
        }
    }

    .jobRoleOverlay {
        position: absolute;
        inset: -300px;

        width: 1000px;
        height: 1000px;
        display: none;

        &.show {
            display: block;
        }
    }
}

.setting_body {
    width: 100%;
    display: flex;
    flex: 1 1;
    height: 100%;

    :is(*, *::after, *::before) {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
    }

    .error_span {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        font-family: var(--font-stack);
        color: var(--red);
    }

    .error {
        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            font-family: var(--font-stack);
            color: var(--red);
        }
    }

    .loading {
        background: rgba(0, 0, 0, 0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1110;
    }

    > .settingsContainer {
        display: flex;
        flex-direction: column;

        background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;

        > .logOutBtn {
            flex-grow: 0;
            flex-shrink: 0;

            color: var(--danger);
            text-transform: none;

            height: max-content;
            min-height: 48px;
            margin-block-start: auto;
            padding-block: 10px 8px;
            border-radius: 0;
            transition: all 0.1s linear;

            &::before {
                content: '';

                position: absolute;
                inset: 0 0 auto 0;

                width: calc(100% - 36px);
                height: 1px;
                margin-inline: auto;
                background: var(--graybar);
            }

            &:hover {
                color: var(--textWhite);
                background: var(--submission_btn);

                &::before {
                    background: none;
                }
            }
        }
    }

    .MuiTabs-vertical {
        width: 258px;
        min-width: 258px;
        height: max-content;
        border: none;

        .MuiTabs-flexContainer {
            gap: 0px;
        }

        .MuiTabs-indicator {
            display: none;
        }

        .MuiButtonBase-root.MuiTab-root {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            gap: 13px;
            min-height: 42px;
            border-radius: 0;
            padding-block: 0;
            padding-inline-start: 28px;
            padding-inline-end: 18px;
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            font-family: var(--font-stack);
            transition: var(--transition);
            -webkit-transition: var(--transition);
            -moz-transition: var(--transition);
            color: var(--cpbutton);
            text-transform: capitalize;

            .not_pro {
                padding: 4px;
                background: var(--parple);
                border: 1px solid var(--parple);
                font-size: 10px;
                font-weight: 500;
                font-family: var(--font-stack);
                color: var(--textWhite);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
            }

            svg {
                margin: 0;
                width: 24px;

                path {
                    fill: var(--parple);
                }
            }

            &:is(.Mui-selected) {
                color: var(--textWhite);
                background: var(--cpbutton);
                text-transform: capitalize;

                svg {
                    path {
                        fill: var(--textWhite);
                    }
                }

                &:hover {
                    color: var(--textWhite);
                    background: var(--cpbutton);
                }

                .not_pro {
                    border: 1px solid var(--textWhite);
                }
            }

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
            }
        }

        & + * {
            flex: 1;
            width: 100%;
        }
    }

    .tabbody {
        flex: 1;
        background: var(--background-white);
        position: relative;

        & > div {
            height: 100%;
        }

        .loading {
            background: var(--background-white);
            position: relative;
            min-height: 580px;
        }

        .body_head {
            padding: 17px 30px;
            background: var(--background-white);
            display: flex;
            align-items: center;
            min-height: 66px;

            &.pad_inline_0 {
                padding-inline: 0;
                margin-block-end: 12px;
            }

            &.justify_space_between {
                justify-content: space-between;
            }

            &.box_shadow {
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
            }

            .watch_tutorial_btn {
                @include watch_tutorial_btn;
                max-width: min-content;

                & .MuiButton-startIcon {
                    margin-left: 0;
                    margin-right: 5px;

                    svg {
                        display: flex;
                        font-size: 19px;
                    }
                }

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
                }
            }

            h2 {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                font-family: var(--font-stack);
                color: var(--activation_text);
            }

            .addbtn {
                margin-inline-start: 16px;

                button {
                    @include step_btn;
                    // padding: 5.5px 13px 5.5px 8px;
                    padding-block: 5.5px;
                    padding-inline-end: 13px;
                    padding-inline-start: 8px;
                    line-height: 15px;
                    min-height: 32px;

                    .MuiButton-startIcon {
                        margin-inline-end: 2px;
                    }

                    &:hover {
                        background-color: var(--btnhover);
                    }
                }
            }
        }

        // .whiteBoard_landingpage {
        //     width: 100%;
        //     padding-block-start: 35px;

        //     .container {
        //         width: 100%;
        //         margin-inline: auto;
        //         margin-block: 0;
        //         padding: 0 8px;
        //     }

        //     .inner_whiteBoard_landingpage {
        //         width: 100%;
        //         text-align: center;
        //     }

        //     .info_content {
        //         width: 100%;
        //         margin-inline: auto;
        //         margin-block: 0;
        //         max-width: 400px;

        //         h3 {
        //             font-size: 16px;
        //             line-height: 20px;
        //             font-weight: 500;
        //             font-family: var(--font-stack);
        //             color: var(--parple);
        //         }

        //         p {
        //             font-size: 14px;
        //             line-height: 20px;
        //             font-weight: 400;
        //             font-family: var(--font-stack);
        //             color: var(--activation_text);
        //             margin-block-start: 12px;
        //         }
        //     }
        // }
    }

    .subcription_bottom {
        width: 100%;
        @include displayFlex(column, flex-start, center);
        min-height: 66px;
        margin-block-end: 12px;
    }

    .backto_page {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        color: var(--cpbutton);
        width: auto;
        cursor: pointer;
        padding: 0 0;

        // padding-block-start: 24px;
        // padding-inline-end: 32px;
        &:hover span {
            text-decoration: underline;
        }

        span {
            color: var(--cpbutton);
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            font-family: var(--font-stack);
        }

        svg {
            font-size: 16px;
        }
    }

    .subcription_big_btn {
        width: 100%;
        max-width: 540px;

        &.margin_16px {
            button {
                margin-block-start: 16px;
            }
        }

        button {
            @include green_sub_btn;
            margin-block-start: 32px;

            &:hover {
                background: var(--green_btn_hover);
            }
        }
    }

    .subcription_big_btn + .MuiDivider-root {
        border-color: var(--light-bg);
        width: 100%;
        max-width: 540px;
        margin-block: 20px 6px;
        margin-inline: 0;
    }

    .subject_sec {
        width: 100%;
        padding-inline-start: 8px;

        .error_span {
            padding-inline-start: 16px;
        }

        .subject_head {
            width: 100%;
            margin-block-end: 7px;

            h5 {
                color: var(--user_emailtext);
                font-family: var(--font-stack);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }

        .subject_checkbox {
            width: 100%;

            // padding-inline-start: 5px;
            .MuiFormControl-root {
                margin: 0;
            }

            .MuiFormControlLabel-root {
                margin: 0;
            }

            .MuiFormGroup-root {
                flex-direction: row;
            }

            .checkbox {
                width: 50%;
                float: left;
            }
        }
    }

    .how_to_started {
        width: 100%;
        text-align: center;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            color: var(--parple);
            gap: 5px;
            margin-block-start: 8px;
            font-family: var(--font-stack);
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            margin-inline: auto;
            height: 32px;
            cursor: pointer;

            svg {
                display: flex;
                font-size: 18px;
                color: var(--parple);
            }

            & > span {
                font-family: var(--font-stack);
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
            }
        }
    }

    .profile_update_btn {
        width: 100%;
        padding-block-start: 18px;

        &.flex {
            display: flex;
            gap: 8px;
        }
        &.row2 {
            @include displayFlex(column, flex-start, flex-start);
            padding-block-start: 16px;
            gap: 15px;
            .link {
                color: var(--parple);
                font-size: 14px;
                line-height: 21px;
                font-family: var(--font-stack);
                font-weight: 400;
                cursor: pointer;
                @include displayFlex(row, center, center);
                gap: 9px;
                margin-inline-start: 16px;

                &:hover {
                    text-decoration: underline !important;
                }

                &.red {
                    color: var(--danger) !important;

                    a {
                        color: var(--danger) !important;
                    }
                }
            }
        }
        & + .profile_update_btn {
            padding-block-start: 16px;
            button {
                &.danger_btn {
                    background: none;
                    color: var(--danger);

                    &:hover {
                        background: none;
                    }
                }
            }
        }

        button {
            @include profile_update_btn;
            background: var(--btnbgcolor);
            max-width: fit-content;
            min-width: 180px !important;
            padding: 8px 16px !important;

            .MuiButton-startIcon {
                margin-left: 0;
                margin-right: 0;
                margin-inline-end: 6px;
            }

            &.btn_no_bg {
                background: transparent;
                color: var(--activation_text);
                cursor: default;
                pointer-events: none;
                width: fit-content;
                padding: 0 !important;
                min-width: auto !important;

                .MuiLoadingButton-loadingIndicator {
                    padding-inline-end: 8px;
                }

                &:hover {
                    background: none;
                }
            }

            &:hover {
                background: var(--btnhover);
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: 0;
                display: flex;
                padding-inline: 0 5px;

                .MuiCircularProgress-root svg {
                    height: auto !important;
                }
            }

            &.danger_btn {
                background: var(--footer_btn);

                &:hover {
                    background: var(--no_more_red);
                }
            }

            &.cancel {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;
                min-width: 120px;
                max-width: fit-content;
                color: var(--parple);

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
                }
            }

            &.reset {
                background: var(--reset_light_bg);
                min-width: 120px;
                max-width: fit-content;
                color: var(--reset_color);

                &:hover {
                    background: var(--reset_hover_bg);
                }

                .MuiButton-startIcon {
                    margin: 0;
                    margin-inline-end: 2px;
                }
            }
        }
    }

    .first_subscription {
        width: calc(100% - 290px);
        position: fixed;
        bottom: 0px;
        background: var(--background-white);
        min-height: 48px;
        @include displayFlex(row, center, flex-start);

        &.verified {
            background: var(--light_green_bg);
            border-top: none;
            justify-content: center;
            width: calc(100% - 256px);
            left: 256px;
            min-height: 36px;

            p {
                a {
                    color: var(--green_btn_text);
                }
            }
        }

        .MuiDivider-root {
            border-color: var(--light-bg);
            margin-block-end: 12px;
            margin-inline: 0;
        }

        p {
            font-family: var(--font-stack);
            color: var(--activation_text);
            text-align: start;
            line-height: 21px;
            font-size: 14px;
            font-weight: 400;
            word-break: break-word;

            a {
                font-family: var(--font-stack);
                color: var(--parple);
                text-align: start;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }

    .subscribe_btn {
        width: 100%;
        padding-block-start: 26px;
        display: flex;
        align-items: center;
        gap: 8px;

        button {
            @include profile_update_btn;
            background-color: var(--btnbgcolor) !important;
            min-height: 40px !important;
            min-width: 180px !important;
            max-width: fit-content !important;

            &:hover {
                background-color: var(--btnhover) !important;
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: 0;
                display: flex;
                padding-inline: 0 5px;
            }

            &.btnlink {
                background: none !important;
                color: var(--parple) !important;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.learnmore {
    width: 100%;
    margin-block-start: 24px;

    button {
        @include theme_btn;
        padding: 7px 20px !important;
        font-size: 14px;
        color: var(--btnbgcolor);
        min-height: 32px !important;
        min-width: 113px !important;

        &.MuiButton-containedPrimary {
            color: var(--textWhite);
            background-color: var(--btnbgcolor) !important;

            &:hover {
                background-color: var(--btnhover) !important;
            }
        }

        .MuiLoadingButton-startIconLoadingStart {
            display: none;
        }

        .MuiLoadingButton-loadingIndicator {
            position: relative;
            left: 0;
            display: flex;
            padding-inline: 0 5px;
        }
    }
}

// whiteboard
// .whiteBoard_landingpage_btn_group {
//     display: flex;
//     align-items: center;
//     margin-block-start: 5px;
//     padding: 0 20px;
//     justify-content: center;
//     margin-block-start: 32px;
//     gap: 16px;

//     .imagesbtn {
//         width: 100%;
//         max-width: 180px;

//         input {
//             display: none;
//         }
//     }

//     button {
//         @include whiteBoard_landingpage_btn;
//         flex-direction: column;
//         gap: 18px;
//         background: linear-gradient(0deg, rgba(99, 120, 255, 0.1), rgba(99, 120, 255, 0.1)), #ffffff;

//         &:hover {
//             background: linear-gradient(0deg, rgba(99, 120, 255, 0.14), rgba(99, 120, 255, 0.14)), #ffffff;
//         }

//         svg {
//             height: 24px;
//         }

//         .MuiButton-startIcon {
//             transform: rotate(-45deg) translateX(1px);
//         }

//         .MuiTouchRipple-ripple {
//             .MuiTouchRipple-child {
//                 background-color: var(--Ripplebg_color);
//             }
//         }

//         &.Mui-disabled {
//             color: var(--activation_text);
//         }
//     }
// }

// .whiteboard_Image_sec {
//     width: 100%;

//     .container {
//         width: 100%;
//         padding: 0 8px;

//         .inner_whiteboard_img {
//             width: 100%;
//             overflow: hidden auto;
//             height: 465px;
//             display: flex;
//             align-items: flex-start;
//             justify-content: center;
//             padding-inline-start: 10px;
//             padding-block-start: 12px;

//             .fallBackImage {
//                 min-height: 104px;
//             }

//             .MuiMasonry-root {
//                 align-content: flex-start;
//             }

//             .images_boxs {
//                 border: 1px solid var(--borderlight);
//                 box-shadow: none !important;

//                 .big_sh_body {
//                     height: calc(100% - 32px);
//                     cursor: default;
//                 }

//                 .big_sh_body + img {
//                     height: 104px;
//                     border-radius: 0;
//                     border-start-start-radius: 12px;
//                     border-start-end-radius: 12px;
//                     object-fit: cover;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     background: var(--background-white);
//                     width: 100%;
//                 }

//                 .loading_img {
//                     height: 104px;
//                     width: 100%;
//                     border-radius: 0;
//                     border-start-start-radius: 12px;
//                     border-start-end-radius: 12px;
//                     background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;

//                     svg {
//                         height: 24px;
//                         color: var(--textWhite);
//                         display: flex;
//                     }
//                 }
//             }

//             .sh_footer {
//                 min-height: 32px;
//                 padding-block: 3.5px;
//                 padding-inline-start: 12px;
//                 padding-inline-end: 4px;

//                 .profile_foot {
//                     p {
//                         line-height: 19px;
//                     }

//                     .MuiInput-underline {
//                         input {
//                             color: var(--activation_text);
//                             font-family: var(--font-stack);
//                             font-size: 13px;
//                             font-weight: 400;
//                             padding-block-end: 2px;
//                             padding-inline-end: 0px;
//                             padding-inline-start: 0px;
//                             padding-block-start: 0;

//                             &::placeholder {
//                                 color: var(--placeholder-color);
//                                 opacity: 1;
//                             }
//                         }

//                         &.Mui-error {
//                             &::after {
//                                 border-block-end: 1px solid var(--danger);
//                             }
//                         }

//                         &::after {
//                             border-block-end: 1px solid var(--parple);
//                         }

//                         &::before {
//                             border-block-end: 1px solid var(--secondary_text);
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// .whiteboardupload_modal {
//     padding: 0 !important;
//     position: absolute;
//     top: 0%;
//     left: 0%;
//     bottom: 0;
//     right: 0;
//     margin: auto;
//     height: fit-content;
//     // transform: translate(-50%, -50%);
//     width: fit-content;
//     max-width: 465px;
//     min-width: 352px;
//     background-color: #fff;
//     box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
//         0px 9px 46px 8px rgb(0 0 0 / 12%);
//     box-shadow: none !important;
//     border: none !important;
//     outline: none !important;
//     border-radius: 16px;

//     &.wdth_400 {
//         max-width: 400px;
//         min-width: 400px;
//     }

//     .whiteBoard_landingpage_btn_group {
//         margin-block-start: 0;
//         padding: 32px;
//     }

//     .upload_image_modal {
//         padding: 16px;

//         .closebtn {
//             width: 100%;
//             display: flex;
//             align-items: flex-end;
//             justify-content: flex-end;

//             svg {
//                 font-size: 18px;
//                 cursor: pointer;
//             }
//         }

//         .img_upload_warp {
//             height: 234px;
//             margin-block-start: 6px;
//             background: var(--background-white);
//             border-radius: 12px;
//             overflow: hidden;
//             width: fit-content;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             min-width: 320px;
//             max-width: 420px;
//             border: 1px solid var(--borderlight);

//             img {
//                 object-fit: cover;
//                 min-height: 234px;
//                 max-height: 234px;
//                 width: 100%;
//             }
//         }
//     }

//     .whiteboard_field {
//         width: 100%;
//         margin-block-start: 7px;
//         min-height: 62px;

//         .MuiFormControl-root {
//             width: 100%;

//             p.Mui-error {
//                 padding-inline-start: 0px;
//                 color: var(--danger);
//                 font-size: 12px;
//                 line-height: 16px;
//                 font-family: var(--font-stack);
//                 font-weight: 400;
//             }

//             .MuiInputLabel-root {
//                 color: var(--text-gray);
//                 -webkit-transform: translate(0px, 18px) scale(1);
//                 -moz-transform: translate(0px, 18px) scale(1);
//                 -ms-transform: translate(0px, 18px) scale(1);
//                 transform: translate(0px, 18px) scale(1);
//                 font-size: 14px;
//                 font-family: var(--font-stack);

//                 &.Mui-focused {
//                     color: var(--parple);
//                 }

//                 &.MuiInputLabel-shrink {
//                     -webkit-transform: translate(0px, 3px) scale(0.75);
//                     -moz-transform: translate(0px, 3px) scale(0.75);
//                     -ms-transform: translate(0px, 3px) scale(0.75);
//                     transform: translate(0px, 3px) scale(0.75);
//                 }
//             }

//             .MuiInput-underline {
//                 input {
//                     color: var(--activation_text);
//                     font-family: var(--font-stack);
//                     font-size: 14px;
//                     font-weight: 400;
//                     padding-block-end: 6px;
//                     padding-inline-end: 6px;
//                     padding-inline-start: 0px;
//                     padding-block-start: 0;

//                     &::placeholder {
//                         color: var(--placeholder-color);
//                         opacity: 1;
//                     }
//                 }

//                 &.Mui-error {
//                     &::after {
//                         border-block-end: 1px solid var(--danger);
//                     }
//                 }

//                 &::after {
//                     border-block-end: 1px solid var(--parple);
//                 }

//                 &::before {
//                     border-block-end: 1px solid var(--secondary_text);
//                 }
//             }
//         }
//     }

//     .button_sec {
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         gap: 6px;
//         margin-block-start: 6px;

//         &.justify_center {
//             justify-content: center;
//         }

//         button {
//             @include theme_btn;
//             padding: 7px 20px !important;
//             font-size: 14px;
//             color: var(--btnbgcolor);
//             min-height: 35px !important;
//             min-width: 118px !important;

//             &.MuiButton-containedPrimary {
//                 color: var(--textWhite);
//                 background-color: var(--btnbgcolor) !important;

//                 &:hover {
//                     background-color: var(--btnhover) !important;
//                 }
//             }

//             .MuiLoadingButton-startIconLoadingStart {
//                 display: none;
//             }

//             .MuiLoadingButton-loadingIndicator {
//                 position: relative;
//                 left: 0;
//                 display: flex;
//                 padding-inline: 0 5px;
//             }
//         }
//     }
// }

.classpoint_about {
    padding: 12px 32px;
    width: 100%;
    min-height: 514px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    .media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .update_img {
            width: 88px;
            padding: 0px 0 0;
            margin-inline-end: 32px;

            img {
                width: 100%;
            }
        }

        .media_body {
            flex: 1;

            .update_content {
                width: 100%;

                h2 {
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    text-align: start;
                    line-height: 24px;
                    font-size: 24px;
                    font-weight: 500;
                }

                h4 {
                    font-family: var(--font-stack);
                    color: var(--parple);
                    text-align: start;
                    line-height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    margin-block-start: 4px;
                }

                .restart {
                    font-family: var(--font-stack);
                    color: var(--reset_color);
                    text-align: start;
                    line-height: 19px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }

    .about_info {
        width: 100%;
        padding-block-start: 28px;

        p {
            font-family: var(--font-stack);
            color: var(--activation_text);
            text-align: start;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .social_icon {
        width: 100%;
        padding-block-start: 30px;

        ul {
            display: flex;
            align-items: center;
            gap: 20px;

            li {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--parple);
                    cursor: pointer;
                }

                svg {
                    fill: var(--parple);
                    height: 20px;
                }
            }
        }
    }

    .classpoint_inner {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .footer_copyright {
        padding: 8px;
        width: 100%;
        text-align: center;

        p {
            font-family: var(--font-stack);
            color: var(--greytext_color);
            line-height: 16px;
            font-size: 12px;
            font-weight: 400;
        }
    }
}

// slideshow_sec
.slideshow_sec {
    padding: 12px 32px;
    width: 100%;

    .dynamic_slide_img {
        width: 358px;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.08);
        display: flex;

        .toolbar {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: flex;
        }

        .sidecode {
            position: absolute;
            top: 4px;
            right: 4px;
            display: flex;
            align-items: center;
            gap: 3px;

            img {
                max-width: 100%;
            }

            span {
                width: 10px;
                font-family: var(--font-stack);
                color: var(--textWhite);
                line-height: 4px;
                font-size: 4px;
                font-weight: 500;
            }

            p {
                font-family: var(--font-stack);
                color: var(--textWhite);
                line-height: 7px;
                font-size: 7px;
                font-weight: 500;
            }
        }

        img {
            width: 100%;
        }
    }

    .MuiDivider-root {
        border-color: var(--light-bg);
        width: 80%;
        margin-block: 18px;
        margin-inline: 0;
    }

    .slide_option {
        width: 100%;
        padding-block-start: 20px;
    }
}

// level_sec
.level_sec {
    padding: 12px 32px;
    width: 100%;

    .level_top_content {
        width: 100%;

        p {
            font-family: var(--font-stack);
            color: var(--activation_text);
            text-align: start;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .how_it_work {
        width: 100%;
        padding-block-start: 24px;

        button {
            @include watch_tutorial;
            padding: 5px 10px;

            .MuiButton-startIcon {
                margin-inline-start: 0;
            }

            &:hover {
                background-color: var(--btnhover);
            }
        }
    }

    .level_display_sec {
        width: 100%;
        padding-block-start: 20px;

        // min-height: 375px;
        & + .profile_update_btn {
            padding-block-start: 15px;
        }

        .level_error {
            display: block;
            padding-block-start: 5px;
            min-height: 28px;
        }

        .level_wrapper {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 8px 7px;
        }

        .level_card {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 10px 12px;
            border-radius: 12px;
            border: 1px solid var(--borderlight);
            width: 114px;
            min-height: 154px;
            position: relative;

            &.add_btn {
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .add_btn_icon {
                    width: 100%;
                    text-align: center;

                    svg {
                        color: var(--parple);
                        font-size: 34px;
                    }
                }

                .add_btn_txt {
                    width: 100%;
                    text-align: center;

                    p {
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                        line-height: 22px;
                        font-size: 16px;
                        font-weight: 500;
                        word-break: break-word;
                    }
                }
            }

            .close {
                position: absolute;
                top: 4px;
                right: 4px;
                cursor: pointer;

                svg {
                    display: flex;
                    font-size: 20px;
                    color: var(--footer_btn);
                }
            }

            .level_title {
                width: 100%;
                text-align: center;

                h4 {
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    line-height: 22px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .level_icon {
                width: 100%;
                text-align: center;
                margin-block-start: 8px;
                display: flex;
                justify-content: center;

                img {
                    width: 52px;
                }
            }
        }

        .level_input {
            width: 100%;
            text-align: center;
            margin-block-start: 10px;

            .level_star_number {
                min-height: 32px;
                @include displayFlex(row, center, center);
                width: 100%;
                gap: 5px;

                & > img {
                    width: 20px;
                }

                p {
                    color: var(--text_color);
                    font-family: var(--font-stack);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            .MuiOutlinedInput-root {
                width: 52px;
                text-align: center;
                border-radius: 4px;

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }

                &.Mui-error {
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid var(--danger);
                    }

                    input {
                        color: var(--danger);
                    }
                }

                &.Mui-disabled {
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid transparent !important;
                    }

                    input {
                        -webkit-text-fill-color: var(--text_color);
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border: 1px solid var(--borderlight);
                }

                // &:hover {
                //     .MuiOutlinedInput-notchedOutline {
                //         border: 1px solid currentColor;
                //     }
                // }
                &.Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid var(--backgroundcolor);
                    }

                    &.Mui-error {
                        input {
                            color: var(--text_color);
                        }
                    }
                }

                &:active {
                    background: transparent !important;

                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid var(--backgroundcolor);
                    }
                }

                &:focus {
                    outline: 0;
                    border: 1px solid var(--btnbgcolor);
                }
            }

            input {
                color: var(--text_color);
                font-family: var(--font-stack);
                font-size: 16px;
                font-weight: 400;
                padding: 0;
                text-align: center;

                &::placeholder {
                    color: var(--placeholder-color);
                    opacity: 1;
                }
            }

            .inactive input {
                &::selection {
                    background: transparent;
                }
            }
        }
    }
}

.checkbox {
    &.qnaOption {
        padding-block-start: 10px;
    }

    .Mui-disabled {
        .MuiCheckbox-root {
            opacity: 0.4;
        }
    }

    .MuiTypography-body1 {
        font-family: var(--font-stack);
        color: var(--activation_text) !important;
        line-height: 16px;
        font-size: 14px;
        font-weight: 400;
        padding-inline-start: 5px;
    }

    .caption {
        font-family: var(--font-stack);
        color: var(--greytext_color);
        line-height: 13px;
        font-size: 13px;
        font-weight: 400;
        display: block;
        padding-inline-start: 29px;
    }

    .MuiCheckbox-root {
        color: var(--placeholder-color);
        padding: 5px;

        &.Mui-checked {
            color: var(--parple);
        }

        svg {
            position: relative;
            top: -1px;
        }
    }
}

.profile_form + .backto_page {
    padding-inline-start: 32px;
}

.profile_form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 24px 32px 12px 32px;

    &.min_h_435 {
        min-height: 435px;
        padding-block-start: 12px;
    }

    .profile_left {
        flex: 1;

        &.user_profile_box {
            .profile_update_btn {
                padding-inline-end: 28px;

                button {
                    min-width: 150px !important;
                }
            }
        }

        .listItemContainer {
            display: none;

            &.show {
                position: absolute;
                inset: 46px 0 auto auto;
                z-index: 2;
                display: inline-block;
                width: 100%;
                height: fit-content;
                background: var(--background-white);
                box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);

                & > button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 6px;

                    color: var(--backgroundcolor);
                    border-radius: 0;
                    width: 100%;
                    height: 36px;
                    padding: 5px 16px;
                    background: var(--background-white);

                    &:hover {
                        background: rgba(99, 120, 255, 0.08);
                    }

                    &.active {
                        background: rgba(99, 120, 255, 0.08);
                    }

                    &:nth-of-type(1) {
                        border-radius: 0;
                        border-start-start-radius: 4px;
                        border-start-end-radius: 4px;
                    }

                    &:nth-last-of-type(1) {
                        border-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            font-size: 20px;
                            font-weight: 500;
                            color: var(--parple);
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: none;
                        color: var(--activation_text);
                    }
                }
            }
        }

        .progressContainer {
            padding-block-start: 45px;

            .label {
                font-size: 12px;
                font-weight: 500;
                line-height: 15.84px;
                color: #49454f;
                letter-spacing: 1px;

                padding-block-end: 8px;
            }

            .progressBar {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                align-items: center;
                justify-content: center;

                width: 348px;
                height: 8px;
                border-radius: 100px;

                .progressPart {
                    position: relative;

                    width: 70px;
                    height: 100%;
                    border: 2px solid #ffffff;
                    border-radius: 100px;
                    background: hsla(231.9, 100%, 69.4%, 0.11);

                    &:nth-of-type(1) {
                        margin-inline-start: 0;
                        z-index: 5;
                    }

                    &:nth-of-type(2) {
                        margin-inline-start: -5px;
                        z-index: 4;
                    }

                    &:nth-of-type(3) {
                        margin-inline-start: -10px;
                        z-index: 3;
                    }

                    &:nth-of-type(4) {
                        margin-inline-start: -15px;
                        z-index: 2;
                    }

                    &:nth-of-type(5) {
                        margin-inline-start: -20px;
                        z-index: 1;
                    }
                }
            }

            &.done1 .progressPart {
                &:nth-of-type(1) {
                    background: var(--user-sucess);
                }
            }

            &.done2 .progressPart {
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    background: var(--user-sucess);
                }
            }

            &.done3 .progressPart {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    background: var(--user-sucess);
                }
            }

            &.done4 .progressPart {
                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    background: var(--user-sucess);
                }
            }

            &.done5 {
                display: none;
            }
        }
    }

    .pf_form {
        width: 100%;
        min-height: 68px;
        position: relative;

        .inactive input {
            &::selection {
                background: transparent;
            }
        }

        .MuiFormControl-root.select_box {
            .MuiInputLabel-root {
                -webkit-transform: translate(0px, -1.5px) scale(0.75);
                -moz-transform: translate(0px, 1px) scale(0.75);
                -ms-transform: translate(0px, 1px) scale(0.75);
                transform: translate(0px, 1px) scale(0.75);

                &.Mui-disabled + p {
                    cursor: default;

                    &::after {
                        border: none;
                    }

                    &::before {
                        border: none;
                    }

                    svg {
                        display: none;
                    }
                }
            }

            &.show_focus {
                .MuiInputLabel-root {
                    color: var(--parple);
                }

                .select::after {
                    -webkit-transform: scaleX(1);
                    -moz-transform: scaleX(1);
                    -ms-transform: scaleX(1);
                    transform: scaleX(1);
                }

                svg {
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                }
            }
        }

        .select {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--activation_text);
            font-family: var(--font-stack);
            font-size: 14px;
            font-weight: 400;
            padding-block-end: 6px;
            padding-inline-end: 6px;
            padding-block-start: 6px;
            margin-block-start: 12px;
            position: relative;
            cursor: pointer;

            // &:focus{
            //     font-size: 44px;
            // }
            svg {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 20px;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -webkit-transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }

            &::before {
                border-block-end: 1px solid var(--secondary_text);
                left: 0;
                bottom: 0;
                content: '\00a0';
                position: absolute;
                right: 0;
                -webkit-transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                pointer-events: none;
                height: 0;
            }

            &::after {
                border-block-end: 1px solid var(--parple);
                left: 0;
                bottom: 0;
                content: '\00a0';
                position: absolute;
                -webkit-transform: scaleX(0);
                -moz-transform: scaleX(0);
                -ms-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                pointer-events: none;
                height: 0;
                right: 0;
            }

            &:focus::after {
                -webkit-transform: scaleX(1);
                -moz-transform: scaleX(1);
                -ms-transform: scaleX(1);
                transform: scaleX(1);
            }
        }

        .MuiFormControl-root {
            width: 100%;
            margin: 0;

            p.Mui-error {
                padding-inline-start: 0px;
                color: var(--danger);
                font-size: 12px;
                line-height: 16px;
                font-family: var(--font-stack);
                font-weight: 400;
                text-align: start;
            }

            .MuiInputLabel-root {
                font-family: var(--font-stack);
                font-size: 14px;
                color: var(--user_emailtext);
                -webkit-transform: translate(0px, 19px) scale(1);
                -moz-transform: translate(0px, 19px) scale(1);
                -ms-transform: translate(0px, 19px) scale(1);
                transform: translate(0px, 19px) scale(1);

                &.Mui-focused {
                    color: var(--parple);
                }

                &.MuiInputLabel-shrink {
                    -webkit-transform: translate(0px, -1.5px) scale(0.75);
                    -moz-transform: translate(0px, 1px) scale(0.75);
                    -ms-transform: translate(0px, 1px) scale(0.75);
                    transform: translate(0px, 1px) scale(0.75);
                }
            }

            label + .MuiInput-underline {
                margin-block-start: 12px;

                &.editableInputBox {
                    &.Mui-disabled {
                        &.MuiInput-underline {
                            &::after {
                                display: block;
                            }

                            &::before {
                                display: block;
                            }
                        }

                        input {
                            & + .MuiInputAdornment-root {
                                display: flex;
                            }
                        }
                    }
                }
            }

            .Mui-disabled {
                &.MuiInput-underline {
                    &::after {
                        display: none;
                    }

                    &::before {
                        display: none;
                    }
                }

                input {
                    -webkit-text-fill-color: var(--activation_text);

                    & + .MuiInputAdornment-root {
                        display: none;
                    }
                }
            }

            .MuiInput-underline {
                input {
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 400;
                    padding-block-end: 6px;
                    padding-inline-end: 6px;
                    padding-inline-start: 0px;

                    &::placeholder {
                        color: var(--placeholder-color);
                        opacity: 1;
                    }
                }

                &.Mui-error {
                    &::after {
                        border-block-end: 1px solid var(--danger);
                    }
                }

                &::after {
                    border-block-end: 1px solid var(--parple);
                }

                &::before {
                    border-block-end: 1px solid var(--secondary_text);
                }
            }
        }
    }

    .profile_right {
        position: relative;
        margin-inline-end: 35px;

        .profile_top {
            @include displayFlex(column, center, center);
            width: 212px;
            height: 196px;
            padding-inline: 20px;
            border: 1px solid var(--borderlight);
            border-radius: 12px;
            .email {
                display: inline-block;

                font-size: 12px;
                line-height: 12px;
                color: #787579;
                text-align: center;

                width: 100%;
                padding-block-start: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .divider {
                width: 100%;
                height: 1.5px;
                margin-block: 12px;
                background: hsla(231.9, 100%, 69.4%, 0.14);
            }

            .chip_user {
                border-radius: 100px;

                p {
                    font-size: 12px;
                    line-height: 12px;
                    color: #ffffff;

                    padding-block: 6px 5px;
                    padding-inline: 12px;
                }

                &.basic {
                    background: var(--user-sucess);
                }

                &.pro {
                    background: var(--user_pro);
                }

                &.trial {
                    background: var(--trialbtn);
                }
            }

            .MuiAvatar-root {
                height: 80px;
                width: 80px;
                outline: 1px solid #cfd5ff7a;
                font-size: 24px;
                cursor: pointer;
            }

            .upload_profile_images {
                height: 80px;
                width: 80px;
                background: var(--light-bg);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-radius: 50%;
                position: relative;

                input {
                    display: none;
                }

                label {
                    line-height: 0px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    gap: 4px;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                }

                span {
                    color: var(--parple);
                    font-size: 14px;
                    line-height: 24px;
                    font-family: var(--font-stack);
                    font-weight: 500;
                }

                .MuiSvgIcon-root {
                    font-size: 30px;
                    color: var(--backgroundcolor);
                }
            }

            .changes_profile_images {
                position: absolute;
                bottom: 0;
                right: 2px;
                z-index: 2;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                font-size: 20px;
                box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
                    0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
                background: var(--background-white);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                input,
                .MuiInput-underline {
                    display: none;
                }

                label {
                    line-height: 0px;
                    cursor: pointer;
                }

                .MuiSvgIcon-root {
                    font-size: 18px;
                    color: var(--backgroundcolor);
                }
            }
        }
        .certified_tainner {
            border: 1px solid var(--borderlight);
            padding: 8px 11px;
            border-radius: 12px;
            @include displayFlex(row, center, center);
            gap: 6px;
            width: 100%;
            margin-block-start: 8px;
            margin-inline: auto;
            cursor: pointer;

            img {
                height: 20px;
            }

            h4 {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 12px;
                line-height: 24px;
                font-weight: 400;
            }
        }
        .certified_tainner_badge {
            border: 1px solid var(--borderlight);
            padding: 8px 11px;
            border-radius: 12px;
            margin-block-start: 8px;
            @include displayFlex(row, center, center);
            gap: 12px;
            width: 100%;
            margin-inline: auto;
            cursor: pointer;
            img {
                height: 20px;
            }
        }
    }
}

// Subscription sec
.subscription_sec {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    min-height: 480px;
    height: 100%;
    position: relative;

    &:not(.noPadding) {
        padding: 0 32px 32px;
    }

    .subscription_top {
        width: 100%;
        height: 100%;
    }

    // .subcription_display {
    //     width: 540px;
    //     background: var(--parple_08);
    //     border-radius: 8px;
    //     padding-block: 28px;
    //     padding-inline: 28px;

    //     .planbox {
    //         display: flex;
    //         align-items: flex-start;
    //         gap: 8px;
    //         margin-block-start: 8px;

    //         &:first-child {
    //             margin-block-start: 0px;
    //         }

    //         .plan_head {
    //             min-width: 110px;

    //             h3 {
    //                 font-family: var(--font-stack);
    //                 color: var(--parple);
    //                 line-height: 18px;
    //                 font-size: 14px;
    //                 font-weight: 500;
    //             }
    //         }

    //         .plan_body {
    //             flex: 1;

    //             h4 {
    //                 font-family: var(--font-stack);
    //                 color: var(--activation_text);
    //                 line-height: 20px;
    //                 font-size: 14px;
    //                 font-weight: 400;

    //                 &.red {
    //                     color: var(--danger);
    //                 }
    //             }

    //             p {
    //                 font-family: var(--font-stack);
    //                 color: var(--greytext_color);
    //                 line-height: 18px;
    //                 font-size: 12px;
    //                 font-weight: 400;
    //             }
    //         }

    //         .chip_btn {
    //             width: 70px;
    //             text-align: end;
    //             direction: rtl;

    //             .MuiChip-root {
    //                 max-width: 58px;
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: center;
    //                 height: 22px;

    //                 .MuiChip-label {
    //                     padding: 0;
    //                     font-family: var(--font-stack);
    //                     color: var(--textWhite);
    //                     line-height: 18px;
    //                     font-size: 12px;
    //                     font-weight: 400;
    //                     text-transform: uppercase;
    //                 }
    //             }

    //             .trial {
    //                 background: var(--trialbtn);
    //             }

    //             .pro {
    //                 background: var(--user_pro);
    //             }

    //             .basic {
    //                 background: var(--user-sucess);
    //             }
    //         }

    //         .chip_user {
    //             padding: 2px 6px;
    //             min-height: 24px;
    //             min-width: 60px;
    //             border-radius: 100px;
    //             margin-inline: auto;
    //             width: fit-content;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;

    //             &.basic {
    //                 background: var(--user-sucess);
    //             }

    //             &.pro {
    //                 background: var(--user_pro);
    //             }

    //             &.trial {
    //                 background: var(--trialbtn);
    //             }

    //             p {
    //                 font-family: var(--font-stack);
    //                 font-weight: 500;
    //                 font-size: 13px;
    //                 text-transform: uppercase;
    //                 color: var(--textWhite);
    //                 line-height: 13px;
    //             }
    //         }
    //     }
    // }

    .sub_info_content {
        width: 100%;
        max-width: 540px;

        p,
        .para {
            font-family: var(--font-stack);
            color: var(--activation_text);
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;

            b {
                font-weight: 600;
            }

            a {
                font-family: var(--font-stack);
                color: var(--parple);
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
            }
        }

        ul {
            margin-block-start: 18px;

            li {
                display: block;
                font-family: var(--font-stack);
                color: var(--activation_text);
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                margin-block-start: 12px;
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    display: flex;
                    font-size: 20px;
                    color: var(--parple);
                }
            }
        }
    }

    .change_product_key {
        width: 100%;
        margin-block-start: 16px;

        ul {
            li {
                display: block;
                margin-block-start: 8px;

                // &:first-child{
                //     margin-block-start: px;
                // }
                .error {
                    color: var(--danger);
                }

                a {
                    color: var(--parple);
                    font-size: 14px;
                    line-height: 24px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    display: inline;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }

    .profile_form {
        padding: 0;
        margin-block-start: 20px;
    }
}

.premiumBanner {
    position: absolute;
    inset: auto 0 0 0;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    height: 32px;
    margin-inline: auto;
    background: var(--settings_premium_ui);

    .text {
        font-size: 14px;
        font-weight: 500;
        color: var(--activation_text);
    }
}

.info_text {
    color: var(--activation_text);
    font-size: 14px;
    line-height: 21px;
    font-family: var(--font-stack);
    font-weight: 400;
    grid-column: span 2;
    margin-block-start: 50px;
}
.change_product_key_sec {
    width: 90%;
    min-height: 435px;

    .helpdeskForm_sec {
        width: 100%;

        .helpdeskfrom_inner {
            padding-block: 12px 24px;
            position: relative;

            .error {
                position: absolute;
                bottom: 2px;

                p {
                    color: var(--danger);
                    font-size: 12px;
                    line-height: 12px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                }
            }

            .testField {
                width: 100%;
                border: 1px solid rgba(121, 116, 126, 0.5);
                border-radius: 4px;
                padding: 16px;
                max-width: 280px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                height: 120px;
                // margin-block-start: 20px;

                textarea {
                    resize: none;
                    height: 100% !important;
                    border: none;
                    outline: none;
                    width: 100%;
                    padding: 0;
                    // margin-block-end: 10px;
                    overflow-y: auto !important;
                    color: var(--activation_text);
                    font-family: var(--font-stack);
                    font-size: 14px;
                    font-weight: 400;

                    &::placeholder {
                        color: var(--placeholder-color);
                    }
                }

                .images_upload_area {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex-wrap: wrap;
                    margin-block-start: 10px;

                    .upload_imagesbox {
                        width: auto;
                        position: relative;

                        .MuiAvatar-root {
                            background: linear-gradient(0deg, rgba(99, 120, 255, 0.12), rgba(99, 120, 255, 0.12)),
                                #ffffff;
                        }

                        .cross {
                            position: absolute;
                            top: -4px;
                            right: -4px;
                            width: 14px;
                            height: 14px;
                            background: #bbbbbb;
                            border-radius: 50%;
                            display: grid;
                            place-items: center;

                            svg {
                                color: var(--textWhite);
                                font-size: 11px;
                                display: flex;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .helper_btn_group {
        display: flex;
        align-items: center;
        margin-block-start: 5px;
        gap: 11px;

        button {
            @include helper_btn;
            color: var(--textWhite);
            background-color: var(--btnbgcolor);
            gap: 4px;
            align-items: center;
            line-height: 14px;
            max-width: 145px;

            &.MuiLoadingButton-loading {
                .MuiButton-startIcon {
                    display: none;
                }
            }

            .MuiButton-startIcon {
                display: inherit;
                margin-inline-end: 0px;
                margin-inline-start: 0px;
                transform: rotate(-45deg) translateX(4px);

                svg {
                    font-size: 16px;
                }
            }

            &:hover {
                background-color: var(--btnhover);
            }
        }
    }

    .cancel_feature_list {
        width: 100%;
        margin-block-start: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        .list_head {
            color: var(--activation_text);
            font-size: 14px;
            line-height: 17px;
            font-family: var(--font-stack);
            font-weight: 400;
            grid-column: span 2;
            margin-block-end: 8px;
        }

        .items {
            width: 100%;
            @include displayFlex(row, center, flex-start);
            gap: 8px;

            svg {
                display: flex;
                font-size: 18px;
                color: var(--parple);
            }

            p {
                color: var(--activation_text);
                font-size: 14px;
                line-height: 18px;
                font-family: var(--font-stack);
                font-weight: 400;
            }

            &:last-child {
                p {
                    color: var(--parple);
                    cursor: pointer;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .profile_update_btn {
        padding-block-start: 32px;
    }

    .MuiDivider-root {
        border-color: var(--light-bg);
        margin: 0 0;
        margin-block-start: 32px;
    }

    .product_key_text {
        width: 100%;

        .eligibility_image {
            padding-block-start: 24px;
            padding-block-end: 8px;
        }

        h4 {
            font-family: var(--font-stack);
            color: var(--activation_text);
            line-height: 22px;
            font-size: 16px;
            font-weight: 500;
        }

        h4 + h5 {
            margin-block-start: 20px;
        }

        h5 {
            font-family: var(--font-stack);
            color: var(--parple);
            line-height: 24px;
            font-size: 20px;
            font-weight: 500;
        }

        p {
            font-family: var(--font-stack);
            color: var(--activation_text);
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            margin-block-start: 8px;

            &.margin_BT_20 {
                margin-block-end: 20px;
            }

            &.success {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    font-size: 28px;
                    display: flex;
                }
            }

            &.error {
                display: flex;
                align-items: center;
                gap: 10px;

                svg {
                    font-size: 28px;
                    display: flex;
                }
            }

            &.margin_top_4px {
                margin-block-start: 4px;
            }
        }

        p:only-child {
            margin-block-start: 0;
        }
    }

    .profile_form {
        margin-block-start: 10px;
        display: block;

        .billing_cycle_radio {
            margin-block-start: 18px;
        }

        & + .profile_update_btn {
            padding-block-start: 32px;
        }
    }

    .other_comment_area {
        width: 100%;
        padding-inline-start: 32px;

        .MuiFormControl-root {
            width: 100%;

            .MuiOutlinedInput-root {
                border-radius: 4px;

                &:active {
                    background: none !important;
                }

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            input {
                color: var(--activation_text);
                font-family: var(--font-stack);
                font-size: 14px;
                font-weight: 400;
                padding: 8px 12px;

                &::placeholder {
                    color: var(--placeholder-color);
                    opacity: 1;
                }
            }
        }
    }

    .subject_checkbox {
        width: 100%;

        .MuiFormControl-root {
            margin: 0;
            padding-inline-start: 4px;
        }
    }

    .radio_group {
        margin-block-start: 12px;
    }

    .planradio {
        .MuiRadio-root {
            padding: 6px 9px;

            &.Mui-checked {
                color: var(--parple);
            }
        }

        color: var(--activation_text);
        font-family: var(--font-stack);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        h5 {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        span.green {
            color: var(--checkboxGreen);
            font-weight: 500;
        }

        &.Mui-disabled {
            opacity: 0.4;
        }
    }
}

// unlockpro
.unlockpro {
    width: 100%;
    height: 100%;
    padding: 32px;
    padding-block-start: 12%;

    &.activate_section {
        padding-block-start: 15px;
        padding-inline: 30px 90px;

        p {
            color: var(--activation_text);
            font-family: var(--font-stack);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .unlockpro_content {
        text-align: center;
    }

    svg {
        height: 150px !important;
    }

    .profile_update_btn {
        text-align: center;
        padding-block-start: 28px;

        &.giftbtn {
            button {
                min-height: 40px !important;
                min-width: 195px !important;
            }

            svg {
                height: 24px !important;
            }
        }
    }
}

// subscription sec

// modal
.csv_upload_modal {
    .csvmodal_box {
        padding: 0 !important;
        position: absolute;
        top: 0%;
        left: 0%;
        bottom: 0;
        right: 0;
        margin: auto;
        height: fit-content;
        // transform: translate(-50%, -50%);
        width: 100%;
        max-width: 356px;
        background-color: #fff;
        box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
            0px 9px 46px 8px rgb(0 0 0 / 12%);
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
        border-radius: 16px;

        &.wdth_400 {
            max-width: 400px;
        }
    }
}

.settingbutton_sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    padding: 12px 20px;
    background: var(--background-white);
    border-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    min-height: 72px;

    &.justify_center {
        justify-content: center;
    }

    button {
        @include theme_btn;
        padding: 7px 20px !important;
        font-size: 14px;
        color: var(--btnbgcolor);

        &.MuiButton-containedPrimary {
            color: var(--textWhite);
            background-color: var(--btnbgcolor) !important;

            &:hover {
                background-color: var(--btnhover) !important;
            }
        }
    }
}

.error_file .modal_Box.csvmodal_box {
    padding: 0px !important;
    background-color: #000;
    width: fit-content !important;
    max-width: 736px;
    min-width: 400px;

    .settingbutton_sec {
        justify-content: center;

        button.MuiButton-containedPrimary.dark_button {
            color: var(--cpbutton);
            box-shadow: none !important;
            background-color: transparent !important;

            &:hover {
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.2), rgba(99, 120, 255, 0.2)), #ffffff;
            }
        }
    }

    .closebtn {
        position: relative;
        width: 100%;
        text-align: end;
        background-color: var(--background-white);
        cursor: pointer;
        padding: 1px;

        &:hover {
            opacity: 0.3;
        }
    }

    .App {
        background-color: #000;
        height: fit-content;
        min-height: 221px;
        max-height: 341px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 0;
        border-start-start-radius: 16px;
        border-start-end-radius: 16px;
        overflow: hidden;
    }

    .ReactCrop {
        // width: 100%;
        max-height: 240px;
        background-color: #000;
        outline: none;

        .ReactCrop__image {
            width: 100%;
            max-height: 240px;
            object-fit: contain;
        }
    }
}

// launguage
.language_sec {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 480px;

    .language_top {
        width: 100%;
        padding: 12px 32px;
    }

    .language_display {
        width: 540px;
        background: var(--parple_08);
        border-radius: 8px;
        padding-block: 24px;
        padding-inline: 40px 22px;
        margin-block-end: 28px;

        .planbox {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-block-start: 8px;

            &:first-child {
                margin-block-start: 0px;
            }

            .plan_head {
                min-width: 110px;

                h3 {
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    line-height: 18px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .plan_body {
                flex: 1;

                h4 {
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                    line-height: 20px;
                    font-size: 14px;
                    font-weight: 400;

                    span {
                        font-family: var(--font-stack);
                        color: var(--greytext_color);
                        line-height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                p {
                    font-family: var(--font-stack);
                    color: var(--greytext_color);
                    line-height: 18px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .language_flag {
                width: 80px;

                .MuiAvatar-root {
                    width: 28px;
                    height: 28px;
                    margin-inline-start: auto;
                }
            }
        }
    }

    .language_area {
        width: 100%;
        padding: 0px 32px;

        .language_wrap {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px 5px;
            flex-wrap: wrap;

            .language_box {
                width: 32.7%;
                min-width: auto;
                min-height: auto;
                padding: 7.5px 12px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px;
                border: 1px solid transparent;

                .MuiAvatar-root {
                    width: 24px;
                    height: 24px;
                }

                .language_name {
                    flex: 1;
                    margin-inline-start: 8px;

                    h4 {
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                        line-height: 18px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: start;
                        text-transform: none;
                    }

                    .sub {
                        font-family: var(--font-stack);
                        color: var(--activation_text);
                        line-height: 16px;
                        font-size: 12px;
                        font-weight: 400;
                        text-align: start;
                        color: var(--greytext_color);
                        text-transform: none;
                    }
                }

                &:hover {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
                }

                .MuiTouchRipple-ripple {
                    .MuiTouchRipple-child {
                        background-color: var(--Ripplebg_color);
                    }
                }

                &.active {
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.11), rgba(99, 120, 255, 0.11)), #ffffff;
                    border: 1px solid var(--parple);
                }
            }
        }
    }
}

// comming_soon_modal
.modal {
    &.comming_soon_modal {
        .back_drop {
            position: absolute;
        }

        .comming_soon_content {
            width: 100%;

            .icon_gup {
                text-align: center;
                display: flex;
                width: 100%;
                align-items: center;
                gap: 5px;
                justify-content: center;
                margin-block-end: 12px;

                svg {
                    color: var(--parple);
                }
            }
        }
    }
}

// product_key_box
.error_file.modal_Box {
    padding: 28px 20px !important;
    background-color: var(--background-white);
    width: fit-content !important;
    min-width: 320px;
    border-radius: 16px;
}

// subscription sec
.product_key_box {
    width: 320px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &.package_info {
        height: 100%;

        .media_body {
            p {
                font-family: var(--font-stack);
                color: var(--text_color);
                line-height: 19px;
                font-size: 14px;
                font-weight: 400;
                text-align: start;
            }
        }
    }

    .media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;

        .media_icon {
            .info {
                font-size: 24px;
                color: var(--info);
                margin-inline-end: 20px;
            }

            .danger {
                font-size: 24px;
                color: var(--danger);
                margin-inline-end: 20px;
            }
        }

        .media_body {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }
    }
}

// subscription sec

// sharing_sec
.sharing_sec {
    width: 100%;
    @include displayFlex(column, center, center);
    height: 520px;

    .sharing_box {
        width: 100%;
        @include displayFlex(column, center, center);
        height: fit-content;
        position: relative;
        isolation: isolate;
        padding-inline: 30px;
        text-align: center;
        max-width: 520px;
        margin-inline: auto;

        .content {
            max-width: 410px;
            margin-inline: auto;

            .lottie_icon {
                width: 110px;
                margin-inline: auto;
                margin-block-end: 10px;
            }

            .title {
                @include gradientText(var(--clr_grad_text_01));
                font-size: 24px;
                line-height: 31px;
                font-weight: 500;
                margin-block-end: 10px;
            }

            .content_text {
                color: var(--activation_text);
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
            }
        }

        // .background_img {
        //     position: absolute;
        //     inset: 0 0 0 0;
        //     margin: auto;
        //     width: 100%;
        //     text-align: center;
        //     height: 100%;
        //     z-index: -1;
        //     @include displayFlex(row, center, center);

        //     img {
        //         max-width: 100%;
        //     }
        // }
    }

    .copy_url {
        width: 100%;
        max-width: 400px;
        margin-inline: auto;
        min-height: 36px;
        @include displayFlex(row, center, center);
        // background: var(--background-white);
        // border: 1px solid var(--borderlight);
        border: 1px solid transparent;
        background: linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0, 194, 255, 1), rgba(99, 120, 255, 1));
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        border-radius: 36px;
        padding-inline-start: 16px;
        margin-block: 36px 28px;

        input {
            border: none;
            box-shadow: none;
            outline: none;
            flex: 1;
            line-height: 19px;
            font-size: 14px;
            color: var(--parple);

            &::placeholder {
                color: var(--placeholder-color);
            }
        }

        .btn {
            width: 100px;
            padding: 6px 10px;
            line-height: 19px;
            font-size: 14px;
            font-weight: 500;
            color: var(--textWhite);
            border-radius: 40px;
            background: var(--background_grad_01);
            gap: 6px;
            min-height: 36px;
            box-shadow: none;
            text-transform: none;
            position: relative;
            left: 1px;

            .MuiButton-endIcon {
                margin: 0;
            }

            &:hover {
                background: var(--background_grad_01);
            }
        }
    }

    .social_media {
        width: 100%;

        .title {
            color: var(--activation_text);
            line-height: 19px;
            font-size: 14px;
            font-weight: 400;
            font-family: var(--font-stack);
        }

        .icon_list {
            width: 100%;
            @include displayFlex(row, center, center);
            gap: 28px;
            // margin-block-start: 16px;

            button {
                @include displayFlex(row, center, center);
                cursor: pointer;

                img {
                    width: 28px;
                }

                // svg {
                //     width: 32px;
                //     height: 32px;
                // }
            }
            .icon {
                @include displayFlex(row, center, center);
                cursor: pointer;

                img {
                    width: 28px;
                }
            }
        }
    }

    .total_download {
        width: 100%;
        @include displayFlex(row, center, center);
        gap: 12px;
        margin-block-start: 40px;

        .number {
            min-width: 60px;
            min-height: 60px;
            padding: 5px 10px;
            @include displayFlex(row, center, center);
            background: var(--footer_bg_second);
            border-radius: 80px;

            &.green {
                background: #ecfaf0;

                p {
                    color: var(--user-sucess);
                }
            }

            p {
                color: var(--gray_text_color);
                line-height: 41px;
                font-size: 32px;
                font-weight: 500;
            }
        }

        .text {
            // flex: 1;
            width: fit-content;
            text-align: start;

            p {
                color: var(--activation_text);
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

// Verify Email Modal Box
.sendCodeText {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 19px;

    padding-block: 24px;
    padding-inline: 24px;

    :is(.loadingIcon .MuiLoadingButton-loadingIndicator, .tickIcon) {
        position: absolute;
        margin-block: auto;
    }

    .loadingIcon {
        min-width: 0 !important;
        padding: 0 !important;
        border: none !important;
        margin-inline-end: -19px;

        .MuiLoadingButton-loadingIndicator {
            inset: 0 auto 0 13.5px;

            width: 23px !important;
            height: 23px !important;
            padding: 0 !important;

            .MuiCircularProgress-root {
                width: 23px !important;
                height: 23px !important;
            }
        }
    }

    .tickIcon {
        inset: 0 auto 0 24px;
        color: var(--user-sucess);

        width: 27px;
        height: 27px;
    }

    .text {
        font-size: 14px;
        line-height: 19.6px;

        padding-inline-start: calc(27px + 10px);
    }
}

// new new_subscription
.new_subscription {
    width: 100%;
    height: calc(100%);
    position: relative;

    &:not(.noPadding) {
        height: calc(100% - 66px);
        // padding-block-start: 19px;
    }

    > .verifyEmailWarningText {
        width: 100%;
        // margin-block-end: -17px;
        padding-inline: 30px;

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            font-size: 14px;
            line-height: 18px;
            color: #49454f;

            width: 100%;

            padding-block: 8px;
            padding-inline: 16px;
            border-radius: 4px;
            background: #fff2d7;

            .email {
                display: inline-block;

                width: min-content;
                max-width: 260px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .link {
                color: #da7f14;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .new_sb_card_sec {
        padding-inline: 32px;
        @include displayFlex(row, center, center);
        gap: 12px;
        height: calc(100% - 50px);

        &.showVerifyEmailText {
            height: calc(100% - 82px);
        }

        .new_card {
            background: var(--background-white);
            border-radius: 20px;
            border: 3px solid rgba(151, 165, 253, 0.35);
            @include displayFlex(column, center, center);
            position: relative;
            overflow: hidden;
            // padding-block: 28px;

            &.small {
                width: 262px;

                .card_icon {
                    background: var(--graybar);
                }
            }

            &.large {
                width: 304px;
                border: 5px solid transparent;
                background: linear-gradient(to right, white, white),
                    linear-gradient(to right, rgba(0, 194, 255, 1), rgba(99, 120, 255, 1));
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

                .card_icon {
                    background: var(--background_grad_gold);
                }

                .inner_card {
                    .feature_list {
                        .items {
                            svg {
                                color: var(--parple);
                            }

                            &:last-child {
                                p {
                                    color: var(--parple);
                                    cursor: pointer;
                                    text-decoration: underline;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }

                .info {
                    cursor: pointer;

                    svg {
                        font-size: 24px;
                        color: var(--text_gray);
                    }
                }
            }

            .inner_card {
                position: relative;

                width: 100%;
                margin-inline: auto;

                .topBanner {
                    position: absolute;
                    inset: 0 0 auto 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 13px;
                    line-height: 16px;

                    height: 28px;
                    background: linear-gradient(180deg, #ffc700 16.06%, #f1a208 153.79%);
                }

                .top_box {
                    width: 100%;
                    padding-inline: 28px;
                    padding-block: 52px 26px;

                    // &.reduceTopSpace {
                    //     padding-block: 26px;
                    // }

                    &.discountTop {
                        padding-block: 48px 18px;
                    }

                    .btn_group {
                        button {
                            width: 100% !important;
                            max-width: 100% !important;
                        }
                    }
                }

                .card_icon {
                    border-radius: 50%;
                    @include displayFlex(row, center, center);
                    width: 24px;
                    height: 24px;

                    img {
                        max-width: 14px;
                    }
                }

                .title {
                    width: 100%;
                    @include displayFlex(row, center, flex-start);
                    gap: 8px;

                    h4 {
                        font-size: 22px;
                        line-height: 28px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--text_color);
                    }
                }

                .basic_title {
                    width: 100%;
                    margin-block-start: 12px;
                    text-align: center;

                    .main_title {
                        font-size: 14px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--text_color);
                    }

                    .big_sub {
                        font-size: 20px;
                        line-height: 24px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--parple);
                        margin-block-start: 5px;
                    }
                }

                .pro_title {
                    width: 100%;
                    padding-block-end: 18px;

                    .main_title {
                        font-size: 14px;
                        line-height: 20px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--text_color);
                    }

                    .small_sub {
                        font-size: 14px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        color: var(--error_span_color);
                        text-decoration: line-through;

                        b {
                            font-weight: 600;
                        }

                        &:nth-of-type(1) {
                            margin-block-start: 8px;
                        }
                    }

                    .big_sub {
                        @include displayFlex(row, flex-start, flex-start);
                        font-size: 40px;
                        line-height: 1.1;
                        font-family: var(--font-stack);
                        font-weight: 600;
                        margin-block-start: 12px;
                        position: relative;
                        width: fit-content;
                        color: var(--activation_text);

                        &.smallFont {
                            font-size: 32px;
                        }

                        .sup {
                            position: relative;
                            font-size: 12px;
                            line-height: 21px;
                            font-family: var(--font-stack);
                            font-weight: 500;
                            top: 3px;
                        }

                        // .discount {
                        //     position: absolute;
                        //     top: 0;
                        //     right: -10px;
                        //     transform: translateX(100%);
                        //     font-size: 12px;
                        //     line-height: 19px;
                        //     font-family: var(--font-stack);
                        //     font-weight: 400;
                        //     color: var(--danger);
                        //     text-decoration: line-through;
                        //     background: var(--background_light_red);
                        //     border-radius: 30px;
                        //     padding: 2px 6px;
                        //     background-clip: initial;
                        //     -webkit-text-fill-color: initial;
                        //     min-width: 26px;
                        // }

                        .sub {
                            position: absolute;
                            bottom: 0;
                            right: -5px;
                            transform: translateX(100%);
                            font-size: 12px;
                            line-height: 21px;
                            font-family: var(--font-stack);
                            font-weight: 400;
                            width: max-content;
                        }
                    }
                }

                .feature_list {
                    width: 100%;
                    padding-inline: 28px;
                    padding-block: 8px 28px;
                    background: var(--background_primary_05);

                    .list_head {
                        color: var(--activation_text);
                        font-size: 12px;
                        line-height: 17px;
                        font-family: var(--font-stack);
                        font-weight: 500;
                        // padding-inline: 35px;
                        margin-block-start: 8px;
                    }

                    .items {
                        width: 100%;
                        @include displayFlex(row, center, flex-start);
                        gap: 8px;
                        margin-block-start: 8px;

                        svg {
                            color: var(--graybar);
                            display: flex;
                            font-size: 18px;
                        }

                        p {
                            color: var(--activation_text);
                            font-size: 12px;
                            line-height: 18px;
                            font-family: var(--font-stack);
                            font-weight: 400;
                        }
                    }

                    // .swiper {
                    //     .swiper-wrapper {
                    //         padding-inline: 35px;
                    //     }

                    //     .swiper-button-prev {
                    //         left: 2px;

                    //         &::after {
                    //             font-size: 16px;
                    //             color: var(--text_gray);
                    //         }

                    //         &.swiper-button-disabled {
                    //             &::after {
                    //                 opacity: 0.7;
                    //             }
                    //         }
                    //     }

                    //     .swiper-button-next {
                    //         right: 2px;

                    //         &::after {
                    //             font-size: 16px;
                    //             color: var(--text_gray);
                    //         }

                    //         &.swiper-button-disabled {
                    //             &::after {
                    //                 opacity: 0.7;
                    //             }
                    //         }
                    //     }
                    // }
                }
            }

            // .info {
            //     position: absolute;
            //     top: 18px;
            //     right: 18px;
            //     width: fit-content;
            //     height: fit-content;

            //     svg {
            //         display: flex;
            //         font-size: 28px;
            //         color: var(--user-sucess);
            //     }
            // }
        }
    }

    .btn_group {
        width: 100%;
        @include displayFlex(column, center, center);
        margin-block-start: 28px;

        &.upgradeCta {
            margin-block-start: 13px;
        }

        &.row {
            flex-direction: row !important;
            justify-content: flex-start !important;
            gap: 24px;

            margin-block-start: 40px;
            margin-inline: 0;
        }

        .text_link {
            font-size: 14px;
            line-height: 21px;
            font-family: var(--font-stack);
            font-weight: 400;
            color: var(--parple);
            cursor: pointer;

            &:hover {
                text-decoration: underline !important;
            }
        }

        &.basic {
            button {
                color: var(--user-sucess);
                pointer-events: none;
                background: var(--background_primary_05);
            }
        }

        &.pro {
            button {
                background: var(--background_grad_01);
                color: var(--textWhite);
            }
        }

        button {
            border-radius: 70px;
            min-height: 40px;
            border: 1px solid var(--borderlight);
            font-size: 14px;
            font-family: var(--font-stack);
            font-weight: 500;
            padding: 0;
            padding-block: 8px;
            padding-inline: 14px;
            text-transform: none;
            width: 100%;
            max-width: 180px;

            .small {
                font-size: 10px;
                line-height: 17px;
                font-family: var(--font-stack);
            }

            .MuiLoadingButton-startIconLoadingStart {
                display: none;
            }

            .MuiButton-startIcon {
                margin-left: 0;
                margin-right: 0;
                margin-inline-end: 6px;
            }

            .MuiLoadingButton-loadingIndicator {
                position: relative;
                left: 0;
                display: flex;
                padding-inline: 0 5px;
            }
        }

        // .noCredit {
        //     color: var(--text_gray);
        //     margin-block-start: 8px;
        //     font-size: 11px;
        //     font-family: var(--font-stack);
        //     font-weight: 400;
        //     line-height: 17px;
        // }
    }

    .inner_subscription {
        width: 100%;
        height: calc(100% - 48px);

        .body_head {
            padding-inline-start: 0;
            margin-block-end: 12px;
        }

        .big_card {
            width: 100%;
            border: 3px solid transparent;
            background: linear-gradient(to right, white, white),
                linear-gradient(to right, rgba(0, 194, 255, 1), rgba(99, 120, 255, 1));
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            border-radius: 16px;
            // padding: 30px;

            .subcription_display {
                width: 100%;
                @include displayFlex(row, flex-start, flex-start);
                gap: 18px;
                background: none;
                padding-block: 28px;
                padding-inline: 28px;

                .card_icon {
                    width: 42px;
                    height: 42px;
                    background: var(--background_grad_gold);
                    border-radius: 50%;
                    @include displayFlex(row, center, center);

                    img {
                        max-width: 24px;
                    }
                }

                .plan_box_wrapper {
                    width: 100%;
                    flex: 1;

                    .planbox {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;
                        margin-block-start: 8px;

                        &:first-child {
                            margin-block-start: 0px;
                        }

                        .plan_head {
                            min-width: 105px;
                            max-width: 105px;

                            h3 {
                                font-family: var(--font-stack);
                                color: var(--parple);
                                line-height: 19px;
                                font-size: 14px;
                                font-weight: 500;
                                word-break: break-word;
                            }
                        }

                        .plan_body {
                            flex: 1;

                            .subscriptionCancelled {
                                color: var(--danger);
                            }

                            .title {
                                font-weight: 500;
                            }

                            .expiryDate {
                                color: var(--activation_text);
                            }

                            h4 {
                                font-family: var(--font-stack);
                                @include displayFlex(row, center, flex-start);
                                gap: 5px;
                                color: var(--activation_text);
                                line-height: 18px;
                                font-size: 14px;
                                font-weight: 400;

                                &.red {
                                    color: var(--danger);
                                }
                            }

                            p {
                                font-family: var(--font-stack);
                                color: var(--greytext_color);
                                line-height: 20px;
                                font-size: 14px;
                                font-weight: 400;
                                padding-block: 5px 10px;
                            }
                        }

                        // chip_btn
                        // .chip_btn {
                        //     width: 70px;
                        //     text-align: end;
                        //     direction: rtl;

                        //     .MuiChip-root {
                        //         max-width: 58px;
                        //         display: flex;
                        //         align-items: center;
                        //         justify-content: center;
                        //         height: 22px;

                        //         .MuiChip-label {
                        //             padding: 0;
                        //             font-family: var(--font-stack);
                        //             color: var(--textWhite);
                        //             line-height: 18px;
                        //             font-size: 12px;
                        //             font-weight: 400;
                        //             text-transform: uppercase;
                        //         }
                        //     }

                        //     .trial {
                        //         background: var(--trialbtn);
                        //     }

                        //     .pro {
                        //         background: var(--user_pro);
                        //     }

                        //     .basic {
                        //         background: var(--user-sucess);
                        //     }
                        // }

                        // chip user
                        .chip_user {
                            padding: 2px 6px;
                            min-height: 22px;
                            min-width: 58px;
                            border-radius: 100px;
                            // margin-block-start: 8px;
                            margin-inline: auto;
                            width: fit-content;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;

                            &.basic {
                                background: var(--user-sucess);
                            }

                            &.pro {
                                background: var(--user_pro);
                            }

                            &.trial {
                                background: var(--trialbtn);
                            }

                            &.premium {
                                background: var(--settings_premium_ui);
                                padding-inline: 10px;

                                .image {
                                    width: 12px;
                                    height: 12px;
                                    margin-block-end: 2px;
                                }

                                p {
                                    letter-spacing: 0.5px;
                                    color: var(--premium_black);
                                }
                            }

                            p {
                                font-family: var(--font-stack);
                                font-weight: 500;
                                font-size: 12px;
                                text-transform: uppercase;
                                color: var(--textWhite);
                                line-height: 13px;
                            }
                        }
                    }
                }
            }
        }

        .feature_list {
            width: 100%;
            margin-block-start: 40px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;

            .list_head {
                color: var(--activation_text);
                font-size: 14px;
                line-height: 17px;
                font-family: var(--font-stack);
                font-weight: 500;
                grid-column: span 2;
                margin-block-end: 8px;
            }

            .items {
                width: 100%;
                @include displayFlex(row, center, flex-start);
                gap: 8px;

                svg {
                    display: flex;
                    font-size: 18px;
                    color: var(--parple);
                }

                p {
                    color: var(--activation_text);
                    font-size: 14px;
                    line-height: 18px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                }

                &:not(.premiumList) {
                    &:last-child {
                        p {
                            color: var(--parple);
                            cursor: pointer;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .link {
            @include displayFlex(row, center, flex-start);
            margin-block-start: 30px;
            gap: 20px;

            &.red {
                a {
                    color: var(--danger) !important;

                    &.cancelSubscription {
                        color: var(--textColor) !important;
                        text-decoration: underline !important;
                    }
                }
            }

            a {
                font-size: 14px;
                line-height: 19px;
                font-family: var(--font-stack);
                font-weight: 400;
                color: var(--parple);
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        .subsription_box {
            width: 100%;
            margin-block-start: 28px;

            p {
                color: var(--activation_text);
                font-size: 14px;
                line-height: 18px;
                font-family: var(--font-stack);
                font-weight: 400;
            }
        }
    }

    .have_product_key {
        width: 100%;
        margin-block-start: 10px;
        text-align: center;
        @include displayFlex(row, center, center);
        gap: 10px;

        .link {
            color: var(--parple);
            font-size: 14px;
            line-height: 20px;
            font-family: var(--font-stack);
            font-weight: 400;
            cursor: pointer;
            @include displayFlex(row, center, center);
            gap: 9px;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}

// bottom fixed

.bottom_fixed {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-inline: 32px;

    .content_bottom {
        width: 100%;
        min-height: 48px;
        text-align: center;
        @include displayFlex(row, center, center);
        gap: 10px;
        border-top: 1px solid var(--light-bg);

        &.sh_ClassPoint {
            min-height: 56px;
        }

        .d_flex {
            @include displayFlex(row, center, center);
            gap: 3px;
        }

        .link {
            color: var(--parple);
            font-size: 14px;
            line-height: 21px;
            font-family: var(--font-stack);
            font-weight: 400;
            cursor: pointer;
            @include displayFlex(row, center, center);
            gap: 9px;

            &:hover {
                text-decoration: underline !important;
            }

            &.error {
                color: var(--danger) !important;
            }

            &.red {
                color: var(--danger) !important;

                a {
                    color: var(--danger) !important;
                }
            }
        }

        &.first_subscription {
            position: relative;
        }
    }
}

// modal referral
.referral_modal {
    isolation: isolate;

    .back_drop {
        position: absolute;
        z-index: 0;
        backdrop-filter: none;
    }

    .modal_body {
        padding-block: 28px 0;
        padding-inline: 20px 8px;

        .referral_name {
            width: 100%;

            .inner_referral {
                width: 100%;
                padding-inline-end: 12px;
                @include displayFlex(column, flex-start, flex-start);
                min-height: 220px;
                max-height: 275px;
                overflow: auto;
                padding-block-end: 10px;

                .referral_card_name {
                    width: 100%;
                    padding-block: 8px;
                    padding-inline: 15px;
                    @include displayFlex(row, center, flex-start);
                    gap: 12px;

                    .avater_box {
                        .MuiAvatar-root {
                            height: 40px;
                            width: 40px;
                            font-size: 18px;
                            cursor: pointer;
                        }

                        .upload_profile_images {
                            height: 40px;
                            width: 40px;
                            background: var(--light-bg);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            border-radius: 50%;
                        }
                    }

                    .content_box {
                        flex: 1;
                        text-align: start;

                        .name {
                            color: var(--activation_text);
                            font-size: 16px;
                            line-height: 23px;
                            font-family: var(--font-stack);
                            font-weight: 400;
                        }

                        .email {
                            color: var(--secondary_text);
                            font-size: 14px;
                            line-height: 19px;
                            font-family: var(--font-stack);
                            font-weight: 400;
                        }
                    }

                    &:nth-of-type(odd) {
                        background: var(--light_blue_05);
                    }
                }
            }
        }
    }
}

// referral_link_modal
.referral_link_modal {
    isolation: isolate;

    .back_drop {
        position: absolute;
        z-index: 0;
        backdrop-filter: none;
    }

    .modal_body {
        padding-block: 10px 24px;
        padding-inline: 30px;

        .referral_link {
            width: 100%;

            .purlePapperPlan {
                width: 148px;
                height: 148px;
                margin-inline: auto;
            }

            .inner_content {
                width: 100%;
                text-align: center;
                isolation: isolate;

                .info {
                    color: var(--activation_text);
                    font-size: 14px;
                    line-height: 19px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    max-width: 250px;
                    margin-inline: auto;
                }

                .copy_url_modal {
                    width: 100%;
                    margin-inline: auto;
                    min-height: 36px;
                    @include displayFlex(row, center, center);
                    background: var(--background-white);
                    border: 1px solid var(--borderlight);
                    border-radius: 36px;
                    padding-inline: 16px;
                    margin-block: 12px 24px;
                    position: relative;
                    z-index: -2;

                    input {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        flex: 1;
                        line-height: 19px;
                        font-size: 14px;
                        color: var(--parple);
                        text-align: center;

                        &::placeholder {
                            color: var(--placeholder-color);
                        }
                    }
                }

                .btn_copy_url {
                    width: fit-content;
                    min-width: 110px;
                    padding: 6px 15px;
                    line-height: 19px;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--textWhite);
                    border-radius: 6px;
                    background: var(--parple);
                    gap: 6px;
                    min-height: 40px;
                    box-shadow: none;
                    text-transform: none;
                    position: relative;

                    .MuiButton-endIcon {
                        margin: 0;
                    }

                    &:hover {
                        background: var(--btnhover);
                    }
                }
            }
        }
    }
}

// discount_modal
.discount_modal {
    isolation: isolate;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;

    .back_drop {
        position: absolute;
        z-index: 0;
        backdrop-filter: none;
    }

    .modal_body {
        padding-block: 56px 24px;
        padding-inline: 24px;

        .discount {
            height: 50px;

            .text {
                color: var(--text_color);
                font-size: 16px;
                font-weight: 500;
            }
        }

        .discount_link {
            width: 100%;

            .purlePapperPlan {
                width: 148px;
                height: 148px;
                margin-inline: auto;
            }

            .inner_content {
                width: 100%;
                text-align: center;
                isolation: isolate;

                .info {
                    color: var(--activation_text);
                    font-size: 14px;
                    line-height: 19px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    max-width: 280px;
                    margin-inline: auto;
                    margin-block: 0 24px;
                }
            }
        }
    }
}

// copyurl_tooltip
.copyurl_tooltip {
    position: absolute;
    background: var(--tooltip_black_800);
    background-blend-mode: overlay;
    border-radius: 4px;
    top: -5px;
    left: auto;
    width: 90px;
    padding-block: 5px;
    padding-inline: 8px;
    @include displayFlex(row, center, center);
    gap: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    font-family: var(--font-stack);
    color: var(--textWhite);
    transition: var(--transition);
    transform: translateY(-100%);
    opacity: 1;
    pointer-events: none;
    z-index: -1;

    svg {
        font-size: 16px;
    }
}

// discount_shopee_modal
.discount_shopee_modal {
    isolation: isolate;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;

    .back_drop {
        position: absolute;
        z-index: 0;
        backdrop-filter: none;
    }

    .modal_body {
        padding-block: 76px 24px;
        padding-inline: 24px;

        .discount {
            height: 50px;

            .text {
                color: var(--text_color);
                font-size: 16px;
                font-weight: 500;
            }
        }

        .discount_link {
            width: 100%;

            .purlePapperPlan {
                width: 140px;
                height: 140px;
                margin-inline: auto;
            }

            .inner_content {
                width: 100%;
                text-align: center;
                isolation: isolate;

                .info {
                    color: var(--activation_text);
                    font-size: 14px;
                    line-height: 19px;
                    font-family: var(--font-stack);
                    font-weight: 400;
                    max-width: 280px;
                    margin-inline: auto;
                }
            }

            .coupon_code {
                width: 100%;
                max-width: 240px;
                margin-inline: auto;
                min-height: 36px;
                border: 1px solid var(--borderMain);
                border-radius: 40px;
                @include displayFlex(row, center, center);
                margin-block: 8px 18px;

                .code {
                    color: var(--parple);
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .shopee_btn {
                margin-block-start: 18px;
                @include displayFlex(row, center, center);

                .shopeBtn {
                    text-transform: none !important;
                    @include shopeBtn;
                }
            }
        }
    }
}

.giveUpMessageInputContainer {
    padding-block-start: 25px;

    .cancelSubscriptionTextField {
        width: 60%;
        border: 1px solid rgba(121, 116, 126, 0.5);
        border-radius: 4px;
        padding: 16px;
        max-width: 480px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 130px;

        textarea {
            resize: none;
            height: 100% !important;
            border: none;
            outline: none;
            width: 100%;
            padding: 0;
            // margin-block-end: 10px;
            overflow-y: auto !important;
            color: var(--activation_text);
            font-family: var(--font-stack);
            font-size: 14px;
            font-weight: 400;

            &::placeholder {
                color: var(--placeholder-color);
            }
        }
    }

    .error {
        padding-block-start: 15px;

        p {
            color: var(--danger);
            font-size: 14px;
            line-height: 12px;
            font-family: var(--font-stack);
            font-weight: 400;
        }
    }

    .helper_btn_group {
        padding-block-start: 10px;
    }
}
