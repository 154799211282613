@import '../../../../Style.scss';

.modal_viewport {
    margin-inline: auto;
    margin-block: 0;
    position: relative;
    outline: none;
    box-shadow: none;
}

.sky_slider_container {
    position: absolute;
    inset: 0;
    margin: auto;
    height: max-content;
    width: max-content;
}

.close_modal {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
}

button.sky_slider__prev {
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    background-size: 2.4rem 2.4rem;
    padding: 10px;
    max-height: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    min-width: auto;
    border-radius: 50%;
    color: var(--activation_text);
    background: var(--background-white);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    opacity: 0.7;

    &:hover {
        opacity: 1;
        background: var(--background-white);
    }

    .chevron_right {
        height: 100%;
        fill: var(--activation_text);
        transition: fill 0.3s ease-in;
        transform: rotateY(180deg);
        font-size: 24px;
        display: flex;
    }

    &:hover {
        .chevron_right {
            fill: var(--activation_text);
            transition: fill 0.3s ease-in-out;
        }
    }
}

button.sky_slider__next {
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    background-size: 2.4rem 2.4rem;
    padding: 10px;
    max-height: 40px;
    max-width: 40px;
    width: 40px;
    height: 40px;
    min-width: auto;
    border-radius: 50%;
    border-radius: 50%;
    color: var(--activation_text);
    background: var(--background-white);
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    right: 50px;
    opacity: 0.7;

    &:hover {
        opacity: 1;
        background: var(--background-white);
    }

    .chevron_right {
        height: 100%;
        fill: var(--activation_text);
        transition: fill var(--transition);
        font-size: 24px;
        display: flex;
    }

    &:hover,
    &:focus {
        outline: none;

        .chevron_right {
            fill: var(--activation_text);
            transition: fill 0.2s ease-in;
        }
    }
}

.short_ans_modal {
    .short_ans_box {
        position: absolute;
        top: 0%;
        left: 0%;
        bottom: 0;
        right: 0;
        margin: auto;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        // min-width: 700px;
        border: none !important;
        outline: none !important;
        // background: rgba(255, 255, 255, 0.274);
        padding: 30px;

        .currentSlideIndex {
            position: absolute;
            inset: auto 0 50px 0;

            color: var(--textWhite);
            text-align: center;

            margin-inline: auto;
        }

        .big_sh_box {
            background: var(--textWhite);
            /* CP2/Elevation Light/3 */
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            border-radius: 12px;
            width: 100%;
            height: fit-content;
            margin-inline: auto;
            margin-block: 0;
            position: relative;

            .big_sh_content {
                padding: 20px;
                text-align: center;
                min-height: 280px;
                border-radius: 0;
                border-start-start-radius: 12px;
                border-start-end-radius: 12px;

                .quote {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-block-end: 30px;
                    margin-block-start: 10px;

                    svg {
                        width: 50px;
                        display: flex;
                        // transform: rotate(180deg);
                    }
                }

                p,
                .shortmodal_paragraph {
                    font-family: var(--mali);
                    font-style: normal;
                    font-weight: normal;
                    font-size: 28px;
                    // line-height: 1.32;
                    color: var(--text_color);
                    text-align: center;
                    // padding: 0 40px;
                    text-align: start;
                    word-break: break-word;

                    &.size26 {
                        font-size: 26px;

                        * {
                            font-size: 26px;
                        }
                    }

                    &.size24 {
                        font-size: 24px;

                        * {
                            font-size: 24px;
                        }
                    }

                    &.size22 {
                        font-size: 22px;

                        * {
                            font-size: 22px;
                        }
                    }

                    &.size20 {
                        font-size: 20px;

                        * {
                            font-size: 20px;
                        }
                    }

                    &.size18 {
                        font-size: 18px;

                        * {
                            font-size: 18px;
                        }
                    }

                    &.size16 {
                        font-size: 16px;

                        * {
                            font-size: 16px;
                        }
                    }

                    * {
                        font-family: var(--mali);
                        word-break: break-word;
                        font-size: 28px;
                    }

                    strong,
                    b {
                        font-weight: bold;
                    }

                    ul {
                        padding-inline-start: 28px;

                        li {
                            list-style: disc;
                        }
                    }

                    ol {
                        padding-inline-start: 28px;
                        margin: 0;

                        li {
                            list-style: decimal;
                        }
                    }
                }
            }

            .big_sh_head {
                width: 100%;
                padding: 15px 10px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .like_count {
                    width: auto;
                    padding: 4px 8px;
                    border: 3px solid var(--textWhite);
                    background: #ef476f;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    gap: 0.3rem;
                    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);

                    svg {
                        color: var(--textWhite);
                        font-size: 18px;
                        display: flex;
                    }

                    span {
                        color: var(--textWhite);
                        font-family: var(--font-stack);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}
