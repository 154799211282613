@use '../../../Style.scss' as *;

.barchart_sec {
    width: 100%;
    background: rgb(255, 255, 255);
    background: var(--gradientbg);
    .multipleChoiceContainer {
        max-width: 1440px;
        margin-inline: auto;
        margin-block: 0;
        padding: 0 15px;
    }
    .inner_barchart {
        width: 100%;
        display: flex;
        align-items: flex-end;
        height: var(--activitycontentHeight);
        padding-block-end: 62px;
        .chart {
            &.checking {
                li {
                    div {
                        i {
                            display: block;
                            color: var(--text_color);
                        }

                        div.no_height {
                            position: absolute;
                            transform: translateY(-105%);

                            & > span {
                                & > div {
                                    cursor: default;
                                }
                            }
                            i {
                                display: block;
                                color: rgb(13, 172, 61);
                            }
                        }
                    }
                }
            }

            display: table;
            table-layout: fixed;
            padding-block-start: 76px;
            width: 100%;
            max-width: 800px;
            height: 100%;
            margin-inline: auto;
            margin-block: 0;

            li {
                position: relative;
                display: table-cell;
                vertical-align: bottom;
            }

            div {
                margin-block: 0;
                margin-inline: 10%;
                display: block;
                position: relative;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                min-height: 8px;
                span {
                    display: flex;
                    div {
                        &::before {
                            display: none;
                        }
                    }
                }
                div {
                    margin: 0;
                }
                i {
                    display: none;

                    svg {
                        display: flex;
                        font-size: 48px;
                    }
                }

                .chart_tooltip {
                    position: absolute;
                    top: -46px;
                    left: 50%;
                    transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    color: var(--textWhite);
                    background: var(--activation_text);
                    font-weight: 500;
                    padding: 6px 10px;
                    font-size: 16px;
                    line-height: 16px;
                    border-radius: 20px;
                    white-space: nowrap;
                    display: inline-block;
                    font-family: var(--font-stack);
                    &::after {
                        position: absolute;
                        content: '';
                        bottom: -9px;
                        left: 50%;
                        transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -webkit-transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-inline-start: 8px solid transparent;
                        border-inline-end: 8px solid transparent;
                        border-block-start: 10px solid var(--activation_text);
                    }
                }

                &:before {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 100%;
                    padding-block-start: 5px;
                    padding-block-end: 0;
                    padding-inline: 1em;
                    display: block;
                    text-align: center;
                    content: attr(title);
                    word-wrap: break-word;
                    font-size: 24px;
                    font-weight: 500;
                    font-family: var(--font-stack);
                    color: var(--activation_text);
                }
            }
        }
    }
}
