@import '../../Style.scss';

.qna {
    width: 100%;
    height: 100%;

    .head {
        width: 100%;
        min-height: 68.38px;
    }

    .body_content {
        width: 100%;
        height: var(--activitycontentHeight);
        background: var(--lightdeepbg2);
    }

    .footer {
        position: relative;
        z-index: 1;
    }
}

.qnaViewport {
    .fullScreenPointGlobal {
        position: absolute;
        z-index: 1000;

        overflow: hidden;
    }
}
