@use '../../Style.scss' as *;

.contextualTutorial {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    height: 100%;

    .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 450px;

        .slideItem {
            width: 100%;
            height: 450px;

            .image {
                width: 100%;
                height: 100%;
            }
        }
    }

    .line_progress {
        flex-shrink: 0;
        width: 100%;
        height: 4px;

        .MuiLinearProgress-root {
            height: 4px;
            border-radius: 4px;
            background: var(--secondary_clr_2);

            &.regular .MuiLinearProgress-bar {
                transition: none;
                background: var(--primary-color);
            }

            span {
                transition: all 1s linear;
            }
        }
    }

    .textContainer {
        display: grid;
        grid-template-columns: 63% 37%;
        align-items: center;
        justify-content: center;
        gap: 10px;

        width: 100%;
        height: calc(800px - 454px);
        padding-inline: 28px;
        padding-block: 15px;

        &.shrinkButtonContainer {
            grid-template-columns: 70% 30%;
        }

        .sliderContainer {
            width: 100%;
            height: max-content;

            .slideItem {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 100%;
                height: max-content;
                margin-block: auto;

                .slideContent {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 10px;
                    width: 100%;
                    height: max-content;

                    .title {
                        font-size: 18px;
                        font-weight: 600;
                        color: var(--primary-color);
                    }

                    .description {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        color: var(--activation_text);
                    }
                }
            }
        }

        .btnContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            width: 100%;
            height: 40px;

            .nav_previous {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;

                color: var(--parple) !important;
                text-transform: none !important;

                width: max-content;
                max-width: max-content !important;
                height: 100%;
                padding-inline: 25px !important;
                border: none !important;
                border-radius: 1000px !important;
                background: none;
                cursor: pointer;

                &.hideBackBtn {
                    display: none;
                }

                .text {
                    font-size: 14px;
                    font-weight: 500 !important;
                }

                .icon {
                    @include displayFlex(row, center, center);
                    font-size: 18px;
                }
            }

            .nav_forward {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;

                flex-shrink: 0;
                font-weight: 500 !important;
                color: var(--textWhite) !important;
                text-transform: none !important;

                width: 95px;
                // max-width: max-content !important;
                height: 100%;
                // margin-inline-start: auto;
                // padding-inline: 25px !important;
                border: none !important;
                border-radius: 1000px !important;
                background: linear-gradient(90deg, var(--primary-color) 24.31%, #e963ff 105.79%);
                cursor: pointer;

                &.hideNextBtn {
                    display: none;
                }

                .text {
                    font-size: 14px;
                    font-weight: 500 !important;
                }

                .icon {
                    @include displayFlex(row, center, center);
                    font-size: 18px;
                }
            }
        }
    }
}
