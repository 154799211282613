.trialActivationButton {
    font-weight: 500 !important;
    color: var(--textWhite) !important;
    text-transform: none !important;
    white-space: nowrap;
    line-height: 19px;
    width: max-content;
    height: 40px;
    padding-inline: 25px !important;
    padding-block-start: 7px !important;
    border: none !important;
    border-radius: 1000px !important;
    background: linear-gradient(90deg, var(--primary-color) 24.31%, #e963ff 105.79%);
    max-width: max-content !important;

    &:hover {
        box-shadow: none;
    }

    &.aiLongButton {
        width: 220px !important;
        max-width: 220px !important;
    }

    .icon {
        padding-inline-start: 7px;
    }
}

.deleteGroupBtn {
    color: var(--error_span_color) !important;
    background: var(--error_span_color_light) !important;

    &.MuiLoadingButton-loading {
        color: transparent !important;
        background: var(--error_span_color_light) !important;

        .MuiCircularProgress-root {
            color: var(--error_span_color) !important;
        }
    }

    &:hover {
        color: var(--textWhite) !important;
        background: var(--error_span_color) !important;
    }
}
