.groupDetails {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .name {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .label {
            font-size: 14px;
            color: var(--greytext_color);

            padding-block-end: 5px;
        }

        .groupName {
            width: 250px;

            .MuiInput-input {
                text-align: center;
                box-shadow: none;
            }

            .MuiInput-root {
                &::after {
                    border-color: var(--backgroundcolor);
                }
            }
        }
    }

    .color {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding-block-start: 40px;

        .label {
            font-size: 14px;
            color: var(--greytext_color);

            padding-block-end: 16px;
        }

        .colorOptions {
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            gap: 16px 8px;

            width: max-content;

            .option {
                width: 32px;
                min-width: 0;
                height: 32px;
                border-radius: 50%;

                :is(.doneIcon, .takenIcon) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
