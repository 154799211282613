@use '../../../Style.scss' as *;

.sidebar {
    flex-shrink: 0;
    width: 324px;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    position: relative;

    &.close {
        width: 0;
        right: 0;

        .content .arrow {
            transform: rotate(0);
            left: 5px;
        }

        .content {
            border: none;

            .innerBody {
                display: none;
            }
        }
    }

    .content {
        margin-block-start: 24px;
        width: 100%;
        height: 380px;
        // overflow: auto;
        border-inline-end: 1px solid var(--light-bg);
        position: relative;

        .arrow {
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
            position: absolute;
            top: calc(50% - 32px);
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid var(--light-bg);
            left: 308px;
            background-color: var(--background-white);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 11;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.05), 0px 2px 1px rgba(0, 0, 0, 0.05);
            transform: rotate(180deg);

            svg {
                color: var(--activation_text);
                font-size: 16px;
                display: flex;
            }
        }

        .innerBody {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            width: 100%;
            height: 100%;

            // padding-inline-start: 40px;
            overflow: hidden auto;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;

            .qrCode {
                display: flex;
                align-items: center;
                justify-content: center;

                width: max-content;
                height: max-content;
                padding-block: 20px;
                padding-inline: 20px;
                border-radius: 15px;
                background: var(--background-white);
                cursor: pointer;
            }

            .classDetails {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                :is(.link, .code) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 3px;
                    flex-shrink: 0;

                    padding-block: 12px;
                    padding-inline: 10px;
                    border-radius: 10px;
                    background: var(--background-white);

                    .tag {
                        position: relative;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 3px;

                        font-size: 12px;
                        font-weight: 500;
                        color: var(--placeholder-color);

                        .copyIcon {
                            font-size: 14px;

                            margin-block-start: -2px;
                            cursor: pointer;

                            &:hover {
                                color: var(--primary-color);
                            }
                        }

                        .copyurl_tooltip {
                            position: absolute;
                            background: var(--tooltip_black_800);
                            background-blend-mode: overlay;
                            border-radius: 4px;
                            top: -5px;
                            left: auto;
                            width: 90px;
                            padding-block: 5px;
                            padding-inline: 8px;
                            @include displayFlex(row, center, center);
                            gap: 5px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            font-family: var(--font-stack);
                            color: var(--textWhite);
                            transition: var(--transition);
                            transform: translateY(-100%);
                            opacity: 1;
                            pointer-events: none;
                            z-index: 0;

                            svg {
                                font-size: 16px;
                            }
                        }
                    }

                    .value {
                        font-size: 19px;
                        font-weight: 500;
                        color: var(--primary-color);
                    }
                }

                .link {
                    width: 140px;
                }

                .code {
                    width: 110px;
                }
            }

            .MuiChip-root {
                width: fit-content;
                background: var(--background-white);
                // border: 2px solid #ef476f;

                .MuiAvatar-root {
                    margin-inline-start: 3px;
                    width: 24px;
                    height: 24px;
                    font-size: 10px;
                    line-height: 17px;
                }

                .MuiChip-label {
                    font-family: var(--font-stack);
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--text_color);
                    padding-inline-start: 8px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 150px;
                    white-space: nowrap;
                }
            }
        }
    }
}
