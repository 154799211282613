@import '../../../../Style.scss';

.qnaStatus {
    position: absolute;
    inset: auto 0 120px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 32px;

    &.enabled {
        background: var(--checkboxGreen);
    }

    &.disabled {
        background: var(--submission_btn);
    }

    .text {
        font-size: 16px;
        color: var(--textWhite);
        text-align: center;
    }
}
