@use '../../../../../Style.scss' as *;
.contentUi__sec {
    height: 100%;
    width: 100%;
    // height: 100vh;
    // background: yellow;
    background-size: cover;
    background-position: bottom center;
    -webkit-transition: background-image 0.8s ease-in-out;
    -o-transition: background-image 0.8s ease-in-out;
    -moz-transition: background-image 0.8s ease-in-out;
    transition: background-image 0.8s ease-in-out;
    .inner_content {
        width: 100%;
        text-align: center;
        padding-block-start: clamp(60px, 10%, 108px);
        .cp_logo {
            width: 80px;
            margin-inline: auto;
            img {
                width: 100%;
            }
        }
        .urlsearch {
            width: 100%;
            max-width: 400px;
            margin-block-start: 36px;
            margin-inline: auto;

            .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:active {
                background: var(--background-white) !important;

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: var(--light_border);

                .MuiOutlinedInput-input {
                    box-shadow: none;
                }
            }

            .MuiOutlinedInput-root {
                .MuiOutlinedInput-input {
                    box-shadow: none;
                }

                &.Mui-focused {
                    border-color: var(--parple);
                }
                border-radius: 30px;
                height: 36px;
                background: var(--background-white) !important;
                padding-inline: 12px 8px;
                .MuiInputAdornment-root {
                    svg {
                        color: var(--icon_color);
                        font-size: 18px;
                        &.qrcodeicon {
                            margin-inline-end: 8px;
                        }
                    }
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: var(--light_border);
            }

            input {
                padding: 6px 0px;
                font-size: 14px;
                line-height: 16px;
                font-family: var(--font-stack);
                color: var(--activation_text);

                &::placeholder {
                    color: var(--placeholder-color);
                    opacity: 1;
                }
            }

            label {
                -webkit-transform: translate(14px, 11px) scale(1);
                -moz-transform: translate(14px, 11px) scale(1);
                -ms-transform: translate(14px, 11px) scale(1);
                transform: translate(14px, 11px) scale(1);
            }

            label.Mui-focused {
                -webkit-transform: translate(14px, -6px) scale(0.75);
                -moz-transform: translate(14px, -6px) scale(0.75);
                -ms-transform: translate(14px, -6px) scale(0.75);
                transform: translate(14px, -6px) scale(0.75);
            }

            .MuiInputLabel-shrink {
                -webkit-transform: translate(14px, -6px) scale(0.75);
                -moz-transform: translate(14px, -6px) scale(0.75);
                -ms-transform: translate(14px, -6px) scale(0.75);
                transform: translate(14px, -6px) scale(0.75);
            }
        }
    }
    .start_page_pin {
        width: 100%;
        padding-block-start: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .pin_wraper {
            width: fit-content;
            display: flex;
            align-items: flex-start;
            gap: 28px 36px;
            flex-wrap: wrap;
            max-width: 364px;
            .pin_box {
                width: auto;
                padding: 10px;
                border-radius: 16px;
                background: var(--background-white);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 24px;
                    height: 24px;
                    object-fit: contain;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
