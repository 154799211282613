.profile_upload {
    width: auto;
    position: relative;

    .MuiAvatar-root {
        height: 80px;
        width: 80px;
        outline: 1px solid #cfd5ff7a;
        font-size: 46px;
        cursor: pointer;
    }

    .upload_profile_images {
        height: 80px;
        width: 80px;
        background: var(--light-bg);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;

        input {
            display: none;
        }

        label {
            line-height: 0vh;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            height: 100%;
            justify-content: center;
        }

        span {
            color: var(--parple);
            font-size: 14px;
            line-height: 24px;
            font-family: var(--font-stack);
            font-weight: 500;
        }

        .MuiSvgIcon-root {
            font-size: 30px;
            color: var(--backgroundcolor);
        }
    }

    .changes_profile_images {
        position: absolute;
        bottom: -5px;
        right: -3px;
        z-index: 2;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 20px;
        box-shadow: 0px 0.857143px 1.71429px rgba(0, 0, 0, 0.05),
            0px 0.857143px 3.42857px 0.857143px rgba(0, 0, 0, 0.05);
        background: var(--background-white);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        input {
            display: none;
        }

        label {
            line-height: 0vh;
            cursor: pointer;
        }

        .MuiSvgIcon-root {
            font-size: 18px;
            color: var(--backgroundcolor);

            width: 100%;
            height: 100%;
            padding: 7px;
        }
    }
}
