@import '../../../../Style.scss';

.questionCard {
    width: 100%;
    border-radius: 12px;
    box-shadow: var(--shadow_02);
    background: var(--background-white);
    overflow: hidden;

    .inner_questionCard {
        width: 100%;
        position: relative;

        .question_area {
            width: 100%;
            padding: 22px;
            padding-inline-end: 46px;
            cursor: pointer;

            .text {
                font-size: 16px;
                line-height: 22px;
                color: var(--activation_text);
            }
        }

        .upgrade_ans {
            position: absolute;
            top: 12px;
            right: 12px;
            @include displayFlex(row, center, center);
            width: fit-content;
            height: fit-content;
            gap: 4px;

            .icon {
                display: flex;
                align-items: center;

                svg {
                    width: 14px;
                    height: 14px;
                    margin-block-end: 2px;
                }
            }

            .number {
                font-size: 16px;
                color: var(--activation_text);
            }
        }
    }
}

html {
    &:global(.rtl) {
        .questionCard {
            .inner_questionCard {
                .upgrade_ans {
                    position: absolute;
                    right: initial;
                    left: 10px;
                }
            }
        }
    }
}
