@use '../../../Style.scss' as *;

.aiBasicUser {
    width: 100%;
    height: 100%;
    background: var(--clr_grad_bg_02);
    .inner_aiBasicUsr {
        width: 100%;
        height: 100%;
        @include displayFlex(row, center, center);
        .aiBasic_content {
            width: 100%;
            text-align: center;
            .thumbnail_box {
                position: relative;
                max-width: 600px;
                max-width: 605px;
                min-height: 340px;
                margin-inline: auto;
                border-radius: 14px;
                overflow: hidden;
                background: var(--background_black);
                cursor: pointer;
                @include displayFlex(row, center, center);

                .videoThumbnail {
                    width: 100%;
                    border-radius: 16px;
                }

                .overlay {
                    position: absolute;
                    inset: 0 0 0 0;
                    border-radius: 50%;
                    transition: 0.2s all linear;
                    @include displayFlex(row, center, center);
                    margin: auto;
                    opacity: 0.4;

                    .playIcon {
                        display: flex;
                    }
                }
                &:hover {
                    .overlay {
                        transform: scale(1.2);
                        opacity: 1;
                    }
                }
            }
            .title {
                font-size: 24px;
                font-weight: 500;
                color: var(--activation_text);
                font-family: var(--font-stack);
                margin-block-start: 24px;
            }
            .ai_basic_btn_group {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 17px;

                width: 100%;
                margin-block-start: 24px;

                .default_btn {
                    @include activationbtn;
                    width: 220px;
                    color: var(--parple);
                    padding: 6px 28px !important;
                    background: linear-gradient(0deg, rgba(99, 120, 255, 0.15), rgba(99, 120, 255, 0.15)), #ffffff;

                    &.reset_btn {
                        min-width: auto;
                    }

                    &.Mui-disabled {
                        color: var(--parple);
                        opacity: 0.7;
                        cursor: default;
                    }
                }
            }
        }
    }
}
