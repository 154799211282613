.trialActivationModal {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 480px !important;
    height: 362px !important;
}

.subscriptionTrialActivationModal {
    display: flex;
    align-items: center;
    justify-content: center;

    width: min-content !important;
    max-width: min-content !important;
    height: min-content !important;

    .trialActivationModalContent {
        width: 400px !important;
        max-width: 400px !important;
        height: 400px !important;

        padding-inline: 50px;
    }
}

.classFullTrialActivation {
    .trialActivationModalContent {
        padding-inline: 100px;
    }
}

.trialActivationModalContent {
    &.default {
        width: 280px;
        height: 362px;

        .lottieContainer {
            width: 150px;
            height: 150px;
        }

        .btnContainer {
            width: 200px;
            height: 40px;
        }
    }
}

.trialActivationModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;

    .lottieContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .lottie {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 13px;

        text-align: center;

        .heading {
            font-size: 16px;
            font-weight: 600;
            color: var(--primary-color);
            line-height: 22.4px;

            &.error {
                color: var(--error_span_color);
            }
        }

        .description {
            font-size: 14px;
            font-weight: 400;
            color: var(--activation_text);
            line-height: 22px;
        }
    }

    .btnContainer {
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--textWhite);
            text-transform: none;

            width: 100%;
            height: 100%;
            background: var(--primary-color);
            border-radius: 100px;
            box-shadow: none;
        }
    }
}
