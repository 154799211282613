@import '../../../../Style.scss';

.questionCardFooter {
    width: 100%;
    padding-inline: 24px 16px;
    height: 45px;
    border-top: 1px solid var(--cpButton_14);

    &.modalView {
        height: 56px;
    }

    .click_area {
        width: 100%;
        height: 40px;
        position: absolute;
        left: 0;
        z-index: 0;
        cursor: pointer;
        border-radius: 0 0 12px 12px;
    }

    .inner_questionCardFooter {
        width: 100%;
        height: 100%;
        @include displayFlex(row, center, space-between);
        gap: 10px;

        .profile_foot {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;

            .avater_box {
                width: auto;
                :global(.MuiAvatar-root) {
                    height: 27px;
                    width: 27px;
                    font-size: 14px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .text {
                font-size: 13px;
                font-weight: var(--fw_medium);
                color: var(--activation_text);
                position: relative;
            }

            .largeFontText {
                font-size: 15px;
                line-height: 17px;
                font-weight: var(--fw_medium);
                color: var(--activation_text);
            }

            .smallFontText {
                font-size: 15px;
                line-height: 17px;
                font-weight: var(--fw_medium);
                color: var(--activation_text);
            }
        }

        .right_side {
            width: max-content;
            @include displayFlex(row, center, flex-end);
            gap: 8px;

            &.modalView {
                width: 100%;

                .pointsBtn {
                    width: 35px;
                    height: 35px;
                    margin-inline-end: auto;

                    :global(.givePointButton) {
                        width: 35px;
                        min-width: 0;
                        height: 35px;
                        min-height: 0;

                        :global(.staricon) {
                            width: 23px;
                            height: 23px;
                        }
                    }
                }

                :is(.answer_mark, .dismiss) {
                    font-size: 15px;

                    height: 35px;
                    padding: 3px 15px;
                }

                .answer_mark {
                    gap: 6px;
                    width: 150px;

                    .icon {
                        margin-block-end: 1px;

                        svg {
                            font-size: 20px;
                        }
                    }
                }
            }

            .pointsBtn {
                width: 27px;
                height: 27px;

                :global(.givePointButton) {
                    width: 27px;
                    min-width: 0;
                    height: 27px;
                    min-height: 0;

                    :global(.staricon) {
                        width: 17px;
                        height: 17px;
                    }
                }
            }

            .answer_mark {
                @include answer_mark_btn;
                transition: all 0.1s ease-in-out;

                &:global(.MuiLoadingButton-loading) {
                    color: transparent;
                    background: var(--icon_green);
                    border-color: var(--icon_green);

                    :global(.MuiLoadingButton-loadingIndicator) {
                        position: absolute;
                        left: 50%;

                        padding-inline: 0;
                    }
                }

                &.answered {
                    color: var(--textWhite);

                    border: none;
                    background: var(--icon_green);
                    pointer-events: none;
                }

                .icon {
                    display: flex;
                    color: var(--textWhite);

                    svg {
                        @include displayFlex(row, center, center);
                        font-size: 16px;
                    }
                }

                :global(.MuiTouchRipple-ripple) {
                    :global(.MuiTouchRipple-child) {
                        background-color: var(--icon_green);
                    }
                }
            }

            .dismiss {
                @include dismiss_btn;

                &:global(.MuiLoadingButton-loading) {
                    color: transparent;

                    :global(.MuiLoadingButton-loadingIndicator) {
                        position: absolute;
                        left: 50%;

                        padding-inline: 0;
                    }
                }
            }
        }
    }
}

.qnaPointsOverlay {
    position: absolute;
    inset: 0;

    pointer-events: none;

    &.show {
        background: rgba(0, 0, 0, 0.75);
    }
}
