.savedClassGroupCardContainer {
    width: 100%;
    height: max-content;
    max-height: calc(100% - 40px);
    padding-block-start: 2px;
    overflow: auto;

    .savedClassGroups {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        flex-wrap: wrap;

        width: 100%;
        height: max-content;
        padding-block-end: 15px;
        padding-inline: 21px 15px;

        &.centerAlign {
            justify-content: center;
        }

        .addBtn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            font-size: 16px;
            font-weight: 500;
            color: var(--activation_text);
            text-transform: none;

            width: 280px;
            height: 120px;
            background: var(--background-white);
            border: 1px solid var(--cp-2-sys-light-primary-container, #cfd5ff);
            border-radius: 12px;
            cursor: pointer;
            transition: all 0.15s ease-in-out;

            &:hover {
                background: var(--btnbgcolor_light);
            }

            .icon {
                font-size: 55px;
                color: var(--cpbutton);
            }
        }
    }
}
