@use '../../Style.scss' as *;

.joining_sec {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    input[type='password']::-ms-reveal,
    input[type='password']::-ms-clear {
        display: none;
    }

    .error {
        padding-inline-start: 0px;
        color: var(--danger);
        font-size: 12px;
        line-height: 16px;
        font-family: var(--font-stack);
        font-weight: 400;
        text-align: start;
    }

    .version {
        position: absolute;
        bottom: 16px;
        left: 40px;
        width: fit-content;
        height: fit-content;

        p {
            font-size: 16px;
            line-height: 23px;
            font-weight: 400;
            color: var(--textWhite);
            z-index: 8;
        }
    }

    :is(.top_error, .top_success) {
        margin-block-end: 15px;
        margin-inline-end: auto;

        p {
            padding-inline-start: 0px;
            font-size: 12px;
            line-height: 16px;
            font-family: var(--font-stack);
            font-weight: 400;
            text-align: start;
        }
    }

    .top_error p {
        color: var(--danger);
    }

    .top_success p {
        color: var(--certified);
    }

    .left_side {
        width: 365px;
        height: 100%;
        display: flex;
        text-align: start;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-block-start: 72px;
        padding-inline: 40px 20px;
        max-width: 365px;
        margin-inline: auto;
        background: linear-gradient(132.29deg, #7083ff 29.47%, #5d63f7 94.59%);
        position: relative;
        isolation: isolate;

        .cp_logo {
            width: 100%;

            img {
                max-width: 100%;
            }
        }

        .welcome_content {
            width: 100%;
            margin-block-start: 130px;

            .title {
                color: var(--textWhite);
                font-weight: 600;
                font-size: 40px;
                line-height: 47px;
                margin-block-end: 24px;
            }

            .subtext {
                color: var(--textWhite);
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
            }
        }

        .square1 {
            position: absolute;
            left: -38%;
            bottom: 0;
            width: 328px;
            height: 328px;
            z-index: -2;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .square2 {
            position: absolute;
            left: -38%;
            bottom: 0;
            width: 272px;
            height: 272px;
            z-index: 0;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .shape2 {
            position: absolute;
            right: -40%;
            bottom: 0;
            width: 272px;
            height: 265px;
            z-index: -2;

            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .shapeRound {
            position: absolute;
            right: -5px;
            top: 0;
            width: 170px;
            height: 216px;
            z-index: -1;

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .right_side {
        position: relative;
        flex: 1;
        height: 100%;

        .signUpSimplified {
            position: absolute;
            inset: auto 10px 10px auto;

            font-size: 14px;
            font-weight: 500;
            color: var(--btnbgcolor);
            cursor: pointer;
        }

        .innerContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            max-width: 420px;
            margin-inline: auto;
            padding-inline: 30px;

            .intro {
                width: 100%;
                margin-block-end: 22px;
                // padding-block-start: 20px;/

                &.compact {
                    margin-block-end: 10px;
                }

                h4 {
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 39px;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    color: var(--text_color);
                    margin-block-start: 15px;
                }
            }

            :is(.jobRoleOverlay, .filteredOrgOverlay) {
                position: absolute;
                inset: 0 0 0 0;
                z-index: 1;

                display: none;

                &.show {
                    display: block;
                }
            }

            .form {
                width: 100%;

                .profile_left {
                    width: 100%;

                    &.signUpDetails {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;

                        width: 100%;

                        .signUpName {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 25px;

                            width: 100%;
                        }
                    }

                    .userConcent {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        padding-block-start: 10px;

                        .MuiCheckbox-root {
                            padding: 5px;

                            .icon {
                                color: var(--btnbgcolor);
                                font-size: 18px;
                            }
                        }

                        .text {
                            font-family: var(--font-stack);
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;

                            padding-block-start: 2px;

                            .link {
                                color: var(--upgrade);
                            }

                            &.error {
                                color: var(--error_span_color);

                                .link {
                                    color: var(--error_span_color);
                                }
                            }
                        }
                    }
                }

                :is(.job_List_profile, .orgList) {
                    width: 100%;

                    // .pf_form {
                    //     min-height: 60px !important;
                    // }
                }

                .pf_form {
                    width: 100%;
                    min-height: 68px;
                    position: relative;

                    &.autoCompleteOrganization {
                        z-index: 2;
                    }

                    &.inputMobile {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-height: 46px;
                        width: 100%;
                        padding-block-end: 12px;
                        .MuiFormControl-root {
                            height: 46px;
                        }
                    }

                    .mobileCountryCode {
                        width: 65px !important;
                    }

                    // &.compact {
                    //     min-height: 70px;
                    // }

                    &.active .MuiFormControl-root .MuiFormHelperText-root {
                        color: var(--user-sucess);
                    }

                    input {
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }
                    }

                    :is(.jobError, .subjectError, .loginError) {
                        width: auto;
                        margin-inline-end: auto;

                        p {
                            padding-inline-start: 0px;
                            padding-block-start: 5px;
                            color: var(--danger);
                            font-size: 12px;
                            line-height: 16px;
                            font-family: var(--font-stack);
                            font-weight: 400;
                            text-align: start;
                        }
                    }

                    .forgetPassword {
                        width: auto;
                        position: absolute;
                        right: 0;
                        bottom: 1px;

                        p {
                            cursor: pointer;
                            color: var(--parple);
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;

                            &:hover {
                                text-decoration: underline !important;
                            }
                        }
                    }

                    .inactive input {
                        &::selection {
                            background: transparent;
                        }
                    }

                    .MuiFormControl-root.select_box {
                        .MuiInputLabel-root {
                            &.Mui-disabled + p {
                                cursor: default;

                                &::after {
                                    border: none;
                                }

                                &::before {
                                    border: none;
                                }

                                svg {
                                    display: none;
                                }
                            }
                        }

                        &.focus {
                            .MuiInputLabel-root {
                                -webkit-transform: translate(0px, -1.5px) scale(0.75);
                                -moz-transform: translate(0px, 1px) scale(0.75);
                                -ms-transform: translate(0px, 1px) scale(0.75);
                                transform: translate(0px, 1px) scale(0.75);
                            }
                        }

                        &.show_focus {
                            .MuiInputLabel-root {
                                color: var(--parple);
                                -webkit-transform: translate(0px, -1.5px) scale(0.75);
                                -moz-transform: translate(0px, 1px) scale(0.75);
                                -ms-transform: translate(0px, 1px) scale(0.75);
                                transform: translate(0px, 1px) scale(0.75);
                            }

                            .select::after {
                                -webkit-transform: scaleX(1);
                                -moz-transform: scaleX(1);
                                -ms-transform: scaleX(1);
                                transform: scaleX(1);
                            }

                            svg {
                                transform: rotate(0deg);
                                -webkit-transform: rotate(0deg);
                                -moz-transform: rotate(0deg);
                            }
                        }

                        &:not(.show_focus).hasValue .MuiInputLabel-root {
                            opacity: 0;
                        }
                    }

                    .select {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: var(--activation_text);
                        font-family: var(--font-stack);
                        font-size: 14px;
                        font-weight: 400;
                        padding-block-end: 8px;
                        padding-inline-end: 6px;
                        padding-block-start: 6px;
                        margin-block-start: 12px;
                        position: relative;
                        cursor: pointer;

                        svg {
                            color: var(--activation_text);
                            font-family: var(--font-stack);
                            font-size: 20px;
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                            -moz-transform: rotate(180deg);
                            -webkit-transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            margin-inline-start: auto;
                        }

                        &::before {
                            border-block-end: 1px solid var(--secondary_text);
                            left: 0;
                            bottom: 0;
                            content: '\00a0';
                            position: absolute;
                            right: 0;
                            -webkit-transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            pointer-events: none;
                            height: 0;
                        }

                        &::after {
                            border-block-end: 1px solid var(--parple);
                            left: 0;
                            bottom: 0;
                            content: '\00a0';
                            position: absolute;
                            -webkit-transform: scaleX(0);
                            -moz-transform: scaleX(0);
                            -ms-transform: scaleX(0);
                            transform: scaleX(0);
                            -webkit-transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            transition: border-block-end-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            pointer-events: none;
                            height: 0;
                            right: 0;
                        }

                        &:focus::after {
                            -webkit-transform: scaleX(1);
                            -moz-transform: scaleX(1);
                            -ms-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }

                    .subject_checkbox {
                        width: 100%;

                        .MuiFormControl-root {
                            margin: 0;
                        }

                        .MuiFormControlLabel-root {
                            margin: 0;
                        }

                        .MuiFormGroup-root {
                            flex-direction: row;
                        }

                        .checkbox {
                            width: 50%;
                            float: left;
                        }
                    }

                    .MuiFormControl-root {
                        width: 100%;
                        margin: 0;

                        p.Mui-error {
                            padding-inline-start: 0px;
                            color: var(--danger);
                            font-size: 12px;
                            line-height: 16px;
                            font-family: var(--font-stack);
                            font-weight: 400;
                            text-align: start;
                        }

                        .MuiInputLabel-root {
                            font-family: var(--font-stack);
                            font-size: 14px;
                            color: var(--user_emailtext);
                            -webkit-transform: translate(0px, 21px) scale(1);
                            -moz-transform: translate(0px, 21px) scale(1);
                            -ms-transform: translate(0px, 21px) scale(1);
                            transform: translate(0px, 21px) scale(1);

                            &.Mui-focused {
                                color: var(--parple);
                            }

                            &.MuiInputLabel-shrink {
                                -webkit-transform: translate(0px, -1.5px) scale(0.75);
                                -moz-transform: translate(0px, 1px) scale(0.75);
                                -ms-transform: translate(0px, 1px) scale(0.75);
                                transform: translate(0px, 1px) scale(0.75);
                            }
                        }

                        label + .MuiInput-underline {
                            margin: 0;
                            margin-block-start: 16px;

                            .MuiInputAdornment-root {
                                margin: 0;
                                margin-inline-start: 8px;
                            }

                            &.editableInputBox {
                                &.Mui-disabled {
                                    &.MuiInput-underline {
                                        &::after {
                                            display: block;
                                        }

                                        &::before {
                                            display: block;
                                        }
                                    }

                                    input {
                                        & + .MuiInputAdornment-root {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }

                        .Mui-disabled {
                            &.MuiInput-underline {
                                &::after {
                                    display: none;
                                }

                                &::before {
                                    display: none;
                                }
                            }

                            input {
                                -webkit-text-fill-color: var(--activation_text);

                                & + .MuiInputAdornment-root {
                                    display: none;
                                }
                            }
                        }

                        .MuiInput-underline {
                            input {
                                color: var(--activation_text);
                                font-family: var(--font-stack);
                                font-size: 14px;
                                font-weight: 400;
                                padding-block-end: 8px;
                                padding-inline-end: 6px;
                                padding-inline-start: 0px;
                                box-shadow: none;

                                &::placeholder {
                                    color: var(--placeholder-color);
                                    opacity: 1;
                                }
                            }

                            &.Mui-error {
                                &::after {
                                    border-block-end: 1px solid var(--danger);
                                }
                            }

                            &::after {
                                border-block-end: 1px solid var(--parple);
                            }

                            &::before {
                                border-block-end: 1px solid var(--text-gray);
                            }
                        }
                    }

                    &.passwordField {
                        min-height: 50px;
                    }
                }

                .passwordGuideline {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    font-size: 12px;
                    line-height: 16px;
                    color: #49454f;

                    padding-block-end: 12px;

                    :is(.letter, .number, .minimumChar) {
                        padding-inline-start: 3px;

                        &.active {
                            color: #3bba59;
                        }

                        // span {
                        //     font-weight: 600;
                        // }
                    }
                }
            }
            .divider {
                width: 100%;
                text-align: center;
                position: relative;
                .text {
                    padding-block: 22px;
                    padding-inline: 18px;
                    background: var(--background-white);
                    width: fit-content;
                    margin-inline: auto;
                    position: relative;
                    z-index: 1;
                    font-size: 12px;
                }
                // &::after {
                //     content: '';
                //     position: absolute;
                //     left: 0;
                //     bottom: 0px;
                //     top: 0;
                //     margin-block: auto;
                //     height: 1px;
                //     width: 100%;
                //     z-index: 0;
                //     background: var(--borderlight);
                // }
            }

            .social_btn {
                // width: 100%;
                // display: grid;
                // grid-template-columns: repeat(3, 1fr);
                display: flex;
                gap: 20px;

                width: max-content;
                margin-inline: auto;
                // margin-block-start: 14px;

                .fb_button {
                    @include displayFlex(row, center, center);
                    // gap: 7px;
                    // padding-inline: 16px;
                    // min-height: 40px;
                    border-radius: 1000px;
                    // border-radius: 40px;
                    // background: var(--fb_bg);
                    // color: var(--textWhite);
                    // text-transform: none;
                    border: 1px solid var(--border_D7D6D7);
                    // font-size: 13px;
                    // font-weight: 500;
                    // line-height: 19px;
                    width: 50px;
                    min-width: 0;
                    height: 50px;
                    padding: 0;
                }

                :is(.google_btn, .microsoft_btn) {
                    button {
                        @include displayFlex(row, center, center);
                        // gap: 7px;
                        // padding-inline: 16px;
                        // min-height: 40px;
                        border-radius: 1000px;
                        // border-radius: 40px;
                        // background: var(--background-white);
                        // color: var(--activation_text);
                        // text-transform: none;
                        border: 1px solid var(--border_D7D6D7);
                        // font-size: 13px;
                        // font-weight: 500;
                        // line-height: 19px;
                        width: 50px;
                        min-width: 0;
                        height: 50px;
                        padding: 0;
                    }
                }

                :is(.fb_button, .google_btn, .microsoft_btn) {
                    .image {
                        width: 25px;
                        height: 25px;
                        object-fit: contain;
                    }
                }
            }

            :is(.signUp_link, .signIn_link) {
                width: 100%;
                text-align: center;
                // margin-block-start: 40px;
                position: absolute;
                bottom: 18px;
                left: 0;
                right: 0;
                margin-inline: auto;

                // &.compact {
                //     margin-block-start: 20px;
                // }

                p {
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--text_color);

                    span {
                        color: var(--parple);
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }
            }

            .profile_update_btn {
                width: 100%;
                padding-block-start: 24px;
                display: flex;
                gap: 8px;

                &.compact {
                    padding-block-start: 15px;
                }

                button {
                    @include profile_update_btn;
                    background: var(--btnbgcolor);
                    max-width: 100%;
                    min-height: 40px !important;
                    border-radius: 6px;

                    .MuiButton-startIcon {
                        margin-left: 0;
                        margin-right: 0;
                        margin-inline-end: 6px;
                    }

                    &:hover {
                        background: var(--btnhover);
                    }

                    .MuiLoadingButton-startIconLoadingStart {
                        display: none;
                    }

                    .MuiLoadingButton-loadingIndicator {
                        position: relative;
                        left: 0;
                        display: flex;
                        padding-inline: 0 5px;

                        .MuiCircularProgress-root svg {
                            height: auto !important;
                        }
                    }
                }

                .backBtn {
                    max-width: 48px;
                    padding: 0 !important;
                    background: hsla(231.9, 100%, 69.4%, 0.11);

                    svg {
                        color: #6378ff;

                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: #6378ff;

                        svg {
                            color: #ffffff;
                        }
                    }
                }
            }

            &.newLogin {
                padding-inline: 60px !important;
                padding-block-end: 20px;

                .logo {
                    width: 55px;
                    aspect-ratio: 1;

                    .image {
                        width: 100%;
                        height: 100%;
                    }
                }

                .heading {
                    font-size: 24px;
                    font-weight: 500;
                    color: var(--activation_text);

                    margin-inline-end: auto;
                    padding-block: 15px 25px;
                }

                .socialButtons {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    width: 100%;
                    padding-block-end: 15px;

                    .button {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        color: var(--activation_text);
                        text-transform: none;

                        width: 100%;
                        padding: 8px 8px;
                        border: 1px solid var(--border_color_DFDFDF);
                        border-radius: 4px;

                        .image {
                            width: 18px;

                            &.google {
                                width: 21px;
                            }
                        }
                    }
                }

                .divider {
                    margin-block-start: 10px;

                    .line {
                        position: absolute;
                        inset: 0;

                        height: max-content;
                        margin: auto;
                        border-top: 1px solid #e9e9e9;
                    }

                    .text {
                        font-size: 16px;
                        font-weight: 500;
                        color: var(--activation_text);

                        padding-block: 0;
                    }
                }

                .form.newLogin {
                    padding-block-start: 15px;

                    .pf_form {
                        min-height: 65px;
                    }
                }
            }
        }

        .language_change_sec {
            position: absolute;
            top: 15px;
            right: 10px;

            .language_change_btn {
                align-items: center;
                background: var(--background-white);
                border-radius: 12px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                gap: 8px;
                border: 1px solid var(--borderlight);
                border-radius: 12px;
                min-height: 40px;
                min-width: 100px;
                padding-inline: 10px 4px;

                .globe {
                    color: var(--parple);
                }

                .flagIcon {
                    width: 24px;
                    height: 24px;
                }

                h3 {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--text_color);

                    svg {
                        font-size: 20px;
                        margin-inline-start: 6px;
                    }
                }
            }
        }

        .listItemContainer {
            display: none;

            &.show {
                position: absolute;
                inset: 46px 0 auto auto;
                z-index: 2;
                display: inline-block;
                width: 100%;
                max-height: 155px;
                background: var(--background-white);
                box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
                overflow: auto;

                & > button {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 6px;

                    color: var(--backgroundcolor);
                    border-radius: 0;
                    width: 100%;
                    // height: 45px;
                    padding: 8px 16px;
                    background: var(--background-white);

                    &:hover {
                        background: rgba(99, 120, 255, 0.08);
                    }

                    &.active {
                        background: rgba(99, 120, 255, 0.08);
                    }

                    &:nth-of-type(1) {
                        border-radius: 0;
                        border-start-start-radius: 4px;
                        border-start-end-radius: 4px;
                    }

                    &:nth-last-of-type(1) {
                        border-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            font-size: 20px;
                            font-weight: 500;
                            color: var(--parple);
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        text-transform: none;
                        color: var(--activation_text);
                        text-align: start;
                    }
                }
            }
        }
    }

    .dropdown_menu {
        width: 100%;

        &.show {
            display: block;
        }

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            display: none;

            &.show {
                display: block;
            }
        }

        .content_menu {
            position: absolute;
            top: 40px;
            right: 00px;
            background: var(--clr_background_primary);
            box-shadow: 0px 5px 8px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            transition: var(--transition);
            opacity: 0;
            -webkit-transform-origin: right top 0px;
            transform-origin: right top 0px;
            -webkit-transform: scale(0);
            transform: scale(0);
            z-index: 1000;
            max-height: 196px;
            overflow: overlay;

            &.show {
                opacity: 1;
                -webkit-transform-origin: right top 0px;
                transform-origin: right top 0px;
                -webkit-transform: scale(1);
                transform: scale(1);
            }

            ul {
                min-width: 182px;
                padding-block: 8px;
                list-style: none;
                position: relative;
                background: linear-gradient(0deg, rgba(99, 120, 255, 0.05), rgba(99, 120, 255, 0.05)), #ffffff;
                border-radius: 8px;

                li {
                    list-style: none;
                    width: 100%;

                    p {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        padding-block: 8px;
                        padding-inline: 12px;
                        color: var(--text_color);
                        white-space: nowrap;
                        cursor: pointer;
                        font-size: 14px;
                        line-height: 20px;
                        border-radius: 8px;

                        .icon {
                            display: flex;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            margin-inline-end: 4px;
                        }

                        img {
                            max-width: 100%;
                            width: 20px;
                        }

                        svg {
                            font-size: 20px;
                            color: var(--btnbgcolor);
                        }

                        &:hover {
                            background: var(--clr_background_light);
                        }

                        &.active {
                            background: var(--parple);
                            color: var(--textWhite);
                        }
                    }
                }

                @include max(999px) {
                    min-width: 172px;
                }
            }
        }
    }

    &.fullView {
        .left_side {
            display: none;
        }

        .right_side {
            .innerContainer {
                max-width: 780px;

                .intro {
                    text-align: center;
                    padding-block-start: 0;
                    margin-block-end: 48px;
                }

                .profile_update_btn {
                    max-width: 272px;
                    margin-inline: auto;
                }
            }
        }
    }
}

.signUpInterest {
    .chip_group {
        @include displayFlex(row, flex-start, center);
        flex-wrap: wrap;
        gap: 16px;
        padding-block-end: 25px;

        .chipP {
            @include displayFlex(row, center, center);
            background: linear-gradient(0deg, rgba(99, 120, 255, 0.08), rgba(99, 120, 255, 0.08)), #ffffff;
            border-radius: 30px;
            padding: 12px 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            border: 2px solid transparent;
            color: var(--activation_text);
            cursor: pointer;
            min-height: auto;
            text-transform: none;
            isolation: isolate;

            // border: 1px solid var(--RippleBg_color2);
            .MuiTouchRipple-root {
                z-index: -1;

                .MuiTouchRipple-child {
                    background-color: var(--RippleBg_color2);
                }
            }

            &.active {
                border: 2px solid var(--parple);
                color: var(--parple);
                font-weight: 500;
            }
        }
    }

    .errorText {
        width: auto;
        margin-inline-end: auto;

        p {
            padding-inline-start: 0px;
            padding-block-start: 5px;
            color: var(--danger);
            font-size: 12px;
            line-height: 16px;
            font-family: var(--font-stack);
            font-weight: 400;
            text-align: start;
        }
    }
}

.signUpAnimationContainer {
    .signUpLottieContainer {
        width: 175px;
        margin-inline: auto;
    }

    .textContainer {
        .text {
            font-size: 28px;
            font-weight: 500;
            text-align: center;
        }
    }
}
