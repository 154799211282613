@use '../../../Style.scss' as *;

.myClassGroupView {
    width: 100%;
    height: 425px;
    padding-block: 20px 10px;
    padding-inline: 22px 20px;
    overflow: auto;

    &.sidebarHidden {
        padding-inline: 62px 40px;
    }
}
