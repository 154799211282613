// Gradient
@mixin gradientText($gradient) {
    background: $gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

// activationbtn
@mixin activationbtn {
    height: auto;
    width: 188px;
    min-width: 188px;
    line-height: 1.75 !important;
    color: var(--textWhite);
    padding: 6px 14px !important;
    font-size: 16px;
    text-transform: none;
    border-radius: 0.7vh;
    box-shadow: none !important;
    cursor: pointer;
    border-radius: 40px;
}

@mixin leaderBoardbtn {
    height: auto;
    width: 52px;
    min-width: 52px;
    line-height: 1.75 !important;
    color: var(--textWhite);
    padding: 5px !important;
    font-size: 16px;
    text-transform: none;
    border-radius: 50%;
    cursor: pointer;
    border-radius: 40px;
    min-height: 52px;
}

@mixin table_view_btn {
    height: auto;
    width: auto;
    min-width: auto;
    line-height: 1.1 !important;
    color: var(--textWhite);
    padding: 6px 6px !important;
    font-size: 14px;
    text-transform: none;
    border-radius: 0.7vh;
    box-shadow: none !important;
    cursor: pointer;
    box-shadow: none !important;
    border-radius: 4px;
    min-height: 28px;
    font-weight: 400;
}

@mixin autopick {
    height: auto;
    width: 132px;
    line-height: 19px !important;
    color: var(--textWhite);
    padding: 6px 5px !important;
    font-size: 14px;
    text-transform: none;
    min-width: auto !important;
    border-radius: 0.7vh;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
    cursor: pointer;
    box-shadow: none !important;
    min-height: 36px;
}

@mixin luckybtn {
    height: 43px;
    width: 154px;
    line-height: 16px !important;
    color: var(--textWhite);
    padding: 10px 5px !important;
    font-size: 16px;
    text-transform: none;
    min-width: auto !important;
    border-radius: 0.7vh;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
    cursor: pointer;
    box-shadow: none !important;
    border-radius: 40px;
}

@mixin quickpool {
    font-size: 26px;
    font-weight: 500;
    color: var(--cpbutton);
    line-height: 20px;
    background-color: transparent;
    padding: 10px;
    border: 2.5px solid var(--cpbutton);
    border-radius: 100px;
    outline: 0;
    transition: 0.1s all linear;
    cursor: pointer;
    font-family: var(--font-stack);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 88px;
    min-height: 50px;
}

@mixin starBtn {
    width: auto;
    min-width: 188px;
    min-height: 40px;
    background-color: var(--parple);
    border-radius: 40px;
    cursor: pointer;
    -webkit-transition: 0.1s ease-out;
    -moz-transition: 0.1s ease-out;
    -o-transition: 0.1s ease-out;
    transition: 0.1s ease-out;
    isolation: isolate;
    color: var(--textWhite);
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
    position: relative;
    padding: 4px;
}

@mixin chatbot_btn {
    width: auto;
    // min-width: 188px;
    min-height: 40px;
    background-color: var(--btnbgcolor);
    border-radius: 40px;
    cursor: pointer;
    isolation: isolate;
    color: var(--textWhite);
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-transform: none;
    position: relative;
    padding: 4px 15px;
    box-shadow: none;

    &:hover {
        background-color: var(--btnhover);
    }

    &.Mui-disabled {
        opacity: 0.5;
        cursor: default;
        color: var(--textWhite);
    }
}

@mixin chatbot_default_btn {
    width: auto;
    min-width: 203px;
    min-height: 45px;
    background: none;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    isolation: isolate;
    color: var(--parple);
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    text-transform: none;
    position: relative;
    padding: 4px 32px;
    box-shadow: none;

    &:hover {
        background: var(--cpButton_20);
    }

    &.Mui-disabled {
        opacity: 0.5;
        cursor: default;
        color: var(--parple);
    }
}

@mixin head_h2() {
    font-size: 4vh;
    line-height: 5vh;
    font-weight: 500;
}

@mixin head_h3() {
    font-size: 3vh;
    line-height: 3.5vh;
}

@mixin head_h33() {
    font-size: 3.5vh;
    line-height: 3.5vh;
}

@mixin head_h4() {
    font-size: 2.7vh;
    line-height: 3.2vh;
}

@mixin head_h5() {
    font-size: 2.5vh;
    line-height: 3vh;
}

@mixin head_h6() {
    font-size: 2.4vh;
    line-height: 2.9vh;
}

@mixin para_a() {
    font-size: 2.4vh;
    line-height: 2.8vh;
}

@mixin para_p() {
    font-size: 2.4vh;
    line-height: 2.5vh;
}

@mixin para_p2() {
    font-size: 2.4vh;
    line-height: 3vh;
}

@mixin chip_size() {
    font-size: 2vh;
    line-height: 2.5vh;
}

@mixin span() {
    font-size: 2vh;
    line-height: 2.5vh;
}

@mixin input_size() {
    font-size: 3.1vh;
    line-height: 3.1vh;
}

@mixin theme_btn() {
    width: auto;
    padding: 1.5vh 10vh !important;
    text-transform: initial;
    font-size: 2.8vh;
    height: auto !important;
    min-width: auto !important;
    line-height: 1.5 !important;
    border-radius: 4px;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
    min-height: 35px !important;
}

@mixin welcome_btn() {
    width: auto;
    padding: 3px 18px !important;
    text-transform: initial;
    font-size: 14px;
    height: auto !important;
    min-width: 180px !important;
    line-height: 1.5 !important;
    border-radius: 40px;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
    min-height: 40px !important;
    color: var(--textWhite);
}

@mixin updatebtn_btn() {
    width: auto;
    padding: 2.2vh 9vh !important;
    text-transform: initial;
    font-size: 2.8vh;
    height: auto !important;
    min-width: auto !important;
    line-height: 1.5 !important;
    min-height: auto !important;
    border-radius: 0.7vh;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
}

@mixin error_bound_btn() {
    width: auto;
    padding: 1.8vh 14vh !important;
    text-transform: initial;
    font-size: 2.8vh;
    height: auto !important;
    min-width: auto !important;
    line-height: 1.5 !important;
    min-height: auto !important;
    border-radius: 0.7vh;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
}

@mixin active_btn() {
    width: 39vh;
    padding: 1.5vh !important;
    text-transform: initial;
    font-size: 2.6vh;
    height: auto !important;
    min-width: auto !important;
    line-height: 1.5 !important;
    min-height: auto !important;
    border-radius: 0.7vh;
    box-shadow: none !important;
    font-family: var(--font-stack);
    font-weight: 500;
}

@mixin watch_tutorial {
    background: var(--parple);
    color: var(--textWhite);
    width: auto;
    height: auto;
    border-radius: 50px;
    box-shadow: none;
    font-family: var(--inter);
    font-size: 14px;
    font-weight: 500;
    padding: 6px 10px;
    margin-inline: auto;
    margin-block: 0;
    box-shadow: none !important;
    text-transform: capitalize;
    line-height: 14px;
}

@mixin step_btn() {
    background: var(--parple);
    color: var(--textWhite);
    width: auto;
    height: auto;
    border-radius: 4px;
    box-shadow: none;
    font-family: var(--font-stack);
    font-size: 14px;
    font-weight: 500;
    padding: 11px 40px;
    margin-inline: auto;
    margin-block: 0;
    box-shadow: none !important;
    text-transform: capitalize;
    line-height: 20px;
    min-height: 40px;
}

@mixin green_sub_btn() {
    background: var(--green_btn);
    color: var(--green_btn_text);
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: none;
    font-family: var(--font-stack);
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    margin-inline: auto;
    margin-block: 0;
    box-shadow: none !important;
    text-transform: inherit;
    line-height: 17px;
    min-height: 40px;
}

@mixin helper_btn() {
    width: 100%;
    padding: 6px !important;
    text-transform: initial;
    font-family: var(--font-stack);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    height: auto !important;
    min-width: auto !important;
    border-radius: 4px;
    box-shadow: none !important;
    min-height: 32px !important;
    max-width: 235px;
}

@mixin movable_btn() {
    width: 100%;
    padding: 6px !important;
    text-transform: initial;
    font-family: var(--font-stack);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    height: auto !important;
    min-width: auto !important;
    border-radius: 0px;
    box-shadow: none !important;
    min-height: 48px !important;
}

@mixin whiteBoard_landingpage_btn() {
    width: 100%;
    padding: 6px !important;
    text-transform: initial;
    font-family: var(--font-stack);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    height: auto !important;
    min-width: auto !important;
    border-radius: 16px;
    box-shadow: none !important;
    min-height: 120px !important;
    max-width: 180px;
    color: var(--activation_text);
}

@mixin profile_update_btn() {
    width: 100%;
    padding: 8px 11px !important;
    text-transform: initial;
    font-family: var(--font-stack);
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    height: auto !important;
    min-width: auto !important;
    border-radius: 4px;
    box-shadow: none !important;
    min-height: 32px !important;
    max-width: 180px;
    color: var(--textWhite);
}

@mixin watch_tutorial_btn {
    width: 100%;
    padding-block: 2px !important;
    padding-inline: 3px 8px !important;
    text-transform: initial;
    font-family: var(--font-stack);
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    height: auto !important;
    min-width: auto !important;
    border-radius: 30px;
    box-shadow: none !important;
    min-height: 28px !important;
    max-width: 125px;
    color: var(--parple);
    border: 1px solid var(--parple);
    white-space: nowrap;
}

@mixin timmer_btn() {
    width: 100%;
    padding: 6px;
    text-transform: initial;
    font-family: var(--font-stack);
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    height: auto;
    min-width: auto;
    border-radius: 40px;
    box-shadow: none;
    min-height: 32px;
    max-width: 100px;
    color: var(--textWhite);
}

@mixin shopeBtn() {
    background: var(--Shoppee_bg);
    color: var(--textWhite);
    width: auto;
    height: auto;
    border-radius: 6px;
    box-shadow: none;
    font-family: var(--font-stack);
    font-size: 14px;
    font-weight: 500;
    padding: 4px 22px;
    margin-inline: auto;
    margin-block: 0;
    box-shadow: none !important;
    text-transform: capitalize;
    line-height: 19px;
    min-height: 40px;
    gap: 8px;

    & > * {
        color: var(--textWhite);
        font-family: var(--font-stack);
        font-size: 14px;
        line-height: 19px;
        font-weight: 500;
    }
}

@mixin answer_mark_btn() {
    background: var(--background-white);
    color: var(--activation_text);
    // width: auto;
    height: 28px;
    min-width: 110px;
    min-height: 28px;
    border-radius: 20px;
    padding: 0px 10px;
    box-shadow: none !important;
    text-transform: none;
    font-family: var(--font-stack);
    font-size: 12px;
    font-weight: var(--fw_medium);
    border: 1px solid var(--border_01);
    gap: 3px;

    & > * {
        color: var(--textWhite);
        font-family: var(--font-stack);
        font-size: 11px;
        font-weight: var(--fw_medium);
    }

    &:hover {
        color: var(--textWhite);
        background: var(--icon_green);
        border: 1px solid var(--icon_green);
    }
}

@mixin dismiss_btn() {
    background: var(--background-white);
    color: var(--activation_text);
    width: auto;
    height: 28px;
    min-height: 28px;
    border-radius: 20px;
    padding: 0px 10px;
    box-shadow: none !important;
    text-transform: none;
    font-family: var(--font-stack);
    font-size: 12px;
    font-weight: var(--fw_medium);
    border: none;
    gap: 6px;

    & > * {
        color: var(--activation_text);
        font-family: var(--font-stack);
        font-size: 11px;
        font-weight: var(--fw_medium);
    }
    &:hover {
        background: var(--bg_clr_gray_10);
    }
}
// Flexbox
@mixin displayFlex($dir, $align, $justify) {
    display: flex;
    flex-direction: $dir;
    align-items: $align;
    justify-content: $justify;
}

// Media Query
@mixin min($screen) {
    @media (min-width: $screen) {
        @content;
    }
}

@mixin max($screen) {
    @media (max-width: $screen) {
        @content;
    }
}
